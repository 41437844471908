import {ExperiencesHostVideo} from '@/features/experiences-host-video/types.ts'

export const steps = {
    intro: ['intro'],
    preparation: [
        'intro',
        'require_prepping_space',
        'you_unique',
        'experience_unique',
        'highlights',
        'take_anything_home',
        'get_ready_to_shoot'
    ],
    ready_to_film: ['intro', 'specs', 'perspective', 'get_help', 'schedule'],
    upload: [
        'intro',
        'video_upload',
        'voiceover_language',
        'voiceover_guidelines',
        'name_quick_intro',
        'unique_guests_can_do',
        'record_voiceover_unique',
        'record_voiceover_highlights'
    ],
    completed: ['completed']
} as const

export type Step = (typeof steps)[keyof typeof steps][number]

const calculatePercentage = (index: number, stepsLength: number) => ((index + 1) / stepsLength) * 100

type ProgressResult = [number, number, number]
export const stepToProgress = (
    status: ExperiencesHostVideo['status']['key_name'],
    step: ExperiencesHostVideo['status']['step_key_name']
): ProgressResult => {
    const preparationIndex = steps['preparation'].findIndex(preparationStep => preparationStep == step)
    if (status == 'preparation') {
        return [calculatePercentage(preparationIndex, steps['preparation'].length), 0, 0]
    }

    const readyToFilmIndex = steps['ready_to_film'].findIndex(readyToFilmStep => readyToFilmStep == step)
    if (status == 'ready_to_film') {
        return [100, calculatePercentage(readyToFilmIndex, steps['ready_to_film'].length), 0]
    }

    const uploadIndex = steps['upload'].findIndex(uploadStep => uploadStep == step)
    if (status == 'upload') {
        return [100, 100, calculatePercentage(uploadIndex, steps['upload'].length)]
    }

    return [0, 0, 0]
}
