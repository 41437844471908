import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {SlidingModalCloseButton, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    ExperiencesHostVideoModalBody,
    ExperiencesHostVideoParagraph
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Checklist} from '@/features/experiences-host-video/components/checklist/Checklist.tsx'
import {Question} from '@/features/experiences-host-video/types.ts'
import {StyledChecklistModal, StyledTitle} from '@/features/experiences-host-video/components/checklist-modal/style.ts'
import {ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'

interface ChecklistModalProps {
    answers: Question[]
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const ChecklistModal: FC<ChecklistModalProps> = ({answers, slidingModalState, setSlidingModalState}) => {
    const {t} = useTranslation()

    return (
        <StyledChecklistModal slidingModalState={slidingModalState}>
            <StyledTitle>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                <ModalTitle>{t('experiences_host_video:checklist')}</ModalTitle>
            </StyledTitle>
            <ExperiencesHostVideoModalBody>
                <ExperiencesHostVideoParagraph>
                    {t('experiences_host_video:get_ready_shoot:description')}
                </ExperiencesHostVideoParagraph>
                <Checklist haveCheckbox answers={answers} />
            </ExperiencesHostVideoModalBody>
        </StyledChecklistModal>
    )
}
