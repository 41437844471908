import styled, {css} from 'styled-components'

export const StyledStatusDescriptionParagraph = styled.p<{centered?: boolean}>(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['900']};

        a {
            cursor: pointer;
            text-decoration: underline;
        }
    `
)
