import styled, {css} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'

export const StyledPhotoStatsContainer = styled(Container)<{backgroundImg?: string}>`
    ${({theme: {mediaQueries}, backgroundImg}) => css`
        background: url(${backgroundImg}) left 30px no-repeat;
        background-size: cover;
        background-origin: content-box;
        ${mediaQueries.m} {
            background-position: bottom;
            background-size: revert;
        }
    `}
`

export const StyledPhotoStatsWrapper = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        padding: ${spacing * 20}px 0;
        gap: ${spacing * 20}px;
        & > h2 {
            ${typography.displaySm};
            color: ${palette.neutral['900']};
            font-weight: 700;
        }
        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
            gap: ${spacing * 16}px;
        }
    `}
`

export const StyledPhotoStatsBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: ${spacing * 12}px;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `}
`
