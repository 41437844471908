import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {httpSendReviewedApproval} from '@/features/host-approval/services/hostApproval.http.ts'

type useSendReviewedApprovalProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useSendReviewedApproval = (options?: useSendReviewedApprovalProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.SEND_REVIEWED_APPROVAL],
        mutationFn: httpSendReviewedApproval,
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            options?.onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.APPROVAL_REQUEST_DETAILS]})
        }
    })
}
