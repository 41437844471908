import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/modal/Modal.tsx'

export const StyledModal = styled(Modal)(
    ({theme: {spacing, typography}}) => css`
        display: grid;
        grid-template-rows: minmax(0, 1fr) auto;
        height: 900px;
        padding-top: ${spacing * 13}px;

        & .rating-label {
            ${typography.textSm}
        }
        & .textarea {
            padding-bottom: ${spacing * 6}px;
        }
    `
)

export const StyledContainer = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px 0;
    `
)

export const StyledTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl}
        color: ${palette.neutral['900']};
        font-weight: 500;
    `
)

export const StyledSubTitle = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral['900']};
    `
)

export const StyledQuestion = styled.h5(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd}
        color: ${palette.neutral['900']};
        font-weight: 500;
    `
)

export const StyledFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['300']};
    `
)
