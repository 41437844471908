import {FC} from 'react'
import {DateChip} from './DateChip'
import {StyledDateChips} from './style'
import {HostDates} from './types'

interface HostDateChipsProps {
    selectedDates: HostDates
    removeDate: (value: number) => void
}

export const HostDateChips: FC<HostDateChipsProps> = ({selectedDates, removeDate}) => {
    return (
        <StyledDateChips gap={2}>
            {selectedDates
                .filter((_, index) => index < selectedDates.length)
                .map((selectedDate, index) => (
                    <DateChip
                        key={selectedDate.toISOString()}
                        selectedDate={selectedDate}
                        onClose={() => removeDate(index)}
                    />
                ))}
        </StyledDateChips>
    )
}
