import {useTranslation} from 'react-i18next'
import {
    StyledCancelStepDesc,
    StyledCancelStepFooter,
    StyledCancelStepHeader,
    StyledNoResults
} from '@/features/service-cancel/components/service-cancellation-process/style.ts'
import Button from '@components/ui/button-legacy/Button.tsx'
import {useServiceCancellationStore} from '@/features/service-cancel/store/serviceCancellationStore.tsx'
import {useOutletContext} from 'react-router-dom'
import {Radio} from '@components/commons/radio/Radio.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {useForm} from 'react-hook-form'
import {ReasonsFormLoadingSkeleton} from '@/features/service-decline/utils.tsx'
import {zodResolver} from '@hookform/resolvers/zod'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ServiceCancellationSchema} from '@/features/service-cancel/types.ts'
import {useServiceCancellationReasons} from '@/features/service-cancel/services/queries/useServiceCancellationReasons.ts'
import {useServiceCancellation} from '@/features/service-cancel/services/queries/useServiceCancellation.ts'
import {errorHandler, getServiceType} from '@utilities/helpers.ts'
import {
    cancelStepsConfigByService,
    removeCancellationReasonPrefix,
    serviceTypeToCancellationReason
} from '@/features/service-cancel/utils.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon} from '@components/ui/icon'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {CANCEL_OTHER_REASON_ID} from '@utilities/constants'

export const CancelServiceFinalStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const serviceToCancel = useServiceCancellationStore(state => state.service)
    const serviceType = serviceToCancel
        ? getServiceType(serviceToCancel.task_media.name, serviceToCancel.task_category.name)
        : 'home'
    const cancelServiceModalToggle = useServiceCancellationStore(state => state.cancellationServiceModalToggle)
    const reasonsQuery = useServiceCancellationReasons({
        task_media_name: serviceToCancel?.task_media.name ?? 'photo',
        by: 'host'
    })
    //cancel service mutation
    const cancelServiceMutation = useServiceCancellation({
        onSuccess: cancelServiceModalToggle,
        onError: error => errorHandler(error)
    })
    //reason form model and submit handler
    const {
        formState: {isValid},
        watch,
        register,
        handleSubmit
    } = useForm<ServiceCancellationSchema>({
        resolver: zodResolver(ServiceCancellationSchema),
        defaultValues: {
            notes: ''
        }
    })

    const onSubmit = (data: ServiceCancellationSchema) => {
        cancelServiceMutation.mutate({
            ...urlParams,
            notes: data.notes,
            reason_id: data.reason_id
        })
    }

    return (
        <>
            <StyledCancelStepHeader>
                {t(cancelStepsConfigByService['warning'][serviceType].modal_title)}
            </StyledCancelStepHeader>
            <ScrollArea scrollbar={<Scrollbar />}>
                <Flexbox direction={'column'} gap={6} padding={6}>
                    <StyledCancelStepDesc direction={'column'} gap={2}>
                        <h5>{t(cancelStepsConfigByService['final'][serviceType].title)}</h5>
                        <small>{t(cancelStepsConfigByService['final'][serviceType].subtitle)}</small>
                    </StyledCancelStepDesc>
                    <Flexbox as="form" direction="column" gap={4} onSubmit={handleSubmit(onSubmit)}>
                        {reasonsQuery.isPending ? (
                            <ReasonsFormLoadingSkeleton />
                        ) : reasonsQuery.isError ? (
                            <ErrorBox title={t('errors:default')} icon={<AlertTriangleIcon />} />
                        ) : reasonsQuery.data.length == 0 ? (
                            <StyledNoResults>{t('service_cancel:no_results')}</StyledNoResults>
                        ) : (
                            <>
                                {reasonsQuery.data.map(reason => (
                                    <Radio
                                        key={reason.id}
                                        value={reason.id}
                                        label={t(
                                            serviceTypeToCancellationReason[serviceType][
                                                removeCancellationReasonPrefix(reason.key_name)
                                            ]
                                        )}
                                        labelPosition={'right'}
                                        {...register('reason_id')}
                                    />
                                ))}
                                {watch('reason_id') == CANCEL_OTHER_REASON_ID && (
                                    <TextArea
                                        placeholder={t('service_cancel:final:textarea_placeholder')}
                                        className="notes-textarea"
                                        {...register('notes')}
                                        label={t(`commons:reason`)}
                                    />
                                )}
                            </>
                        )}
                    </Flexbox>
                </Flexbox>
            </ScrollArea>
            <StyledCancelStepFooter justify={'space-between'}>
                <Button variant={'tertiary'} size={'md'} onClick={() => cancelServiceModalToggle()}>
                    {t(cancelStepsConfigByService['final'][serviceType].secondary_cta)}
                </Button>
                <Button
                    variant={'primary'}
                    size={'md'}
                    onClick={handleSubmit(onSubmit)}
                    disabled={cancelServiceMutation.isPending || !isValid || reasonsQuery.isPending}
                >
                    {t(cancelStepsConfigByService['final'][serviceType].primary_cta)}
                    {cancelServiceMutation.isPending && <Spinner size={20} />}
                </Button>
            </StyledCancelStepFooter>
        </>
    )
}

CancelServiceFinalStep.displayName = 'CancelModalFinalStep'
