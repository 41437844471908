import {FC, ReactNode, useRef} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledColumns,
    StyledTitle,
    StyledVideo
} from '@/features/experiences-host-video/components/perspective-type/style.ts'
import {useVideoController} from '@/features/experiences-host-video/hooks/useVideoController.ts'

interface PerspectiveTypeProps {
    title: ReactNode
    description: string
    leftCardTitle: ReactNode
    leftCardVideoSrc: string
    leftCardPosterSrc: string
    rightCardTitle: ReactNode
    rightCardVideoSrc: string
    rightCardPosterSrc: string
}
export const PerspectiveType: FC<PerspectiveTypeProps> = ({
    title,
    description,
    leftCardTitle,
    leftCardVideoSrc,
    leftCardPosterSrc,
    rightCardTitle,
    rightCardVideoSrc,
    rightCardPosterSrc
}) => {
    const leftVideoRef = useRef<HTMLVideoElement>(null)
    const rightVideoRef = useRef<HTMLVideoElement>(null)
    const leftVideoController = useVideoController(leftVideoRef, {initialVideoState: 'playing', defaultMuted: true})
    const rightVideoController = useVideoController(rightVideoRef, {initialVideoState: 'playing', defaultMuted: true})

    return (
        <Flexbox direction="column" gap={4}>
            <Flexbox direction="column" gap={1}>
                <StyledTitle>{title}</StyledTitle>
                <p>{description}</p>
            </Flexbox>
            <StyledColumns>
                <Flexbox direction="column" gap={2}>
                    <h4>{leftCardTitle}</h4>
                    <StyledVideo
                        ref={leftVideoRef}
                        poster={leftCardPosterSrc}
                        loop
                        playsInline
                        disableRemotePlayback
                        disablePictureInPicture
                        {...leftVideoController}
                    >
                        <source src={leftCardVideoSrc} />
                    </StyledVideo>
                </Flexbox>
                <Flexbox direction="column" gap={2}>
                    <h4>{rightCardTitle}</h4>
                    <StyledVideo
                        ref={rightVideoRef}
                        poster={rightCardPosterSrc}
                        loop
                        playsInline
                        disableRemotePlayback
                        disablePictureInPicture
                        {...rightVideoController}
                    >
                        <source src={rightCardVideoSrc} />
                    </StyledVideo>
                </Flexbox>
            </StyledColumns>
        </Flexbox>
    )
}
