import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

type StyledTimePreferenceButtonProps = {
    isSelected: boolean
}

export const StyledHostDatePickerHeader = styled(Flexbox)(
    ({theme: {palette, typography, spacing, mediaQueries}}) => css`
        padding: ${spacing * 8}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        h2 {
            color: ${palette.neutral.black};
            font-size: ${typography.textXl};
            font-weight: 700;
        }
        p {
            font-size: ${typography.textSm};
        }
        ${mediaQueries.m} {
            padding: ${spacing * 6}px ${spacing * 4}px;
        }
    `
)

export const StyledHostDatePickerGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        padding: ${spacing * 4}px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
            'selectedDates selectedDates selectedDates selectedDates selectedDates'
            'calendar calendar calendar preferences preferences';
        gap: ${spacing * 2}px ${spacing * 8}px;
        ${mediaQueries.m} {
            grid-gap: ${spacing * 2}px;
            grid-template-columns: 1fr;
            grid-template-areas:
                'calendar calendar'
                'selectedDates selectedDates'
                'preferences preferences';
        }
    `
)

export const StyledHostDatePickerSelected = styled(Flexbox)(
    ({theme: {palette, spacing, mediaQueries}}) => css`
        grid-area: selectedDates;
        padding: ${spacing * 4}px;
        min-height: 64px;
        span {
            color: ${palette.neutral.black};
        }
        ${mediaQueries.m} {
            padding: ${spacing * 4}px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: ${spacing * 2}px;
        }
    `
)

export const StyledHostDatePickerCalendarWrapper = styled.div`
    grid-area: calendar;
`

export const StyledHostDatePickerAside = styled(Flexbox)`
    grid-area: preferences;
`

export const StyledHostDatePickerFooter = styled(Flexbox)(
    ({theme: {palette, spacing, mediaQueries}}) => css`
        padding: ${spacing * 5}px ${spacing * 8}px;
        margin-top: ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral['200']};
        ${mediaQueries.m} {
            flex-direction: column-reverse;
            gap: ${spacing * 2}px;
            button {
                width: 100%;
            }
        }
    `
)

export const StyledCurrentDate = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral.black};
        font-size: ${typography.textLg};
    `
)

export const StyledHostDatePickerLimitReached = styled(Flexbox)(
    ({theme: {mediaQueries, typography}}) => css`
        font-size: ${typography.textMd} ${mediaQueries.m} {
            padding: 0px;
        }
    `
)

export const StyledTimePreferenceButton = styled.div<StyledTimePreferenceButtonProps>`
    ${({theme: {palette, typography}, isSelected}) => css`
        display: block;
        width: 100%;
        cursor: pointer;
        font-size: ${typography.textSm};
        color: ${palette.primary['600']};
        text-align: center;
        border-radius: 8px;
        padding: 10px;
        font-weight: 700;
        box-shadow: inset 0 0 0 ${isSelected ? `2px ${palette.primary['600']}` : `1px ${palette.neutral['200']}`};
        background-color: ${isSelected ? palette.primary['50'] : 'transparent'};
        transition: ease-in-out 200ms;
        ${!isSelected &&
        `
            &:hover {
                box-shadow: inset 0 0 0 1px ${palette.primary['600']};
            }
        `}
    `}
`

export const StyledDateChips = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            flex-direction: column;
        }
    }
`
)

export const StyledDateChip = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        font-size: ${typography.textXs};
        line-height: 22px;
        gap: ${spacing * 2}px;
        font-weight: 700;
        color: ${palette.neutral.black};
        border: 1px solid ${palette.neutral['200']};
        padding: ${spacing}px ${spacing * 2}px;
        border-radius: 16px;
        button {
            display: inline-flex;
            justify-content: space-between;
            cursor: pointer;
            &:hover {
                justify-content: space-between;
                color: ${palette.primary['600']};
            }
        }
    `
)

export const StyledShareHostDatesSuccessBody = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral['900']};
        text-align: center;
        svg {
            fill: ${palette.warning['600']};
        }
        h2 {
            ${typography.textXl};
            font-weight: 500;
        }
        p {
            ${typography.textSm};
        }
    `
)

export const StyledShareHostDatesSuccessFooter = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 5}px;
        border-top: 1px solid ${palette.neutral[200]};
    `
)
