import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {UploadClipsDropzone} from '@/features/experiences-host-video/components/upload-clips-dropzone/UploadClipsDropzone.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {useEffect} from 'react'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'

export const UploadClips = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const setVideosetId = useClipsUpload(store => store.setVideosetId)
    const videosetId = useClipsUpload(store => store.videosetId)
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})
    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const uploadedFiles = useClipsUpload(store => store.uploadedFiles)

    useEffect(() => {
        if (showExperiencesHostVideoQuery.data && showExperiencesHostVideoQuery.data?.videoset) {
            setVideosetId(showExperiencesHostVideoQuery.data?.videoset.id)
        }
    }, [showExperiencesHostVideoQuery.isSuccess])

    return (
        <>
            <ExperiencesHostVideoBody gap={6}>
                <ExperiencesHostVideoTitle>{t('experiences_host_video:upload_clip:title')}</ExperiencesHostVideoTitle>
                {showExperiencesHostVideoQuery.isPending && !videosetId ? (
                    <Spinner size={48} />
                ) : (
                    <UploadClipsDropzone />
                )}
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'upload', step: 'intro'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'upload',
                                    step: 'voiceover-language'
                                })
                            }
                            disabled={
                                continueUpdateStepMutation.isPending ||
                                backUpdateStepMutation.isPending ||
                                uploadedFiles.length < 1
                            }
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
