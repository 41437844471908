import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {StyledChatbotResources} from '@/features/chatbot/components/styles.ts'
import i18next from 'i18next'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {ServiceResourcesInfo} from '@/features/service-resources/utils.ts'

export const hp6 = ({backToGeneric}: {backToGeneric?: boolean}): ChatbotStep[] => [
    {
        id: 'HP6',
        options: [
            {
                value: 'HP6.1',
                label: i18next.t('chatbot:HP6:HP6.1_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'How do I prepare for the photoshoot?'
                    })
                    return 'HP6.1'
                }
            },
            {
                value: 'HP6.2',
                label: i18next.t('chatbot:HP6:HP6.2_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'How long will the photoshoot last?'
                    })
                    return 'HP6.2'
                }
            },
            {
                value: 'HP6.3',
                label: i18next.t('chatbot:HP6:HP6.3_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'Will the photographer help style?'
                    })
                    return 'HP6.3'
                }
            },
            {
                value: 'HP6.4',
                label: i18next.t('chatbot:HP6:HP6.4_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'Can I tell the photographer what I want them to capture?'
                    })
                    return 'HP6.4'
                }
            },
            {
                value: 'HP6.5',
                label: i18next.t('chatbot:HP6:HP6.5_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'Do I need to be present during the photoshoot?'
                    })
                    return 'HP6.5'
                }
            },
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP6',
                        source: 'User',
                        text: 'Other'
                    })
                    return backToGeneric ? 'generic_flows_dash_options' : 'start'
                }
            }
        ]
    },
    /*{
        id: "HP6.1",
        message: i18next.t("chatbot:HP6:HP6.1_message"),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: "HP6.1",
                source: "Chatbot",
                text: "Make sure your listing is tidy and clutter-free before the shoot. We also recommend reviewing this Help article to know more about styling:"
            });
            return "HP6.1.1";
        }
    },*/
    {
        id: 'HP6.1',
        component: (
            <StyledChatbotResources target={'_blank'} to={ServiceResourcesInfo.host_prep.link}>
                <img src={ServiceResourcesInfo.host_prep.image} alt={'host prep'} />
                <p>{i18next.t(ServiceResourcesInfo.host_prep.linkText)}</p>
            </StyledChatbotResources>
            /* <StyledChatbotLink target={'_blank'} to="https://www.airbnb.com/d/pro-photo-guide">
                 {i18next.t('chatbot:HP6:HP6.1.1_link')}
             </StyledChatbotLink>*/
        ),
        asMessage: true,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP6.1.1',
                source: 'Chatbot',
                text: '[LINK] Airbnb Pro-Photo Guide'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP6.2',
        message: i18next.t('chatbot:HP6:HP6.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP6.2',
                source: 'Chatbot',
                text: 'The duration of the photoshoot will depend on the size of your space and how well-prepared you are. On average, a photoshoot lasts between 1 and 2 hours. We ask that your house is photoshoot-ready by 8:00 AM the day of the scheduled appointment.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    /*{
        id: "HP6.3",
        message: i18next.t("chatbot:HP6:HP6.3_message"),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: "HP6.3",
                source: "Chatbot",
                text: "The photographer's role is to take the photos. They may assist in adjusting pillows or turning off lights. The house must be photo-ready when they arrive. This will ensure that the photographer has enough time allotted to capture all necessary photos. We also recommend reviewing this Help article to know more about styling:"
            });
            return "6.3.1";
        }
    },*/
    {
        id: '6.3',
        component: (
            <StyledChatbotResources target={'_blank'} to={ServiceResourcesInfo.host_prep.link}>
                <img src={ServiceResourcesInfo.host_prep.image} alt={'host prep'} />
                <p>{i18next.t(ServiceResourcesInfo.host_prep.linkText)}</p>
            </StyledChatbotResources>
            /* <StyledChatbotLink target={"_blank"} to="https://www.airbnb.com/d/pro-photo-guide">
                {i18next.t("chatbot:HP6:HP6.3.1_link")}
            </StyledChatbotLink>*/
        ),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: '6.3.1',
                source: 'Chatbot',
                text: '[LINK] Airbnb Pro-Photo Guide'
            })
            return 'oq_message'
        }, // Final trigger of the flow's branch
        asMessage: true
    },
    {
        id: 'HP6.4',
        message: i18next.t('chatbot:HP6:HP6.4_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP6.4',
                source: 'Chatbot',
                text: 'We ask our photographers to capture 2-3 images of each room offered in the listing. If there are areas that you want to emphasize, let your photographer know in advance.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP6.5',
        message: i18next.t('chatbot:HP6:HP6.5_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP6.5',
                source: 'Chatbot',
                text: "You do not have to be present but you'll need to make sure the photographer has the ability to access your listing. Please also be sure to communicate any special requests to the photographer prior to the photoshoot. We ask that your house is photoshoot-ready by 8:00 AM the day of the scheduled appointment."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
