import styled, {css} from 'styled-components'
import {
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoModalBody
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'

export const StyledFriendOnboardingModalIntroStep = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
`

export const StyledFriendOnboardingModalIntroStepBody = styled(ExperiencesHostVideoModalBody)(
    ({theme: {spacing}}) => css`
        align-self: center;
        gap: ${spacing * 8}px;
        text-align: center;

        & img {
            align-self: center;
            width: 300px;
            height: 300px;
        }
    `
)

export const StyledFriendOnboardingModalIntroStepFooter = styled(ExperiencesHostVideoFooter)(
    ({theme: {palette}}) => css`
        border-top: 1px solid ${palette.neutral['300']};
    `
)
