import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {
    httpSendCoAlarmRequest,
    HttpSendCoAlarmRequestOptions,
    HttpSendCoAlarmRequestResponse
} from '@/features/co-alarm/services/coAlarm.http'
import {ErrorResponseData} from '@/types/commons.ts'
import {AxiosError} from 'axios'
import {captureException} from '@sentry/react'

type UseSendCoAlarmRequest = {
    onSuccess?: (response: HttpSendCoAlarmRequestResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useSendCoAlarmRequest = (options?: UseSendCoAlarmRequest) => {
    return useMutation<HttpSendCoAlarmRequestResponse, AxiosError<ErrorResponseData>, HttpSendCoAlarmRequestOptions>({
        ...options,
        mutationKey: [MUTATION_KEYS.SEND_CO_ALARM_REQUEST],
        mutationFn: httpSendCoAlarmRequest,
        onSuccess: async (response: HttpSendCoAlarmRequestResponse) => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.CO_ALARM_LISTINGS]})
            options?.onSuccess?.(response)
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            captureException(error.response)
            options?.onError?.(error)
        }
    })
}
