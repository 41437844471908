import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {XCloseIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from 'react-i18next'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const WalkthroughCanceledStatusDescription = () => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <XCloseIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:walkthrough:canceled_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraph>
                {t('service_details:descriptions:walkthrough:hard_canceled_paragraph')}
            </StatusDescriptionParagraph>
        </StatusDescriptionWrapper>
    )
}

WalkthroughCanceledStatusDescription.displayName = 'WalkthroughCanceledStatusDescription'
