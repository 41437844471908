import {StyledActionCard} from '@/features/service-actions/components/action-card/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC} from 'react'
import {CheckIcon, IconProps, XCloseIcon} from '@components/ui/icon'
import Button from '@components/ui/button-legacy/Button.tsx'

interface ActionCardProps {
    icon: FC<IconProps>
    isCompleted?: boolean
    onClick?: () => void
    onRemove?: () => void
    paragraph: string
    title: string
}

export const ActionCard: FC<ActionCardProps> = ({icon: Icon, isCompleted, onClick, onRemove, paragraph, title}) => {
    return (
        <StyledActionCard direction="column" padding={4} gap={7} onClick={onClick} isCompleted={isCompleted}>
            {onRemove ? (
                <Button variant="tertiary" size="sm" onClick={onRemove}>
                    <XCloseIcon />
                </Button>
            ) : null}
            {isCompleted ? (
                <Flexbox className="check" width="32px" height="32px" justify="center" align="center">
                    <CheckIcon size={24} />
                </Flexbox>
            ) : (
                <Icon size={28} />
            )}
            <Flexbox direction="column" gap={1}>
                <h3>{title}</h3>
                <p>{paragraph}</p>
            </Flexbox>
        </StyledActionCard>
    )
}
