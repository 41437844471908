import styled, {css} from 'styled-components'

export const CoAlarmSendRequestInfoText = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};

        & > a {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)
