import Container from '@components/ui/container/Container.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import {ChevronLeftIcon} from '@components/ui/icon'
import {getServiceType} from '@utilities/helpers.ts'
import {StyledHero} from '@/features/service/components/service-details-hero/styles.ts'
import {useTranslation} from 'react-i18next'
import {serviceTypeToServiceSubtitle} from '@/features/service/utils.tsx'
import {generatePath, useOutletContext} from 'react-router-dom'
import {FC, useEffect, useState} from 'react'
import {HttpGetServiceResponse} from '@/features/service/services/service.http.ts'
import {ServiceRecap} from '@/features/service/components/service-recap/ServiceRecap.tsx'
import {ServiceDetailsSearchParams, ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink.tsx'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {NotificationConsentModal} from '@/features/service/components/notification-consent-modal/NotificationConsentModal.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {routes} from '@utilities/constants'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'

export const ServiceDetailsHero: FC<{currentService: HttpGetServiceResponse}> = ({currentService}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const {searchParams, setSearchParams} = useParsedSearchParams([], ServiceDetailsSearchParams)
    const serviceType = getServiceType(currentService.task_media.name, currentService.task_category.name)
    //accept service store
    const setService = useServiceAcceptStore(state => state.setService)
    //otp store
    const setOtpService = useOtpStore(state => state.setCurrentService)
    const otpChangeStep = useOtpStore(state => state.changeStep)
    const setOtpCurrentHost = useOtpStore(state => state.setCurrentHost)
    const setNotifyToggleStep = useOtpStore(state => state.setNotifyToggleStep)
    //chatbot store
    const setChatbotService = useChatbotStore(state => state.setService)
    //state
    const [showNotificationConsent, setShowNotificationConsent] = useState(searchParams.isTextNotificationsDefaultOpen)
    //utils
    const textNotificationsActive = currentService.quote.notification_preferences?.send_phone_notification
    const canSeeSmsSettings = currentService.quote.can_see_sms_settings

    useEffect(() => {
        const serviceToSet = {
            ...currentService.quote,
            entity: currentService.entity,
            task_category: currentService.task_category,
            task_media: currentService.task_media,
            task_service: currentService.task_service
        }
        setService(serviceToSet)
        //chatbot
        setChatbotService(currentService)
        //otp
        setOtpCurrentHost(currentService.host)
        setNotifyToggleStep(true)
        setOtpService(serviceToSet)
        //this setter needs to be adapted based on textNotificationsActive
        otpChangeStep('channel_toggle', false)

        //reset
        return () => {
            otpChangeStep('base', false)
            setNotifyToggleStep(false)
        }
    }, [currentService])

    const onCloseConsentModal = () => {
        setShowNotificationConsent(false)
        otpChangeStep('channel_toggle', false)
        if (searchParams.isTextNotificationsDefaultOpen) {
            setSearchParams({isTextNotificationsDefaultOpen: undefined})
        }
    }

    return (
        <>
            {showNotificationConsent && (
                <Modal width={374} onClose={onCloseConsentModal} onOverlayClick={onCloseConsentModal}>
                    <NotificationConsentModal onCloseCb={onCloseConsentModal} />
                </Modal>
            )}

            <StyledHero isGrey={currentService.quote.quote_status != 'cancelled'}>
                <Container className="hero-container">
                    <Flexbox justify="space-between" align="center" gap={5}>
                        <ButtonLink
                            className="dashboard-link"
                            variant="tertiary"
                            size="sm"
                            to={generatePath(routes.DASHBOARD.path, {
                                hostCode: urlParams.hostCode,
                                hostPassword: urlParams.hostPassword
                            })}
                        >
                            <ChevronLeftIcon /> {t('commons:dashboard')}
                        </ButtonLink>
                        {canSeeSmsSettings && (
                            <Button size="sm" variant="secondary" onClick={() => setShowNotificationConsent(true)}>
                                {t('commons:text_notifications', {
                                    value: textNotificationsActive ? t('commons:on') : t('commons:off')
                                })}
                            </Button>
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={1.5}>
                        <h2>
                            <a href={currentService.entity.airbnb_link ?? undefined} target="_blank">
                                {currentService.entity.title ||
                                    t('commons:listing_n', {listingId: currentService.entity.external_id})}
                            </a>
                        </h2>
                        <h3>{t(serviceTypeToServiceSubtitle[serviceType])}</h3>
                    </Flexbox>
                    <ServiceRecap
                        entity={currentService.entity}
                        entityMeta={currentService.entity_meta}
                        quote={currentService.quote}
                        serviceType={serviceType}
                    />
                </Container>
            </StyledHero>
        </>
    )
}
