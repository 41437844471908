import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledActionCardProps = {
    onClick?: () => void
    isCompleted?: boolean
}
export const StyledActionCard = styled(Flexbox)<StyledActionCardProps>(
    ({isCompleted, onClick, theme: {palette, typography, shadows, mediaQueries}}) => css`
        width: 280px;
        border-radius: 16px;
        border-width: 1px;
        border-style: solid;
        box-shadow: ${shadows.md};
        color: ${palette.neutral['900']};
        cursor: ${onClick ? 'pointer' : 'auto'};

        & h3 {
            ${typography.textMd};
            font-weight: 500;
        }

        & p {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }

        & .check {
            background: ${palette.success['600']};
            color: ${palette.neutral.white};
            border-radius: 50%;
        }

        ${isCompleted
            ? css`
                  color: ${palette.success['600']};
                  background: ${palette.success['50']};
                  border-color: ${palette.success['600']};
              `
            : css`
                  background: ${palette.neutral.white};
                  border-color: ${palette.neutral['300']};
              `}

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)
