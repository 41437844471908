import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledQuizModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledModalHeader = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
    `
)
