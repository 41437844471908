import {FC, useState} from 'react'
import {useDatePicker} from '@rehookify/datepicker'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {HostDateSelector} from '@/features/service/components/host-calendar/HostDateSelector.tsx'
import {HostMonthSelector} from '@/features/service/components/host-calendar/HostMonthSelector.tsx'
import {HostYearSelector} from '@/features/service/components/host-calendar/HostYearSelector.tsx'

export type View = 'date' | 'month' | 'year'

export type CalendarProps = {
    numMonths: number
    selectedDates: Date[]
    onDatesChange: (dates: Date[]) => void
    mode: 'single' | 'multiple' | 'range'
    toggle?: boolean
    minDate?: Date
    maxDate?: Date
    locale?: string
    currentDate?: Date
}
export const HostCalendar: FC<CalendarProps> = ({
    numMonths,
    selectedDates,
    onDatesChange,
    mode,
    toggle = false,
    minDate,
    maxDate,
    locale = 'en',
    currentDate
}) => {
    const {
        data: {weekDays, calendars, months, years},
        propGetters: {
            dayButton,
            addOffset,
            subtractOffset,
            monthButton,
            yearButton,
            nextYearsButton,
            previousYearsButton
        }
    } = useDatePicker({
        selectedDates,
        onDatesChange,
        dates: {toggle, mode, minDate, maxDate},
        calendar: {
            startDay: 0,
            offsets: [...Array(numMonths).keys()].slice(1)
        }
    })
    const [view, setView] = useState<View>('date')

    return (
        <Flexbox>
            {view == 'year' ? (
                <HostYearSelector
                    setViewState={setView}
                    years={years}
                    yearButton={yearButton}
                    nextYearsButton={nextYearsButton}
                    previousYearsButton={previousYearsButton}
                    currentYear={calendars[0].year}
                />
            ) : view == 'month' ? (
                <HostMonthSelector
                    setViewState={setView}
                    months={months}
                    monthButton={monthButton}
                    currentMonth={calendars[0].month}
                />
            ) : (
                calendars.map((calendar, index) => (
                    <HostDateSelector
                        mode={mode}
                        setViewState={setView}
                        locale={locale}
                        key={`${calendar.month}-${calendar.year}`}
                        calendar={calendar}
                        dayButton={dayButton}
                        weekDays={weekDays}
                        subtractOffset={index == 0 ? subtractOffset : undefined}
                        addOffset={index == calendars.length - 1 ? addOffset : undefined}
                        currentDate={currentDate}
                    />
                ))
            )}
        </Flexbox>
    )
}
