import {useState, useMemo} from 'react'
import {generateLocalizedCountriesOptions} from '@utilities/constants/countries.tsx'
import {geocodeByAddress, geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete'

interface LatLng {
    lat: number
    lng: number
}

interface UseAddressGeocodingResult {
    addressLatLng: LatLng | undefined
    shippingAddressFormat: google.maps.GeocoderResult | undefined
    retrieveLatLng: (address: string) => void
    retrieveStructured: (placeId: string) => void
    countriesLocalizedOptions: ReturnType<typeof generateLocalizedCountriesOptions>
    resetGeocoding: () => void
}

const useAddressGeocoding = (language: string): UseAddressGeocodingResult => {
    const [addressLatLng, setAddressLatLng] = useState<LatLng | undefined>()
    const [shippingAddressFormat, setShippingAddressFormat] = useState<google.maps.GeocoderResult | undefined>()
    const countriesLocalizedOptions = useMemo(() => generateLocalizedCountriesOptions(), [language])

    const retrieveLatLng = (address: string) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                setAddressLatLng({lat, lng})
            })
    }

    const retrieveStructured = (placeId: string) => {
        geocodeByPlaceId(placeId).then(res => {
            if (res[0]) {
                setShippingAddressFormat(res[0])
            } else {
                // todo: error handling for frontend side
            }
        })
    }

    const resetGeocoding = () => {
        setAddressLatLng(undefined)
        setShippingAddressFormat(undefined)
    }

    return {
        addressLatLng,
        shippingAddressFormat,
        retrieveLatLng,
        retrieveStructured,
        countriesLocalizedOptions,
        resetGeocoding
    }
}

export default useAddressGeocoding
