import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledUseYourAnswerWrapper = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        border-radius: 8px;
        padding: ${spacing * 4}px;
        background-color: ${palette.neutral[50]};
        color: ${palette.neutral[900]};
        ${typography.textMd};
        & h4 {
            color: ${palette.neutral[700]};
            font-weight: 500;
            ${typography.textSm};
        }
    `
)
