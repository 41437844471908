import {FC} from 'react'
import {TimePreferenceButton} from './TimePreferenceButton'
import {StyledCurrentDate, StyledHostDatePickerAside, StyledHostDatePickerLimitReached} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'
import {SLOTS, isCompliantDate} from './utils'

type HostDatePickerAsideType = {
    selectedDates: Date[]
    currentDate: Date
    updateSelectedDatesCb: (value: number) => void
}

export const HostDatePickerAside: FC<HostDatePickerAsideType> = ({
    selectedDates,
    currentDate,
    updateSelectedDatesCb
}) => {
    const {t} = useTranslation()

    return (
        <StyledHostDatePickerAside direction="column" gap={6}>
            <StyledCurrentDate>{dayjs(currentDate).format('LL')}</StyledCurrentDate>
            {selectedDates.length === 2 && !isCompliantDate(currentDate) ? (
                <StyledHostDatePickerLimitReached direction="column" gap={4}>
                    <p>{t('service_details:host_dates_picker:completed_slots_text_1')}</p>
                    <p>{t('service_details:host_dates_picker:completed_slots_text_2')}</p>
                </StyledHostDatePickerLimitReached>
            ) : (
                <Flexbox direction="column" gap={2} width={'100%'} height={'100%'} justify={'space-between'}>
                    {SLOTS.map(slot => (
                        <TimePreferenceButton
                            key={slot.value}
                            slot={slot}
                            currentDate={currentDate}
                            selectedDates={selectedDates}
                            onClick={updateSelectedDatesCb}
                        />
                    ))}
                </Flexbox>
            )}
        </StyledHostDatePickerAside>
    )
}
