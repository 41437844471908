import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetPhotoServiceRequests} from '../service-requests.http.ts'
import {useInputSearch} from '@/hooks/useInputSearch'
import {
    HttpGetPhotoServiceRequestsParams,
    HttpGetPhotoServiceRequestsResponse
} from '@/features/service-requests/types.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const usePhotoServiceRequests = (
    params: HttpGetPhotoServiceRequestsParams,
    options?: Omit<UseQueryOptions<HttpGetPhotoServiceRequestsResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch()

    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PHOTO_SERVICE_REQUESTS, searchValue],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPhotoServiceRequests(params),
                onZodError: captureException,
                responseShape: HttpGetPhotoServiceRequestsResponse
            })
    })

    return {
        ...query,
        searchValue,
        onSearch,
        onResetSearch,
        searchError
    }
}
