import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const InactiveWrapper = styled.div`
    ${() => css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 1fr;
        justify-items: center;
        height: 100%;
    `}
`

export const InactiveBox = styled(Flexbox)`
    ${({theme: {typography, palette, spacing, shadows}}) => css`
        max-width: 480px;
        text-align: center;
        & > img {
            margin: 0 auto ${spacing * 6}px;
        }

        & > h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 6}px 0;
            color: ${palette.neutral['900']};
        }

        & > a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing * 2}px;
            border-radius: 8px;
            transition: ease-in-out 200ms;
            cursor: pointer;
            font-weight: 600;
            color: ${palette.neutral.white};
            background: ${palette.primary['600']};
            border: 1px solid ${palette.primary['600']};
            box-shadow: ${shadows.xs};
            height: 44px;
            padding: 0 ${spacing * 4.5}px;
            &:not(:disabled):hover {
                background: ${palette.primary['700']};
                border: 1px solid ${palette.primary['700']};
            }
        }
    `}
`
