import {StyledFreelancerCard} from '@/features/service/components/freelancer-card/style.ts'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Mail01Icon, PhoneIcon} from '@components/ui/icon'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import {ServiceType} from '@/types/commons.ts'

export const FreelancerCard: FC<{freelancer: Assignment['photographer']; serviceType: ServiceType}> = ({
    freelancer,
    serviceType
}) => {
    const {t} = useTranslation()

    return (
        <StyledFreelancerCard padding={6} gap={4} justify="space-between">
            <Flexbox direction="column" gap={4}>
                <Flexbox direction="column" gap={1}>
                    <h3 className="title">
                        {t('service_details:photographer_card_title', {freelancer: freelancer?.first_name})}
                    </h3>
                    <p className="subtitle">{t(`service_details:photographer_card_subtitle:${serviceType}`)}</p>
                    <p className="desc">{freelancer?.description}</p>
                </Flexbox>
                <Flexbox direction="column" gap={2}>
                    {freelancer?.phone && (
                        <Flexbox align="center" gap={2} className="contacts">
                            <PhoneIcon size={20} />
                            <a href={freelancer?.phone ? `tel:${freelancer?.phone}` : undefined}>{freelancer?.phone}</a>
                        </Flexbox>
                    )}
                    <Flexbox align="center" gap={2} className="contacts">
                        <Mail01Icon size={20} />
                        <a href={freelancer?.email ? `mailto:${freelancer?.email}` : undefined}>{freelancer?.email}</a>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <Avatar
                name={freelancer?.first_name ?? 'Photog'}
                imageUrl={freelancer?.profile_photo_url ?? ''}
                size="lg"
            />
        </StyledFreelancerCard>
    )
}
