import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import Container from '@components/ui/container/Container.tsx'
import {
    StyledHostApprovalCard,
    StyledHostApprovalCardInfo,
    StyledHostApprovalHeader,
    StyledHostApprovalListWrapper
} from '@/features/host-approval/components/host-approval-section/style.ts'
import {HostApprovalRequest} from '@/features/host-approval/types.ts'
import {generatePath, useOutletContext} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'

export interface HostApprovalSectionProps {
    hostApprovalRequests: HostApprovalRequest[]
}

export const HostApprovalSection: FC<HostApprovalSectionProps> = ({hostApprovalRequests}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()

    return (
        <Container fullHeight={false}>
            <StyledHostApprovalHeader align={'center'}>
                <span />
                <h4>{t('hostApproval:title')}</h4>
            </StyledHostApprovalHeader>
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledHostApprovalListWrapper>
                    {hostApprovalRequests.map(request => (
                        <StyledHostApprovalCard key={request.id} align={'start'} direction={'column'} gap={1}>
                            <StyledHostApprovalCardInfo>
                                <h4>{t('hostApproval:card:title')}</h4>
                                <p>
                                    <Trans
                                        i18nKey={'hostApproval:card:subtitle'}
                                        values={{listingTitle: request.title}}
                                        components={[<span />]}
                                    />
                                </p>
                            </StyledHostApprovalCardInfo>
                            {
                                <ButtonLink
                                    variant={'tertiary'}
                                    size={'sm'}
                                    to={generatePath(routes.APPROVAL_REQUEST_DETAILS.path, {
                                        hostCode: urlParams.hostCode,
                                        hostPassword: urlParams.hostPassword,
                                        requestId: request.id
                                    })}
                                >
                                    {t('hostApproval:card:cta')}
                                </ButtonLink>
                            }
                        </StyledHostApprovalCard>
                    ))}
                </StyledHostApprovalListWrapper>
            </ScrollArea>
        </Container>
    )
}

HostApprovalSection.displayName = 'HostApprovalSection'
