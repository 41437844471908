import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledFreelancerCard = styled(Flexbox)(
    ({theme: {palette, typography, mediaQueries}}) => css`
        border-radius: 24px;
        border: 1px solid ${palette.neutral['300']};
        width: 400px;
        height: max-content;
        flex: none;

        & .title {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
            word-break: break-word;
        }

        & .subtitle {
            ${typography.textXs};
            color: ${palette.neutral['900']};
        }

        & .desc {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }

        & .contacts {
            ${typography.textSm};
            color: ${palette.neutral['700']};

            a {
                cursor: pointer;
            }
        }

        ${mediaQueries.l} {
            width: 100%;
        }
    `
)
