import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckIcon} from '@components/ui/icon'
import {
    StyledCompletedPageBody,
    StyledCompletedPageIconContainer
} from '@/features/experiences-host-video/components/completed-step/style.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'

export const CompletedStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()

    return (
        <StyledCompletedPageBody justify={'center'} align={'center'} direction={'column'} gap={6}>
            <StyledCompletedPageIconContainer>
                <CheckIcon size={42} />
            </StyledCompletedPageIconContainer>
            <Flexbox direction={'column'} gap={2} align={'center'}>
                <h1>{t('experiences_host_video:completed_step:title')}</h1>
                <p>{t('experiences_host_video:completed_step:description')}</p>
            </Flexbox>
            <ButtonLink
                to={generatePath(routes.DASHBOARD.path, {
                    hostCode: routeParams.hostCode,
                    hostPassword: routeParams.hostPassword
                })}
            >
                {t('commons:dashboard')}
            </ButtonLink>
        </StyledCompletedPageBody>
    )
}
