import {FC, useMemo} from 'react'
import {addTimeToDate} from './utils'
import {StyledTimePreferenceButton} from './style'
import {Slot} from './types'

type TimePreferenceButtonProps = {
    slot: Slot
    currentDate: Date
    selectedDates: Date[]
    onClick: (value: number) => void
}

export const TimePreferenceButton: FC<TimePreferenceButtonProps> = ({slot, currentDate, selectedDates, onClick}) => {
    const {label: slotLabel, value: slotName} = slot

    const isSelectedTime: boolean = useMemo(() => {
        const dateTime = addTimeToDate(currentDate, slotName)
        return selectedDates.some(date => date.getTime() === dateTime.getTime())
    }, [currentDate, selectedDates])

    return (
        <StyledTimePreferenceButton onClick={() => onClick(slotName)} isSelected={isSelectedTime}>
            {slotLabel}
        </StyledTimePreferenceButton>
    )
}
