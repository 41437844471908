import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpCompleteOnboarding} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {ExperiencesHostVideo, ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

interface UseUpdateStepArgs {
    params: ExperiencesHostVideoRouteParams
    mutationOptions?: Omit<UseMutationOptions, 'mutationFn'>
}
export const useCompleteOnboarding = ({params, mutationOptions}: UseUpdateStepArgs) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () =>
            parseAxiosPromise({
                axiosPromise: httpCompleteOnboarding({
                    urlParams: params,
                    params: {response_type: 'extended'}
                }),
                responseShape: ExperiencesHostVideo,
                onZodError: captureException
            }),
        ...mutationOptions,
        onSuccess: async (data, variables, context) => {
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_FRIEND]})
            queryClient.setQueriesData(
                {
                    queryKey: [
                        QUERY_KEYS.EXPERIENCES_HOST_VIDEO_FRIEND,
                        params.hostCode,
                        params.hostPassword,
                        params.experiencesHostVideoId,
                        params.taskId
                    ]
                },
                data
            )
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
