import styled, {css} from 'styled-components'

export const StyledHero = styled.div<{isGrey: boolean}>(
    ({isGrey, theme: {typography, palette, spacing, mediaQueries}}) => css`
        background-color: ${isGrey ? palette.neutral['50'] : 'transparent'};
        padding: ${spacing * 8}px 0 ${spacing * 27}px;

        .hero-container {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 6}px;

            .dashboard-link {
                flex: none;
            }
            h2 {
                ${typography.displaySm};
                font-weight: 500;
                color: ${palette.neutral['900']};

                a {
                    cursor: pointer;
                }
            }
            h3 {
                ${typography.textMd};
                color: ${palette.neutral['700']};
            }
        }

        ${mediaQueries.m} {
            padding: ${spacing * 8}px 0 ${spacing * 8}px;
        }
    `
)
