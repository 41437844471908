import styled, {css} from 'styled-components'

export const ServiceStepsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, typography, palette}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing * 16}px;
        padding: ${spacing * 10}px 0;

        & > h3 {
            display: flex;
            flex-direction: column;
            gap: ${spacing}px;
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};

            & span {
                font-weight: 400;
                color: ${palette.neutral['500']};
            }
        }

        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
        }
    `}
`

export const ServiceStepsList = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 16}px;
        ${mediaQueries.m} {
            gap: ${spacing * 24}px;
        }
    `}
`

export const ServiceSingleStep = styled.div`
    ${({theme: {spacing, mediaQueries, typography, palette}}) => css`
        position: relative;
        display: grid;
        grid-template-columns: auto 1.2fr 1fr;
        gap: ${spacing * 6}px;
        justify-items: end;

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            justify-items: start;
        }

        & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
            border-radius: 36px;
            border: 1px solid ${palette.neutral['300']};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }

        & > div {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;

            & h5 {
                ${typography.textMd};
                font-weight: 500;
                color: ${palette.neutral['900']};
                ${mediaQueries.m} {
                    max-width: 200px;
                }
            }

            & p {
                ${typography.textMd};
                font-weight: 400;
                color: ${palette.neutral['500']};
                & > img {
                    display: none;
                }
            }

            ${mediaQueries.m} {
                display: grid;
                grid-template-columns: 1fr;
                gap: ${spacing * 4}px;

                & > p {
                    ${typography.textMd};
                    font-weight: 400;
                    color: ${palette.neutral['500']};

                    & > img {
                        display: block;
                        position: absolute;
                        right: 0;
                        width: 140px;
                        top: -32px;
                    }
                }
            }
        }

        & > img {
            width: 190px;
            ${mediaQueries.m} {
                display: none;
            }
        }
    `}
`
