import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {DefaultTheme, css} from 'styled-components'

type StyledCategoryChipProps = {
    theme: DefaultTheme
    disabled: boolean
}

export const StyledCategoryChipContainer = styled.div(
    ({theme: {spacing, palette}}) => css`
        border: 1px solid ${palette.neutral['300']};
        padding: ${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px;
        border-radius: 6px;
        color: ${palette.neutral.black};
    `
)

export const StyledCategoryChip = styled(Flexbox)<StyledCategoryChipProps>(
    ({theme: {palette, typography}, disabled}) => css`
        ${typography.textSm};
        line-height: 20px;
        font-weight: 700;
        max-width: fit-content;
        cursor: default;
        button {
            display: inline-flex;
            justify-content: space-between;
            cursor: pointer;
            color: 1px solid ${palette.neutral['400']};
            &:hover {
                color: ${palette.primary['600']};
            }
        }

        svg,
        img {
            width: 16px;
            height: 16px;
        }

        ${disabled &&
        css`
            img,
            img + span {
                opacity: 0.4;
            }
        `}
    `
)
