import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledPageHeaderContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        align-items: start;
        grid-template-columns: 1fr auto;
        padding: ${spacing * 16}px 0 ${spacing * 8}px;

        ${mediaQueries.m} {
            padding-top: ${spacing * 8}px;
        }
    `}
`

export const StyledPageHeaderText = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            font-weight: 600;
        }
        & p {
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }
    `}
`

export const StyledPageHeaderContantWrapper = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            flex-direction: column;
            align-items: flex-start;
        }
    `
)

export const StyledPageHeaderEndSlot = styled.div`
    flex-shrink: 0;
`
