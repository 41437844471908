import {useTranslation} from 'react-i18next'
import {SearchLgIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledPromoWalkthroughExplainContainer,
    StyledPromoWalkthroughExplainIcon,
    StyledPromoWalkthroughExplainWrapper
} from '@/features/promotional-services/components/promo-walkthrough-section/style.ts'

export const PromoWalkthroughExplain = () => {
    const {t} = useTranslation()

    return (
        <StyledPromoWalkthroughExplainWrapper>
            <StyledPromoWalkthroughExplainContainer>
                <Flexbox direction={'column'} gap={4}>
                    <StyledPromoWalkthroughExplainIcon align={'center'} justify={'center'}>
                        <SearchLgIcon />
                    </StyledPromoWalkthroughExplainIcon>
                    <Flexbox direction={'column'}>
                        <h5>{t('dashboard:promo_walkthrough:landing_mode_explain_section_title')}</h5>
                        <p>{t('dashboard:promo_walkthrough:landing_mode_explain_section_desc')}</p>
                    </Flexbox>
                </Flexbox>
            </StyledPromoWalkthroughExplainContainer>
        </StyledPromoWalkthroughExplainWrapper>
    )
}

PromoWalkthroughExplain.displayName = 'PromoWalkthroughExplain'
