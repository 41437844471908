import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'

export const StyledQuizModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledModalHeader = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
    `
)

export const StyledTextarea = styled(TextArea)`
    width: 100%;
`

export const StyledSuccessIconContainer = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: ${palette.success['50']};
        color: ${palette.success['600']};
    `
)

export const StyledSuccessTexts = styled.div(
    ({theme: {palette, typography, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 2}px;
        text-align: center;
        & h3 {
            ${typography.textXlFixed};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
    `
)
