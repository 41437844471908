import axios from '@/axiosInstance.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const httpClickAction = (params: ServiceDetailsURLParams & {actionId: ServiceAction['id']}) => {
    return axios.post(
        `host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/actions/${params.actionId}/clicked`
    )
}

export const httpCompleteAction = (params: ServiceDetailsURLParams & {actionId: ServiceAction['id']}) => {
    return axios.post(
        `host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/actions/${params.actionId}/completed`
    )
}
