import {
    ExperiencesHostVideoActionCard,
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {copyTextToClipboard, formatLocaleDate} from '@utilities/helpers.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {
    AlertCircleIcon,
    CalendarIcon,
    CheckIcon,
    ClockIcon,
    Dotpoints02Icon,
    VideoRecorderIcon
} from '@components/ui/icon'
import {useEffect, useState} from 'react'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {RequirementsPerspectiveModal} from '@/features/experiences-host-video/components/requirements-perspective-modal/RequirementsPerspectiveModal.tsx'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {ChecklistModal} from '@/features/experiences-host-video/components/checklist-modal/ChecklistModal.tsx'
import {useUpdateExperiencesHostVideo} from '@/features/experiences-host-video/services/useUpdateExperiencesHostVideo.ts'
import Divider from '@components/ui/divider/Divider.tsx'
import {useTheme} from 'styled-components'
import {
    StyledScheduleInputs,
    StyledScheduleTitle,
    StyledSupportText
} from '@/features/experiences-host-video/components/ready-to-film-schedule-step/style.ts'
import {DatePicker} from '@components/commons/date-picker/DatePicker.tsx'
import dayjs from '@utilities/dayjs.ts'
import {TimePicker} from '@components/commons/time-picker/TimePicker.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {ConfirmScheduleStepModal} from '@/features/experiences-host-video/components/confirm-schedule-step-modal/ConfirmScheduleStepModal.tsx'
import {SupportModal} from '@/features/experiences-host-video/components/support-modal/SupportModal.tsx'
import {DeviceModal} from '@/features/experiences-host-video/components/device-modal/DeviceModal.tsx'

const DEFAULT_TIME = '8:00 AM'

export const ReadyToFilmScheduleStep = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const queryClient = useQueryClient()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})
    const [requirementsPerspectiveSlidingModalState, setRequirementsPerspectiveSlidingModalState] =
        useState<SlidingModalState>('closed')
    const [checklistSlidingModalState, setChecklistSlidingModalState] = useState<SlidingModalState>('closed')
    const [supportSlidingModalState, setSupportSlidingModalState] = useState<SlidingModalState>('closed')
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false)
    const scheduledAt = showExperiencesHostVideoQuery?.data?.scheduled_at
    const scheduledAtDate = showExperiencesHostVideoQuery?.data?.scheduled_at
        ? dayjs(showExperiencesHostVideoQuery?.data.scheduled_at)
              .startOf('day')
              .format('YYYY-MM-DD')
        : undefined
    const scheduledAtTime = showExperiencesHostVideoQuery?.data?.scheduled_at
        ? dayjs(showExperiencesHostVideoQuery?.data.scheduled_at).format('h:mm A')
        : undefined

    const copyToClipboard = async () => {
        await copyTextToClipboard(
            `${window.location.origin}${generatePath(routes.EXPERIENCE_HOST_VIDEO_FRIEND.path, routeParams)}`
        )
        toast.success(t('experiences_host_video:link_copied_successfully'))
        await navigator.share({
            url: `${window.location.origin}${generatePath(routes.EXPERIENCE_HOST_VIDEO_FRIEND.path, routeParams)}`
        })
    }

    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const updateIsSelfFilmedMutation = useUpdateExperiencesHostVideo({
        params: routeParams,
        mutationOptions: {
            onError: () => toast.error(t('errors:default')),
            onSuccess: copyToClipboard
        }
    })
    const updateScheduledAtMutation = useUpdateExperiencesHostVideo({
        params: routeParams,
        mutationOptions: {
            onMutate: variables => {
                queryClient.setQueriesData(
                    {
                        queryKey: [
                            QUERY_KEYS.EXPERIENCES_HOST_VIDEO,
                            routeParams.hostCode,
                            routeParams.hostPassword,
                            routeParams.experiencesHostVideoId,
                            routeParams.taskId
                        ]
                    },
                    {
                        ...showExperiencesHostVideoQuery.data,
                        scheduled_at: variables.scheduled_at
                    }
                )
            },
            onError: () => {
                toast.error(t('errors:default'))
                queryClient.setQueriesData(
                    {
                        queryKey: [
                            QUERY_KEYS.EXPERIENCES_HOST_VIDEO,
                            routeParams.hostCode,
                            routeParams.hostPassword,
                            routeParams.experiencesHostVideoId,
                            routeParams.taskId
                        ]
                    },
                    showExperiencesHostVideoQuery.data
                )
            }
        }
    })

    useEffect(() => {
        if (showExperiencesHostVideoQuery.isSuccess && !showExperiencesHostVideoQuery.data.device_id) {
            setIsDeviceModalOpen(true)
        }
    }, [showExperiencesHostVideoQuery.isSuccess])

    return (
        <>
            <div>
                <ExperiencesHostVideoBody>
                    <Flexbox direction="column" gap={2}>
                        <ExperiencesHostVideoTitle>
                            {t('experiences_host_video:get_ready_to_film')}
                        </ExperiencesHostVideoTitle>
                        <ExperiencesHostVideoParagraph>
                            {t('experiences_host_video:get_ready_to_film_description')}
                        </ExperiencesHostVideoParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={3} width="100%">
                        <ExperiencesHostVideoActionCard onClick={() => setChecklistSlidingModalState('open')}>
                            <Dotpoints02Icon />
                            <Flexbox direction="column" gap={2}>
                                <h3>{t('experiences_host_video:checklist')}</h3>
                                <p>{t('experiences_host_video:ready_to_film_schedule:checklist_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                        <ExperiencesHostVideoActionCard
                            onClick={() => setRequirementsPerspectiveSlidingModalState('open')}
                        >
                            <VideoRecorderIcon />
                            <Flexbox direction="column" gap={2}>
                                <h3>{t('experiences_host_video:requirements:title')}</h3>
                                <p>{t('experiences_host_video:requirements:cta_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                        {showExperiencesHostVideoQuery.data?.is_self_filmed ? (
                            <ExperiencesHostVideoActionCard
                                variant="danger"
                                disabled={updateIsSelfFilmedMutation.isPending}
                                onClick={() => updateIsSelfFilmedMutation.mutate({is_self_filmed: false})}
                            >
                                {updateIsSelfFilmedMutation.isPending ? (
                                    <div>
                                        <Spinner />
                                    </div>
                                ) : (
                                    <AlertCircleIcon />
                                )}
                                <Flexbox direction="column" gap={2}>
                                    <h3>
                                        {t('experiences_host_video:ready_to_film_schedule:onboarding_film_own_title')}
                                    </h3>
                                    <p>
                                        {t(
                                            'experiences_host_video:ready_to_film_schedule:onboarding_film_own_description'
                                        )}
                                    </p>
                                </Flexbox>
                            </ExperiencesHostVideoActionCard>
                        ) : showExperiencesHostVideoQuery.data?.has_friend_completed_onboarding ? (
                            <ExperiencesHostVideoActionCard variant="success" onClick={copyToClipboard}>
                                <CheckIcon />
                                <Flexbox direction="column" gap={2}>
                                    <h3>
                                        {t('experiences_host_video:ready_to_film_schedule:onboarding_completed_title')}
                                    </h3>
                                    <p>{t('experiences_host_video:tap_to_share_link')}</p>
                                </Flexbox>
                            </ExperiencesHostVideoActionCard>
                        ) : (
                            <ExperiencesHostVideoActionCard variant="danger" onClick={copyToClipboard}>
                                <AlertCircleIcon />
                                <Flexbox direction="column" gap={2}>
                                    <h3>
                                        {t('experiences_host_video:ready_to_film_schedule:onboarding_pending_title')}
                                    </h3>
                                    <p>
                                        {t(
                                            'experiences_host_video:ready_to_film_schedule:onboarding_pending_description'
                                        )}
                                    </p>
                                </Flexbox>
                            </ExperiencesHostVideoActionCard>
                        )}
                        <StyledSupportText>
                            <Trans
                                i18nKey="experiences_host_video:ready_to_film_schedule:support"
                                components={{cta: <button onClick={() => setSupportSlidingModalState('open')} />}}
                            />
                        </StyledSupportText>
                    </Flexbox>
                </ExperiencesHostVideoBody>
                <Divider height={8} topSpacing={0} bottomSpacing={0} background={theme.palette.neutral['200']} />
                <ExperiencesHostVideoBody gap={4}>
                    <Flexbox direction="column" gap={1}>
                        <StyledScheduleTitle>
                            {t('experiences_host_video:ready_to_film_schedule:schedule')}
                        </StyledScheduleTitle>
                        <ExperiencesHostVideoParagraph>
                            {t('experiences_host_video:ready_to_film_schedule:schedule_description')}
                        </ExperiencesHostVideoParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={3}>
                        <StyledScheduleInputs>
                            <Flexbox direction="column" align="stretch" gap={1.5}>
                                <label>{t('experiences_host_video:ready_to_film_schedule:date')}</label>
                                <DatePicker
                                    asModal
                                    triggerProps={{
                                        typeIcon: <CalendarIcon />,
                                        placeholder: 'yyyy-mm-dd'
                                    }}
                                    formatter={date => formatLocaleDate(date, 'L')}
                                    mode="single"
                                    numMonths={1}
                                    selectedDates={scheduledAt ? [new Date(scheduledAt)] : []}
                                    onDatesChange={dates => {
                                        const dateToSend = dayjs(
                                            `${dayjs(dates[0]).format('YYYY-MM-DD')} ${
                                                scheduledAtTime ?? DEFAULT_TIME
                                            }`.replace(/-/g, '/')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                        return updateScheduledAtMutation.mutate({
                                            scheduled_at: dateToSend
                                        })
                                    }}
                                />
                            </Flexbox>
                            <Flexbox direction="column" align="stretch" gap={1.5}>
                                <label>{t('experiences_host_video:ready_to_film_schedule:time')}</label>
                                <TimePicker
                                    asModal
                                    triggerProps={{
                                        typeIcon: <ClockIcon />,
                                        placeholder: '00:00'
                                    }}
                                    disabled={!scheduledAt}
                                    selectedTime={scheduledAtTime ?? ''}
                                    formatter={time =>
                                        time ? dayjs(`${scheduledAtDate} ${time}`.replace(/-/g, '/')).format('LT') : ''
                                    }
                                    onSelectTime={time => {
                                        const dateToSend = dayjs(
                                            `${dayjs(scheduledAtDate).format('YYYY-MM-DD')} ${time}`.replace(/-/g, '/')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                        updateScheduledAtMutation.mutate({
                                            scheduled_at: dateToSend
                                        })
                                    }}
                                />
                            </Flexbox>
                        </StyledScheduleInputs>
                        <Flexbox direction="column" gap={1}>
                            <ExperiencesHostVideoParagraph>
                                {t('experiences_host_video:ready_to_film_schedule:reminder_description')}
                            </ExperiencesHostVideoParagraph>
                        </Flexbox>
                    </Flexbox>
                </ExperiencesHostVideoBody>
            </div>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'ready-to-film', step: 'get-help'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                !scheduledAt || dayjs().isBefore(scheduledAt)
                                    ? setIsConfirmModalOpen(true)
                                    : continueUpdateStepMutation.mutate({
                                          status: 'upload',
                                          step: 'intro'
                                      })
                            }
                            disabled={continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
            {isDeviceModalOpen && showExperiencesHostVideoQuery.data?.is_self_filmed && (
                <DeviceModal
                    onClose={() => setIsDeviceModalOpen(false)}
                    experiencesHostVideo={showExperiencesHostVideoQuery.data}
                />
            )}
            {isConfirmModalOpen && <ConfirmScheduleStepModal onClose={() => setIsConfirmModalOpen(false)} />}
            {supportSlidingModalState != 'closed' && (
                <SupportModal
                    slidingModalState={supportSlidingModalState}
                    setSlidingModalState={setSupportSlidingModalState}
                />
            )}
            {checklistSlidingModalState != 'closed' && (
                <ChecklistModal
                    answers={showExperiencesHostVideoQuery.data?.answers ?? []}
                    slidingModalState={checklistSlidingModalState}
                    setSlidingModalState={setChecklistSlidingModalState}
                />
            )}
            {requirementsPerspectiveSlidingModalState != 'closed' && (
                <RequirementsPerspectiveModal
                    userType="host"
                    slidingModalState={requirementsPerspectiveSlidingModalState}
                    setSlidingModalState={setRequirementsPerspectiveSlidingModalState}
                />
            )}
        </>
    )
}
