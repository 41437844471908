import {ReactElement, useState} from 'react'
import {AverageIcon, ExcellentIcon, GoodIcon, PoorIcon, VeryPoorIcon} from '@/features/chatbot/utils.tsx'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {StyledBotRatingOption} from '@/features/chatbot/components/styles.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type RatingOption = {
    value: '1' | '2' | '3' | '4' | '5'
    label: string
    icon: ReactElement
    color: string
    trigger: string
}

const options: RatingOption[] = [
    {value: '1', label: 'Very Poor', icon: <VeryPoorIcon />, color: '#f93533', trigger: 's_comment_bad'},
    {value: '2', label: 'Poor', icon: <PoorIcon />, color: '#f96b04', trigger: 's_comment_bad'},
    {value: '3', label: 'Average', icon: <AverageIcon />, color: '#f9bb06', trigger: 's_comment'},
    {value: '4', label: 'Good', icon: <GoodIcon />, color: '#c4d718', trigger: 's_comment'},
    {value: '5', label: 'Excellent', icon: <ExcellentIcon />, color: '#68c239', trigger: 's_comment'}
]

export const BotRating = () => {
    const chatbotRef = useChatbotStore(state => state.chatbotRef)
    const [selectedRating, setSelectedRating] = useState<string>('')

    const onOptionClick = async (rating: RatingOption) => {
        if (selectedRating) return
        setSelectedRating(rating.value)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        await chatbotRef?.current?.triggerNextStep({value: rating.value, trigger: rating?.trigger})
    }

    return (
        <Flexbox align={'center'} gap={1} justify={'space-between'}>
            {options?.map(e => (
                <StyledBotRatingOption
                    disabled={!!selectedRating && selectedRating !== e.value}
                    selectedRating={selectedRating}
                    value={e.value}
                    gap={1}
                    direction={'column'}
                    align={'center'}
                    key={e.value}
                    onClick={() => onOptionClick(e)}
                >
                    {e?.icon}
                    <small>{e.label}</small>
                </StyledBotRatingOption>
            ))}
        </Flexbox>
    )
}

BotRating.displayName = 'BotRating'
