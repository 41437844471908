import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import i18next from 'i18next'

export const hp20 = ({
    photographerName,
    photographerContact
}: {
    photographerName?: string
    photographerContact?: string
}) => [
    {
        id: 'HP20',
        message: i18next.t('chatbot:HP20:HP20_message', {
            photographerName,
            photographerContact
        }),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP20',
                source: 'Chatbot',
                text: `Please contact your assigned photographer ${photographerName} at ${photographerContact} to reschedule the photoshoot. Please give your photographer at least 24 hours notice.`
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
