import {UseInfiniteQueryOptions} from '@tanstack/react-query'
import {HttpGetListingsCategoriesOptions, HttpGetListingsCategoriesResponse} from '../categories.http'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useListingsCategories} from './useListingsCategories'

export const GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE = 'extended'

export const useListingsCategoriesList = (
    promiseArgs: Omit<HttpGetListingsCategoriesOptions, 'params'>,
    options?: Omit<UseInfiniteQueryOptions<HttpGetListingsCategoriesResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch()

    const listingCategoriesQuery = useListingsCategories({
        ...promiseArgs,
        params: {
            limit: 9,
            response_type: 'extended',
            ...(searchValue && {search: searchValue})
        }
    })

    return {
        ...options,
        ...listingCategoriesQuery,
        searchValue: searchValue,
        searchError,
        onSearch,
        onResetSearch
    }
}
