import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledHeader = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        padding-bottom: 0;
    `
)

export const StyledTab = styled.button<{$isActive: boolean}>(
    ({theme: {typography, palette, spacing}, $isActive}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${$isActive ? palette.neutral['900'] : palette.neutral['400']};
        padding: 0 ${spacing * 2}px ${spacing * 3}px;
        cursor: pointer;

        ${$isActive &&
        css`
            border-bottom: 3px solid ${palette.neutral['900']};
        `}
    `
)
