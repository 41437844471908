import {Trans, useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import Container from '@components/ui/container/Container.tsx'
import {useOutletContext} from 'react-router-dom'
import {TermsOfServiceURLParams} from '@route-guards/terms-route/TermsRoute.tsx'
import {StyledTermsOfServiceBody} from '@pages/terms-of-service/styles.ts'
import {Helmet} from 'react-helmet'

export const TermsOfService = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: TermsOfServiceURLParams}>()

    return (
        <Container>
            <Helmet title={String(t('terms:page_title_meta'))} />
            <StyledTermsOfServiceBody direction={'column'} gap={4}>
                <Flexbox direction={'column'} gap={1}>
                    <h2>{t(`terms:${urlParams.type}:service_title`)}</h2>
                    <small>{t(`terms:${urlParams.type}:service_last_updated`)}</small>
                </Flexbox>
                <Divider topSpacing={0} bottomSpacing={0} />
                <p>
                    <Trans
                        i18nKey={`terms:${urlParams.type}:service_desc1`}
                        components={[
                            <a target="_blank" rel="noreferrer" href="https://www.airbnb.com/terms/payments_terms" />,
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.airbnb.co.uk/help/article/2990/emoney-terms-of-service"
                            />
                        ]}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey={`terms:${urlParams.type}:service_desc2`}
                        components={[
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.airbnb.com/help/article/2908/terms-of-service"
                            />
                        ]}
                    />
                </p>
            </StyledTermsOfServiceBody>
        </Container>
    )
}

TermsOfService.displayName = 'TermsOfService'
