import {QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '@utilities/helpers.ts'
import {httpAnswerCancellationRequest} from '@/features/service/services/service.http.ts'

export const useAnswerCancellationRequest = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: httpAnswerCancellationRequest,
        onError: error => errorHandler(error),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
        }
    })
}
