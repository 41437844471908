import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {
    httpGetRoomsAutocomplete,
    HttpGetRoomsAutocompleteResponse
} from '@/features/matterport/services/matterport.http.ts'
import {captureException} from '@sentry/react'
import {useQuery} from '@tanstack/react-query'

export const useAccessibilityRooms = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.ACCESSIBILITY_ROOMS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetRoomsAutocomplete(),
                onZodError: captureException,
                responseShape: HttpGetRoomsAutocompleteResponse
            })
    })
}
