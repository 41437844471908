import mixpanel from 'mixpanel-browser'
import {User} from '@/features/sso/types.ts'
import {QuoteEventParams} from '@utilities/mixpanel/types.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {retrieveOrganicCardValueProp} from '@utilities/mixpanel/utils.ts'
import {RequestedPhotoService} from '@/features/service-requests/types.ts'
import {Quote} from '@/features/service/types.ts'

export const mixpanelInit = (userId: User['id']) => {
    //Set mixpanel user globally
    mixpanel.identify(userId)
    mixpanel.people.set({
        // Add anything else about the user here
    })
}

export const sendQuoteAcceptedMxEvent = (data: ServiceProposal, manualRequest: RequestedPhotoService) =>
    mixpanel.track('Organic Quote Accepted', {
        accepted_at: data.accepted_at,
        airbnb_listing_id: data.entity.external_id,
        amount: data.amount,
        bedrooms: data.entity.bedrooms,
        country: data.entity.country,
        crated_at: data.created_at,
        entity_type_id: data.entity.entity_type_id,
        entity_type_name: data.entity.entity_type_name,
        external_host_id: data.external_host_id,
        geo: data.entity?.market?.geo,
        identifier: data.identifier,
        photo_count: data.photo_count,
        quote_id: data.id, //only here after quote creation we have this property
        quote_status: data.quote_status,
        region: data.entity.region,
        room_type: data.entity.room_type,
        source: 'Elevate organic',
        space_type: data.entity.space_type,
        task_category_id: data.task_category.id,
        task_media_id: data.task_media.id,
        task_service_id: data.task_service.id,
        task_status_id: data.task?.status_id,
        title: data.entity.title,
        value_prop_type: retrieveOrganicCardValueProp(
            manualRequest?.photo_service_count ?? 0,
            manualRequest?.accuracy ?? 0
        )
    } as QuoteEventParams)

export const sendQuoteDisplayedMxEvent = (
    data: RequestedPhotoService & {external_host_id: Quote['external_host_id']}
) =>
    mixpanel.track('Organic Quote Displayed', {
        accepted_at: null,
        airbnb_listing_id: data.id,
        amount: data.quote_price,
        bedrooms: data.bedrooms,
        country: data.country_code,
        crated_at: null,
        entity_type_id: data.entity_type.id,
        entity_type_name: data.entity_type.name,
        external_host_id: data.external_host_id,
        geo: data.geo,
        identifier: null,
        photo_count: data.photo_service_count,
        quote_status: null,
        region: null,
        room_type: data.room_type_category,
        source: 'Elevate organic',
        space_type: data.property_type_category,
        task_category_id: data.task_category.id,
        task_media_id: data.task_media.id,
        task_service_id: data.task_service.id,
        task_status_id: null,
        title: data.name,
        value_prop_type: retrieveOrganicCardValueProp(data.photo_service_count, data.accuracy ?? 0)
    } as QuoteEventParams)

export const sendQuoteTandCMxEvent = (data: RequestedPhotoService & {external_host_id: Quote['external_host_id']}) =>
    mixpanel.track('Organic Open t&cs', {
        accepted_at: null,
        airbnb_listing_id: data.id,
        amount: data.quote_price,
        bedrooms: data.bedrooms,
        country: data.country_code,
        crated_at: null,
        entity_type_id: data.entity_type.id,
        entity_type_name: data.entity_type.name,
        external_host_id: data.external_host_id,
        geo: data.geo,
        identifier: null,
        photo_count: data.photo_service_count,
        quote_status: null,
        region: null,
        room_type: data.room_type_category,
        source: 'Elevate organic',
        space_type: data.property_type_category,
        task_category_id: data.task_category.id,
        task_media_id: data.task_media.id,
        task_service_id: data.task_service.id,
        task_status_id: null,
        title: data.name,
        value_prop_type: retrieveOrganicCardValueProp(data.photo_service_count ?? 0, data.accuracy ?? 0)
    } as QuoteEventParams)
