import {FC} from 'react'
import {Room} from '../types'
import {
    StyledMatterportAccessibilityGrid,
    StyledMatterportAmenity,
    StyledMatterportRoom,
    StyledMatterportRoomSkeleton
} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {MatterportAccessibilityCard} from './MatterportAccessibilityCard'
import Skeleton from '@components/ui/skeleton/Skeleton.tsx'

interface MatterportRoomProps {
    room: Room
    imagesGridStart?: 4 | 3
}

export const MatterportRoom: FC<MatterportRoomProps> = ({room, imagesGridStart = 3}) => {
    return (
        <StyledMatterportRoom direction="column" gap={4} width="100%">
            <h3>{room.name}</h3>
            <Flexbox direction="column" gap={8} width="100%">
                {room.amenities.map(amenity => (
                    <StyledMatterportAmenity key={amenity.id} direction="column" gap={2} width="100%">
                        <h4>{amenity.name}</h4>
                        <p>{amenity.description}</p>
                        <StyledMatterportAccessibilityGrid $imagesGridStart={imagesGridStart}>
                            {amenity.accessibility_images.map(image => (
                                <MatterportAccessibilityCard
                                    key={image.id}
                                    room={room}
                                    amenity={amenity}
                                    image={image}
                                />
                            ))}
                        </StyledMatterportAccessibilityGrid>
                    </StyledMatterportAmenity>
                ))}
            </Flexbox>
        </StyledMatterportRoom>
    )
}

export const MatterportRoomSkeleton = () => {
    return (
        <StyledMatterportRoomSkeleton direction="column" gap={8} width="100%">
            <Skeleton width={240} height={28} />
            <Flexbox direction="column" gap={8} width="100%">
                <StyledMatterportAmenity direction="column" gap={2} width="100%">
                    <Skeleton width={180} height={24} />
                    <Skeleton width={300} height={20} />
                    <StyledMatterportAccessibilityGrid $imagesGridStart={4}>
                        <Flexbox direction="column" gap={2}>
                            <Skeleton height={180} />
                            <Flexbox gap={2} width="100%">
                                <Skeleton height={44} />
                                <Skeleton height={44} />
                            </Flexbox>
                        </Flexbox>
                        <Flexbox direction="column" gap={2}>
                            <Skeleton height={180} />
                            <Flexbox gap={2} width="100%">
                                <Skeleton height={44} />
                                <Skeleton height={44} />
                            </Flexbox>
                        </Flexbox>
                        <Flexbox direction="column" gap={2}>
                            <Skeleton height={180} />
                            <Flexbox gap={2} width="100%">
                                <Skeleton height={44} />
                                <Skeleton height={44} />
                            </Flexbox>
                        </Flexbox>
                    </StyledMatterportAccessibilityGrid>
                </StyledMatterportAmenity>
            </Flexbox>
        </StyledMatterportRoomSkeleton>
    )
}
