import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledBody = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)
