import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledPreviewUploadedClipsContainer = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: ${spacing * 4}px;
        ${mediaQueries.s} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    `
)

export const StyledPreviewUploadedClipsRemoveAllButton = styled(Button)(
    () => css`
        padding: 0;
    `
)

export const StyledPreviewUploadedClipsCounterLabel = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[900]};
        ${typography.textLg};
    `
)
