import {ServiceValueProps} from '@/features/in-progress-services/utils.tsx'
import {useTranslation} from 'react-i18next'
import {StatsWidget} from '@components/commons/stats-widget/StatsWidget.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledExperienceVideoValuesBody,
    StyledExperienceVideoValuesIconWrapper,
    StyledExperienceVideoValuesWrapper
} from '@/features/promotional-services/components/promo-experience-video-section/styles.ts'

export const PromoExperienceVideoValues = () => {
    const {t} = useTranslation()

    return (
        <StyledExperienceVideoValuesWrapper>
            <Flexbox justify={'space-between'} align={'center'}>
                <h4>{t('exp_video_landing:stats:main_title')}</h4>
            </Flexbox>
            <StyledExperienceVideoValuesBody>
                {Object.values(ServiceValueProps['experience_video']).map((item, index) => (
                    <StatsWidget
                        key={index}
                        icon={
                            <StyledExperienceVideoValuesIconWrapper align={'center'} justify={'center'}>
                                {item.icon}
                            </StyledExperienceVideoValuesIconWrapper>
                        }
                        labelText={t(item.labelText)}
                        description={t(item.description)}
                    />
                ))}
            </StyledExperienceVideoValuesBody>
        </StyledExperienceVideoValuesWrapper>
    )
}

PromoExperienceVideoValues.displayName = 'PromoExperienceVideoValues'
