import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css, DefaultTheme, SimpleInterpolation} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

type StyledDayButtonProps = {
    theme: DefaultTheme
    variant: 'primary' | 'ghost'
    outline?: boolean
}

export type StyleByRangeProps =
    | 'in-range'
    | 'range-start'
    | 'range-end'
    | 'will-be-in-range'
    | 'will-be-range-start'
    | 'will-be-range-end'

const StyleByRangeStatus = ({palette}: DefaultTheme) =>
    ({
        'in-range': css`
            background-color: ${palette.primary['100']};
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            button {
                background-color: ${palette.primary['100']};
                border: none;
                &:hover {
                    background-color: ${palette.primary['200']};
                }
            }
        `,
        'range-start': css`
            position: relative;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            :before {
                content: '';
                position: absolute;
                display: block;
                height: 100%;
                width: 50%;
                background-color: ${palette.primary['100']};
                right: 0;
                z-index: -1;
            }
            button {
                background-color: ${palette.primary['500']};
                color: ${palette.neutral.white};
                &:hover {
                    background-color: ${palette.primary['600']};
                }
            }
        `,
        'range-end': css`
            position: relative;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            :before {
                content: '';
                position: absolute;
                display: block;
                height: 100%;
                width: 50%;
                background-color: ${palette.primary['100']};
                left: 0;
                z-index: -1;
            }
            button {
                background-color: ${palette.primary['500']};
                color: ${palette.neutral.white};
                &:hover {
                    background-color: ${palette.primary['600']};
                }
            }
        `,
        'will-be-in-range': css`
            background-color: ${palette.primary['100']};
            border-radius: 0;
            button {
                background-color: ${palette.primary['100']};
            }
        `,
        'will-be-range-start': css`
            position: relative;
            color: ${palette.neutral.white};
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            :before {
                content: '';
                position: absolute;
                display: block;
                height: 100%;
                width: 50%;
                background-color: ${palette.primary['100']};
                right: 0;
                z-index: -1;
            }
            button {
                background-color: ${palette.primary['500']};
                color: ${palette.neutral.white};
            }
        `,
        'will-be-range-end': css`
            position: relative;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            :before {
                content: '';
                position: absolute;
                display: block;
                height: 100%;
                width: 50%;
                background-color: ${palette.primary['100']};
                left: 0;
                z-index: -1;
            }
            button {
                background-color: ${palette.primary['500']};
                color: ${palette.neutral.white};
            }
        `
    }) as const satisfies Partial<Record<StyleByRangeProps, SimpleInterpolation>>

type DayButtonWrapperProps = {
    rangeStatus: StyleByRangeProps
    inCurrentMonth: boolean
}

export const HostCalendarDayButtonWrapper = styled.div<DayButtonWrapperProps>`
    ${({theme, rangeStatus, inCurrentMonth}) => css`
        display: flex;
        border-radius: 20px;
        justify-content: center;
        &:nth-child(7n),
        &.lastMonthDay {
        }
        &:nth-child(7n + 1),
        &.firstMonthDay {
        }
        ${inCurrentMonth && StyleByRangeStatus(theme)[rangeStatus]}
    `}
`

export const StyledHostCalendarDayButton = styled.button<StyledDayButtonProps>`
    ${({theme, outline, variant}) => css`
        display: grid;
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        place-items: center;
        font-weight: 700;
        cursor: pointer;
        font-size: ${theme.typography.textSm};
        &:disabled {
            color: ${theme.palette.neutral['300']};
        }
        ${ColorStyles(theme)[variant]};
        ${outline &&
        `
                border: 2px solid ${theme.palette.primary['500']};
            `}
    `}
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    primary: css`
        color: ${palette.primary['600']};
        background: ${palette.primary['50']};
        font-weight: 600;
        border: 1px solid transparent;
        &:disabled {
            background: ${palette.primary['200']};
            border-color: ${palette.primary['200']};
            color: ${palette.primary['400']};
            cursor: not-allowed;
        }
    `,
    ghost: css`
        background: ${palette.neutral.white};
        color: ${palette.primary['600']};
        border: 1px solid ${palette.neutral['200']};
        &:not(:disabled):hover {
            border-color: ${palette.primary['500']};
        }
        &:not(:disabled):focus {
            border-color: ${palette.primary['500']};
        }
        &:disabled {
            color: ${palette.neutral['300']};
            background-color: ${palette.neutral['50']};
            cursor: not-allowed;
        }
    `
})

export const HostCalendarWrapper = styled(Flexbox)`
    ${({theme: {palette, mediaQueries}}) => css`
        &:not(:last-child) {
            border-right: 1px solid ${palette.neutral['200']};
            ${mediaQueries.m} {
                border-right: unset;
            }
        }
    `}
`

export const StyledHostCalendarDayGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        row-gap: ${spacing * 2}px;
        grid-template-columns: repeat(7, minmax(40px, 1fr));
        width: 100%;
    `}
`

export const StyledHostYearMonthGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        row-gap: ${spacing * 5}px;
        column-gap: ${spacing * 5}px;
    `}
`
export const StyledHostBackButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['600']};
        margin-left: auto;
        margin-top: auto;
        &:hover {
            color: ${palette.primary['600']};
        }
    `}
`
