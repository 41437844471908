import {
    StyledServicesSectionBody,
    StyledServicesSectionHeader,
    StyledServicesSectionWrapper
} from '@/features/promotional-services/components/promo-services-section/style.ts'
import {ServicePromoCard} from '@/features/promotional-services/components/service-promo-card/ServicePromoCard.tsx'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {useDeclineServiceStore} from '@/features/service-decline/store/declineServiceStore.tsx'
import {ServiceDeclineProcess} from '@/features/service-decline/components/service-decline-process/ServiceDeclineProcess.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {DefaultNamespace} from 'i18next'
import {ReactElement} from 'react'

interface PromoServicesSectionProps {
    id?: string
    services: ServiceProposal[]
    requestServiceModalCb?: () => void
    requestServiceCtaLabel?: DefaultNamespace | string
    showRequestBtn?: boolean
    title: DefaultNamespace | string
    subtitle: DefaultNamespace | ReactElement | string
}

export const PromoServicesSection = ({
    id,
    services,
    requestServiceModalCb,
    requestServiceCtaLabel,
    showRequestBtn = false,
    title,
    subtitle
}: PromoServicesSectionProps) => {
    //decline store state and action
    const declineServiceModalToggle = useDeclineServiceStore(state => state.declineServiceModalToggle)
    const showDeclineModal = useDeclineServiceStore(state => state.modalVisible)
    //accept store state and action
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)

    return (
        <>
            {showDeclineModal && (
                <Modal width={438} onClose={declineServiceModalToggle} onOverlayClick={declineServiceModalToggle}>
                    <ServiceDeclineProcess />
                </Modal>
            )}

            <StyledServicesSectionWrapper id={id}>
                <StyledServicesSectionHeader>
                    <Flexbox justify={'space-between'} align={'start'}>
                        <div>
                            <h4>{title}</h4>
                            <p>{subtitle}</p>
                        </div>
                        {showRequestBtn && (
                            <Button variant={'tertiary'} size={'md'} onClick={requestServiceModalCb}>
                                {requestServiceCtaLabel}
                            </Button>
                        )}
                    </Flexbox>
                </StyledServicesSectionHeader>
                <StyledServicesSectionBody>
                    {services.map((service, index) => (
                        <ServicePromoCard
                            key={index}
                            service={service}
                            onAcceptCb={() => {
                                acceptServiceModalToggle(service)
                            }}
                            onDeclineCb={() => declineServiceModalToggle(service)}
                        />
                    ))}
                </StyledServicesSectionBody>
            </StyledServicesSectionWrapper>
        </>
    )
}

PromoServicesSection.displayName = 'PromoServicesSection'
