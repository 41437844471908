import Container from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'

export const StyledCoAlarmRequestDetails = styled.div`
    min-width: 0;
    position: static;
`

export const StyledCoAlarmRequestDetailsSection = styled(Container)(
    ({theme: {spacing, typography, palette}}) => css`
        & h3 {
            ${typography.textLg};
            color: ${palette.neutral[900]};
            font-weight: 500;
            margin: ${spacing * 2}px 0;
        }
    `
)
