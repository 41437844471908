import {
    StyledSlidingModalWrapper,
    StyledSlidingModalOverlay,
    StyledSlidingModalHeader,
    ANIMATION_DURATION
} from '@components/commons/sliding-modal/style.ts'
import {FC, HTMLAttributes, ReactNode, useEffect} from 'react'
import {XCloseIcon} from '@components/ui/icon'
import {Button} from '@components/ui/button/Button.tsx'

export type SlidingModalState = 'open' | 'closing' | 'closed'

export const handleCloseSlidingModal = (setSlidingModalState: (step: SlidingModalState) => void) => {
    setSlidingModalState('closing')
    setTimeout(() => {
        setSlidingModalState('closed')
    }, ANIMATION_DURATION)
}

export const SlidingModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledSlidingModalHeader {...props} />

export const SlidingModalCloseButton: FC<{setSlidingModalState: (slidingModalState: SlidingModalState) => void}> = ({
    setSlidingModalState
}) => (
    <Button shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)} variant={'ghost'} size={'sm'}>
        <XCloseIcon />
    </Button>
)

interface SlidingModalProps {
    children: ReactNode
    onOverlayClick?: () => void
    slidingModalState: SlidingModalState
    className?: string
}
export const SlidingModal: FC<SlidingModalProps> = ({children, onOverlayClick, slidingModalState, className}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [])
    return (
        <>
            <StyledSlidingModalOverlay onClick={onOverlayClick} $isModalClosing={slidingModalState == 'closing'} />
            <StyledSlidingModalWrapper className={className} $isModalClosing={slidingModalState == 'closing'}>
                {children}
            </StyledSlidingModalWrapper>
        </>
    )
}
