import {FC, ReactNode} from 'react'
import {XCloseIcon} from '@/components/ui/icon'
import {StyledCategoryChip, StyledCategoryChipContainer} from './style'

interface CategoryChipProps {
    label: string
    id: number
    removed?: boolean
    icon?: ReactNode
    buttonPosition?: 'left' | 'right'
    isLimitReached?: boolean
    onClick?: (categoryId: number) => void
}

export const CategoryChip: FC<CategoryChipProps> = ({
    label,
    id,
    removed = false,
    icon,
    buttonPosition = 'right',
    isLimitReached = false,
    onClick
}) => {
    return (
        <StyledCategoryChipContainer onClick={() => removed && onClick && onClick(id)}>
            <StyledCategoryChip
                align={'center'}
                justify={'space-between'}
                direction={buttonPosition === 'right' ? 'row' : 'row-reverse'}
                gap={2}
                disabled={removed}
            >
                {icon}

                <span>{label}</span>

                {onClick && !removed && !isLimitReached && (
                    <button onClick={() => onClick(id)}>
                        <XCloseIcon size={16} />
                    </button>
                )}
            </StyledCategoryChip>
        </StyledCategoryChipContainer>
    )
}
