import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {Image03Icon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {Dispatch, FC, SetStateAction, useState} from 'react'
import {Quote} from '@/features/service/types.ts'
import {httpClickAction} from '@/features/service-actions/services/actions.http.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

interface BlockCalendarDateActionProps {
    actionId: ServiceAction['id']
    isCompleted: boolean
    link?: string
    quoteId: Quote['id']
    setPendingActionsCount: Dispatch<SetStateAction<number>>
}

export const UpdateListingPhotosAction: FC<BlockCalendarDateActionProps> = ({
    actionId,
    isCompleted,
    link,
    setPendingActionsCount
}) => {
    const {t} = useTranslation()
    const [isClicked, setIsClicked] = useState(false)
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()

    const clickAction = () => {
        window.open(link, '_blank')
        httpClickAction({...urlParams, actionId})
        setIsClicked(true)
        setPendingActionsCount(state => state - 1)
    }

    return (
        <ActionCard
            onClick={link ? clickAction : undefined}
            isCompleted={isClicked || isCompleted}
            icon={Image03Icon}
            paragraph={t(`service_details:actions:update_listing_photos:paragraph`)}
            title={t('service_details:actions:update_listing_photos:title')}
        />
    )
}

UpdateListingPhotosAction.displayName = 'UpdateListingPhotosAction'
