import {ReactNode} from 'react'
import {
    StyledPageHeaderContainer,
    StyledPageHeaderContantWrapper,
    StyledPageHeaderEndSlot,
    StyledPageHeaderText
} from '@components/commons/page-hero/style.ts'
import Skeleton from '@components/ui/skeleton/Skeleton.tsx'

interface PageHeaderProps {
    asSkeleton?: boolean
    title: string
    subtitle?: ReactNode
    ctaComponent?: ReactNode
    endSlot?: ReactNode
}

const PageHero = ({asSkeleton, title, subtitle, ctaComponent, endSlot}: PageHeaderProps) => {
    if (asSkeleton) {
        return (
            <StyledPageHeaderContainer>
                <StyledPageHeaderContantWrapper
                    justify="space-between"
                    width="100%"
                    direction="row"
                    align="center"
                    gap={6}
                >
                    <StyledPageHeaderText direction={'column'} gap={3} width="100%">
                        {!!ctaComponent && <Skeleton width={120} height={40} />}
                        <Skeleton width={280} height={38} />
                        <Skeleton width={400} height={24} />
                    </StyledPageHeaderText>
                    {!!endSlot && (
                        <StyledPageHeaderEndSlot>
                            <Skeleton width={80} height={40} />
                        </StyledPageHeaderEndSlot>
                    )}
                </StyledPageHeaderContantWrapper>
            </StyledPageHeaderContainer>
        )
    }

    return (
        <StyledPageHeaderContainer>
            <StyledPageHeaderContantWrapper justify="space-between" width="100%" direction="row" align="center" gap={6}>
                <StyledPageHeaderText direction={'column'} gap={3} width="100%">
                    {ctaComponent}
                    <h1>{title}</h1>
                    {subtitle && <p>{subtitle}</p>}
                </StyledPageHeaderText>
                {endSlot && <StyledPageHeaderEndSlot>{endSlot}</StyledPageHeaderEndSlot>}
            </StyledPageHeaderContantWrapper>
        </StyledPageHeaderContainer>
    )
}

export default PageHero

PageHero.displayName = 'PageHero'
