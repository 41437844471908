import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledListingCell = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            flex-direction: row-reverse;
        }
    `
)

export const StyledListingCellTextWrapper = styled(Flexbox)`
    overflow: hidden;
`

export const StyledCoAlarmButtonsCellWrapper = styled(Flexbox)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 2.5}px 0;

        ${mediaQueries.m} {
            width: 100%;
            padding: 0;

            & > button {
                width: 100%;
            }

            & > a {
                width: 100%;
            }
        }
    `
)

export const StyledCoAlarmListingStatusCell = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        & > div {
            overflow: hidden;
        }

        & > svg {
            flex-shrink: 0;
        }

        ${mediaQueries.m} {
            flex-direction: row-reverse;
        }
    `
)

export const StyledCoAlarmListingStatus = styled.h4(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['900']};
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `
)
