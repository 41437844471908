import styled, {keyframes} from 'styled-components'

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const BlinkingDot = styled.div`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    animation: ${blink} 1s infinite;
`
