import {useServicesProposals} from '@/features/promotional-services/services/queries/useServicesProposals.ts'
import {useInProgressServices} from '@/features/in-progress-services/services/queries/useInProgressServices.ts'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledServicesCard} from '@/features/chatbot/components/show-host-services/style.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {InProgressService} from '@/features/in-progress-services/types.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {httpGetService, HttpGetServiceResponse} from '@/features/service/services/service.http.ts'
import {errorHandler, parseAxiosPromise} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'
import {useState} from 'react'
import {captureException} from '@sentry/react'

export const ShowHostServices = () => {
    //const {t} = useTranslation()
    //prams and host params store
    const hostPassword = useHostParamsStore(state => state.hostPassword)
    const hostCode = useHostParamsStore(state => state.hostCode)
    //chatbot store
    const addStepToHistory = useChatbotStore(state => state.addStepToHistory)
    const setService = useChatbotStore(state => state.setService)
    const chatbotRef = useChatbotStore(state => state.chatbotRef)
    //services proposal query
    const {data: serviceProposals, isPending: loadingProposals} = useServicesProposals({
        hostCode: Number(hostCode),
        hostPassword: String(hostPassword)
    })
    const {remappedData: inProgressServices, isPending: loadingInProgress} = useInProgressServices({
        hostCode: Number(hostCode),
        hostPassword: String(hostPassword)
    })
    //state
    const [selectedService, setSelectedService] = useState<InProgressService | ServiceProposal | null>(null)
    //get selected quote
    const {run} = useAsync()

    const onSelectService = async (service: ServiceProposal | InProgressService) => {
        addStepToHistory({
            id: 'service_select',
            source: 'User',
            text: `[Selected service: ${service?.id}]`
        })
        setSelectedService(service)

        if (hostCode && hostPassword) {
            try {
                const selectedService = await run(
                    parseAxiosPromise({
                        axiosPromise: httpGetService({
                            urlParams: {hostCode: hostCode, hostPassword: hostPassword, quoteId: service?.id},
                            params: {response_type: 'extended'}
                        }),
                        responseShape: HttpGetServiceResponse,
                        onZodError: captureException
                    })
                )
                setService(selectedService)

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                chatbotRef?.current?.triggerNextStep({value: service, trigger: 'start'})
            } catch (e) {
                errorHandler(e as AxiosError<{message: string}>)
            }
        }
    }

    return (
        <Flexbox direction={'column'} gap={2}>
            {loadingProposals && loadingInProgress && <Spinner size={20} />}

            {!loadingProposals && selectedService && (
                <StyledServicesCard direction={'column'} gap={2}>
                    <Flexbox direction={'column'}>
                        <h5>{selectedService.entity.title}</h5>
                    </Flexbox>
                </StyledServicesCard>
            )}

            {!selectedService && (
                <>
                    {inProgressServices &&
                        inProgressServices.map(quote => (
                            <StyledServicesCard
                                key={quote.id}
                                direction={'column'}
                                gap={2}
                                onClick={() => onSelectService(quote)}
                            >
                                <Flexbox direction={'column'}>
                                    <h5>{quote.entity.title}</h5>
                                </Flexbox>
                            </StyledServicesCard>
                        ))}

                    {serviceProposals &&
                        serviceProposals.quotes.map(quote => (
                            <StyledServicesCard
                                key={quote.id}
                                direction={'column'}
                                gap={2}
                                onClick={() => onSelectService(quote)}
                            >
                                <Flexbox direction={'column'}>
                                    <h5>{quote.entity.title}</h5>
                                </Flexbox>
                            </StyledServicesCard>
                        ))}
                </>
            )}
        </Flexbox>
    )
}
