import styled, {css} from 'styled-components'

export const StyledPhotoStatsContainer = styled.div<{backgroundImg?: string}>`
    ${({theme: {mediaQueries}, backgroundImg}) => css`
        background: url(${backgroundImg}) right no-repeat;
        background-size: contain;
        background-origin: padding-box;
        ${mediaQueries.m} {
            background: none;
        }
    `}
`

export const StyledPhotoStatsWrapper = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 12}px;

        & > div {
            & > h4 {
                ${typography.textLg};
                color: ${palette.neutral['900']};
                font-weight: 500;
            }
        }

        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledPhotoStatsBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: ${spacing * 8}px;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `}
`
