import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/utilities/helpers'
import {captureException} from '@sentry/react'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpGetCoAlarmRequestOptions, httpGetCoAlarmRequest} from './coAlarm.http'
import {CoAlarmRequest} from '@/features/co-alarm/types'

export const useGetCoAlarmRequest = (
    promiseArgs: HttpGetCoAlarmRequestOptions,
    options?: Omit<UseQueryOptions<CoAlarmRequest>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.CO_ALARM_REQUEST_DETAILS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetCoAlarmRequest(promiseArgs),
                responseShape: CoAlarmRequest,
                onZodError: captureException
            })
    })
