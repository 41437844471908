import {
    StyledRequirementCard,
    StyledIconWrapper
} from '@/features/experiences-host-video/components/requirement-card/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, HTMLAttributes, ReactElement} from 'react'
export interface RequirementCardProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactElement
    iconWrapperVariant?: 'neutral' | 'danger' | 'success'
}
export const RequirementCard: FC<RequirementCardProps> = ({
    children,
    iconWrapperVariant = 'neutral',
    icon,
    ...rest
}) => {
    return (
        <StyledRequirementCard {...rest}>
            <StyledIconWrapper $variant={iconWrapperVariant}>{icon}</StyledIconWrapper>
            <Flexbox direction="column" gap={2}>
                {children}
            </Flexbox>
        </StyledRequirementCard>
    )
}
