import {StyledInProgressSectionHeader} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import Skeleton from '@components/ui/skeleton/Skeleton.tsx'

export const InProgressSectionHeaderLoader = () => (
    <StyledInProgressSectionHeader gap={1} justify={'space-between'}>
        <div>
            <Skeleton height={30} width={200} />
            <Skeleton height={20} />
        </div>
        <Skeleton width={180} height={40} />
    </StyledInProgressSectionHeader>
)

InProgressSectionHeaderLoader.displayName = 'InProgressSectionHeaderLoader'
