import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {stepToProgress} from '@/features/experiences-host-video/utils.tsx'
import {ProgressBar} from '@/features/experiences-host-video/components/progress-bar/ProgressBar.tsx'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams'

export const ExperiencesHostVideoProgressbar = () => {
    const urlParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams})
    if (!showExperiencesHostVideoQuery?.data) return

    return (
        <Flexbox width="100%" gap={1}>
            {stepToProgress(
                showExperiencesHostVideoQuery.data.status.key_name,
                showExperiencesHostVideoQuery.data.status.step_key_name
            ).map((barPercentage, index) => (
                <ProgressBar key={index} percentage={barPercentage} />
            ))}
        </Flexbox>
    )
}
