import {FC, ReactNode} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StatusDescriptionParagraphsWrapper: FC<{children: ReactNode}> = ({children}) => {
    return (
        <Flexbox direction="column" gap={6}>
            {children}
        </Flexbox>
    )
}
