import {ButtonHTMLAttributes, forwardRef} from 'react'
import {StyledButton} from '@components/ui/button/style.ts'

export type ButtonStyleProps = {
    fullWidth?: boolean
    shape?: 'rectangle' | 'square'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    variant?: 'primary' | 'primaryDanger' | 'secondary' | 'tertiary' | 'ghost' | 'ghostDanger' | 'ghostSuccess'
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({fullWidth, shape = 'rectangle', size = 'md', variant = 'primary', ...props}, ref) => (
        <StyledButton ref={ref} $fullWidth={fullWidth} $shape={shape} $size={size} $variant={variant} {...props} />
    )
)
Button.displayName = 'Button'
