import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {InProgressService} from '@/features/in-progress-services/types.ts'
import {PaginationParams} from '@/types/commons.ts'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'

/**
 * httpGetInProgressServices
 * Here we will fetch all the in progress services data for the dash table
 */
export const InProgressServicesSearchParams = z.object({
    orderBy: z.enum(['title', 'task_status', 'photographer_name', 'scheduled_at']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type InProgressServicesSearchParams = z.infer<typeof InProgressServicesSearchParams>

export const HttpInProgressServicesParams = z.object({
    ...DashboardURLParams.shape,
    ...InProgressServicesSearchParams.shape,
    page: z.number(),
    per_page: z.number()
})
export type HttpInProgressServicesParams = z.infer<typeof HttpInProgressServicesParams>

export const HttpInProgressServicesResponse = z.object({
    data: z.array(InProgressService),
    ...PaginationParams.shape
})
export type HttpInProgressServicesResponse = z.infer<typeof HttpInProgressServicesResponse>

export const httpGetInProgressServices = (params: HttpInProgressServicesParams) => {
    const paramsToSend = {
        page: params.page,
        per_page: params.per_page,
        orderBy: params.orderBy,
        orderDirection: params.orderDirection,
        search: params.search
    }
    const url = `/host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/in-progress`
    return axios.get(url, {params: paramsToSend})
}
