import {ServiceResource} from '@/features/service-resources/types.ts'
import after_photo_request from '@assets/images/service-resources/after_photo_request.jpeg'
import interacting_honestly from '@assets/images/service-resources/interacting_honestly.jpeg'
import paying_photoshoot from '@assets/images/service-resources/paying_photoshoot.jpeg'
import reschedule_cancel_photo from '@assets/images/service-resources/reschedule_cancel_photo.jpeg'
import add_accessibility from '@assets/images/service-resources/add_accessibility.jpeg'
import host_prep from '@assets/images/service-resources/host_prep.jpeg'
import what_expect_photo from '@assets/images/service-resources/what_expect_photo.jpeg'
import photo_for_accessibility from '@assets/images/service-resources/photo_for_accessibility.jpeg'
import after_photoshoot from '@assets/images/service-resources/after_photoshoot.jpeg'
import adding_visual_desc from '@assets/images/service-resources/adding_visual_desc.jpeg'
import social_media_star from '@assets/images/service-resources/social_media_star.jpeg'
import photo_with_phone from '@assets/images/service-resources/photo_with_phone.jpeg'
import add_photo_tour from '@assets/images/service-resources/add_photo_tour.jpeg'
import upgrade_listing_photo from '@assets/images/service-resources/upgrade_listing_photo.png'
import airbnb_profile_photo from '@assets/images/service-resources/airbnb_profile_photo.png'
import important_video_info from '@assets/images/service-resources/important_video_info.jpeg'
import walk_things_to_know from '@assets/images/service-resources/walk_things_to_know.jpeg'

export type ServiceResourceProps = {
    linkText: string
    link: string
    image: string
}

export const ServiceResourcesInfo = {
    after_photo_request: {
        linkText: 'resources:after_photo_request',
        link: 'https://www.airbnb.com/help/article/298',
        image: after_photo_request
    },
    interacting_honestly: {
        linkText: 'resources:interacting_honestly',
        link: 'https://www.airbnb.com/help/article/3049',
        image: interacting_honestly
    },
    paying_photoshoot: {
        linkText: 'resources:paying_photoshoot',
        link: 'https://www.airbnb.com/help/article/2094',
        image: paying_photoshoot
    },
    reschedule_cancel_photo: {
        linkText: 'resources:reschedule_cancel_photo',
        link: 'https://www.airbnb.com/help/article/300',
        image: reschedule_cancel_photo
    },
    add_accessibility: {
        linkText: 'resources:add_accessibility',
        link: 'https://www.airbnb.com/help/article/1961',
        image: add_accessibility
    },
    host_prep: {
        linkText: 'resources:host_prep',
        link: 'https://airbnb.app.box.com/s/o22olodo92xrddgse05ek720bbxhc2uw',
        image: host_prep
    },
    what_expect_photo: {
        linkText: 'resources:what_expect_photo',
        link: 'https://www.airbnb.com/help/article/2018',
        image: what_expect_photo
    },
    photo_for_accessibility: {
        linkText: 'resources:photo_for_accessibility',
        link: 'https://www.airbnb.com/resources/hosting-homes/a/a-guide-to-photographing-accessibility-features-30',
        image: photo_for_accessibility
    },
    after_photoshoot: {
        linkText: 'resources:after_photoshoot',
        link: 'https://www.airbnb.com/help/article/2019',
        image: after_photoshoot
    },
    adding_visual_desc: {
        linkText: 'resources:adding_visual_desc',
        link: 'https://www.airbnb.com/help/article/3452',
        image: adding_visual_desc
    },
    social_media_star: {
        linkText: 'resources:social_media_star',
        link: 'https://www.airbnb.com/resources/hosting-homes/a/turning-your-space-into-a-social-media-star-94',
        image: social_media_star
    },
    photo_with_phone: {
        linkText: 'resources:photo_with_phone',
        link: 'https://www.airbnb.com/resources/hosting-homes/a/take-great-photos-of-your-space-on-your-phone-221',
        image: photo_with_phone
    },
    add_photo_tour: {
        linkText: 'resources:add_photo_tour',
        link: 'https://www.airbnb.com/help/article/3509',
        image: add_photo_tour
    },
    upgrade_listing_photo: {
        linkText: 'resources:upgrade_listing_photo',
        link: 'https://www.airbnb.com/resources/hosting-homes/a/upgrade-your-listing-photos-46',
        image: upgrade_listing_photo
    },
    airbnb_profile_photo: {
        linkText: 'resources:airbnb_profile_photo',
        link: 'https://www.airbnb.com/resources/hosting-homes/a/how-to-take-a-great-airbnb-profile-photo-581',
        image: airbnb_profile_photo
    },
    important_info_video_article: {
        linkText: 'resources:important_info_video_article',
        link: 'https://www.airbnb.com/e/experiences-media-host-prep-guide',
        image: important_video_info
    },
    walkthrough_standards: {
        linkText: 'resources:walkthrough_standards',
        link: 'https://www.airbnb.com/help/article/3307',
        image: add_photo_tour
    },
    prepare_walkthrough: {
        linkText: 'resources:prepare_walkthrough',
        link: 'https://www.airbnb.com/help/article/3307#section-heading-4-0',
        image: host_prep
    },
    walkthrough_things_to_know: {
        linkText: 'resources:walkthrough_things_to_know',
        link: 'https://www.airbnb.com/e/10_things_you_should_know_about_airbnb_and_airbnbluxe',
        image: walk_things_to_know
    }
} as const satisfies Partial<Record<ServiceResource['key_name'], ServiceResourceProps>>
