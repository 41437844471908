import Container from '@components/ui/container/Container.tsx'
import PageHeader from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {InProgressSection} from '@/features/in-progress-services/components/in-progress-section/InProgressSection.tsx'
import {useServicesProposals} from '@/features/promotional-services/services/queries/useServicesProposals.ts'
import {PromoSectionLoader} from '@/features/promotional-services/components/promo-section-loader/PromoSectionLoader.tsx'
import {useEffect, useMemo, useState} from 'react'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {routes} from '@utilities/constants'
import {StyledSSOModal} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget.tsx'
import {AirbnbIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {useInProgressServices} from '@/features/in-progress-services/services/queries/useInProgressServices.ts'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import {StyledDashGrid} from '@pages/dashboard/style.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {DashboardMobileTabs} from '@pages/dashboard/DashboardMobileTabs.tsx'
import {PromoPhotoSection} from '@/features/promotional-services/components/promo-photo-section/PromoPhotoSection.tsx'
import {PromoMatterportSection} from '@/features/promotional-services/components/promo-matterport-section/PromoMatterportSection.tsx'
import {PromoExperienceVideoSection} from '@/features/promotional-services/components/promo-experience-video-section/PromoExperienceVideoSection.tsx'
import {PromoWalkthroughSection} from '@/features/promotional-services/components/promo-walkthrough-section/PromoWalkthroughSection.tsx'
import {atLeastTwoNonEmpty} from '@utilities/helpers.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {ServiceAcceptanceProcess} from '@/features/service-acceptance-flow/components/ServiceAcceptanceProcess.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {breakpoints} from '@/theme/mediaQueries.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {HostApprovalSection} from '@/features/host-approval/components/host-approval-section/HostApprovalSection.tsx'
import {useApprovalRequestsList} from '@/features/host-approval/services/queries/useApprovalRequestsList.ts'

import {HostExpProposalsSection} from '@/features/experiences-host-video/components/host-experience-proposals-section/HostExpPromoSection.tsx'
import {useHostExperienceProposals} from '@/features/experiences-host-video/services/useHostExperienceProposals.ts'

export const Dashboard = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const navigate = useNavigate()
    const {width} = useWindowDimensions()
    const accessToken = useAuthStore(state => state.accessToken)
    //params and host params store
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    const setHostParams = useHostParamsStore(state => state.setHostParams)
    //host approval requests query
    const {data: approvalRequests, isPending: isApprovalRequestsPending} = useApprovalRequestsList({
        urlParams: urlParams,
        params: {response_type: 'extended'}
    })
    //host experience proposals
    const {data: hostExperienceProposals, isPending: isExpProposalsPending} = useHostExperienceProposals({
        urlParams: urlParams,
        params: {response_type: 'extended'}
    })
    //services proposal query
    const {
        experienceVideoServices,
        walkthroughServices,
        photoServices,
        matterportServices,
        isPending: loadingProposals
    } = useServicesProposals(urlParams)
    //in-progress query
    const {remappedData: inProgressServices, isPending: isInProgressServicesPending} = useInProgressServices(urlParams)
    const [showSSOModal, setShowSSOModal] = useState(false)
    //handle request photoShoot btn click
    const onPhotoShootRequestClick = () => {
        if (accessToken) {
            return navigate(routes.PHOTOSHOOT_REQUEST.path)
        } else {
            setShowSSOModal(true)
        }
    }
    //chatbot store
    const resetChatBotService = useChatbotStore(state => state.resetService)
    //services checks
    const hasPhotoServicesPromo = useMemo(() => photoServices.length > 0, [photoServices])
    const hasWalkthroughServicesPromo = useMemo(() => walkthroughServices.length > 0, [walkthroughServices])
    const hasMatterportServicesPromo = useMemo(() => matterportServices.length > 0, [matterportServices])
    const hasExperienceVideoPromo = useMemo(() => experienceVideoServices.length > 0, [experienceVideoServices])
    //to indicate the absence of available promotional services
    const noAvailablePromoServices = useMemo(
        () =>
            !hasPhotoServicesPromo &&
            !hasWalkthroughServicesPromo &&
            !hasMatterportServicesPromo &&
            !hasExperienceVideoPromo,
        [walkthroughServices, photoServices, matterportServices, experienceVideoServices]
    )
    //to indicate that we have at least 2 different promo services
    const atLeastTwoDifferentPromoServices = useMemo(
        () => atLeastTwoNonEmpty(walkthroughServices, photoServices, matterportServices, experienceVideoServices),
        [walkthroughServices, photoServices, matterportServices, experienceVideoServices]
    )
    const showAcceptModal = useServiceAcceptStore(state => state.modalVisible)
    const showPageHero =
        loadingProposals ||
        isInProgressServicesPending ||
        atLeastTwoDifferentPromoServices ||
        noAvailablePromoServices ||
        inProgressServices.length > 0
    const showHostApprovalSection = !isApprovalRequestsPending && !!approvalRequests && approvalRequests.length > 0
    const showHostExpProposalsSection =
        !isExpProposalsPending && !!hostExperienceProposals && hostExperienceProposals.length > 0
    //set host params into the store
    useEffect(() => {
        setHostParams({hostCode: urlParams.hostCode, hostPassword: urlParams.hostPassword})
        resetChatBotService()
    }, [urlParams])

    return (
        <StyledDashGrid $showPaddingTop={!showPageHero && showHostApprovalSection}>
            <Helmet title={String(t('dashboard:page_title_meta'))} />

            {showAcceptModal && (
                <Modal width={438}>
                    <ServiceAcceptanceProcess />
                </Modal>
            )}
            {showSSOModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOModal(!showSSOModal)}>
                    <AirbnbSSOWidget
                        serviceIcon={<AirbnbIcon size={64} fill={palette.primary['600']} />}
                        title={t('sso_login_dash_photo:title')}
                        subtitle={t('sso_login_dash_photo:subtitle')}
                        disclaimer={t('photoshoot_landing:sso:disclaimer')}
                    />
                </StyledSSOModal>
            )}

            {showPageHero && (
                <Container fullHeight={false}>
                    <PageHeader
                        asSkeleton={loadingProposals || isInProgressServicesPending}
                        title={t('dashboard:title')}
                        subtitle={t('dashboard:subtitle')}
                    />
                </Container>
            )}

            {isApprovalRequestsPending && <PromoSectionLoader />}
            {showHostApprovalSection && <HostApprovalSection hostApprovalRequests={approvalRequests} />}

            {isExpProposalsPending && <PromoSectionLoader />}
            {showHostExpProposalsSection && <HostExpProposalsSection hostExpProposals={hostExperienceProposals} />}

            {atLeastTwoDifferentPromoServices && inProgressServices.length > 0 && <DashboardMobileTabs />}

            {width > breakpoints.width.m && atLeastTwoDifferentPromoServices && (
                <Divider topSpacing={0} bottomSpacing={0} />
            )}

            {loadingProposals && <PromoSectionLoader />}
            {!noAvailablePromoServices && !loadingProposals && (
                <>
                    {photoServices.length > 0 && (
                        <PromoPhotoSection
                            onPhotoShootRequestClick={onPhotoShootRequestClick}
                            photoServices={photoServices}
                            showFullServicesInfo={
                                !isInProgressServicesPending &&
                                inProgressServices.length == 0 &&
                                !hasWalkthroughServicesPromo &&
                                !hasMatterportServicesPromo &&
                                !hasExperienceVideoPromo
                            }
                        />
                    )}

                    {walkthroughServices.length > 0 && (
                        <PromoWalkthroughSection
                            walkthroughServices={walkthroughServices}
                            showFullServicesInfo={
                                !isInProgressServicesPending &&
                                inProgressServices.length == 0 &&
                                !hasPhotoServicesPromo &&
                                !hasMatterportServicesPromo &&
                                !hasExperienceVideoPromo
                            }
                        />
                    )}

                    {matterportServices.length > 0 && (
                        <PromoMatterportSection
                            matterportServices={matterportServices}
                            showFullServicesInfo={
                                !isInProgressServicesPending &&
                                inProgressServices.length == 0 &&
                                !hasPhotoServicesPromo &&
                                !hasWalkthroughServicesPromo &&
                                !hasExperienceVideoPromo
                            }
                        />
                    )}

                    {experienceVideoServices.length > 0 && (
                        <PromoExperienceVideoSection
                            experienceVideoService={experienceVideoServices}
                            showFullServicesInfo={
                                !isInProgressServicesPending &&
                                inProgressServices.length == 0 &&
                                !hasPhotoServicesPromo &&
                                !hasWalkthroughServicesPromo &&
                                !hasMatterportServicesPromo
                            }
                        />
                    )}
                </>
            )}

            <InProgressSection
                noAvailablePromoServices={noAvailablePromoServices}
                requestPhotoShootModalCb={onPhotoShootRequestClick}
                userHasPhotoServicesPromo={hasPhotoServicesPromo}
            />
        </StyledDashGrid>
    )
}

Dashboard.displayName = 'Dashboard'
