import {CoAlarmRequest} from '@/features/co-alarm/types'
import {StyledCoAlarmShippingInfoCardGrid, StyledCoAlarmShippingInfoCell} from './style'
import {useTranslation} from 'react-i18next'

export const CoAlarmShippingInfoCard = ({request}: {request: CoAlarmRequest}) => {
    const {t} = useTranslation()

    return (
        <StyledCoAlarmShippingInfoCardGrid>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:first_name')}</h4>
                <p>{request.shipping_first_name}</p>
            </StyledCoAlarmShippingInfoCell>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:last_name')}</h4>
                <p>{request.shipping_last_name}</p>
            </StyledCoAlarmShippingInfoCell>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:country')}</h4>
                <p>{request.shipping_country}</p>
            </StyledCoAlarmShippingInfoCell>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:phone')}</h4>
                <p>+{request.shipping_phone_number}</p>
            </StyledCoAlarmShippingInfoCell>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:address')}</h4>
                <p>{request.shipping_address}</p>
            </StyledCoAlarmShippingInfoCell>
            <StyledCoAlarmShippingInfoCell className="shipping-info-cell" direction="column" justify="center" gap={2}>
                <h4>{t('coAlarm:form:address_extras')}</h4>
                <p>{request.shipping_address_extras ?? '-'}</p>
            </StyledCoAlarmShippingInfoCell>
        </StyledCoAlarmShippingInfoCardGrid>
    )
}
