import {StyledAcceptStepHeaderCloseButton} from '@/features/service-acceptance-flow/components/style.ts'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {useTranslation} from 'react-i18next'
import {FC, useMemo} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {XCloseIcon} from '@components/ui/icon'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {useNotificationPermission} from '@/features/otp-flow/services/queries/useNotificationPermission.ts'
import {AcceptanceModalHeader} from '@/features/service-acceptance-flow/components/AcceptanceModalHeader.tsx'
import {AcceptanceModalStepsIndicator} from '@/features/service-acceptance-flow/components/AcceptanceModalStepsIndicator.tsx'
import {SmsNotifyStep} from '@/features/notifications-settings/components/SmsNotifyStep.tsx'

export const ServiceSmsNotifyStep: FC<{isAcceptanceFlow: boolean}> = ({isAcceptanceFlow}) => {
    const {t} = useTranslation()
    //otp store
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    //acceptance store
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const portraitEnabled = useServiceAcceptStore(state => state.portraitEnabled)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )

    //modal composition utils
    const modalHeaderTitle = useMemo(() => {
        if (coAlarmRequest) {
            return t(AcceptanceStepsConfigByService['sms']['coAlarm'].modal_title)
        } else if (isAcceptanceFlow) {
            return t(AcceptanceStepsConfigByService['sms'][serviceType].modal_title)
        } else {
            return ''
        }
    }, [service, coAlarmRequest])

    const modalHeaderSubtitle = useMemo(
        () => (isAcceptanceFlow ? t('service_accept:common:step_indicator', {current: 1, total: 2}) : ''),
        [service]
    )

    const onCloseModalToggle = () => {
        acceptServiceModalToggle()
    }

    //mutations
    const {isPending} = useNotificationPermission()

    return (
        <>
            <AcceptanceModalHeader
                actionIcon={
                    <StyledAcceptStepHeaderCloseButton
                        disabled={isPending}
                        size="lg"
                        iconOnly
                        variant="ghost"
                        onClick={onCloseModalToggle}
                    >
                        <XCloseIcon />
                    </StyledAcceptStepHeaderCloseButton>
                }
                portraitEnabled={portraitEnabled}
                title={modalHeaderTitle}
                subtitle={modalHeaderSubtitle}
            />
            <AcceptanceModalStepsIndicator portraitEnabled={portraitEnabled} />

            <SmsNotifyStep isAcceptanceFlow={isAcceptanceFlow} />
        </>
    )
}

ServiceSmsNotifyStep.displayName = 'ServiceSmsStep'
