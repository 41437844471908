import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {UserSquareIcon} from '@components/ui/icon'
import Divider from '@components/ui/divider/Divider.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import {useTheme} from 'styled-components'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledBody, StyledFooter} from '@components/commons/profile-photo-proposal/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface ProfilePhotoProposalProps {
    isSubmitting: boolean
    onSubmit: (hasAccepted: boolean) => void
}
export const ProfilePhotoProposal: FC<ProfilePhotoProposalProps> = ({onSubmit, isSubmitting}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledBody direction={'column'} gap={6}>
                <Flexbox direction={'column'} gap={2}>
                    <IconContainer size={'lg'}>
                        <UserSquareIcon fill={theme.palette.primary['600']} />
                    </IconContainer>
                    <h3>{t('profile_photo:title')}</h3>
                    <p>{t('profile_photo:paragraph1')}</p>
                    <p>{t('profile_photo:paragraph2')}</p>
                    <p>{t('profile_photo:paragraph3')}</p>
                </Flexbox>
            </StyledBody>

            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledFooter justify={'space-between'}>
                <Button variant={'tertiary'} size={'md'} onClick={() => onSubmit(false)} disabled={isSubmitting}>
                    {t('commons:decline')}
                </Button>
                <Button variant={'primary'} size={'md'} onClick={() => onSubmit(true)} disabled={isSubmitting}>
                    {t('profile_photo:accept_profile_photo')}
                </Button>
            </StyledFooter>
        </>
    )
}

ProfilePhotoProposal.displayName = 'ProfilePhotoProposal'
