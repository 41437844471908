import {z} from 'zod'
import {DECLINE_OTHER_REASON_ID} from '@utilities/constants'
import {CancellationReasonKeyWithPrefix} from '@/features/service-cancel/types.ts'

export const Reason = z.object({
    id: z.coerce.number(),
    key_name: CancellationReasonKeyWithPrefix
})
export type Reason = z.infer<typeof Reason>

export type DeclineStep = 'warning' | 'final'

export type DeclineStepField = 'title' | 'subtitle' | 'reason' | 'primary_cta' | 'secondary_cta' | 'stats'

export const ServiceDeclineSchema = z
    .object({
        reason_id: z.coerce.number().min(1),
        notes: z.string().optional()
    })
    .superRefine((values, context) => {
        if (values.reason_id == DECLINE_OTHER_REASON_ID && !values.notes) {
            context.addIssue({
                code: 'custom',
                path: ['reason_id'],
                message: 'required'
            })
        }
    })
export type ServiceDeclineSchema = z.infer<typeof ServiceDeclineSchema>
