import {z} from 'zod'

/**
 * HttpServiceResourcesLogParams schema and Types
 */
export const HttpServiceResourcesLogParams = z.object({
    hostCode: z.number(),
    hostPassword: z.string(),
    quoteId: z.number(),
    tipId: z.number()
})
export type HttpServiceResourcesLogParams = z.infer<typeof HttpServiceResourcesLogParams>

/**
 * ServiceResource schema and Types
 */
export const ServiceResource = z.object({
    key_name: z.enum([
        'after_photo_request',
        'interacting_honestly',
        'paying_photoshoot',
        'reschedule_cancel_photo',
        'add_accessibility',
        'host_prep',
        'what_expect_photo',
        'photo_for_accessibility',
        'after_photoshoot',
        'adding_visual_desc',
        'social_media_star',
        'photo_with_phone',
        'add_photo_tour',
        'upgrade_listing_photo',
        'airbnb_profile_photo',
        'important_info_video_article',
        'walkthrough_standards',
        'prepare_walkthrough',
        'walkthrough_things_to_know'
    ]),
    id: z.number()
})
export type ServiceResource = z.infer<typeof ServiceResource>
