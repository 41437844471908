import Container from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AirbnbIcon, AlertTriangleIcon} from '@/components/ui/icon'
import {IconContainer} from '@/components/ui/icon-container/IconContainer'
import {useTheme} from 'styled-components'
import {
    StyledCoAlarmInstallationWarningTitle,
    StyledCoAlarmInstallationWarningParagraph,
    StyledCoAlarmInstallationBoxTextWrapper
} from '@/features/co-alarm/components/co-alarm-installation-warning-box/style'
import {useTranslation} from 'react-i18next'
import Button from '@components/ui/button-legacy/Button'
import {useState} from 'react'
import {ExistingCoAlarmModal} from '../existing-co-alarm-modal/ExistingCoAlarmModal'

export const CoAlarmInstallationWarningBox = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [existingCoAlarmModal, showExistingCoAlarmModal] = useState(false)

    return (
        <>
            <Container fullHeight={false}>
                <Flexbox direction="column" gap={6} align="center">
                    <Flexbox direction="column" gap={4} align="center">
                        <IconContainer size="lg">
                            <AlertTriangleIcon color={palette.warning[600]} />
                        </IconContainer>

                        <StyledCoAlarmInstallationBoxTextWrapper direction="column" gap={2} align="center">
                            <StyledCoAlarmInstallationWarningTitle>
                                {t('coAlarm:installation_cards:update_listing_cta')}
                            </StyledCoAlarmInstallationWarningTitle>
                            <StyledCoAlarmInstallationWarningParagraph>
                                {t('coAlarm:installation_cards:update_listing_paragraph')}
                            </StyledCoAlarmInstallationWarningParagraph>
                        </StyledCoAlarmInstallationBoxTextWrapper>
                    </Flexbox>

                    <Flexbox gap={4}>
                        <Button variant="primary" size="sm" onClick={() => showExistingCoAlarmModal(true)}>
                            <AirbnbIcon />
                            <span>{t('coAlarm:installation_cards:update_listing_cta')}</span>
                        </Button>
                    </Flexbox>
                </Flexbox>
            </Container>
            {existingCoAlarmModal && <ExistingCoAlarmModal hideModal={() => showExistingCoAlarmModal(false)} />}
        </>
    )
}
