import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {HostApprovalRequest} from '@/features/host-approval/types.ts'
import {z} from 'zod'
import {
    httpGetHostApprovalRequestsList,
    httpGetHostApprovalRequestsListParams
} from '@/features/host-approval/services/hostApproval.http.ts'

export const useApprovalRequestsList = (
    params: httpGetHostApprovalRequestsListParams,
    options?: Omit<UseQueryOptions<HostApprovalRequest[]>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.APPROVAL_REQUESTS_LIST],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetHostApprovalRequestsList(params),
                onZodError: captureException,
                responseShape: z.array(HostApprovalRequest)
            })
    })
}
