import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledExperienceVideoValuesWrapper = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 12}px;

        & > div {
            & > h4 {
                ${typography.textLg};
                color: ${palette.neutral['900']};
                font-weight: 500;
            }
        }

        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledExperienceVideoValuesBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: ${spacing * 2}px;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `}
`

export const StyledExperienceVideoValuesIconWrapper = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        width: 32px;
        height: 32px;
        border-radius: 32px;
        box-sizing: border-box;
        background: ${palette.primary['100']};
        color: ${palette.primary['600']};
        margin-bottom: ${spacing * 2}px;
        & > svg {
            width: 16px;
        }
    `}
`
