import {FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {StyledRadio, StyledRadioInput, StyledLabel, StyledFeedbackRadioGroup} from './style'
import {RadioInput} from '@/features/feedback-form/types.ts'

interface FeedbackRadioGroupProps {
    minValue: number
    maxValue: number
    name: keyof RadioInput
}

export const FeedbackRadioGroup: FC<FeedbackRadioGroupProps> = ({minValue, maxValue, name}) => {
    const {register} = useFormContext<RadioInput>()
    return (
        <StyledFeedbackRadioGroup justify="space-between">
            {Array.from({length: maxValue - minValue + 1}).map((_, index) => {
                const value = index + minValue

                return (
                    <StyledLabel key={value}>
                        <StyledRadioInput type="radio" {...register(name)} value={value} />
                        <StyledRadio>{value}</StyledRadio>
                    </StyledLabel>
                )
            })}
        </StyledFeedbackRadioGroup>
    )
}
