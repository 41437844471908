import {FC} from 'react'
import InputText, {CustomInputProps} from '@components/commons/input-text/InputText.tsx'
import * as Ariakit from '@ariakit/react'
import {
    StyledDialog,
    StyledDialogBackdrop,
    StyledPopover,
    StyledTimeListItem
} from '@components/commons/time-picker/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

const hours = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
]

type TimePickerProps = {
    asModal?: boolean
    formatter?: (time: string) => string
    onBlur?: () => void
    disabled?: boolean
    errorMessage?: string
    selectedTime: string
    triggerProps?: Omit<CustomInputProps, 'type' | 'readonly' | 'value'>
    onSelectTime: (time: string) => void
}

export const TimePicker: FC<TimePickerProps> = ({
    asModal,
    selectedTime,
    triggerProps,
    formatter,
    onBlur,
    disabled,
    errorMessage,
    onSelectTime
}) => {
    if (asModal) {
        return (
            <Ariakit.DialogProvider
                setOpen={open => {
                    if (!open) {
                        onBlur?.()
                    }
                }}
                open={disabled ? false : undefined}
            >
                <Ariakit.DialogDisclosure
                    render={
                        <InputText
                            disabled={disabled}
                            {...triggerProps}
                            type="text"
                            value={selectedTime && formatter ? formatter(selectedTime) : selectedTime}
                            readOnly
                            errorMessage={errorMessage}
                        />
                    }
                />
                <StyledDialog portal backdrop={<StyledDialogBackdrop />}>
                    <Flexbox direction="column">
                        {hours.map(hour => (
                            <Ariakit.DialogDismiss
                                key={hour}
                                render={
                                    <StyledTimeListItem
                                        onClick={() => onSelectTime(hour)}
                                        $isSelected={selectedTime == hour}
                                    >
                                        {hour}
                                    </StyledTimeListItem>
                                }
                            />
                        ))}
                    </Flexbox>
                </StyledDialog>
            </Ariakit.DialogProvider>
        )
    }

    return (
        <Ariakit.PopoverProvider
            setOpen={open => {
                if (!open) {
                    onBlur?.()
                }
            }}
            open={disabled ? false : undefined}
        >
            <Ariakit.PopoverDisclosure
                render={
                    <InputText
                        disabled={disabled}
                        {...triggerProps}
                        type="text"
                        value={selectedTime && formatter ? formatter(selectedTime) : selectedTime}
                        readOnly
                        errorMessage={errorMessage}
                    />
                }
            />
            <StyledPopover portal>
                <Flexbox direction="column">
                    {hours.map(hour => (
                        <Ariakit.PopoverDismiss
                            key={hour}
                            render={
                                <StyledTimeListItem
                                    onClick={() => onSelectTime(hour)}
                                    $isSelected={selectedTime == hour}
                                >
                                    {hour}
                                </StyledTimeListItem>
                            }
                        />
                    ))}
                </Flexbox>
            </StyledPopover>
        </Ariakit.PopoverProvider>
    )
}
