import {FC, useEffect} from 'react'
import {errorHandler} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'
import {useAsync} from '@hooks/useAsync.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotReason} from '@/features/chatbot/types.ts'
import {defineTicketInfoByReason} from '@/features/chatbot/utils.tsx'
import {httpSubmitTicket} from '@/features/chatbot/services/submitTicket.http.ts'
import {useTranslation} from 'react-i18next'

interface TicketSubmitProps {
    reason: ChatbotReason
    link: string
}

const TicketSubmit: FC<TicketSubmitProps> = ({reason, link}) => {
    const service = useChatbotStore(state => state.service)
    const history = useChatbotStore(state => state.chatHistory)
    const chatbotRef = useChatbotStore(state => state.chatbotRef)
    const recap = history.map(({message}) => message).join(' --- ') // whole conversation (messages)
    const {run} = useAsync()
    const {t} = useTranslation()

    useEffect(() => {
        onSendTicketResults()
    }, [])

    const onSendTicketResults = async () => {
        if (!service) return
        try {
            await run(
                httpSubmitTicket({
                    code: Number(service?.entity?.code),
                    password: String(service?.entity?.password),
                    media: Number(service?.task_media.id),
                    data: {
                        ...defineTicketInfoByReason({
                            reason,
                            link,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            steps: chatbotRef?.current?.state?.renderedSteps
                        }),
                        recap: recap
                    }
                })
            )
        } catch (e) {
            errorHandler(e as AxiosError<{message: string}>)
        }
    }

    return <p>{t('chatbot:ticket_submit_message')}</p>
}

export default TicketSubmit
