import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Container from '@/components/ui/container/Container'

export const StyledHostApprovalRequestWrapper = styled.div`
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    overflow: hidden;
`

export const StyledHostApprovalRequestHero = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        padding-bottom: ${spacing * 8}px;
        & > h3 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        & > p {
            ${typography.textXl};
            color: ${palette.neutral['900']};
            & > a {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    `
)

export const StyledHostApprovalRequestContainer = styled(Container)(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 10}px;
        padding-bottom: ${spacing * 10}px;
    `
)

export const StyledHostApprovalRequestInfoModal = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)

export const StyledCompletedApprovalRequest = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        color: ${palette.primary['500']};
        height: 100%;
        max-width: 480px;
        justify-self: center;
        text-align: center;
        padding-top: ${spacing * 10}px;
        & > h2 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            font-weight: 600;
        }

        & > p {
            color: ${palette.neutral['900']};
            ${typography.textMd};
        }
    `
)

export const StyledHostApprovalReviewFooterWrapper = styled.div`
    bottom: 0;
    width: 100%;
    position: absolute;
`
