import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Skeleton from '@/components/ui/skeleton/Skeleton'
import {StyledCategoriesRequestBody} from './style'

export const AddCategoriesRequestLoader = () => {
    return (
        <StyledCategoriesRequestBody>
            <Flexbox direction="column" gap={5} width={'100%'}>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
                <Flexbox gap={2} width={'100%'}>
                    <Skeleton height={20} width={22} />
                    <Skeleton height={44} />
                </Flexbox>
            </Flexbox>
        </StyledCategoriesRequestBody>
    )
}
