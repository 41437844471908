import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {HttpGetServiceResponse} from '@/features/service/services/service.http.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'
import dayjs from 'dayjs'

interface genericServicePageStepsParams {
    service: HttpGetServiceResponse
}

export const genericServicePageSteps = ({service}: genericServicePageStepsParams): ChatbotStep[] => {
    const quoteStatus = service?.quote?.quote_status
    const taskStatus = service?.assignment?.task_status?.status
    const postProdStatus = service?.assignment?.task_status?.post_prod_status
    const shootBeyondSchedule = dayjs(service?.assignment?.to_shoot_at).isBefore(dayjs(new Date()))
    const isQuoteNotSigned = quoteStatus !== 'signed' && quoteStatus !== 'completed' && taskStatus != 'auto_cancelled'
    const showPreparingForPhotoshoot = quoteStatus !== 'completed' && taskStatus == 'scheduled'
    const isScheduled = taskStatus == 'scheduled'
    const isCompleted = taskStatus == 'completed'
    const isAssignmentAccepted = taskStatus == 'accepted'
    //const isHardCanceled = taskStatus == 'hard_cancelled' || quoteStatus !== 'cancelled'
    const isSoftCanceled = taskStatus == 'soft_cancelled'
    //const isCompletedOrAssetsInReviewOrPendingPayment =isCompleted || taskStatus == 'assets_in_review' || taskStatus == 'pending_payment'
    const showPhotographerMatch =
        taskStatus == 'pending' ||
        taskStatus == 'soft_cancelled' ||
        quoteStatus == 'declined' ||
        (taskStatus != 'pending_payment' &&
            taskStatus != 'completed' &&
            taskStatus != 'scheduled' &&
            taskStatus != 'hard_cancelled' &&
            taskStatus != 'needs_more_assets' &&
            taskStatus != 'pending_assets' &&
            service?.assignment)

    return [
        {
            id: 'service_detail',
            component: (
                <p>
                    {i18next.t('chatbot:generic_service_page_steps:service_detail_component')}{' '}
                    <strong> {service.entity.title ?? service.entity.external_id} </strong>
                </p>
            ),
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'service_detail',
                    source: 'Chatbot',
                    text: `Hey, you are now talking with our virtual assistant, About the listing: ${service.entity.title}`
                })
                return 'start'
            }
        },
        {
            id: 'start',
            options: [
                {
                    value: 'HP0',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP0_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '💶 I want a new price quote for another listing'
                        })
                        return 'HP0'
                    }
                },
                ...((quoteStatus !== 'completed' && taskStatus != 'pending_payment') || taskStatus == 'hard_cancelled'
                    ? [
                          {
                              value: 'HP1',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP1_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '💸 Price Quote'
                                  })
                                  return 'HP1'
                              }
                          }
                      ]
                    : []),
                ...(taskStatus != 'auto_cancelled' && taskStatus != 'hard_cancelled'
                    ? [
                          {
                              value: 'HP2',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP2_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🖼️ Images'
                                  })
                                  return 'HP2'
                              }
                          }
                      ]
                    : []),
                /*...(isQuoteNotSigned
                    ? [
                          {
                              value: 'HP3',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP3_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🕒 Expiration Date'
                                  })
                                  return 'HP3'
                              }
                          }
                      ]
                    : []),*/
                ...(isQuoteNotSigned && taskStatus != 'hard_cancelled'
                    ? [
                          {
                              value: 'HP4',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP4_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '⚠️ Incorrect details in my quote'
                                  })
                                  return 'HP4'
                              }
                          }
                      ]
                    : []),
                /*...(isQuoteNotSigned || taskStatus == 'accepted'
                    ? [
                          {
                              value: 'HP5',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP5_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🗓️ Scheduling my photoshoot'
                                  })
                                  return 'HP5'
                              }
                          }
                      ]
                    : []),*/
                ...(showPreparingForPhotoshoot
                    ? [
                          {
                              value: 'HP6',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP6_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🏡 Preparing for your photoshoot'
                                  })
                                  return 'HP6'
                              }
                          }
                      ]
                    : []),
                ...(showPhotographerMatch
                    ? [
                          {
                              value: 'HP7',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP7_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '📷️ Photographer match'
                                  })
                                  return 'HP7'
                              }
                          }
                      ]
                    : []),
                ...(taskStatus == 'assets_in_review' || taskStatus == 'needs_more_assets'
                    ? [
                          {
                              value: 'HP8',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP8_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🚥 My retouched images have not been uploaded yet on my airbnb listing page'
                                  })
                                  return 'HP8'
                              }
                          }
                      ]
                    : []),
                ...(isCompleted || postProdStatus == 'upload_completed'
                    ? [
                          {
                              value: 'HP9.2',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP9.2_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '⚠️ My images have been uploaded to the wrong listing page'
                                  })
                                  return 'HP9.2'
                              }
                          }
                      ]
                    : []),
                ...(isCompleted || postProdStatus == 'upload_completed'
                    ? [
                          {
                              value: 'HP10',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP10_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '📂 There are missing images or details in the uploaded photos'
                                  })
                                  return 'HP10'
                              }
                          }
                      ]
                    : []),
                ...(isScheduled
                    ? [
                          {
                              value: 'HP20',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP20_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🗓️ I want to reschedule my photoshoot'
                                  })
                                  return 'HP20'
                              }
                          }
                      ]
                    : []),
                ...(taskStatus == 'soft_cancelled' ||
                taskStatus == 'pending_assets' ||
                taskStatus == 'accepted' ||
                isScheduled ||
                quoteStatus == 'completed' ||
                taskStatus == 'pending_payment'
                    ? [
                          {
                              value: 'HP21',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP21_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🤝 Coordination with my photographer'
                                  })
                                  return 'HP21'
                              }
                          }
                      ]
                    : []),
                ...((isAssignmentAccepted || isSoftCanceled) && !shootBeyondSchedule
                    ? [
                          {
                              value: 'HP30',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP30_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🚫 I want to cancel my photoshoot'
                                  })
                                  return 'HP30'
                              }
                          }
                      ]
                    : []),
                /*...(taskStatus == 'auto_cancelled' || taskStatus == 'soft_cancelled'
                    ? [
                          {
                              value: 'HP31',
                              label: i18next.t('chatbot:generic_service_page_steps:start_HP31_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '📷️ Why has my photoshoot request been canceled? I still want a pro photoshoot.'
                                  })
                                  return 'HP31'
                              }
                          }
                      ]
                    : []),*/
                {
                    value: 'HP11',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP11_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '🧭 My topic is not listed'
                        })
                        return 'HP11'
                    }
                }
            ]
        }
    ]
}
