import styled, {css, DefaultTheme} from 'styled-components'
import {Virtuoso} from 'react-virtuoso'
import {MOBILE_TABLE_SCROLLBAR_WIDTH} from '@components/commons/table/MobileTable/style.ts'

export const StyledMobileTBody = styled(Virtuoso)`
    ${({theme: {palette}}) => css`
        &::-webkit-scrollbar {
            width: ${MOBILE_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`

export const StyledTRowWrapper = styled.div(
    ({theme: {palette, spacing}}: {theme: DefaultTheme}) => css`
        border-bottom: 1px solid ${palette.neutral[200]};
        margin-bottom: ${spacing * 4}px;
        padding: ${spacing * 2}px;
    `
)

export const StyledTRow = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
    `
)

export const StyledTCell = styled.div(
    ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        align-items: center;
        justify-content: start;
        ${typography.textSm}
    `
)

export const StyledTCellColumn = styled.div(
    ({theme: {palette}}) => css`
        display: block;
        min-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
        color: ${palette.neutral[600]};
    `
)

export const StyledTCellValue = styled.div(
    ({theme: {palette}}) => css`
        width: 100%;
        text-align: end;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${palette.neutral[900]};
    `
)
