import {useTranslation} from '@/translations/i18n.tsx'
import {FC, useEffect, useRef} from 'react'
import {
    handleCloseSlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    StyledSlidingModal,
    StyledTrailerExampleVideoBody,
    StyledSlidingModalHeader
} from '@/features/experiences-host-video/components/trailer-example-modal/style.ts'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {VideoTrailerController} from '@/features/experiences-host-video/components/video-trailer-controller/VideoTrailerController.tsx'
import {useVideoController} from '@/features/experiences-host-video/hooks/useVideoController.ts'
import firstVideoPoster from '@assets/images/experiences-host-video/first_video_poster.webp'
import secondVideoPoster from '@assets/images/experiences-host-video/second_video_poster.webp'
import thirdVideoPoster from '@assets/images/experiences-host-video/third_video_poster.webp'

interface TrailerExampleModalProps {
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
}

export const TrailerExampleModal: FC<TrailerExampleModalProps> = ({slidingModalState, setSlidingModalState}) => {
    const {t} = useTranslation()
    const firstVideoRef = useRef<HTMLVideoElement>(null)
    const secondVideoRef = useRef<HTMLVideoElement>(null)
    const thirdVideoRef = useRef<HTMLVideoElement>(null)
    const firstVideoController = useVideoController(firstVideoRef, {initialVideoState: 'playing', defaultMuted: true})
    const secondVideoController = useVideoController(secondVideoRef, {initialVideoState: 'pause', defaultMuted: true})
    const thirdVideoController = useVideoController(thirdVideoRef, {initialVideoState: 'pause', defaultMuted: true})

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(async entry => {
                    const target = entry.target as HTMLVideoElement
                    if (entry.isIntersecting) {
                        await target.play()
                    } else {
                        target.pause()
                    }
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        )

        firstVideoRef.current && observer.observe(firstVideoRef.current)
        secondVideoRef.current && observer.observe(secondVideoRef.current)
        thirdVideoRef.current && observer.observe(thirdVideoRef.current)
    }, [])

    //TODO: add the right video descriptions and titles
    return (
        <StyledSlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledSlidingModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {t('experiences_host_video:video_trailer_example')}
            </StyledSlidingModalHeader>
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledTrailerExampleVideoBody align={'center'} direction={'column'} gap={8}>
                    <VideoTrailerController
                        ref={firstVideoRef}
                        poster={firstVideoPoster}
                        title={t('experiences_host_video:intro:video_example:first_video_title')}
                        description={t('experiences_host_video:intro:video_example:first_video_description')}
                        src={'https://stream.media.muscache.com/a02YucAcdLYE02Xy3jZLv98XMGiTUaflVCYgLefzMJhWY.mp4'}
                        {...firstVideoController}
                    />
                    <VideoTrailerController
                        ref={secondVideoRef}
                        poster={secondVideoPoster}
                        title={t('experiences_host_video:intro:video_example:second_video_title')}
                        description={t('experiences_host_video:intro:video_example:second_video_description')}
                        src={'https://stream.media.muscache.com/maQuSnA7K45qL01ZNZovkLDKeFn00t016pwdXTcojbH9m4.mp4'}
                        {...secondVideoController}
                    />
                    <VideoTrailerController
                        ref={thirdVideoRef}
                        poster={thirdVideoPoster}
                        title={t('experiences_host_video:intro:video_example:third_video_title')}
                        description={t('experiences_host_video:intro:video_example:third_video_description')}
                        src={'https://stream.media.muscache.com/MA70201aVkQ42fMeoj02ba01MfldVo101zB9wvEDVz01YU8fw.mp4'}
                        {...thirdVideoController}
                    />
                </StyledTrailerExampleVideoBody>
            </ScrollArea>
        </StyledSlidingModal>
    )
}
