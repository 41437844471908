import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {UploadClipsDropzone} from '@/features/experiences-host-video/components/upload-clips-dropzone/UploadClipsDropzone.tsx'
import {useEffect} from 'react'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {useShowExperiencesHostVideoFriend} from '@/features/experiences-host-video/services/useShowExperiencesHostVideoFriend.ts'
import {useUpdateFriendStep} from '@/features/experiences-host-video/services/useUpdateFriendStep.ts'

export const UploadClipsFriend = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const setVideosetId = useClipsUpload(store => store.setVideosetId)
    const videosetId = useClipsUpload(store => store.videosetId)
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideoFriend({urlParams: routeParams})
    const backUpdateStepMutation = useUpdateFriendStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateFriendStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    useEffect(() => {
        if (showExperiencesHostVideoQuery.data && showExperiencesHostVideoQuery.data?.videoset) {
            setVideosetId(showExperiencesHostVideoQuery.data?.videoset.id)
        }
    }, [showExperiencesHostVideoQuery.isSuccess])

    return (
        <>
            <ExperiencesHostVideoBody gap={6}>
                <ExperiencesHostVideoTitle>{t('experiences_host_video:upload_clip:title')}</ExperiencesHostVideoTitle>
                {showExperiencesHostVideoQuery.isPending && !videosetId ? (
                    <Spinner size={48} />
                ) : (
                    <UploadClipsDropzone />
                )}
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'upload', step: 'intro'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
