import {z} from 'zod'
import {Entity, Quote, TaskMedia, TaskResource} from '@/features/service/types.ts'

export const ServiceProposal = z.object({
    entity: Entity,
    ...Quote.shape,
    task_category: TaskResource,
    task_media: TaskMedia,
    task_service: TaskResource
})
export type ServiceProposal = z.infer<typeof ServiceProposal>
