import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Modal} from '@components/ui/modal/Modal.tsx'

export const StyledModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    overflow: hidden;
    & .notes-textarea {
        width: 100%;
    }
`

export const StyledCancelStepHeader = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral['900']};
        text-align: center;
        padding: ${spacing * 4.5}px 0;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)

export const StyledCancelStepDesc = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h5 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        ,
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledCancelStatsWidgetWrapper = styled(Flexbox)(
    ({theme: {typography, palette, mediaQueries}}) => css`
        width: 160px;
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }

        & > h4 {
            ${typography.displayXs};
            color: ${palette.neutral['900']};
            font-weight: 700;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledCancelDisclaimer = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textXs};
        color: ${palette.neutral['700']};

        span {
            text-decoration: underline;
        }
    `
)

export const StyledCancelDisclaimerTooltip = styled(Flexbox)(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 400;
    `
)

export const StyledCancelStepFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        border-top: 1px solid ${palette.neutral['300']};
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledNoResults = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['400']};
    `
)
