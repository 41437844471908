import {useState, useEffect, RefObject} from 'react'

export const useInView = <T extends HTMLElement>(ref: RefObject<T>, options?: IntersectionObserverInit): boolean => {
    const [inView, setInView] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setInView(entry.isIntersecting)
        }, options)

        const currentRef = ref.current

        if (currentRef) {
            observer.observe(currentRef)
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef)
            }
        }
    }, [options, ref])

    return inView
}
