import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {useShareHostDatesStore} from '@/features/service/store/ShareHostDatesStore'
import {HostDatePicker} from './HostDatePicker'
import {ShareHostDatesStep} from './types'
import {FC} from 'react'
import {ShareHostDatesSuccess} from './ShareHostDatesSuccess'
import {ServiceType} from '@/types/commons'
import {Modal} from '@/components/ui/modal/Modal'
import {HostAvailabilityDate} from '@/features/service/types.ts'
import dayjs from 'dayjs'

interface ShareHostDatesProps {
    hostAvailabilityDates: [HostAvailabilityDate, HostAvailabilityDate] | []
    urlParams: ServiceDetailsURLParams
    serviceType: ServiceType
    onClose: () => void
}

export const ShareHostDatesProcess: FC<ShareHostDatesProps> = ({
    hostAvailabilityDates,
    urlParams,
    serviceType,
    onClose
}) => {
    const quoteDatesModalStep = useShareHostDatesStore(state => state.step)
    const defaultSelectedDates: [Date, Date] | [] =
        hostAvailabilityDates.length == 2
            ? [
                  dayjs(hostAvailabilityDates[0].starting_time).toDate(),
                  dayjs(hostAvailabilityDates[1].starting_time).toDate()
              ]
            : []
    return (
        <>
            {quoteDatesModalStep === ShareHostDatesStep.Pick && (
                <Modal width={740} onOverlayClick={onClose}>
                    <HostDatePicker
                        urlParams={urlParams}
                        serviceType={serviceType}
                        defaultSelectedDates={defaultSelectedDates}
                    />
                </Modal>
            )}

            {quoteDatesModalStep === ShareHostDatesStep.Success && (
                <Modal width={384}>
                    <ShareHostDatesSuccess serviceType={serviceType} />
                </Modal>
            )}
        </>
    )
}
