import {ChatbotStep} from '@/features/chatbot/types.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import i18next from 'i18next'

export const hp11 = ({quoteLink}: {quoteLink: string}): ChatbotStep[] => [
    {
        id: 'HP11',
        message: i18next.t('chatbot:HP11:HP11_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP11',
                source: 'Chatbot',
                text: 'Please describe in details your issue or question. Make sure to add as much information as possible so for our team to be able to solve quickly your query.'
            })
            return 'HP11_message'
        }
    },
    {
        id: 'HP11_message',
        user: true,
        trigger: ({value}) => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP11_message',
                source: 'User',
                text: String(value)
            })
            return 'HP11_check'
        },
        validator: value => {
            if (value === '') {
                return 'You should enter a message'
            } else if (String(value).length < 30) {
                return 'The message should be longer'
            }
            return true
        }
    },
    {
        id: 'HP11_check',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:HP11:HP11_check_options:label_1'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP11_check',
                        source: 'User',
                        text: 'Send ticket'
                    })
                    return 'HP11_submit'
                }
            },
            {
                value: 'edit',
                label: i18next.t('chatbot:HP11:HP11_check_options:label_2'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP11_check',
                        source: 'User',
                        text: 'Rewrite your message'
                    })
                    return 'HP11_edit'
                }
            }
        ]
    },
    {
        id: 'HP11_edit',
        user: true,
        trigger: ({value}) => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP11_edit',
                source: 'User',
                text: String(value)
            })
            return 'HP11_check'
        },
        validator: value => {
            if (value === '') {
                return 'You should enter a message'
            } else if (String(value)?.length < 30) {
                return 'The message should be longer'
            }
            return true
        }
    },
    {
        id: 'HP11_submit',
        component: <TicketSubmit reason="open_ticket" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP11_submit',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'oq_message'
        }, // Final trigger of the flow's branch
        asMessage: true
    }
]
