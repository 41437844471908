import {useTranslation} from 'react-i18next'
import {
    StyledInProgressSectionBody,
    StyledInProgressSectionHeader,
    StyledInProgressSectionWrapper
} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import Button from '@components/ui/button-legacy/Button.tsx'
import {useInProgressServicesTable} from '@/features/in-progress-services/services/queries/useInProgressServicesTable.ts'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {useOutletContext} from 'react-router-dom'
import {InProgressTable} from '@/features/in-progress-services/components/in-progress-table/inProgressTable.tsx'
import {FC} from 'react'
import {InProgressSectionHeaderLoader} from '@/features/in-progress-services/components/in-progress-section-header-loader/InProgressSectionHeaderLoader.tsx'
import Container from '@components/ui/container/Container.tsx'

interface InProgressSectionProps {
    requestPhotoShootModalCb: () => void
    userHasPhotoServicesPromo: boolean
    noAvailablePromoServices: boolean
}

export const InProgressSection: FC<InProgressSectionProps> = ({
    userHasPhotoServicesPromo,
    requestPhotoShootModalCb,
    noAvailablePromoServices
}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    const {
        remappedData: inProgressServices,
        isPending: loadingInProgress,
        isFetchingNextPage,
        isError,
        fetchNextPage,
        sorter
    } = useInProgressServicesTable(urlParams)
    //we will show this section if we have in progress shoots and when we have a dashboard completely empty
    const showSectionComponents =
        loadingInProgress ||
        inProgressServices.length > 0 ||
        (noAvailablePromoServices && inProgressServices.length == 0)

    if (!showSectionComponents) {
        return false
    }
    if (loadingInProgress) {
        return (
            <Container>
                <InProgressSectionHeaderLoader />
            </Container>
        )
    }

    return (
        <Container>
            <StyledInProgressSectionWrapper id={'in-progress-section'}>
                <StyledInProgressSectionHeader gap={1} justify={'space-between'}>
                    <div>
                        <h4>{t('dashboard:in_progress:title')}</h4>
                        <p>{t('dashboard:in_progress:subtitle')}</p>
                    </div>
                    <Button
                        variant={userHasPhotoServicesPromo ? 'secondary' : 'primary'}
                        size={'md'}
                        disabled={loadingInProgress}
                        onClick={requestPhotoShootModalCb}
                    >
                        {t('commons:request_a_photoshoot')}
                    </Button>
                </StyledInProgressSectionHeader>

                <StyledInProgressSectionBody rows={inProgressServices.length}>
                    <InProgressTable
                        data={inProgressServices}
                        isChangingPage={isFetchingNextPage}
                        isError={isError}
                        isLoading={loadingInProgress}
                        onChangePage={fetchNextPage}
                        sorter={sorter}
                    />
                </StyledInProgressSectionBody>
            </StyledInProgressSectionWrapper>
        </Container>
    )
}

InProgressSection.displayName = 'InProgressSection'
