import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpSendHostDates} from '../sendHostDates.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

type useSendHostDatesProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useSendHostDates = (options?: useSendHostDatesProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.SEND_HOST_DATES],
        mutationFn: httpSendHostDates,
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            options?.onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
        }
    })
}
