import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledFooterNotes = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 8}px 0;
        p {
            ${typography.textXs};
            color: ${palette.neutral['600']};
        }
    `
)
