import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {ServiceToAcceptStep} from '@/features/service-acceptance-flow/components/ServiceToAcceptStep.tsx'
import {ServiceAcceptedStep} from '@/features/service-acceptance-flow/components/ServiceAcceptedStep.tsx'
import {ServiceSmsNotifyStep} from '@/features/service-acceptance-flow/components/ServiceSmsNotifyStep.tsx'
import {ServiceConfirmEmailStep} from '@/features/service-acceptance-flow/components/ServiceConfirmEmailStep.tsx'
import {ServiceAcceptPortraitStep} from '@/features/service-acceptance-flow/components/ServiceAcceptPortraitStep.tsx'
import toast from 'react-hot-toast'
import {AcceptanceFlowCompletedMessage} from '@/features/service-acceptance-flow/components/AcceptanceFlowCompleted.tsx'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {AcceptManualRequestStep} from '@/features/service-acceptance-flow/components/AcceptManualRequestStep.tsx'

export const ServiceAcceptanceProcess = () => {
    const acceptanceActiveStep = useServiceAcceptStore(state => state.step)
    const acceptModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const portraitEnabled = useServiceAcceptStore(state => state.portraitEnabled)
    //otp flow
    const otpFlowReset = useOtpStore(state => state.reset)

    const onConfigurationCompleted = () => {
        toast.success(<AcceptanceFlowCompletedMessage />, {duration: 8000})
        acceptModalToggle()
        otpFlowReset()
    }

    return (
        <>
            {acceptanceActiveStep == 'acceptManualRequest' && <AcceptManualRequestStep />}
            {acceptanceActiveStep == 'accept' && <ServiceToAcceptStep />}
            {acceptanceActiveStep == 'accepted' && <ServiceAcceptedStep />}
            {acceptanceActiveStep == 'sms' && <ServiceSmsNotifyStep isAcceptanceFlow={true} />}
            {acceptanceActiveStep == 'email' && <ServiceConfirmEmailStep />}
            {portraitEnabled && acceptanceActiveStep == 'portrait' && (
                <ServiceAcceptPortraitStep
                    onPortraitAcceptedCb={onConfigurationCompleted}
                    portraitEnabled={portraitEnabled}
                />
            )}
        </>
    )
}

ServiceAcceptanceProcess.displayName = 'ServiceAcceptanceProcess'
