import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledServicesCard = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 260px;
        min-height: 66px;
        background: ${palette.neutral.white};
        padding: ${spacing * 2.5}px ${spacing * 3.5}px;
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        & h5 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['600']};
        }
    `}
`

export const StyledServiceCardCost = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral['900']};
        font-weight: 500;
    `}
`
