import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {
    httpGetListingsCategories,
    HttpGetListingsCategoriesOptions,
    HttpGetListingsCategoriesResponse
} from '../categories.http'

export const useListingsCategories = (promiseArgs: HttpGetListingsCategoriesOptions) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.LISTINGS_CATEGORIES, promiseArgs.params.search],
        queryFn: ({pageParam}: {pageParam: string | undefined}) =>
            parseAxiosPromise({
                axiosPromise: httpGetListingsCategories({
                    ...promiseArgs,
                    params: {
                        ...promiseArgs.params,
                        cursor: pageParam
                    }
                }),
                responseShape: HttpGetListingsCategoriesResponse,
                onZodError: captureException
            }),
        initialPageParam: undefined,
        getNextPageParam: lastPage => lastPage.paging.next_cursor
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        //to avoid results like [undefined]
        remappedData: query.data?.pages[0]?.listings ? query.data.pages.flatMap(page => page.listings) : []
    }
}
