import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {
    StyledExperiencesHostVideoAnswerContainer,
    StyledExperiencesHostVideoAnswerInput
} from '@/features/experiences-host-video/components/experiences-host-video-answer-item/style.ts'

export interface ExperiencesHostVideoAnswerItemProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    className?: string
    label: ReactNode
    type: 'radio' | 'checkbox'
}
export const ExperiencesHostVideoAnswerItem = forwardRef<HTMLInputElement, ExperiencesHostVideoAnswerItemProps>(
    ({className, label, ...rest}, ref) => {
        return (
            <StyledExperiencesHostVideoAnswerContainer $isReadonly={!!rest.readOnly} className={className}>
                {label}
                <StyledExperiencesHostVideoAnswerInput ref={ref} {...rest} />
            </StyledExperiencesHostVideoAnswerContainer>
        )
    }
)
