import {Trans, useTranslation} from 'react-i18next'
import {useRef} from 'react'
import Container from '@components/ui/container/Container.tsx'
import PageHeader from '@components/commons/page-hero/PageHero.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import {
    StyledPhotoshootRequestsWrapper,
    StyledRequestsSearchInfo,
    StyledRequestsSearchWrapper
} from '@pages/photoshoot-requests/style.ts'
import {Helmet} from 'react-helmet'
import {ChevronLeftIcon, SearchLgIcon} from 'src/components/ui/icon'
import {usePhotoServiceRequests} from '@/features/service-requests/services/queries/usePhotoServiceRequests'
import {PhotoServiceContentLoaderCard} from '@/features/service-requests/components/photo-service/photo-service-content-loader-card/PhotoServiceContentLoaderCard'
import Divider from '@components/ui/divider/Divider.tsx'
import {EmptyResultsBox} from '@components/commons/empty-results-box/EmptyResultsBox.tsx'
import {logout} from '@/features/sso/services/ssoAuthActions.ts'
import {useAuthStore} from '@/store/AuthStore'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore'
import {Modal} from '@/components/ui/modal/Modal'
import {ServiceAcceptanceProcess} from '@/features/service-acceptance-flow/components/ServiceAcceptanceProcess'
import {PhotoServiceCard} from '@/features/service-requests/components/photo-service/photo-service-card/PhotoServiceCard.tsx'
import {StyledTermsLink} from '@/features/promotional-services/components/promo-services-section/style.ts'

export const PhotoshootRequests = () => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const user = useAuthStore(state => state.user)
    //accept store state and action
    //const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const showAcceptModal = useServiceAcceptStore(state => state.modalVisible)

    //prevent strange behaviour
    if (!user) {
        throw t('error:default')
    }

    const {
        data: photoServiceRequests,
        isLoading,
        isFetching,
        onSearch,
        onResetSearch,
        searchValue
    } = usePhotoServiceRequests({
        urlParams: {
            hostCode: user.code,
            hostPassword: user.password
        },
        params: {
            media_type: 'photo',
            search: searchRef.current?.value
        }
    })

    return (
        <Container>
            <Helmet title={String(t('photoshoot_requests:page_title_meta'))} />
            <PageHeader
                title={t('commons:request_a_photoshoot')}
                ctaComponent={
                    <ButtonLink
                        variant={'tertiary'}
                        size={'md'}
                        to={generatePath(routes.DASHBOARD.path, {
                            hostCode: user?.code,
                            hostPassword: user?.password
                        })}
                    >
                        <ChevronLeftIcon size={20} />
                        {t('commons:dashboard')}
                    </ButtonLink>
                }
            />
            <Divider bottomSpacing={0} topSpacing={0} />
            <StyledRequestsSearchWrapper gap={1}>
                <StyledRequestsSearchInfo>
                    <h4>{t('commons:your_active_listings')}</h4>
                    <p>
                        <Trans
                            i18nKey={'dashboard:promotional_photo:subtitle'}
                            components={[
                                <StyledTermsLink
                                    target="_blank"
                                    to={generatePath(routes.TERMS_OF_SERVICE.path, {type: 'photo-video'})}
                                />
                            ]}
                        />
                    </p>
                </StyledRequestsSearchInfo>
                <InputText
                    isLoading={isFetching}
                    ref={searchRef}
                    width={320}
                    type="text"
                    defaultValue={searchValue}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('photoshoot_requests:search_placeholder')}
                    onChange={event => onSearch(event.target.value)}
                />
            </StyledRequestsSearchWrapper>
            {searchValue && photoServiceRequests?.length == 0 && (
                <EmptyResultsBox
                    onResetCb={() => onResetSearch(searchRef)}
                    title={t('commons:no_results_found')}
                    subtitle={t('commons:no_results_listings_message', {value: searchValue})}
                />
            )}
            {!searchValue && photoServiceRequests?.length == 0 && (
                <EmptyResultsBox
                    onResetCb={logout}
                    title={t('photoshoot_requests:empty_state:title')}
                    subtitle={t('photoshoot_requests:empty_state:subtitle')}
                    buttonLabel={t('photoshoot_requests:empty_state:cta_label')}
                />
            )}
            <StyledPhotoshootRequestsWrapper>
                {isLoading ? (
                    <>
                        <PhotoServiceContentLoaderCard />
                        <PhotoServiceContentLoaderCard />
                        <PhotoServiceContentLoaderCard />
                    </>
                ) : (
                    photoServiceRequests &&
                    photoServiceRequests.map(photoService => (
                        <PhotoServiceCard key={photoService.id} photoService={photoService} />
                    ))
                )}
            </StyledPhotoshootRequestsWrapper>

            {showAcceptModal && (
                <Modal width={438}>
                    <ServiceAcceptanceProcess />
                </Modal>
            )}
        </Container>
    )
}

PhotoshootRequests.displayName = 'PhotoshootRequests'
