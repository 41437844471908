import {httpAcceptService} from '@/features/service-acceptance-flow/services/serviceAccept.http.ts'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {HttpAcceptQuoteParams} from '@/features/service-acceptance-flow/types.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {ServiceProposal} from '@/features/promotional-services/types.ts'

type useOtpValidationProps = {
    onSuccess?: (data: ServiceProposal) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useAcceptService = (options?: useOtpValidationProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_REQUESTED_SERVICE],
        mutationFn: (params: HttpAcceptQuoteParams) =>
            parseAxiosPromise({
                axiosPromise: httpAcceptService(params),
                onZodError: captureException,
                responseShape: ServiceProposal
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async data => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IN_PROGRESS_SERVICES]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_PROPOSALS]})
            options?.onSuccess?.(data)
        }
    })
}
