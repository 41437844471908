import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StepCardProps} from '@/components/commons/step-card/StepCard'

export const StyledTitleField = styled(Flexbox)(
    ({theme: {typography, mediaQueries, palette, spacing}}) => css`
        & img {
            flex: none;
            border-radius: 8px;
            width: 64px;
            height: 44px;
        }

        & > div {
            overflow: hidden;
            & a {
                max-width: 100%;
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                ${typography.textMd};
                font-weight: 500;
                color: ${palette.neutral['900']};
            }

            & small {
                max-width: 100%;
                ${typography.textSm};
                color: ${palette.neutral['600']};
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        & > a {
            display: none;
            padding: 0;
            position: absolute;
            right: ${spacing * 2}px;
        }

        ${mediaQueries.m} {
            flex-direction: column;
            align-items: start;

            & > a {
                display: block;
            }
        }
    `
)

export const StyledOpenCtaField = styled(Flexbox)`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        ${mediaQueries.m} {
            display: none;
        }
    `}
`

export const StyledStatus = styled(Flexbox)(
    ({theme: {palette}}) => css`
        svg {
            fill: ${palette.danger[600]};
        }
        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)

export const StyledDot = styled.div<{status: StepCardProps['status'] | 'warning'}>(
    ({theme: {palette}, status}) => css`
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${status == 'completed'
            ? palette.success['600']
            : status == 'warning'
            ? palette.warning['500']
            : palette.blue['500']};
        flex: none;
    `
)
