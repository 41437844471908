import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'
import {Trans} from 'react-i18next'

export const hp31 = (): ChatbotStep[] => [
    {
        id: 'HP31',
        options: [
            {
                value: 'HP31_what_is',
                label: i18next.t('chatbot:HP31:HP31.1_what_is_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP31_what_is',
                        source: 'User',
                        text: 'What is Luxe?'
                    })
                    return 'HP31.1_what_is'
                }
            },
            {
                value: 'HP31_other_listing',
                label: i18next.t('chatbot:HP31:HP31.1_other_listing_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP31_other_listing',
                        source: 'User',
                        text: "I have other listings but they weren't invited"
                    })
                    return 'HP31.1_other_listing'
                }
            }
        ]
    },
    {
        id: 'HP31.1_what_is',
        component: (
            <Trans
                i18nKey={'chatbot:HP31:HP31.1_what_is_message'}
                components={[<a href={'https://www.airbnb.com/stays/luxury'} target="_blank" />]}
            />
        ),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP31.1_what_is',
                source: 'Chatbot',
                text: 'There is no cost to Luxe walkthroughs, your quote price will always be $0. However if you cancel last minute, you may be charged a late cancellation fee to compensate the home consultant previously scheduled.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP31.1_other_listing',
        component: (
            <Trans
                i18nKey={'chatbot:HP31:HP31.1_other_listing_message'}
                components={[<a href={'https://www.airbnb.com/help/article/3374'} target="_blank" />]}
            />
        ),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP31.1_other_listing',
                source: 'Chatbot',
                text: 'There is no cost to Luxe walkthroughs, your quote price will always be $0. However if you cancel last minute, you may be charged a late cancellation fee to compensate the home consultant previously scheduled.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
