import {FC} from 'react'
import {StatsWidget} from '@components/commons/stats-widget/StatsWidget.tsx'
import {
    StyledPhotoStatsBody,
    StyledPhotoStatsContainer,
    StyledPhotoStatsWrapper
} from '@/features/landing/components/photo-landing-stats/style.ts'
import {DefaultNamespace} from 'i18next'
import Container from '@components/ui/container/Container.tsx'
import {ServiceValueProps, ServiceValuePropsBg} from '@/features/in-progress-services/utils.tsx'
import {useTranslation} from 'react-i18next'

interface PhotoLandingStatsProps {
    title?: string | DefaultNamespace
}

export const PhotoLandingStats: FC<PhotoLandingStatsProps> = ({title}) => {
    const {t} = useTranslation()

    return (
        <StyledPhotoStatsContainer fullWidth={true} backgroundImg={ServiceValuePropsBg.home}>
            <Container>
                <StyledPhotoStatsWrapper>
                    {title && <h2>{title}</h2>}
                    <StyledPhotoStatsBody>
                        {Object.values(ServiceValueProps['home']).map((item, index) => (
                            <StatsWidget
                                key={index}
                                labelText={t(item.labelText)}
                                data={t(item.data)}
                                subtext={t(item.subtext)}
                                description={t(item.description)}
                            />
                        ))}
                    </StyledPhotoStatsBody>
                </StyledPhotoStatsWrapper>
            </Container>
        </StyledPhotoStatsContainer>
    )
}

PhotoLandingStats.displayName = 'PhotoLandingStats'
