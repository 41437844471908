import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink'
import {PromoBanner} from '@/components/ui/promo-banner-atoms/PromoBannerAtoms'
import styled, {css} from 'styled-components'

export const StyledCoAlarmPromoBanner = styled(PromoBanner)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            margin: 0;
        }
    `
)

export const StyledCoAlarmPromoBannerCtaLink = styled(ButtonLink)(
    ({theme: {palette}}) => css`
        color: ${palette.primary['600']};
    `
)
