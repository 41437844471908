import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledImportantVideoInfoModalFooter = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['300']};
    `
)

export const StyledImportantVideoInfoModalBody = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral['900']};

        h2 {
            ${typography.textXl};
            font-weight: 500;
        }
        h3 {
            ${typography.textMd};
            font-weight: 500;
        }
        p {
            ${typography.textSm};
        }
        a {
            text-decoration: underline;
            font-weight: 500;
            cursor: pointer;
        }
    `
)
