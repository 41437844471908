import {ChatbotStep} from '@/features/chatbot/types.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {GetPpStatus} from '@/features/chatbot/components/get-pp-status/GetPpStatus.tsx'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import i18next from 'i18next'

export const hp8 = ({quoteLink}: {quoteLink: string}): ChatbotStep[] => [
    {
        id: 'HP8',
        component: <GetPpStatus />,
        asMessage: true
    },
    {
        id: 'HP8.1',
        message: i18next.t('chatbot:HP8:HP8.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8.1',
                source: 'Chatbot',
                text: 'Thank you for checking on the status of your images. They are currently with one of our editors for retouching. Our sincere apologies for this slight delay in uploading your final images. We hope to have them added to your Airbnb listing page in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP8_2_send',
        component: <TicketSubmit reason="pp_upload_issue" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8_2_send',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP8.2'
        },
        asMessage: true,
        replace: true
    },
    {
        id: 'HP8.2',
        message: i18next.t('chatbot:HP8:HP8.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8.2',
                source: 'Chatbot',
                text: 'Thank you for checking on the status of your photoshoot. Our team is investigating this delay and we will contact you in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP8_3_send',
        component: <TicketSubmit reason="pp_late" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8_3_send',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP8.3'
        },
        asMessage: true,
        replace: true
    },
    {
        id: 'HP8.3',
        message: i18next.t('chatbot:HP8:HP8.3_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8.3',
                source: 'Chatbot',
                text: 'Thank you for checking on the status of your photoshoot. Our team is investigating this delay and we will contact you in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP8_4_send',
        component: <TicketSubmit reason="pp_unknown" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8_4_send',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP8.4'
        },
        asMessage: true,
        replace: true
    },
    {
        id: 'HP8.4',
        message: i18next.t('chatbot:HP8:HP8.4_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8.4',
                source: 'Chatbot',
                text: 'Thank you for checking on the status of your photoshoot. Our team is investigating this delay and we will contact you in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
