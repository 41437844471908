import {
    httpAirbnbSSOLogin,
    HttpAirbnbSSOResponse,
    httpLogout,
    httpUserMe,
    HttpUserMeResponse
} from '@/features/sso/services/auth.http.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import {isAxiosError} from 'axios'

export const validateSSOUser = async (SSOCode?: string | null) => {
    if (!SSOCode) {
        throw new Error('code is required')
    }

    try {
        const {oauth2_authorization} = await parseAxiosPromise({
            axiosPromise: httpAirbnbSSOLogin(SSOCode),
            onZodError: captureException,
            responseShape: HttpAirbnbSSOResponse
        })
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, oauth2_authorization.access_token)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, oauth2_authorization.refresh_token)
        useAuthStore.setState(() => ({
            accessToken: oauth2_authorization.access_token,
            refreshToken: oauth2_authorization.refresh_token
        }))
    } catch (e) {
        console.log(e)
        toast.error(i18next.t('errors:default'))
    }
}

export const userMe = async () => {
    try {
        const user = await parseAxiosPromise({
            axiosPromise: httpUserMe(),
            onZodError: captureException,
            responseShape: HttpUserMeResponse
        })
        useAuthStore.setState(() => ({user}))
    } catch (error) {
        if (!(isAxiosError(error) && error.response?.status == 401)) {
            toast.error(i18next.t('errors:default'))
        }
    }
}

export const logout = async () => {
    try {
        await httpLogout()
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
        useAuthStore.setState(() => ({user: null, accessToken: undefined, refreshToken: undefined}))
        //Reset mixpanel method on logout required to properly us IDMerge functionality
        mixpanel.reset()
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}
