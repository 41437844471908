import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {httpEnableNotificationChannels} from '@/features/otp-flow/services/opt.http.ts'
import {HttpEnableNotificationChannelsParams} from '@/features/otp-flow/types.ts'

type useNotificationPermissionProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useNotificationPermission = (options?: useNotificationPermissionProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.NOTIFICATION_PERMISSION],
        mutationFn: (params: HttpEnableNotificationChannelsParams) => httpEnableNotificationChannels(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            options?.onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
        }
    })
}
