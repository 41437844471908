import {create} from 'zustand'
import {OtpStep} from '@/features/otp-flow/types.ts'
import {Host} from '@/features/service/types.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {CoAlarmRequest} from '@/features/co-alarm/types.ts'

type CancelServiceStoreState = {
    currentHost: Host | null
    isEditing: boolean
    notifyToggleStepActive: boolean
    sendTo: string
    service: ServiceProposal | null
    coAlarmRequest: CoAlarmRequest | null
    step: OtpStep
}

type CancelServiceStoreActions = {
    changeStep: (nextStep: OtpStep, isEditing: boolean) => void
    reset: () => void
    resetCurrentService: () => void
    setCurrentHost: (host: Host) => void
    setCurrentService: (service: ServiceProposal) => void
    setCoAlarmRequest: (coAlarmRequest: CoAlarmRequest) => void
    setNotifyToggleStep: (value: boolean) => void
    updateSendTo: (newValue: string) => void
}

export const useOtpStore = create<CancelServiceStoreState & CancelServiceStoreActions>()(set => ({
    currentHost: null,
    isEditing: false,
    notifyToggleStepActive: false,
    service: null,
    coAlarmRequest: null,
    step: 'base',
    sendTo: '',
    changeStep: (nextStep, isEditing) => set({step: nextStep, isEditing}),
    reset: () =>
        set({
            notifyToggleStepActive: false,
            currentHost: null,
            isEditing: false,
            //service: null,
            coAlarmRequest: null,
            step: 'base',
            sendTo: ''
        }),
    resetCurrentService: () =>
        set({
            notifyToggleStepActive: false,
            service: null
        }),
    setNotifyToggleStep: value => set({notifyToggleStepActive: value}),
    setCurrentService: service => set({service: service}),
    setCoAlarmRequest: coAlarmRequest => set({coAlarmRequest: coAlarmRequest}),
    setCurrentHost: host => set({currentHost: host}),
    updateSendTo: newValue => set({sendTo: newValue})
}))
