import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import i18next from 'i18next'

export const hp10 = ({quoteLink}: {quoteLink: string}): ChatbotStep[] => [
    {
        id: 'HP10',
        message: i18next.t('chatbot:HP10:HP10_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP10',
                source: 'Chatbot',
                text: 'Please list the spaces or details not featured in the images uploaded.'
            })
            return 'HP10_1'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP10_1',
        user: true,
        trigger: ({value}) => {
            //was {value} before
            useChatbotStore.getState().addStepToHistory({
                id: 'HP10_1',
                source: 'User',
                text: String(value)
            })
            return 'HP10.2'
        },
        validator: value => {
            if (value === '') {
                return 'Please, enter a message'
            } else if (String(value).length < 10) {
                return 'Please, be more specific'
            }
            return true
        }
    },
    {
        id: 'HP10.2',
        component: <TicketSubmit reason="missing_images" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP10.2',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP10.3'
        },
        asMessage: true,
        replace: true
    },
    {
        id: 'HP10.3',
        message: i18next.t('chatbot:HP10:HP10.3_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP10.3',
                source: 'Chatbot',
                text: 'Thank you for the information provided. A ticket has been opened with our Photography team. Our team will review your revision request and will get back to you in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
