import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {Reason} from '@/features/service-decline/types.ts'
import {TaskMedia} from '@/features/service/types.ts'

/**
 * httpDeclineServiceQuote
 * Here we will decline a service quote
 */
export const HttpServiceDeclineParams = z.object({
    ...DashboardURLParams.shape,
    reason_id: z.number().min(1),
    notes: z.string().optional(),
    serviceID: z.number()
})
export type HttpServiceDeclineParams = z.infer<typeof HttpServiceDeclineParams>

export const httpServiceDecline = (params: HttpServiceDeclineParams) => {
    const url = `/host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.serviceID}/decline`
    return axios.post(url, {reason_id: params?.reason_id, note: params.notes})
}

/**
 * httpGetDeclineServiceReasons
 * Here we will retrieve the decline reasons to show and send back after selection
 */
export const HttpServiceDeclineReasonsParams = z.object({
    task_media_name: TaskMedia.shape.name,
    by: z.enum(['host', 'creator'])
})
export type HttpServiceDeclineReasonsParams = z.infer<typeof HttpServiceDeclineReasonsParams>

export const HttpDeclineServiceReasonsResponse = z.array(Reason)
export type HttpDeclineServiceReasonsResponse = z.infer<typeof HttpDeclineServiceReasonsResponse>

export const httpGetServiceDeclineReasons = (params: HttpServiceDeclineReasonsParams) => {
    const url = `/reasons/decline`
    return axios.get(url, {params: {task_media_name: params.task_media_name, by: params.by}})
}
