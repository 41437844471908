import axios from '@/axiosInstance.ts'
import {ResponseType} from '@/types/commons'
import {z} from 'zod'
import {AvailabilityCategory, Category, ListingCategory} from '../types'

/**
 * Get all categories
 */
// Response
export const HttpGetCategoriesResponse = z.array(Category)
export type HttpGetCategoriesResponse = z.infer<typeof HttpGetCategoriesResponse>

// Http request
export const httpGetCategories = () => {
    return axios.get(`/host-services/home-categories`)
}

/**
 * Get active listings and their categories
 */
// Params
export const HttpGetListingsCategoriesOptions = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string()
    }),
    params: z.object({
        search: z.string().optional(),
        cursor: z.string().optional(),
        limit: z.number(),
        response_type: ResponseType
    })
})
export type HttpGetListingsCategoriesOptions = z.infer<typeof HttpGetListingsCategoriesOptions>

// Response
export const HttpGetListingsCategoriesResponse = z.object({
    listings: ListingCategory.array(),
    paging: z.object({
        total_count: z.number(),
        limit: z.number(),
        next_cursor: z.string().nullish()
    })
})
export type HttpGetListingsCategoriesResponse = z.infer<typeof HttpGetListingsCategoriesResponse>

// Http request
export const httpGetListingsCategories = ({urlParams, params}: HttpGetListingsCategoriesOptions) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}`, {params})
}

/**
 * Get categories and their availability
 */
// Params
export const HttpGetCategoriesAvailabilityParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string(),
        listingId: z.string()
    }),
    params: z.object({
        search: z
            .string()
            .nullish()
            .transform(x => x ?? undefined)
    })
})
export type HttpGetCategoriesAvailabilityParams = z.infer<typeof HttpGetCategoriesAvailabilityParams>

// Response
export const HttpGetCategoriesAvailabilityResponse = z.array(AvailabilityCategory)
export type HttpGetCategoriesAvailabilityResponse = z.infer<typeof HttpGetCategoriesAvailabilityResponse>

// Http request
export const httpGetCategoriesAvailability = ({urlParams, params}: HttpGetCategoriesAvailabilityParams) => {
    return axios.get(
        `/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/listings/${urlParams.listingId}/home-categories`,
        {params}
    )
}

/**
 * Request to add categories to a specific listing
 */
// Url Params
export const HttpAddCategoriesUrlParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string(),
    listingId: z.string()
})
export type HttpAddCategoriesUrlParams = z.infer<typeof HttpAddCategoriesUrlParams>

// Payload
export const HttpAddCategoriesPayload = z.object({
    categories: z.number().array()
})
export type HttpAddCategoriesPayload = z.infer<typeof HttpAddCategoriesPayload>

// Params
export const HttpAddCategoriesRequestParams = z.object({
    urlParams: HttpAddCategoriesUrlParams,
    payload: HttpAddCategoriesPayload
})
export type HttpAddCategoriesRequestParams = z.infer<typeof HttpAddCategoriesRequestParams>

// Http request
export const httpAddCategories = ({urlParams, payload}: HttpAddCategoriesRequestParams) => {
    return axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/listings/${urlParams.listingId}/home-categories/request`,
        {
            categories: payload.categories
        }
    )
}

/**
 * Request to remove a category from a specific listing
 */
// Url Params
export const HttpRemoveCategoryUrlParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string(),
    listingId: z.string(),
    categoryId: z.coerce.number().int()
})
export type HttpRemoveCategoryUrlParams = z.infer<typeof HttpRemoveCategoryUrlParams>

// Payload
export const HttpRemoveCategoryPayload = z.object({
    reason: z
        .string()
        .nullish()
        .transform(x => x ?? undefined)
})
export type HttpRemoveCategoryPayload = z.infer<typeof HttpRemoveCategoryPayload>

// Params
export const httpRemoveCategoryParams = z.object({
    urlParams: HttpRemoveCategoryUrlParams,
    payload: HttpRemoveCategoryPayload
})
export type httpRemoveCategoryParams = z.infer<typeof httpRemoveCategoryParams>

// Http request
export const httpRemoveCategory = ({urlParams, payload}: httpRemoveCategoryParams) => {
    return axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/listings/${urlParams.listingId}/home-categories/${urlParams.categoryId}/remove`,
        {
            reason: payload?.reason
        }
    )
}
