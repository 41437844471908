import axios from '@/axiosInstance.ts'
import {HttpServiceResourcesLogParams} from '@/features/service-resources/types.ts'

/**
 * Log Click on a suggested resources
 */
export const httpServiceResourcesLog = (params: HttpServiceResourcesLogParams) => {
    return axios.post(
        `host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/tips/${params.tipId}/clicked`
    )
}
