import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {CSSProperties} from 'react'

export const StyledAudioPlayerWrapper = styled.div`
    width: 100%;
`

export const StyledAudioPlayerContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 44px minmax(0, 1fr);
        gap: ${spacing * 4}px;
        width: 100%;
    `
)

export const StyledAudioPlayPauseButton = styled(Button)`
    width: 44px;
    height: 44px;
    border-radius: 22px;
`

export const StyledAudioProgressInput = styled.input(
    ({theme: {palette}}) => css`
        width: 100%;
        position: absolute;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        height: 6px;
        top: 0;
        left: 0;

        &::-webkit-slider-runnable-track {
            background-color: transparent;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 10px;
            height: 10px;
            background-color: ${palette.neutral[700]};
            border-radius: 50%;
        }
        &::-moz-range-track {
            background-color: transparent;
        }

        &::-moz-range-thumb {
            width: 10px;
            height: 10px;
            background-color: ${palette.neutral[700]};
            border-radius: 50%;
        }
    `
)

export const StyledCustomSliderTruck = styled.div(
    ({theme: {palette}}) => css`
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${palette.neutral[200]};
        width: 100%;
        height: 6px;
        border-radius: 3px;
    `
)

export const StyledCustomSlideThumb = styled.div<{$width: CSSProperties['width']}>(
    ({theme: {palette}, $width}) => css`
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${palette.neutral[700]};
        width: ${$width};
        height: 6px;
        border-radius: 3px;
    `
)
export const StyledProgressBarWrapper = styled.div`
    width: 100%;
    position: relative;
`
