import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpReassignTaskParam = z.object({
    code: z.string(),
    password: z.string(),
    media: z.coerce.number().int()
})
export type HttpReassignTaskParam = z.infer<typeof HttpReassignTaskParam>

export const httpReassignTask = (params: HttpReassignTaskParam) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/reassignTask`
    return axios.get(url)
}
