export const breakpoints = {
    width: {
        xs: 365,
        s: 414,
        m: 768,
        l: 980,
        xl: 1200,
        xxl: 1440,
        xxxl: 1920
    }
} as const

export const mediaQueries = {
    xs: `@media only screen and (max-width: ${breakpoints.width.xs}px)`,
    s: `@media only screen and (max-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (max-width: ${breakpoints.width.m}px)`,
    l: `@media only screen and (max-width: ${breakpoints.width.l}px)`,
    xl: `@media only screen and (max-width: ${breakpoints.width.xl}px)`,
    xxl: `@media only screen and (max-width: ${breakpoints.width.xxl}px)`,
    xxxl: `@media only screen and (max-width: ${breakpoints.width.xxxl}px)`
} as const satisfies Record<keyof (typeof breakpoints)[keyof typeof breakpoints], string>
