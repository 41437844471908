import axios from '@/axiosInstance.ts'
import {z} from 'zod'

/**
 * httpSendHostDates
 * Here we will send host dates request
 */
export const HttpSendHostDatesParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string(),
        quoteId: z.coerce.number().int()
    }),
    params: z.object({
        host_availability_dates: z.string().array().length(2)
    })
})

export type HttpSendHostDatesParams = z.infer<typeof HttpSendHostDatesParams>

export const httpSendHostDates = ({urlParams, params}: HttpSendHostDatesParams) => {
    const url = `/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/host-availability-dates`
    return axios.post(url, {host_availability_dates: params.host_availability_dates})
}
