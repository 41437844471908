import {
    StyledQuizModalAnswerResponse,
    StyledQuizModalFooter,
    StyledQuizModalQuestion,
    StyledQuizModalQuestionHint,
    StyledQuizItemLabelContainer
} from '@/features/experiences-host-video/components/quiz-modal-atoms/style.ts'
import {FC, HTMLAttributes, ReactNode} from 'react'
import {ProgressBar} from '@/features/experiences-host-video/components/progress-bar/ProgressBar.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const QuizModalQuestion = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledQuizModalQuestion {...props} />

export const QuizModalQuestionHint = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledQuizModalQuestionHint {...props} />
)

interface QuizModalAnswerResponseProps {
    variant: 'success' | 'danger'
    title: ReactNode
    paragraph: ReactNode
}
export const QuizModalAnswerResponse: FC<QuizModalAnswerResponseProps> = ({variant, title, paragraph}) => (
    <StyledQuizModalAnswerResponse $variant={variant}>
        <h3>{title}</h3>
        <p>{paragraph}</p>
    </StyledQuizModalAnswerResponse>
)

export const QuizModalFooter: FC<{
    children: ReactNode
    progressPercentages: [number, number, number, number, number]
}> = ({children, progressPercentages}) => (
    <StyledQuizModalFooter>
        <Flexbox width="100%" gap={1}>
            {progressPercentages.map((percentage, index) => (
                <ProgressBar percentage={percentage} key={index} />
            ))}
        </Flexbox>
        {children}
    </StyledQuizModalFooter>
)

interface QuizItemLabelContainerProps extends HTMLAttributes<HTMLDivElement> {
    $isCorrect?: boolean
}

export const QuizItemLabelContainer = (props: QuizItemLabelContainerProps) => (
    <StyledQuizItemLabelContainer {...props} />
)
