import styled, {css} from 'styled-components'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledUploadClipsDropzone = styled(Dropzone)(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 180px;
        border-radius: 16px;
        border: 2px dashed ${palette.neutral[300]};
    `
)

export const StyledUploadClipsTrigger = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        height: 100%;

        & > h3 {
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
    `
)
