import * as Toggle from '@radix-ui/react-toggle'
import styled, {css} from 'styled-components'

const TOGGLE_WIDTH = 36
const TOGGLE_PADDING = 2
const CIRCLE_WIDTH = 16

export const StyledToggleRoot = styled(Toggle.Root)`
    ${({disabled, theme: {palette}}) => css`
        pointer-events: ${disabled ? 'none' : 'auto'};
        cursor: pointer;
        width: 36px;
        height: 20px;
        border-radius: 12px;
        padding: ${TOGGLE_PADDING}px;
        display: flex;
        align-items: center;
        isolation: isolate;
        position: relative;

        &,
        .circle {
            transition: all 0.3s ease-out;
        }

        &[data-state='on'] {
            justify-content: end;
            background-color: ${disabled ? palette.primary[200] : palette.primary[600]};

            .circle {
                left: ${TOGGLE_WIDTH - TOGGLE_PADDING - CIRCLE_WIDTH}px;
            }
        }

        background-color: ${palette.neutral[200]};

        .circle {
            position: absolute;
            left: ${TOGGLE_PADDING}px;
            width: ${CIRCLE_WIDTH}px;
            aspect-ratio: 1/1;
            border-radius: 100%;
            background-color: ${palette.neutral.white};
        }
    `}
`
