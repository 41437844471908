import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {spacing} from '@/theme'
import {Modal} from '@components/ui/modal/Modal.tsx'

export const StyledSSOModal = styled(Modal)`
    ${({theme: {spacing}}) => css`
        padding-left: ${spacing * 4}px;
        padding-right: ${spacing * 4}px;
    `}
`

export const StyledInProgressSectionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        gap: ${spacing * 8}px;
        scroll-margin-top: ${spacing * 8}px;
        padding: ${spacing * 6}px 0 ${spacing * 12}px;
        grid-template-columns: 1fr;
    `}
`

export const StyledInProgressSectionHeader = styled(Flexbox)`
    ${({theme: {typography, mediaQueries, palette}}) => css`
        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing * 4}px;
        }

        & > div {
            & h4 {
                ${typography.textXl};
                font-weight: 500;
                color: ${palette.neutral['900']};
            }

            & p {
                ${typography.textSm};
                color: ${palette.neutral['600']};
            }
        }
    `}
`

const tableHeaderHeight = 42
const singleRowHeightDesk = 84
const singleRowHeightMobile = 250
export const StyledInProgressSectionBody = styled.div<{rows: number}>`
    ${({theme: {mediaQueries}, rows}) => {
        const rowsNumber = rows == 0 ? 3 : rows
        return css`
            min-height: ${rowsNumber > 8 ? 800 : rowsNumber * singleRowHeightDesk + tableHeaderHeight}px;

            ${mediaQueries.m} {
                min-height: ${rowsNumber * singleRowHeightMobile + tableHeaderHeight}px;
            }
        `
    }}
`
