import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledServiceRecap = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        flex-wrap: wrap;
        ${typography.textSm};
        color: ${palette.neutral['900']};
    `
)
