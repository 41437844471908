import {RefObject, useState} from 'react'
type VideoControllerOptions = {
    defaultMuted?: boolean
    initialVideoState?: 'playing' | 'pause'
}

/**
 * sample usage
 *
 const videoRef = useRef<HTMLVideoElement>(null)

 const videoController = useVideoController(videoRef)

 return(
     <video
        ref={videoRef}
        onClick={videoController.playPauseToggle}
        {...videoController}
     >
        <source src={'video-test.mp4'}>
    </video>
 )
 *
 */

export type VideoState = 'playing' | 'pause' | 'loading'

function useVideoController(videoRef: RefObject<HTMLVideoElement>, options?: VideoControllerOptions) {
    const [videoState, setVideoState] = useState<VideoState>(options?.initialVideoState ?? 'pause')
    const [muted, setMuted] = useState(!!options?.defaultMuted)

    const playPauseToggle = () => {
        if (videoState === 'playing') {
            videoRef.current?.pause()
            setVideoState('pause')
        } else {
            videoRef.current
                ?.play()
                .then(() => setVideoState('playing'))
                .catch(() => setVideoState('pause'))
        }
    }
    const volumeToggle = () => {
        if (videoRef.current) {
            videoRef.current.muted = !muted
            setMuted(!muted)
        }
    }

    const onPlay = () => {
        setVideoState('playing')
    }
    const onPause = () => {
        setVideoState('pause')
    }
    const onWaiting = () => {
        setVideoState('loading')
    }
    const onPlaying = () => {
        setVideoState('playing')
    }
    return {
        playPauseToggle,
        volumeToggle,
        setVideoState,
        videoState,
        onPlay,
        onPause,
        onWaiting,
        onPlaying,
        autoPlay: options?.initialVideoState == 'playing',
        muted
    }
}

export {useVideoController}
