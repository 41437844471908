import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'

export const hp30 = (): ChatbotStep[] => [
    {
        id: 'HP30',
        message: i18next.t('chatbot:HP30:HP30_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP30',
                source: 'Chatbot',
                text: `If you would like to cancel a service you can just go to the quote page, scroll down and clicking the Cancel button on the bottom right of the page.`
            })
            return 'oq_message'
        }
    }
]
