import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {UserCircleIcon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {ProfilePhotoProposal} from '@components/commons/profile-photo-proposal/ProfilePhotoProposal.tsx'
import {useAcceptPortraitShoot} from '@/features/service-acceptance-flow/services/queries/useAcceptPortraitShoot.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {StyledModal} from '@/features/service-actions/components/profile-photo-action/styled.ts'
import {httpClickAction} from '@/features/service-actions/services/actions.http.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const ProfilePhotoAction: FC<{actionId: ServiceAction['id']; isCompleted: boolean}> = ({
    actionId,
    isCompleted
}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const [showModal, setShowModal] = useState(false)
    const {mutate: acceptPortrait, isPending} = useAcceptPortraitShoot({
        onSuccess: () => {
            toast.success(t('profile_photo:success'))
            setShowModal(false)
        },
        onError: error => errorHandler(error)
    })

    const onSubmit = (hostPreference: boolean) => {
        if (urlParams) {
            acceptPortrait({
                hostPreference,
                ...urlParams
            })
        }
    }

    const clickAction = () => {
        setShowModal(true)
        httpClickAction({...urlParams, actionId})
    }

    return (
        <>
            <ActionCard
                icon={UserCircleIcon}
                paragraph={t('profile_photo:action_card_paragraph')}
                title={t('profile_photo:accept_profile_photo')}
                onClick={isCompleted ? undefined : clickAction}
                isCompleted={isCompleted}
            />
            {showModal && (
                <StyledModal width={438} onClose={() => setShowModal(false)} onOverlayClick={() => setShowModal(false)}>
                    <ProfilePhotoProposal isSubmitting={isPending} onSubmit={onSubmit} />
                </StyledModal>
            )}
        </>
    )
}

ProfilePhotoAction.displayName = 'ProfilePhotoAction'
