import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpFriendQuizAnswer} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {UseQuizAnswerResponse} from '@/features/experiences-host-video/services/useQuizAnswer.ts'

interface UseQuizAnswerArgs {
    params: ExperiencesHostVideoRouteParams & {quizId?: number}
    mutationOptions?: Omit<UseMutationOptions<UseQuizAnswerResponse, unknown, {questionId: number}>, 'mutationFn'>
}
export const useFriendQuizAnswer = ({params, mutationOptions}: UseQuizAnswerArgs) => {
    return useMutation<UseQuizAnswerResponse, unknown, {questionId: number}>({
        mutationFn: ({questionId}) =>
            parseAxiosPromise({
                axiosPromise: httpFriendQuizAnswer({
                    urlParams: {...params, questionId, quizId: params.quizId || raise('quizId is undefined')}
                }),
                responseShape: UseQuizAnswerResponse,
                onZodError: captureException
            }),
        ...mutationOptions
    })
}
