import {Flexbox} from '@components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledEmptyResultBox = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        background-color: ${palette.neutral['50']};
        border-radius: 12px;
        padding: ${spacing * 8}px ${spacing * 4}px;
    `
)

export const StyledEmptyResultBoxHeader = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        text-align: center;
        & h4 {
            ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & p {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['700']};
        }
    `}
`
