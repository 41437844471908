import {z} from 'zod'

export const Category = z.object({
    id: z.number(),
    name: z.string(),
    available_request: z.number(),
    unavailability_reason: z.string().nullable(),
    available_re_request: z.number(),
    days_re_request: z.number().nullable(),
    poi: z.coerce.boolean()
})
export type Category = z.infer<typeof Category>

export const CategoryRequestStatus = z.enum([
    'requested',
    'removed',
    'approved',
    'denied',
    'escalated',
    'completed',
    'approved_unacceptable_photos'
])
export type CategoryRequestStatus = z.infer<typeof CategoryRequestStatus>

export const CategoryRequestOperation = z.enum(['add', 'remove'])
export type CategoryRequestOperation = z.infer<typeof CategoryRequestOperation>

export const HomeCategoryRequests = z.object({
    id: z.number(),
    action: CategoryRequestOperation,
    entity_id: z.number(),
    batch: z.number(),
    home_category_id: z.number(),
    created_at: z.string(),
    status: z.string()
})
export type HomeCategoryRequests = z.infer<typeof HomeCategoryRequests>

export const ListingCategory = z.object({
    id: z.coerce.string(),
    name: z.string(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    country_code: z.string(),
    categories: z.string().array(),
    image_url: z.string().nullable(),
    entity: z
        .object({
            home_category_requests: HomeCategoryRequests.array()
        })
        .nullable()
})
export type ListingCategory = z.infer<typeof ListingCategory>

export type RemappedRequest = Category & {
    status: CategoryRequestStatus
    created_at: string
}

export type RemappedRequests = {
    created_at: string
    requests: RemappedRequest[]
    status: CategoryRequestStatus
    action: CategoryRequestOperation
}

export const AvailabilityCategoryStatus = z.enum([
    'review',
    'denied',
    'disabled',
    'available',
    'unavailable',
    'assigned'
])
export type AvailabilityCategoryStatus = z.infer<typeof AvailabilityCategoryStatus>

export const AvailabilityCategory = z.object({
    home_category_id: z.coerce.number(),
    status: AvailabilityCategoryStatus
})
export type AvailabilityCategory = z.infer<typeof AvailabilityCategory>
