import {AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot, AvatarFallback} from '@components/ui/avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'

interface AvatarProps {
    name: string
    imageUrl?: string
    size?: 'lg' | 'md' | 'sm'
}

const Avatar = ({name, imageUrl, size = 'md'}: AvatarProps) => (
    <AvatarRoot size={size}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback size={size} delayMs={600}>
            {getInitials(name)}
        </AvatarFallback>
    </AvatarRoot>
)

export default Avatar

Avatar.displayName = 'Avatar'
