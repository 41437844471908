import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledFeedbackRadioGroup = styled(Flexbox)(
    ({theme: {spacing, mediaQueries}}) => css`
        gap: ${spacing * 4}px;

        ${mediaQueries.m} {
            gap: ${spacing}px;
        }
    `
)

export const StyledLabel = styled.label`
    width: 100%;
`

export const StyledRadio = styled.div(
    ({theme: {palette, shadows}}) => css`
        height: 40px;
        transition: ease-in-out 200ms;
        color: ${palette.neutral['700']};
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.xs};
        cursor: pointer;
        background: ${palette.neutral.white};
        font-weight: 600;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: ${palette.neutral['50']};
        }
    `
)
export const StyledRadioInput = styled.input(
    ({theme: {palette}}) => css`
        display: none;
        &:checked + ${StyledRadio} {
            background: ${palette.primary['200']};
            border: 1px solid ${palette.primary['400']};
            color: ${palette.primary['700']};
        }
    `
)
