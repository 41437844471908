import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCoAlarmInstallationBoxTextWrapper = styled(Flexbox)`
    max-width: 480px;
    text-align: center;
`

export const StyledCoAlarmInstallationWarningTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledCoAlarmInstallationWarningParagraph = styled.p(
    ({theme: {palette}}) => css`
        color: ${palette.neutral[900]};
    `
)
