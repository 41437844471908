import styled, {css} from 'styled-components'
import {SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledShortClipModalHeader = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
    `
)
export const StyledShortClipExampleWrapper = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        background: ${palette.neutral.black};
    `
)

export const StyledShortClipExample = styled.video(
    () => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
        cursor: not-allowed;
    `
)
