import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './translations/i18n.tsx'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from '@/queryClient.ts'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Helmet} from 'react-helmet'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import {envVars} from '@/envVars.ts'

// Init Mixpanel
mixpanel.init(envVars.VITE_MIXPANEL_TOKEN, {
    debug: envVars.VITE_MIXPANEL_DEBUG_ENABLED,
    track_pageview: 'url-with-path-and-query-string', // Track when the path or query string change, ignoring hash changes
    persistence: 'localStorage',
    ignore_dnt: true
})

// Only initialize Sentry and Google Tag Manager in production environment
if (import.meta.env.MODE === 'production') {
    // Initialize Google Tag Manager
    const tagManagerArgs = {
        gtmId: 'GTM-PZ7JFW4W'
    }
    TagManager.initialize(tagManagerArgs)

    // Initialize Sentry
    Sentry.init({
        dsn: 'https://cf06a2d8aaa987fe8a629f5e52828296@o1170454.ingest.sentry.io/4506399659589632',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['https://elevate.translated.com/'],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Helmet>
                <script src="https://www.airbnb.com/platform_js" async defer></script>
            </Helmet>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>
)
