import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateQuestionAnswerNote} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'

interface UseUpdateQuestionAnswerNote {
    params: {
        hostCode: number
        hostPassword: string
        taskId: number
        experiencesHostVideoId: number
    }
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                questionAnswerId: number
                notes: string
                noteId: number
            }
        >,
        'mutationFn'
    >
}
export const useUpdateQuestionAnswerNote = ({params, mutationOptions}: UseUpdateQuestionAnswerNote) => {
    return useMutation<unknown, unknown, {questionAnswerId: number; notes: string; noteId: number}>({
        mutationFn: ({questionAnswerId, notes, noteId}) =>
            httpUpdateQuestionAnswerNote({
                urlParams: {...params},
                payload: {questionAnswerId, notes, noteId}
            }),

        ...mutationOptions
    })
}
