export type SlotValue = 8 | 10 | 12 | 14 | 16 | 18
export type Slot = {
    value: SlotValue
    label: string
}

export type HostDates = [] | [Date] | [Date, Date]

export enum ShareHostDatesStep {
    Pick = 'pick_date',
    Success = 'success'
}

export type SendQuoteModalStep = ShareHostDatesStep
