import styled, {css} from 'styled-components'

export const StyledTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledColumns = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;

        & h4 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledVideo = styled.video(
    ({theme: {palette}}) => css`
        border-radius: 6px;
        width: 100%;
        aspect-ratio: 9/16;
        object-fit: cover;
        background-color: ${palette.neutral['900']};
        pointer-events: none;
    `
)
