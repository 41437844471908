import {Steps} from '@/components/commons/steps/Steps'
import styled, {css} from 'styled-components'

export const StyledServiceDetails = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        min-width: 0;

        .quote-details-content {
            position: relative;
            top: -${spacing * 19}px;

            ${mediaQueries.m} {
                position: static;
            }
        }
    `
)

export const StyledCancelServiceSection = styled.div(
    ({theme: {palette, spacing}}) => css`
        margin: ${spacing * 8}px 0 0;
        padding: ${spacing * 8}px 0;
        border-top: 1px solid ${palette.neutral['200']};
    `
)

export const StyledServiceDetailsSteps = styled(Steps)(
    ({theme: {mediaQueries}}) => css`
        grid-template-columns: repeat(5, minmax(190px, 1fr));

        ${mediaQueries.m} {
            grid-template-columns: repeat(5, minmax(max-content, 1fr));
        }
    `
)
