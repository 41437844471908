import styled, {css} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledPromoWalkthroughExplainWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        background: ${palette.neutral[50]};
        padding: ${spacing * 12}px 0;
    `}
`

export const StyledPromoWalkthroughExplainContainer = styled(Container)`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        & h5 {
            ${typography.textXl};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }

        & p {
            max-width: 760px;
            ${typography.textXl};
            color: ${palette.neutral['700']};
            font-weight: 400;
        }

        margin-bottom: ${spacing * 10}px;
        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledPromoWalkthroughExplainIcon = styled(Flexbox)`
    ${({theme: {palette}}) => css`
        width: 48px;
        height: 48px;
        border-radius: 48px;
        box-sizing: border-box;
        border: 1px solid ${palette.neutral['300']};
        background: ${palette.neutral.white};
        color: ${palette.primary['600']};
    `}
`
