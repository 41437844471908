import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import PageHero from '@/components/commons/page-hero/PageHero'
import {CoAlarmDashboardListings} from '@/features/co-alarm/components/co-alarm-dashboard-listings/CoAlarmDashboardListings'
import {StyledCoAlarmDashboardContainer, StyledNotEligible} from '@/pages/co-alarm/dashboard/style'
import {useAuthStore} from '@/store/AuthStore'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {ButtonNavLink} from '@components/ui/button-nav-link/ButtonNavLink.tsx'
import {useMemo} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {InfoCircleIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'

export const CoAlarmDashboard = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const user = useAuthStore(state => state.user)
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.l
    const isCoRequestEnabled = useMemo(
        () =>
            (user?.co_alarm.status == 'eligible' || user?.co_alarm.status == 'installed') &&
            user?.co_alarm.requests_count == 0,
        [user]
    )

    return (
        <StyledCoAlarmDashboardContainer>
            <Helmet title={String(t('coAlarm:page_title_meta'))} />
            <PageHero
                title={t('coAlarm:dashboard:title')}
                subtitle={t('coAlarm:dashboard:subtitle')}
                endSlot={
                    isMobile && (
                        <>
                            {isCoRequestEnabled ? (
                                <ButtonNavLink
                                    to={generatePath(routes.CO_ALARM_SEND_REQUEST.path)}
                                    disabled={!isCoRequestEnabled}
                                >
                                    {t('coAlarm:dashboard:cta')}
                                </ButtonNavLink>
                            ) : (
                                <StyledNotEligible>
                                    <IconContainer>
                                        <InfoCircleIcon fill={theme.palette.danger['600']} />
                                    </IconContainer>
                                    <Flexbox direction="column" gap={1}>
                                        <h3>{t('coAlarm:dashboard:not_eligible_title')}</h3>
                                        <ul>
                                            <li>{t('coAlarm:dashboard:not_eligible_title_reason_1')}</li>
                                            <li>{t('coAlarm:dashboard:not_eligible_title_reason_2')}</li>
                                            <li>{t('coAlarm:dashboard:not_eligible_title_reason_3')}</li>
                                        </ul>
                                    </Flexbox>
                                </StyledNotEligible>
                            )}
                        </>
                    )
                }
            />
            <CoAlarmDashboardListings isCoRequestEnabled={isCoRequestEnabled} />
        </StyledCoAlarmDashboardContainer>
    )
}
