import * as RAccordion from '@radix-ui/react-accordion'
import {FC, ReactNode, useEffect, useState} from 'react'
import {StyledAccordionHeader, StyledAccordionTrigger} from './style'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ChevronDownIcon} from '@/components/ui/icon'
import Divider from '@/components/ui/divider/Divider'

interface CategoryRequestsAccordionProps {
    id: string
    value: boolean
    pendingRequestsCount: number
    content: ReactNode
    onChange: () => void
}

export const CategoryRequestsAccordion: FC<CategoryRequestsAccordionProps> = ({
    id,
    pendingRequestsCount,
    value = false,
    onChange,
    content
}) => {
    const [open, setOpen] = useState<string>(value ? id : '')
    const {t} = useTranslation()

    useEffect(() => {
        if (value) {
            setOpen(id)
        } else {
            setOpen('')
        }
    }, [value])

    return (
        <RAccordion.Root type={'single'} value={open} onValueChange={setOpen}>
            <RAccordion.Item value={id}>
                <Flexbox justify="space-between">
                    <StyledAccordionHeader>
                        {t('categories:card:pending_requests', {count: pendingRequestsCount})}
                    </StyledAccordionHeader>

                    {pendingRequestsCount > 0 && (
                        <StyledAccordionTrigger className="accordion-trigger" isOpen={!!open} onClick={onChange}>
                            <span id="accordion-label-trigger">
                                {open === id ? t('commons:close') : t('commons:expand')}
                            </span>
                            <ChevronDownIcon className="ChevronIcon" width={20} height={20} />
                        </StyledAccordionTrigger>
                    )}
                </Flexbox>

                {open && <Divider topSpacing={12} bottomSpacing={0} />}

                <RAccordion.Content>{content}</RAccordion.Content>
            </RAccordion.Item>
        </RAccordion.Root>
    )
}
