import {FC, ReactNode} from 'react'
import {StyledStatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/style.ts'

interface StatusDescriptionWrapperProps {
    centered?: boolean
    children: ReactNode
}
export const StatusDescriptionWrapper: FC<StatusDescriptionWrapperProps> = ({centered, children}) => {
    return (
        <StyledStatusDescriptionWrapper direction="column" gap={6} centered={centered}>
            {children}
        </StyledStatusDescriptionWrapper>
    )
}
