import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpSendLogParams = z.object({
    code: z.string(),
    password: z.string(),
    media: z.coerce.number().int(),
    data: z.object({
        flow: z.string(),
        recap: z.string(),
        highlights: z.string(),
        ticket: z.boolean(),
        thread_id: z.string()
    })
})
export type HttpSendLogParams = z.infer<typeof HttpSendLogParams>

export const httpSubmitLog = (params: HttpSendLogParams) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/logs`
    return axios.post(url, {...params.data})
}
