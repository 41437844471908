import {AlertCircleIcon} from '@/components/ui/icon'
import {ServiceType} from '@/types/commons'
import {FC} from 'react'
import {StyledShareHostDatesSuccessBody, StyledShareHostDatesSuccessFooter} from './style'
import Button from '@components/ui/button-legacy/Button'
import {useShareHostDatesStore} from '@/features/service/store/ShareHostDatesStore'
import {useTranslation} from 'react-i18next'

interface ServiceTypeProps {
    serviceType: ServiceType
}

export const ShareHostDatesSuccess: FC<ServiceTypeProps> = ({serviceType}) => {
    const {t} = useTranslation()
    const toggleShareHostDatesModal = useShareHostDatesStore(state => state.toggleModal)

    return (
        <>
            <StyledShareHostDatesSuccessBody padding={6} gap={1} align="center" justify="center" direction="column">
                <AlertCircleIcon size={48} />
                <h2>{t('service_details:host_dates_picker:success_title')}</h2>
                <p>{t(`service_details:host_dates_picker:${serviceType}:success_description`)}</p>
            </StyledShareHostDatesSuccessBody>

            <StyledShareHostDatesSuccessFooter justify="center">
                <Button variant="tertiary" onClick={() => toggleShareHostDatesModal()}>
                    {t(`commons:okay`)}
                </Button>
            </StyledShareHostDatesSuccessFooter>
        </>
    )
}
