import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCompletedPageBody = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 4}px;
        text-align: center;
        & h1 {
            ${typography.displaySmFixed};
            color: ${palette.neutral[900]};
            font-weight: 500;
        }
        & p {
            ${typography.textMd};
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledCompletedPageIconContainer = styled.div(
    ({theme: {palette}}) => css`
        height: 56px;
        width: 56px;
        border-radius: 28px;
        background-color: ${palette.success[50]};
        color: ${palette.success[600]};
        display: flex;
        justify-content: center;
        align-items: center;
    `
)
