import styled, {css} from 'styled-components'
import {ModalHeader} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {SelectComboboxPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'

export const StyledModalHeader = styled(ModalHeader)(
    ({theme: {palette, spacing}}) => css`
        display: flex;
        justify-content: center;
        border-bottom: 1px solid ${palette.neutral['200']};
        padding: ${spacing * 4.5}px;
    `
)

export const StyledSelectPopover = styled(SelectComboboxPopover)`
    max-height: var(--popover-available-height);
`

export const StyledInfoBox = styled.div<{$variant: 'warning' | 'success' | 'info'}>(
    ({theme: {palette, typography, spacing}, $variant}) => css`
        background-color: ${$variant == 'warning'
            ? palette.warning['50']
            : $variant == 'success'
            ? palette.success['50']
            : palette.blue['50']};
        color: ${$variant == 'warning'
            ? palette.warning['600']
            : $variant == 'success'
            ? palette.success['600']
            : palette.blue['600']};
        border-radius: 12px;
        border-width: 1px;
        border-style: solid;
        border-color: ${$variant == 'warning'
            ? palette.warning['300']
            : $variant == 'success'
            ? palette.success['300']
            : palette.blue['300']};
        padding: ${spacing * 2.5}px ${spacing * 3}px;
        display: flex;
        width: 100%;
        gap: ${spacing * 2}px;
        ${typography.textSm};

        & svg {
            width: 16px;
            height: 16px;
        }
    `
)
