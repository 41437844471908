import InputText from '@/components/commons/input-text/InputText'
import TextArea from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledCategoriesRequestHeader = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px 0px ${spacing * 6}px;
        color: ${palette.neutral[900]};

        h3 {
            ${typography.textXl};
            font-weight: 500;
        }

        p {
            ${typography.textSm};
            font-weight: 400;
        }
    `
)

export const StyledCategoriesRequestBody = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledCategoriesRequestInputText = styled(InputText)`
    width: 100%;
`

type StyledCategoryRowProps = {
    theme: DefaultTheme
    disabled: boolean
}

export const StyledCategoryRow = styled(Flexbox)<StyledCategoryRowProps>(
    ({theme: {typography, palette}, disabled}) => css`
        color: ${palette.neutral.black};

        label {
            ${typography.textSm};
            font-weight: 500;
        }

        svg,
        img {
            width: 20px;
            height: 20px;
        }

        p {
            ${typography.textXs};
            font-weight: 400;
        }

        ${disabled &&
        css`
            opacity: 0.5;
        `}
    `
)

export const StyledCategoriesRequestFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral[300]};
    `
)

export const StyledSuccessRequestToast = styled.div(
    ({theme: {typography, palette}}) => css`
        h5 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
        p {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral[700]};
        }
    `
)

export const StyledRemoveIconWrapper = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        width: 40px;
        height: 40px;
        background-color: ${palette.primary[50]};
        border-radius: 100%;
        color: ${palette.primary[600]};
        margin-bottom: ${spacing * 4}px;
    `
)

export const StyledCategoryRemoveInfo = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral[700]};
    `
)

export const StyledCategoryRemoveReason = styled(TextArea)(
    ({theme: {typography}}) => css`
        ${typography.textSm};
        width: 100%;

        textarea {
            ::placeholder {
                ${typography.textSm};
            }
        }
    `
)
