import styled, {css} from 'styled-components'

export const StyledUploadClipWarning = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2.5}px ${spacing * 3}px;
        border: 1px solid ${palette.warning[300]};
        ${typography.textSm}
        border-radius: 12px;
        color: ${palette.warning[600]};
        background-color: ${palette.warning[50]};
        font-weight: 500;
    `
)
