import {HostApprovalURLParams} from '@route-guards/host-approval-route/HostApprovalRoute.tsx'
import {generatePath, useOutletContext} from 'react-router-dom'
import {MatterportRoom, MatterportRoomSkeleton} from '@/features/matterport/components/MatterportRoom.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon, ChevronLeftIcon, Image01Icon} from '@components/ui/icon'
import {Trans, useTranslation} from 'react-i18next'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useMatterportStore} from '@/features/matterport/store/MatterportStore.tsx'
import {useAmenitiesList} from '@/features/host-approval/services/queries/useAmenitiesList.ts'
import {useAccessibilityRooms} from '@/features/host-approval/services/queries/useAccessibilityRooms.ts'
import {MatterportReviewFooter} from '@/features/matterport/components/MatterportReviewFooter.tsx'
import {useApprovalRequestDetails} from '@/features/host-approval/services/queries/useApprovalRequestDetails.ts'
import {
    StyledCompletedApprovalRequest,
    StyledHostApprovalRequestContainer,
    StyledHostApprovalRequestHero,
    StyledHostApprovalRequestInfoModal,
    StyledHostApprovalRequestWrapper,
    StyledHostApprovalReviewFooterWrapper
} from '@pages/host-approval-request/style.ts'
import {routes} from '@utilities/constants'
import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useSendReviewedApproval} from '@/features/host-approval/services/queries/useSendReviewedApproval.ts'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {imagesObjArraysFlatter} from '@/features/matterport/utils.ts'
import Container from '@components/ui/container/Container.tsx'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {useInView} from '@/hooks/useInView'
import {MatterportIntroModal} from '@/features/matterport/components/MatterportIntroModal'
import Button from '@components/ui/button-legacy/Button'

export const HostApprovalRequest = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: HostApprovalURLParams}>()
    const [isIntroModalVisible, setIsIntroModalVisible] = useState(true)
    //store
    const roomsList = useMatterportStore(state => state.roomsList)
    const toReviewList = useMatterportStore(state => state.toReviewList)
    const initHostApprovalRequest = useMatterportStore(state => state.initHostApprovalRequest)
    //queries
    const {
        data: requestDetails,
        isPending: isDetailsPending,
        isError: isDetailsError
    } = useApprovalRequestDetails({urlParams, params: {response_type: 'extended'}})
    const {data: amenities, isPending: isLoadingAmenities, isError: isAmenitiesError} = useAmenitiesList()
    const {data: rooms, isPending: isLoadingRooms, isError: isRoomsError} = useAccessibilityRooms()
    //mutations
    const {mutate: sendApprovedImages, isPending: isApprovalPending} = useSendReviewedApproval({
        onError: errorHandler,
        onSuccess: () => toast.success(t('hostApproval:success_toast'), {duration: 2500})
    })
    //utils
    const [isFullScrolled, setIsFullScrolled] = useState<boolean>(false)
    const isLoadingStructure = isLoadingAmenities || isLoadingRooms || isDetailsPending
    const isStructureInError = isAmenitiesError || isRoomsError || isDetailsError
    const contentRef = useRef<HTMLDivElement | null>(null)
    const lastElementRef = useRef<HTMLSpanElement | null>(null)
    const pendingCount: number = useMemo(
        () => Object.keys(toReviewList).filter(imageId => toReviewList[imageId].toCheck).length,
        [toReviewList]
    )
    const isInView = useInView(lastElementRef, {threshold: 0.5})

    // Get and set approval request data
    useEffect(() => {
        if (rooms && amenities && requestDetails) {
            //initMatterportStore(rooms, amenities, requestDetails)
            initHostApprovalRequest(rooms, amenities, requestDetails)
        }
    }, [rooms, amenities, requestDetails])

    //submit reviewed data
    const onSubmit = () => {
        sendApprovedImages({
            urlParams,
            params: imagesObjArraysFlatter(toReviewList)
        })
    }

    // Check if the content is scrolled up to 100px from the bottom border
    const handleScroll = () => {
        if (
            contentRef.current &&
            !isLoadingStructure &&
            contentRef.current.scrollHeight - contentRef.current.scrollTop <= contentRef.current.clientHeight + 100
        ) {
            setIsFullScrolled(true)
        }
    }

    return isLoadingStructure ? (
        <Container>
            <MatterportRoomSkeleton />
            <MatterportRoomSkeleton />
            <MatterportRoomSkeleton />
        </Container>
    ) : isStructureInError ? (
        <ErrorBox title={t('errors:default')} icon={<AlertTriangleIcon />} />
    ) : (
        <>
            {requestDetails && requestDetails.approved_at ? (
                <StyledCompletedApprovalRequest direction={'column'} gap={4} align={'center'} justify={'center'}>
                    <IconContainer>
                        <Image01Icon />
                    </IconContainer>
                    <h2>{t('hostApproval:completed:title')} </h2>
                    <p>{t('hostApproval:completed:subtitle')} </p>
                    <ButtonLink
                        variant="primary"
                        size="sm"
                        to={generatePath(routes.DASHBOARD.path, {
                            hostCode: urlParams.hostCode,
                            hostPassword: urlParams.hostPassword
                        })}
                    >
                        {t('commons:dashboard')}
                    </ButtonLink>
                </StyledCompletedApprovalRequest>
            ) : (
                <StyledHostApprovalRequestWrapper>
                    <ScrollArea scrollbar={<Scrollbar />} viewportProps={{onScroll: handleScroll, ref: contentRef}}>
                        <StyledHostApprovalRequestContainer fullHeight={false}>
                            <Flexbox direction={'column'} gap={3} align={'start'}>
                                <ButtonLink
                                    className="dashboard-link"
                                    variant="tertiary"
                                    size="sm"
                                    to={generatePath(routes.DASHBOARD.path, {
                                        hostCode: urlParams.hostCode,
                                        hostPassword: urlParams.hostPassword
                                    })}
                                >
                                    <ChevronLeftIcon /> {t('commons:dashboard')}
                                </ButtonLink>
                                <StyledHostApprovalRequestHero direction={'column'} gap={2}>
                                    <h3>{t('matterport:accessibility_features:title')}</h3>
                                    <p>
                                        <Trans
                                            i18nKey={'hostApproval:card:subtitle'}
                                            values={{listingTitle: requestDetails?.title ?? '-'}}
                                            components={[<a href={''} target="_blank" />]}
                                        />
                                    </p>
                                </StyledHostApprovalRequestHero>
                            </Flexbox>

                            <Flexbox gap={8} direction="column">
                                <>
                                    {roomsList.map(room => (
                                        <MatterportRoom key={room.id} room={room} imagesGridStart={4} />
                                    ))}
                                    <span ref={lastElementRef} />
                                </>
                            </Flexbox>
                        </StyledHostApprovalRequestContainer>
                    </ScrollArea>
                    <StyledHostApprovalReviewFooterWrapper>
                        <MatterportReviewFooter
                            isLoadingGet={isLoadingStructure}
                            hasScrolled={isFullScrolled || isInView}
                            pendingCount={pendingCount}
                            onSubmit={onSubmit}
                            isSubmitting={isApprovalPending}
                            isFixed={false}
                        />
                    </StyledHostApprovalReviewFooterWrapper>
                </StyledHostApprovalRequestWrapper>
            )}

            {isIntroModalVisible && (
                <MatterportIntroModal
                    onClose={() => setIsIntroModalVisible(false)}
                    endSlot={
                        <StyledHostApprovalRequestInfoModal justify="end">
                            <Button onClick={() => setIsIntroModalVisible(false)}>
                                {t('hostApproval:close_modal_cta')}
                            </Button>
                        </StyledHostApprovalRequestInfoModal>
                    }
                />
            )}
        </>
    )
}

HostApprovalRequest.displayName = 'HostApprovalRequest'
