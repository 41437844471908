import {StyledScrollbar, StyledScrollbarThumb} from '@components/ui/scrollbar/style.ts'
import {ScrollAreaScrollbarProps} from '@radix-ui/react-scroll-area'
import {FC} from 'react'

export const Scrollbar: FC<ScrollAreaScrollbarProps> = props => {
    return (
        <StyledScrollbar {...props}>
            <StyledScrollbarThumb />
        </StyledScrollbar>
    )
}
