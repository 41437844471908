import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'

export const hp4 = ({quoteLink}: {quoteLink: string}): ChatbotStep[] => {
    return [
        {
            id: 'HP4',
            options: [
                {
                    value: 'HP4.1',
                    label: i18next.t('chatbot:HP4:HP4.1_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP4',
                            source: 'User',
                            text: 'The number of bedrooms in my quote is wrong, what can I do?'
                        })
                        return 'HP4.1'
                    }
                },
                {
                    value: 'HP4.2',
                    label: i18next.t('chatbot:HP4:HP4.2_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP4',
                            source: 'User',
                            text: 'I want a price quote for a different listing ID'
                        })
                        return 'HP0'
                    }
                },
                {
                    value: 'start',
                    label: i18next.t('commons:other'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP4',
                            source: 'User',
                            text: 'Other'
                        })
                        return 'start'
                    }
                }
            ]
        },
        {
            id: 'HP4.1',
            message: i18next.t('chatbot:HP4:HP4.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP4.1',
                    source: 'Chatbot',
                    text: 'The number of bedroom in your price quote is based on your Airbnb listing page. If this information is incorrect, please update your Airbnb listing page. How many bedrooms does your listing have?'
                })
                return 'HP4_1_input'
            }
        },
        {
            id: 'HP4_1_input',
            user: true,
            trigger: ({value}) => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP4_1_input',
                    source: 'User',
                    text: value?.toString() ?? '-'
                })
                return 'HP4.1.2'
            },
            validator: value => {
                if (isNaN(Number(value))) {
                    return 'Please enter a number'
                } else if (Number(value) <= 0) {
                    return 'Please enter a positive number'
                }
                return true
            }
        },
        {
            id: 'HP4.1.2',
            component: <TicketSubmit reason="wrong_bedrooms" link={quoteLink} />,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP4.1.2',
                    source: 'Chatbot',
                    text: '[Ticket submitted]'
                })
                return 'HP4.1.3'
            },
            asMessage: true,
            replace: true
        },
        {
            id: 'HP4.1.3',
            message: i18next.t('chatbot:HP4:HP4.1.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP4.1.3',
                    source: 'Chatbot',
                    text: 'Thank you! Our team will review your listing information and update your quote accordingly. Please do not sign your current quote, we will send you an updated quote in the next 48 hours.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        }
    ]
}
