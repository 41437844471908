import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledStepNumber = styled(Flexbox)(
    ({theme: {palette}}) => css`
        border-radius: 100%;
        font-weight: 700;
        background-color: ${palette.neutral['100']};
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    `
)

export const StyledStepTitle = styled.h4(
    ({theme: {palette, typography, spacing}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['900']};

        & > a {
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: ${spacing * 0.5}px;
        }
    `
)
