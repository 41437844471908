import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Link} from 'react-router-dom'

export const StyledDashGrid = styled.div<{$showPaddingTop: boolean}>(
    ({theme: {spacing}, $showPaddingTop}) => css`
        display: flex;
        flex-direction: column;
        padding-top: ${$showPaddingTop ? spacing * 16 : 0}px;
    `
)

export const StyledDashTabsWrapper = styled(Flexbox)`
    ${({theme: {zIndex, mediaQueries, spacing, palette}}) => css`
        display: none;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 4}px;
            overflow-y: hidden;
            overflow-x: scroll;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, minmax(100px, 1fr));
            position: sticky;
            top: 0;
            height: 48px;
            background: ${palette.neutral.white};
            z-index: ${zIndex.dashMobileTabBar};
            border-bottom: 1px solid ${palette.neutral['300']};
        }
    `}
`

export const StyledDashTabs = styled(Link)<{$isActive: boolean}>`
    ${({$isActive, theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-items: center;
        gap: ${spacing}px;
        cursor: pointer;
        transition: ease-in-out 300ms;
        box-sizing: border-box;
        ${typography.textSm};
        font-weight: 500;
        color: ${$isActive ? palette.primary['700'] : palette.neutral['500']};
        padding: 0 0 ${spacing * 2.5}px;
        border-bottom: 2px solid ${$isActive ? palette.primary['700'] : 'transparent'};
        & > span {
            ${typography.textXs};
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 22px;
            height: 22px;
            min-width: 22px;
            border: 1px solid ${$isActive ? palette.primary['300'] : palette.neutral['200']};
            background: ${$isActive ? palette.primary['100'] : palette.neutral['50']};
            color: ${$isActive ? palette.primary['700'] : palette.neutral['700']};
        }

        &:hover {
            color: ${palette.primary['700']};
            border-bottom: 2px solid ${palette.primary['700']};
        }
    `}
`
