import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {httpShowExperiencesHostVideoRedirect} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'

const Response = z.object({
    experience_task: z.object({
        id: z.number().int().positive(),
        experience_id: z.number().int().positive()
    })
})
type Response = z.infer<typeof Response>

interface UseUpdateStepArgs {
    urlParams: {hostCode?: number; hostPassword?: string; airbnbId: string}
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, Response>, 'queryKey' | 'queryFn'>
}
export const useShowExperiencesHostVideoRedirect = ({urlParams, queryOptions}: UseUpdateStepArgs) =>
    useQuery<unknown, unknown, Response>({
        queryKey: [
            QUERY_KEYS.EXPERIENCES_HOST_VIDEO_REDIRECT,
            urlParams.hostCode,
            urlParams.hostPassword,
            urlParams.airbnbId
        ],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpShowExperiencesHostVideoRedirect({
                    urlParams: {
                        hostCode: urlParams.hostCode ?? raise('hostCode is nullish'),
                        hostPassword: urlParams.hostPassword ?? raise('hostPassword is nullish'),
                        airbnbId: urlParams.airbnbId
                    },
                    params: {response_type: 'extended', task_type: 'video'}
                }),
                responseShape: Response,
                onZodError: captureException
            }),
        ...queryOptions
    })
