import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {StyledChatbotLink} from '@/features/chatbot/components/styles.ts'
import i18next from 'i18next'
import {HttpGetServiceResponse} from '@/features/service/services/service.http.ts'

export const hp2 = ({service}: {service?: HttpGetServiceResponse | null}): ChatbotStep[] => {
    const isAssigmentCompleted =
        service?.assignment?.task_status.status == 'completed' ||
        service?.assignment?.task_status.status == 'pending_payment'
    const backToGeneric = !service

    return [
        {
            id: 'HP2',
            options: [
                ...(isAssigmentCompleted
                    ? [
                          {
                              value: 'HP2.1',
                              label: i18next.t('chatbot:HP2:HP2.1_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP2',
                                      source: 'User',
                                      text: 'Will I receive a copy of the retouched images?'
                                  })
                                  return 'HP2.1'
                              }
                          }
                      ]
                    : []),
                ...(!isAssigmentCompleted
                    ? [
                          {
                              value: 'HP2.2',
                              label: i18next.t('chatbot:HP2:HP2.2_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP2',
                                      source: 'User',
                                      text: 'How long will it take to get the retouched images on my listing page?'
                                  })
                                  return 'HP2.2'
                              }
                          }
                      ]
                    : []),
                ...(!isAssigmentCompleted
                    ? [
                          {
                              value: 'HP2.3',
                              label: i18next.t('chatbot:HP2:HP2.3_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP2',
                                      source: 'User',
                                      text: 'Can I choose which images will be published on my listing page?'
                                  })
                                  return 'HP2.3'
                              }
                          }
                      ]
                    : []),
                ...(!backToGeneric && !isAssigmentCompleted
                    ? [
                          {
                              value: 'HP2.4',
                              label: i18next.t('chatbot:HP2:HP2.4_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP2',
                                      source: 'User',
                                      text: 'How many retouched images will I get?'
                                  })
                                  return 'HP2.4'
                              }
                          }
                      ]
                    : []),
                ...(!isAssigmentCompleted
                    ? [
                          {
                              value: 'HP2.5',
                              label: i18next.t('chatbot:HP2:HP2.5_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP2',
                                      source: 'User',
                                      text: 'What will happen to my existing images?'
                                  })
                                  return 'HP2.5'
                              }
                          }
                      ]
                    : []),
                {
                    value: 'start',
                    label: i18next.t('commons:other'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP2',
                            source: 'User',
                            text: 'Other'
                        })
                        return backToGeneric ? 'generic_flows_dash_options' : 'start'
                    }
                }
            ]
        },
        {
            id: 'HP2.1',
            message: i18next.t('chatbot:HP2:HP2.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.1',
                    source: 'Chatbot',
                    text: 'Once your images are approved, they are published to your listing, where you can then change their order, delete, and/or add captions.'
                })
                return 'HP2.1_p2'
            }
        },
        {
            id: 'HP2.1_p2',
            message: i18next.t('chatbot:HP2:HP2.1_p1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.1_p2',
                    source: 'Chatbot',
                    text: 'We are unable to send the images directly to you, but you do have the option of downloading them directly from your Airbnb listing page.'
                })
                return 'HP2.1.1'
            }
        },
        {
            id: 'HP2.1.1',
            component: (
                <StyledChatbotLink
                    target={'_blank'}
                    to="https://www.airbnb.com/help/article/2568/how-do-i-save-my-professional-photographs"
                >
                    {i18next.t('chatbot:HP2:HP2.1.1_link')}
                </StyledChatbotLink>
            ),
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.1.1',
                    source: 'Chatbot',
                    text: '[LINK] Here is an FAQ with simple instructions.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP2.2',
            message: i18next.t('chatbot:HP2:HP2.2_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.2',
                    source: 'Chatbot',
                    text: 'Once the photoshoot is complete, the new images will typically be published to your listing page within 8 days.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP2.3',
            message: i18next.t('chatbot:HP2:HP2.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.3',
                    source: 'Chatbot',
                    text: 'A team of editors will curate a selection of images taken by the photographer. They will retouch and upload them to your listing page.'
                })
                return 'HP2.3.1'
            }
        },
        {
            id: 'HP2.3.1',
            message: i18next.t('chatbot:HP2:HP2.3.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.3.1',
                    source: 'Chatbot',
                    text: 'You have full control of your images: you can reorder them, delete or add new images. You can also add captions.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP2.4',
            component: <ImagesNumberInfo />,
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.4',
                    source: 'Chatbot',
                    text: "The number of images depends on the size of your listing. You can expect 2-3 photos of each main area in your listing like bedroom, living room, or kitchen, and 1-2 photos of amenities such as outdoor area, gym, or common space. Each space is unique, so let your photographer know what's special about your listing."
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP2.5',
            message: i18next.t('chatbot:HP2:HP2.5_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.5',
                    source: 'Chatbot',
                    text: 'After the photoshoot is complete, our photo editors will retouch the images and upload them to your listing page. None of your existing images will be removed.'
                })
                return 'HP2.5.1'
            }
        },
        {
            id: 'HP2.5.1',
            component: (
                <StyledChatbotLink target={'_blank'} to="https://www.airbnb.com/help/article/477/add-or-edit-photos">
                    {i18next.t('chatbot:HP2:HP2.5.1_link')}
                </StyledChatbotLink>
            ),
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP2.5.1',
                    source: 'Chatbot',
                    text: '[Link] Check this Help article to learn more about how you can edit, add, or remove any of these images.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        }
    ]
}

export const ImagesNumberInfo = () => {
    const service = useChatbotStore(state => state.service)

    return <div>{i18next.t('chatbot:HP2:HP2.4_component', {photoCount: service?.quote.photo_count})}</div>
}
