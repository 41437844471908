import Container from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'
import {mediaQueries} from '@/theme/mediaQueries.ts'

export const StyledCoAlarmDashboardContainer = styled(Container)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`

export const StyledNotEligible = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        max-width: 400px;

        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['700']};
        }
        & ul {
            list-style: disc;
            ${typography.textSm};
            color: ${palette.neutral['600']};
            margin-left: 24px;
        }

        ${mediaQueries.m} {
            max-width: 100%;
        }
    `
)
