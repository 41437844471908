import {create} from 'zustand'
import {persist} from 'zustand/middleware'

export interface CheckListStore {
    checkBoxIds: string[]
    addCheckBoxId: (id: string) => void
    removeCheckBoxId: (id: string) => void
}

export const useChecklist = create(
    persist<CheckListStore>(
        (setState, getState) => ({
            checkBoxIds: [],
            addCheckBoxId: id => {
                const prevCheckBoxIds = getState().checkBoxIds
                setState({
                    checkBoxIds: [...prevCheckBoxIds, id]
                })
            },
            removeCheckBoxId: (id: string) => {
                const prevCheckBoxIds = getState().checkBoxIds
                setState({
                    checkBoxIds: prevCheckBoxIds.filter(prevId => prevId != id)
                })
            }
        }),
        {name: 'checklist-store'}
    )
)
