import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {SubmitLog} from '@/features/chatbot/components/submit-log/SubmitLog.tsx'
import {BotRating} from '@/features/chatbot/components/bot-rating/BotRating.tsx'
import {SendSurvey} from '@/features/chatbot/components/survay-submit/SurvaySubmit.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'

export const finalFlowsSteps = ({
    backToGeneric,
    isLuxeWalkthrough
}: {
    backToGeneric?: boolean
    isLuxeWalkthrough: boolean
}): ChatbotStep[] => [
    {
        id: 'oq_message',
        component: <SubmitLog />,
        replace: true,
        trigger: () => {
            useChatbotStore.getState().resetChatHistory()
            return 'oq_message_2'
        }
    },
    {
        id: 'oq_message_2',
        message: 'Do you have any other questions?',
        trigger: () => 'oq_options'
    },
    {
        id: 'oq_options',
        options: [
            {
                value: 'yes',
                label: 'Yes',
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'oq_options',
                        source: 'User',
                        text: '[Other question]'
                    })
                    return backToGeneric ? 'generic_1' : isLuxeWalkthrough ? 'start_luxe' : 'start'
                }
            },
            {
                value: 'no',
                label: 'No',
                trigger: () => 's_start'
            }
        ]
    },
    {
        id: 's_start',
        message: 'Great! How well did we answer your question(s)?',
        trigger: () => 's_options_rating'
    },
    {
        id: 's_options_rating',
        component: <BotRating />
    },
    {
        id: 's_comment_bad',
        message:
            "We're sorry to hear that. Please, would you like to leave a comment below so we can improve our service?",
        trigger: () => 's_user_comment'
    },
    {
        id: 's_comment',
        message: 'Perfect! Would you like to add any additional comments?',
        trigger: () => 's_user_comment'
    },
    {
        id: 's_user_comment',
        placeholder: 'I found the bot...',
        user: true,
        trigger: () => 's_submit_survey'
    },
    {
        id: 's_submit_survey',
        component: <SendSurvey />,
        trigger: () => 's_end',
        asMessage: true
    },
    {
        id: 's_end',
        message: 'Thank you for your feedback! It helps us improve our service.',
        end: true
    }
]
