import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledPhotoServiceCard = styled(Flexbox)(
    ({theme: {shadows, palette}}) => css`
        overflow: hidden;
        border-radius: 16px;
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.md};
    `
)

export const StyledPhotoServiceCardHeader = styled(Flexbox)(
    ({theme: {palette}}) => css`
        position: relative;
        width: 100%;
        height: 169px; // todo: check
        border-bottom: 1px solid ${palette.neutral['300']};
        padding-bottom: 44.2%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }
    `
)

export const StyledPhotoServiceCardBody = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        border-bottom: 1px solid ${palette.neutral['300']};
        flex: 1;
        padding: ${spacing * 6}px;
        & h4 {
            ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral['900']};
            padding-bottom: ${spacing};
            a {
                cursor: pointer;
            }
        }
        & h5 {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledRequestValues = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        border-radius: 12px;
        background: ${palette.neutral['100']};
        width: 100%;
        padding: ${spacing * 3}px ${spacing * 4}px;

        svg {
            flex-shrink: 0;
        }
    `
)

export const StyledRequestValuesText = styled.div(
    ({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${palette.neutral['900']};
        padding-bottom: ${spacing};
        & > b {
            font-weight: 500;
        }
    `
)

export const StyledPhotoServiceCardFooter = styled(Flexbox)(
    ({theme: {spacing, typography}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        min-height: 74px;
        button {
            flex: none;
        }
        p {
            font-size: ${typography.textSm}px;
        }
    `
)

export const StyledFooterItem = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        & h4 {
            ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textXs};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
    `
)

export const StyledTooltipContent = styled.div(
    ({theme: {palette, typography, spacing}}) => css`
        max-width: 300px;
        padding: ${spacing * 1.5}px;
        & h4 {
            ${typography.textXs};
            font-weight: 600;
        }
        & p {
            ${typography.textXs};
            font-weight: 400;
            color: ${palette.neutral['300']};
        }
    `
)
