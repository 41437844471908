import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {NewPhotoRequest} from '@/features/chatbot/components/new-photo-request/NewPhotoRequest.tsx'
import i18next from 'i18next'

export const hp0 = (): ChatbotStep[] => {
    return [
        {
            id: 'HP0',
            message: i18next.t('chatbot:HP0:HP0_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: '1',
                    source: 'Chatbot',
                    text: `To request another price quote for a listing you just need to follow these steps:`
                })
                return 'HP0.1'
            }
        },
        {
            id: 'HP0.1',
            message: i18next.t('chatbot:HP0:HP0.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP0.1',
                    source: 'Chatbot',
                    text: '1 - Be sure that the listing that you want a new quote for is an active one.'
                })
                return 'HP0.2'
            }
        },
        {
            id: 'HP0.2',
            asMessage: true,
            component: <NewPhotoRequest />,
            trigger: () => 'HP0.3'
        },
        {
            id: 'HP0.3',
            message: i18next.t('chatbot:HP0:HP0.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP0.2',
                    source: 'Chatbot',
                    text: '2 - Click the cta below, login with you Airbnb account and you will be redirected in the Request a photo-shoot section. From here you can simply accept our proposals for your active listings.'
                })
                return 'HP0.4'
            }
        },
        {
            id: 'HP0.4',
            message: i18next.t('chatbot:HP0:HP0.4_message'),
            trigger: () => 'oq_options'
        }
    ]
}
