import styled, {css} from 'styled-components'

export const StyledNumberContainer = styled.div(
    ({theme: {palette, typography}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        ${typography.textMd};
        font-weight: 700;
        color: ${palette.neutral['900']};
        background: ${palette.neutral['100']};
    `
)

export const StyledStepTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledStepDescription = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['900']};
    `
)

export const StyledStepImgContainer = styled.img`
    width: 70px;
    height: 70px;
`
