import {Trans, useTranslation} from 'react-i18next'
import {generatePath} from 'react-router-dom'
import {FC} from 'react'
import {routes} from '@utilities/constants'
import {PromoServicesSection} from '@/features/promotional-services/components/promo-services-section/PromoPhotoshootsSection.tsx'
import {StyledTermsLink} from '@/features/promotional-services/components/promo-services-section/style.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import Container from '@components/ui/container/Container.tsx'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {PromoMatterportSectionValues} from '@/features/promotional-services/components/promo-matterport-section/PromoMatterportSectionValues.tsx'

export interface PromoMatterportSectionProps {
    matterportServices: ServiceProposal[]
    showFullServicesInfo: boolean
}

export const PromoMatterportSection: FC<PromoMatterportSectionProps> = ({matterportServices, showFullServicesInfo}) => {
    const {t} = useTranslation()

    return (
        <Container>
            {showFullServicesInfo && (
                <StyledPageHeaderContainer>
                    <StyledPageHeaderText direction={'column'} gap={3}>
                        <h1>{t('dashboard:promo_matterport:landing_mode_title')}</h1>
                    </StyledPageHeaderText>
                </StyledPageHeaderContainer>
            )}

            <PromoServicesSection
                id={'matterport'}
                title={t('dashboard:promo_matterport:title')}
                subtitle={
                    <Trans
                        i18nKey={'dashboard:promo_matterport:subtitle'}
                        components={[
                            <StyledTermsLink
                                to={generatePath(routes.TERMS_OF_SERVICE.path, {type: 'photo-video'})}
                                target={'_blank'}
                            />
                        ]}
                    />
                }
                services={matterportServices}
            />

            {showFullServicesInfo && <PromoMatterportSectionValues />}
        </Container>
    )
}

PromoMatterportSection.displayName = 'PromoMatterportSection'
