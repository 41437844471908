import styled, {css} from 'styled-components'

export const StyledLangModalToggle = styled.div(
    ({theme: {mediaQueries, spacing, typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: pointer;
        border-radius: 4px;
        background: ${palette.neutral.white};
        ${mediaQueries.m} {
            & > span {
                display: none;
            }
        }
    `
)
