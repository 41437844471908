import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {ClockIcon, HomeLineIcon, Image01Icon, VideoRecorderIcon} from '@components/ui/icon'
import Divider from '@components/ui/divider/Divider.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import {
    ServicePromoCardInfoItem,
    StyledServicePromoCardBody,
    StyledServicePromoCardCtas,
    StyledServicePromoCardFooter,
    StyledServicePromoCardInfo,
    StyledServicePromoCardQuoteInfo,
    StyledServicePromoCardTitle,
    StyledServicePromoCardWrapper
} from '@/features/promotional-services/components/service-promo-card/style.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {formatCurrency, formatLocaleDate, getServiceType} from '@utilities/helpers.ts'

export interface ServicePromoCardProps {
    service: ServiceProposal
    onAcceptCb: () => void
    onDeclineCb?: () => void
}

export const ServicePromoCard: FC<ServicePromoCardProps> = ({service, onAcceptCb, onDeclineCb}) => {
    const {t} = useTranslation()
    const serviceType = getServiceType(service.task_media.name, service.task_category.name)
    const showDeclineBtn = serviceType != 'experience_video' && onDeclineCb
    const showBedrooms = serviceType == 'home' || serviceType == 'matterport'
    const showDuration = serviceType == 'walkthrough'
    const showTotalImages = serviceType == 'home'
    const showVideoInfo = serviceType == 'experience_video'

    return (
        <StyledServicePromoCardWrapper>
            <StyledServicePromoCardBody direction={'column'}>
                <StyledServicePromoCardTitle direction={'column'}>
                    <a
                        href={service.entity.airbnb_link ?? undefined}
                        title={service.entity.title || service.entity.external_id}
                        target="_blank"
                    >
                        {service.entity.title || t('commons:listing_n', {listingId: service.entity.external_id})}
                    </a>
                    <p>{service.entity.city}</p>
                </StyledServicePromoCardTitle>
                <StyledServicePromoCardInfo>
                    {showBedrooms && (
                        <ServicePromoCardInfoItem align={'center'}>
                            <HomeLineIcon width={20} />
                            <p>{t('commons:n_bedrooms', {count: service.entity.bedrooms ?? 0})}</p>
                        </ServicePromoCardInfoItem>
                    )}

                    {showTotalImages && (
                        <ServicePromoCardInfoItem align={'center'}>
                            <Image01Icon width={20} />
                            <p>{t('dashboard:promotional_photo:photos_number', {count: service.photo_count ?? 0})}</p>
                        </ServicePromoCardInfoItem>
                    )}

                    {showDuration && (
                        <ServicePromoCardInfoItem align={'center'}>
                            <ClockIcon width={20} />
                            <p>{t('dashboard:promo_walkthrough:duration', {count: 30})}</p>
                        </ServicePromoCardInfoItem>
                    )}

                    {showVideoInfo && (
                        <>
                            <ServicePromoCardInfoItem align={'center'}>
                                <VideoRecorderIcon width={20} />
                                <p>{t('dashboard:promo_experience_video:quantity', {count: 1})}</p>
                            </ServicePromoCardInfoItem>
                            <ServicePromoCardInfoItem align={'center'}>
                                <Image01Icon width={20} />
                                <p>
                                    {t('dashboard:promo_experience_video:photos_number', {
                                        count: service.photo_count ?? 5
                                    })}
                                </p>
                            </ServicePromoCardInfoItem>
                        </>
                    )}
                </StyledServicePromoCardInfo>
            </StyledServicePromoCardBody>

            <Divider bottomSpacing={0} topSpacing={0} />

            <StyledServicePromoCardFooter align={'center'} justify={'space-between'}>
                <StyledServicePromoCardQuoteInfo direction={'column'}>
                    {showVideoInfo ? (
                        <h6>
                            <span>{formatCurrency(3200)}</span>
                            {t('commons:free')}
                        </h6>
                    ) : (
                        <h6>{service.amount ? formatCurrency(service.amount) : t('commons:free')}</h6>
                    )}
                    <small>
                        {t('commons:expires_on')} {formatLocaleDate(service.expire_at)}
                    </small>
                </StyledServicePromoCardQuoteInfo>
                <StyledServicePromoCardCtas>
                    {showDeclineBtn && (
                        <Button variant={'tertiary'} size={'sm'} onClick={onDeclineCb}>
                            {t('commons:decline')}
                        </Button>
                    )}
                    <Button variant={'primary'} size={'sm'} onClick={onAcceptCb}>
                        {t('commons:accept')}
                    </Button>
                </StyledServicePromoCardCtas>
            </StyledServicePromoCardFooter>
        </StyledServicePromoCardWrapper>
    )
}

ServicePromoCard.displayName = 'ServicePromoCard'
