import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import InputText from '@/components/commons/input-text/InputText'

export const StyledInputText = styled(InputText)`
    width: 100%;

    & div {
        height: 40px;
    }
    & label {
        font-weight: 400;
    }
`

export const StyledDeleteIconWrapper = styled(Flexbox)<{$disabled: boolean}>(
    ({theme: {palette}, $disabled}) => css`
        width: 40px;
        height: 40px;
        border: 1px solid ${palette.neutral[300]};
        border-radius: 8px;
        color: ${palette.neutral[700]};
        cursor: pointer;

        ${$disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
    `
)
