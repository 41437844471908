import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ShowHostServices} from '@/features/chatbot/components/show-host-services/ShowHostServices.tsx'
import i18next from 'i18next'

export const genericRootFlowSteps = () => {
    return [
        {
            id: 'generic_start',
            message: i18next.t('chatbot:generic_root_flow:generic_start_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'generic_start',
                    source: 'Chatbot',
                    text: `Hey, you are now talking with our virtual assistant. What can I help you with today?`
                })
                return 'generic_1'
            }
        },
        {
            id: 'generic_1',
            options: [
                {
                    value: 'service_select',
                    label: i18next.t('chatbot:generic_root_flow:generic_1_message'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'generic_1',
                            source: 'User',
                            text: 'I need help!'
                        })
                        return 'service_select_message'
                    }
                },
                {
                    value: 'service_walkthrough_select',
                    label: i18next.t('chatbot:generic_root_flow:generic_3_message'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'generic_3',
                            source: 'User',
                            text: 'I need help!'
                        })
                        return 'service_select_message'
                    }
                },
                {
                    value: 'generic_questions',
                    label: i18next.t('chatbot:generic_root_flow:generic_2_message'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'generic_2',
                            source: 'User',
                            text: 'I need help!'
                        })
                        return 'generic_flows_dash_service_choose'
                    }
                }
            ]
        },
        {
            id: 'generic_flows_dash_service_choose',
            options: [
                {
                    value: 'photo_service_select',
                    label: i18next.t('commons:photoshoot'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'photo_service_select',
                            source: 'User',
                            text: 'I need help!'
                        })
                        return 'generic_flows_dash_photo_options'
                    }
                },
                {
                    value: 'walk_service_select',
                    label: i18next.t('commons:walkthrough'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'walk_service_select',
                            source: 'User',
                            text: 'I need help!'
                        })
                        return 'generic_flows_dash_walk_options'
                    }
                }
            ]
        },
        {
            id: 'service_select_message',
            message: i18next.t('chatbot:generic_root_flow:service_select_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP0.1',
                    source: 'Chatbot',
                    text: 'Here is a list of your quotes and services that are currently in progress. Please select the one for which you need assistance.'
                })
                return 'service_select'
            }
        },
        {
            id: 'service_select',
            component: <ShowHostServices /> // trigger should be managed in the component
        },
        {
            id: 'generic_flows_dash_photo_options',
            options: [
                {
                    value: 'HP0',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP0_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '💶 I want a new price quote for another listing'
                        })
                        return 'HP0'
                    }
                },
                {
                    value: 'HP1',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP1_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '💸 Price Quote'
                        })
                        return 'HP1'
                    }
                },
                {
                    value: 'HP2',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP2_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '🖼️ Images'
                        })
                        return 'HP2'
                    }
                },
                {
                    value: 'HP6',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP6_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '🏡 Preparing for your photoshoot'
                        })
                        return 'HP6'
                    }
                },
                {
                    value: 'HP7',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP7_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '📷️ Photographer match'
                        })
                        return 'HP7'
                    }
                },
                {
                    value: 'HP11',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP11_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '🧭 My topic is not listed'
                        })
                        return 'HP11'
                    }
                }
            ]
        },
        {
            id: 'generic_flows_dash_walk_options',
            options: [
                //price quote
                {
                    value: 'HP1_luxe',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP1_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP1_luxe',
                            source: 'User',
                            text: '💸 Price Quote'
                        })
                        return 'HP1_luxe'
                    }
                },
                //luxe invitation
                {
                    value: 'HP31',
                    label: i18next.t('chatbot:luxe_service_page_steps:start_HP31_root'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP31_luxe',
                            source: 'User',
                            text: 'Luxe invitation'
                        })
                        return 'HP31'
                    }
                },
                {
                    value: 'HP33.3',
                    label: i18next.t('chatbot:HP33:options:label_3'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.3',
                            source: 'User',
                            text: 'Do I need to be present during the walkthrough?'
                        })
                        return 'HP33.3.1'
                    }
                },
                {
                    value: 'HP33.5',
                    label: i18next.t('chatbot:HP33:options:label_5'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.5',
                            source: 'User',
                            text: 'How long will the walkthrough last?'
                        })
                        return 'HP33.5.1'
                    }
                },
                {
                    value: 'HP35.2',
                    label: i18next.t('chatbot:HP35:options:label_2'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35.2',
                            source: 'User',
                            text: 'Requesting a photoshoot'
                        })
                        return 'HP35.2.1'
                    }
                },
                {
                    value: 'HP32',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP11_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP32',
                            source: 'User',
                            text: '🧭 My topic is not listed'
                        })
                        return 'HP32'
                    }
                }
            ]
        }
    ]
}
