import {FC, useMemo, useState} from 'react'
import {CategoryRequestOperation, CategoryRequestStatus, ListingCategory} from '../types'
import {useTranslation} from 'react-i18next'
import {PlusIcon, InfoCircleIcon} from '@/components/ui/icon'
import {CategoryChip} from '../category-chip/CategoryChip'
import {CategoryRequestsAccordion} from '../category-requests-accordion/CategoryRequestsAccordion'
import {CategoryRequestsContent} from '../category-requests-accordion/CategoryRequestsContent'
import {CategoriesDictionary, CategoryDictionaryKey} from '../utils'
import {useCategories} from '../services/queries/useCategories'
import {useCategoriesRequestStore} from '../store/CategoriesRequestStore'
import {
    StyledCategoriesRequestsWarning,
    StyledListigCategoriesHeader,
    StyledListingCategoriesBody,
    StyledListingCategoriesBodyHeading,
    StyledListingCategoriesChips,
    StyledListingCategoriesFooter,
    StyledListingCategoriesButton,
    StyledListingCategoriesCard,
    StyledListingCategoriesHeaderContainer,
    StyledListingCategoriesHeaderText
} from './style'
import {
    ListingThumbnailImage,
    ListingThumbnailName,
    ListingThumbnailParagraph
} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'

interface ListingCategoriesCardProps {
    listing: ListingCategory
}

export const ListingCategoriesCard: FC<ListingCategoriesCardProps> = ({listing}) => {
    const {data: categories} = useCategories()
    const [isAccordionOpened, setIsAccordionOpened] = useState(false)
    const toggleCategoriesModal = useCategoriesRequestStore(store => store.toggleModalVisibility)
    const homeCategoryRequests = listing.entity ? listing.entity.home_category_requests : []
    const {t} = useTranslation()

    const removedCategories = useMemo<number[]>(
        () =>
            listing.entity
                ? listing.entity.home_category_requests
                      .filter(category => category.status === CategoryRequestStatus.Enum.removed)
                      .map(category => category.home_category_id)
                      .flat()
                : [],
        [listing, categories]
    )

    const activeRequestsCount = useMemo<number>(
        () =>
            listing.entity
                ? listing.entity.home_category_requests.filter(request => request.status == 'requested').length
                : 0,
        [listing.entity?.home_category_requests]
    )

    const onChangeAccordion = () => {
        setIsAccordionOpened(!isAccordionOpened)
    }

    const handleClickCategory = (categoryId: number) => {
        if (categoryId && removedCategories.includes(categoryId)) {
            setIsAccordionOpened(true)
        } else {
            toggleCategoriesModal(CategoryRequestOperation.Enum.remove, activeRequestsCount, listing.id, categoryId)
        }
    }

    return (
        <StyledListingCategoriesCard>
            <StyledListigCategoriesHeader width={'100%'} gap={2}>
                <StyledListingCategoriesHeaderContainer gap={4}>
                    <ListingThumbnailImage src={listing.image_url} alt={listing.name} />
                    <StyledListingCategoriesHeaderText direction="column">
                        <ListingThumbnailName title={listing.name}>{listing.name}</ListingThumbnailName>
                        <ListingThumbnailParagraph title={`${listing.city}, ${listing.state}`}>
                            {`${listing?.city ?? '-'}, ${listing?.state ?? '-'}`}
                        </ListingThumbnailParagraph>
                    </StyledListingCategoriesHeaderText>
                </StyledListingCategoriesHeaderContainer>

                <StyledListingCategoriesButton
                    variant="secondary"
                    size={'sm'}
                    onClick={() =>
                        toggleCategoriesModal(CategoryRequestOperation.Enum.add, activeRequestsCount, listing.id)
                    }
                    disabled={activeRequestsCount === 5}
                    className="button"
                >
                    <PlusIcon />
                    {t('categories:add')}
                </StyledListingCategoriesButton>
            </StyledListigCategoriesHeader>

            {activeRequestsCount === 5 && (
                <StyledCategoriesRequestsWarning gap={2} align="center">
                    <InfoCircleIcon />
                    {t('categories:card:limit_reached')}
                </StyledCategoriesRequestsWarning>
            )}

            <StyledListingCategoriesBody direction="column">
                <StyledListingCategoriesBodyHeading>
                    <h3>{t('categories:title')}</h3>
                    <p>{t('categories:card:subtitle')}</p>
                </StyledListingCategoriesBodyHeading>

                <StyledListingCategoriesChips gap={2}>
                    {listing.categories.length > 0 ? (
                        listing.categories.map(currentCategory => {
                            const category =
                                currentCategory === 'One-of-a-kind'
                                    ? categories.find(({name}) => 'OMG!' === name)
                                    : categories.find(({name}) => currentCategory === name)
                            return (
                                category &&
                                currentCategory !== 'Trending' && (
                                    <CategoryChip
                                        key={category.id}
                                        id={category.id}
                                        label={
                                            t(CategoriesDictionary[category.name as CategoryDictionaryKey]?.label) ??
                                            category.name
                                        }
                                        icon={CategoriesDictionary[category.name as CategoryDictionaryKey]?.icon}
                                        onClick={handleClickCategory}
                                        isLimitReached={activeRequestsCount === 5}
                                        removed={removedCategories.includes(category.id)}
                                    />
                                )
                            )
                        })
                    ) : (
                        <p>{t('categories:card:empty_categories')}</p>
                    )}
                </StyledListingCategoriesChips>
            </StyledListingCategoriesBody>

            {homeCategoryRequests.length > 0 && (
                <StyledListingCategoriesFooter>
                    <CategoryRequestsAccordion
                        id="pending-requests"
                        pendingRequestsCount={homeCategoryRequests.length}
                        content={<CategoryRequestsContent requests={homeCategoryRequests} />}
                        value={isAccordionOpened}
                        onChange={onChangeAccordion}
                    />
                </StyledListingCategoriesFooter>
            )}
        </StyledListingCategoriesCard>
    )
}
