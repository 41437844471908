import {ServiceSmsNotifyStep} from '@/features/service-acceptance-flow/components/ServiceSmsNotifyStep.tsx'
import {ServiceConfirmEmailStep} from '@/features/service-acceptance-flow/components/ServiceConfirmEmailStep.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'

export const CoAlarmNotifyProcess = () => {
    const coNotifyActiveStep = useServiceAcceptStore(state => state.step)

    return (
        <>
            {coNotifyActiveStep == 'sms' && <ServiceSmsNotifyStep isAcceptanceFlow={true} />}
            {coNotifyActiveStep == 'email' && <ServiceConfirmEmailStep />}
        </>
    )
}

CoAlarmNotifyProcess.displayName = 'CoAlarmNotifyProcess'
