import styled, {css} from 'styled-components'
import {ExperiencesHostVideoAnswerItem} from '@/features/experiences-host-video/components/experiences-host-video-answer-item/ExperiencesHostVideoAnswerItem.tsx'

export const StyledVideoAnswerItem = styled(ExperiencesHostVideoAnswerItem)(
    () => css`
        width: 100%;
        position: relative;
    `
)

export const StyledPhoneAnswerLabelWrapper = styled.div<{$isCorrect?: boolean}>(
    ({theme: {palette}, $isCorrect}) => css`
        position: absolute;
        left: 8px;
        top: 8px;
        & svg {
            color: ${$isCorrect ? palette.success[600] : palette.danger[600]};
        }
    `
)
