import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Entity} from '@/features/service/types.ts'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon'
import {useMatterportStore} from '../store/MatterportStore'
import {useAsync} from '@/hooks/useAsync'
import {MatterportRoom, MatterportRoomSkeleton} from './MatterportRoom'
import {MatterportReviewFooter} from './MatterportReviewFooter'
import {MatterportUrlParams} from '../types'
import {getMatterportData, imagesObjArraysFlatter} from '../utils'
import {httpConfirmMatterportReview} from '../services/matterport.http'
import {StyledMatterportTitle, StyledMatterportModal, StyledMatterportReviewContent} from './style'
import toast from 'react-hot-toast'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {errorHandler} from '@utilities/helpers.ts'

interface MatterportReviewProps {
    entity: Entity
    urlParams: MatterportUrlParams
}

export const MatterportReview: FC<MatterportReviewProps> = ({entity, urlParams}) => {
    const [hasScrolled, setHasScrolled] = useState<boolean>(false)
    const {t} = useTranslation()
    const roomsList = useMatterportStore(state => state.roomsList)
    const toReviewList = useMatterportStore(state => state.toReviewList)
    const initMatterportStore = useMatterportStore(state => state.initMatterport)
    const closeModal = useMatterportStore(state => state.closeModal)
    const {run: runGet, isLoading: isLoadingGet, isIdle: isIdleGet, isError: isErrorGet} = useAsync()
    const {run: runConfirm, isLoading: isSubmitting} = useAsync()
    const contentRef = useRef<HTMLDivElement | null>(null)
    const pendingCount: number = useMemo(
        () => Object.keys(toReviewList).filter(imageId => toReviewList[imageId].toCheck).length,
        [toReviewList]
    )

    // Get and merge matterport data
    const initCurrenTask = async () => {
        try {
            const {rooms, amenities, infos} = await runGet(getMatterportData(entity))
            initMatterportStore(rooms, amenities, infos)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        initCurrenTask()
    }, [])

    // Check if the content is scrolled up to 100px from the bottom border
    const handleScroll = () => {
        if (
            contentRef.current &&
            !isLoadingGet &&
            contentRef.current.scrollHeight - contentRef.current.scrollTop <= contentRef.current.clientHeight + 100
        ) {
            setHasScrolled(true)
        }
    }

    // Check if all images have been reviewed and if so perform the confirmation function
    const onSubmit = async () => {
        try {
            await runConfirm(
                httpConfirmMatterportReview({
                    urlParams,
                    params: imagesObjArraysFlatter(toReviewList)
                })
            )

            toast.success(t('matterport:review:confirm_success'), {duration: 4500})
            closeModal()
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <StyledMatterportModal width={1000} onClose={closeModal}>
            <StyledMatterportTitle>{t('matterport:accessibility_features:title')}</StyledMatterportTitle>
            <ScrollArea scrollbar={<Scrollbar />} viewportProps={{onScroll: handleScroll, ref: contentRef}}>
                <StyledMatterportReviewContent gap={8} direction="column">
                    <h2>{t('matterport:review:title')}</h2>
                    {isLoadingGet || isIdleGet ? (
                        <>
                            <MatterportRoomSkeleton />
                            <MatterportRoomSkeleton />
                            <MatterportRoomSkeleton />
                        </>
                    ) : isErrorGet ? (
                        <ErrorBox title={t('errors:default')} icon={<AlertTriangleIcon />} />
                    ) : (
                        roomsList.map(room => <MatterportRoom key={room.id} room={room} />)
                    )}
                </StyledMatterportReviewContent>
            </ScrollArea>
            <MatterportReviewFooter
                isLoadingGet={isLoadingGet}
                hasScrolled={hasScrolled}
                pendingCount={pendingCount}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
            />
        </StyledMatterportModal>
    )
}
