import styled, {css} from 'styled-components'

export const PhotoMosaicContainer = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing * 16}px;
        padding: ${spacing * 10}px 0;
        & > h3 {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;
            ${typography.displayXs};
            font-weight: 500;
            color: ${palette.neutral['900']};
            & span {
                color: ${palette.neutral['500']};
            }
        }
        ${mediaQueries.m} {
            gap: ${spacing * 10}px;
            padding: ${spacing * 12}px 0;
        }
    `}
`

export const PhotoMosaicWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `}
`

export const PhotoMosaicMainImage = styled.img`
    ${() => css`
        object-fit: cover;
    `}
`

export const PhotoMosaicSideImages = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
    `}
`
