import styled, {DefaultTheme, css} from 'styled-components'
import * as RAccordion from '@radix-ui/react-accordion'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    AvailabilityCategoryStatus,
    CategoryRequestOperation,
    CategoryRequestStatus
} from '@/features/categories/types.ts'

export const StyledAccordionHeader = styled(RAccordion.Header)`
    width: 100%;
`

type StyledAccordionTriggerProps = {
    theme: DefaultTheme
    isOpen: boolean
}

export const StyledAccordionTrigger = styled.button<StyledAccordionTriggerProps>`
    ${({theme: {typography}, isOpen}) => css`
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        ${typography.textSm};
        font-weight: 500;

        svg.ChevronIcon {
            transition: ease-in-out 200ms;
        }

        svg.ChevronIcon {
            transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
        }
    `}
`

export const StyledCategoryRequests = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 3}px 0;
    `
)

export const StyledCategoryRequestItemLabel = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
        font-weight: 500;
    `
)

export const StyledCategoryRequestItemDate = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
    `
)

export const StyledCategoryRequestItemStatus = styled(Flexbox)<{
    status?: AvailabilityCategoryStatus | CategoryRequestOperation | CategoryRequestStatus
}>(
    ({status, theme: {typography, palette}}) => css`
        color: ${status == 'requested' || status == 'approved' || status == 'approved_unacceptable_photos'
            ? palette.neutral[500]
            : status == 'completed' || status == 'escalated'
            ? palette.success[500]
            : palette.danger[500]};
        & > svg {
            width: 14px;
        }
        & > span {
            ${typography.textSm}
        }
    `
)

export const StyledPoiMessage = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral['500']};
    `
)

export const StyledCategoryRequestItem = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        gap: ${spacing * 1.5}px;
        color: ${palette.neutral.black};
        font-weight: 500;
        & > svg,
        img {
            width: 20px;
            height: 20px;
        }
    `
)
