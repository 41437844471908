import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpSubmitSurveyParams = z.object({
    code: z.coerce.number().int(),
    password: z.string(),
    media: z.coerce.number().int(),
    data: z.object({
        flow: z.string(),
        notes: z.string(),
        rating: z.coerce.number(),
        thread_id: z.string()
    })
})
export type HttpSubmitSurveyParams = z.infer<typeof HttpSubmitSurveyParams>

export const httpSubmitSurvey = (params: HttpSubmitSurveyParams) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/rate`
    return axios.post(url, {...params.data})
}
