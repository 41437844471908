import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpRetrievePpStatusParams = z.object({
    code: z.string(),
    password: z.string(),
    media: z.coerce.number().int()
})
export type HttpRetrievePpStatusParams = z.infer<typeof HttpRetrievePpStatusParams>

export const httpRetrievePpStatus = (params: HttpRetrievePpStatusParams) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/retrieveStatus`
    return axios.get(url)
}
