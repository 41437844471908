import {StyledAcceptStepHeader, StyledStepCounter} from '@/features/service-acceptance-flow/components/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultNamespace} from 'i18next'
import {FC, ReactElement} from 'react'

interface AcceptanceModalHeaderProps {
    actionIcon: ReactElement
    portraitEnabled: boolean
    subtitle?: DefaultNamespace | string
    title: DefaultNamespace | string
}

export const AcceptanceModalHeader: FC<AcceptanceModalHeaderProps> = ({
    actionIcon,
    title,
    subtitle,
    portraitEnabled
}) => {
    return (
        <StyledAcceptStepHeader align={'center'} justify={'center'}>
            {actionIcon}
            <Flexbox align={'center'} direction={'column'} gap={0.5}>
                {title}
                {portraitEnabled && <StyledStepCounter> {subtitle} </StyledStepCounter>}
            </Flexbox>
        </StyledAcceptStepHeader>
    )
}

AcceptanceModalHeader.displayName = 'AcceptanceModalHeader'
