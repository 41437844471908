import styled, {css} from 'styled-components'

export const StyledSelfFilmingButton = styled.button(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['700']};
        text-decoration: underline;
        cursor: pointer;
    `
)
