import styled, {css} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'

export const StyledStatusWrapper = styled(Container)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding-top: ${spacing * 10.5}px;
        padding-bottom: ${spacing * 10.5}px;
        display: flex;
        justify-content: space-between;
        gap: ${spacing * 6}px;

        ${mediaQueries.l} {
            flex-direction: column;
        }
    `
)
