import {useServiceCancellationStore} from '@/features/service-cancel/store/serviceCancellationStore.tsx'
import {CancelServiceWarningStep} from '@/features/service-cancel/components/service-cancellation-process/CancelServiceWarningStep.tsx'
import {CancelServiceFinalStep} from '@/features/service-cancel/components/service-cancellation-process/CancelServiceFinalStep.tsx'
import {StyledModal} from '@/features/service-cancel/components/service-cancellation-process/style.ts'

export const ServiceCancellationProcess = () => {
    const cancellationActiveStep = useServiceCancellationStore(state => state.step)
    const toggleCancelServiceModal = useServiceCancellationStore(state => state.cancellationServiceModalToggle)

    return (
        <StyledModal width={374} onClose={toggleCancelServiceModal} onOverlayClick={toggleCancelServiceModal}>
            {cancellationActiveStep == 'warning' && <CancelServiceWarningStep />}
            {cancellationActiveStep == 'final' && <CancelServiceFinalStep />}
        </StyledModal>
    )
}

ServiceCancellationProcess.displayName = 'ServiceCancellationProcess'
