import styled, {css} from 'styled-components'

export const StyledIconContainer = styled.div<{size: 'md' | 'lg'; type: 'neutral' | 'danger' | 'success' | 'warning'}>(
    ({size, type, theme: {palette}}) => css`
        flex: none;
        width: ${size == 'lg' ? '48px' : '40px'};
        height: ${size == 'lg' ? '48px' : '40px'};
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid
            ${type == 'neutral'
                ? palette.neutral['200']
                : type == 'danger'
                ? palette.danger['200']
                : type == 'warning'
                ? palette.warning['200']
                : palette.success['200']};
        background: ${type == 'neutral'
            ? 'transparent'
            : type == 'danger'
            ? palette.danger['600']
            : type == 'warning'
            ? palette.warning['600']
            : palette.success['600']};
        svg {
            width: ${size == 'lg' ? '24px' : '20px'};
        }
    `
)
