import {useAuthStore} from '@/store/AuthStore.tsx'
import {generatePath, Navigate} from 'react-router-dom'
import {routes} from '@utilities/constants'

/**
 * In this specific case the home page act as Gatekeeper based on the auth params
 */
export const Home = () => {
    const user = useAuthStore(state => state.user)

    return (
        <>
            {user ? (
                <Navigate
                    to={generatePath(routes.DASHBOARD.path, {
                        hostCode: user.code,
                        hostPassword: user.password
                    })}
                />
            ) : (
                <Navigate to={routes.SSO_LOGIN.path} />
            )}
        </>
    )
}

Home.displayName = 'Home'
