import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledChecklistModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`

export const StyledTitle = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        & h3 {
            padding-left: ${spacing * 2}px;
        }
    `
)
