import {z} from 'zod'
import {NotificationPreferences} from '@/features/notifications-settings/types.ts'

export const SensorRequest = z.object({
    address: z.string(),
    address_1: z.string().nullable(),
    address_2: z.string().nullable(),
    address_extras: z.string().nullish(),
    country: z.string(),
    city: z.string().nullable(),
    created_at: z.string(),
    state: z.string().nullable(),
    sensors_requested: z.number(),
    zip: z.string().nullable()
})
export type SensorRequest = z.infer<typeof SensorRequest>

export const AddressType = z.enum([
    'route',
    'sublocality_level_1',
    'locality',
    'administrative_area_level_1',
    'postal_code',
    'street_number',
    'country'
])
export type AddressType = z.infer<typeof AddressType>

export const ParsedManualAddress = z.object({
    formatted_address: z.string(),
    address: z
        .object({
            long_name: z.string(),
            short_name: z.string(),
            types: AddressType.array()
        })
        .array()
})
export type ParsedManualAddress = z.infer<typeof ParsedManualAddress>

export const HttpCoAlarmHostInfo = z.object({
    active_listings: z.number(),
    total_sensors_requested: z.number(),
    co_sensor_requests_details: z.array(SensorRequest)
})
export type HttpCoAlarmHostInfo = z.infer<typeof HttpCoAlarmHostInfo>

export const CoAlarmListingsSearchParams = z.object({
    search: z.string().optional()
})
export type CoAlarmListingsSearchParams = z.infer<typeof CoAlarmListingsSearchParams>

export const CoAlarmStatus = z.enum([
    'eligible',
    'ineligible',
    'requested',
    'shipped',
    'delivered',
    'to_install',
    'installed'
])
export type CoAlarmStatus = z.infer<typeof CoAlarmStatus>

export const CoAlarmListing = z.object({
    id: z.coerce.string(),
    name: z.string(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    country_code: z.string(),
    image_url: z.string().nullable()
})
export type CoAlarmListing = z.infer<typeof CoAlarmListing>

export const CoAlarmRequest = z.object({
    id: z.coerce.string(),
    notification_preferences: NotificationPreferences.nullish(),
    listing: CoAlarmListing,
    shipping_first_name: z.string(),
    shipping_last_name: z.string(),
    shipping_phone_number: z.string(),
    shipping_address: z.string(),
    shipping_address_1: z.string(),
    shipping_address_2: z.string().nullable(),
    shipping_address_extras: z.string().nullable(),
    shipping_state: z.string(),
    shipping_city: z.string(),
    shipping_country: z.string(),
    shipping_zip: z.string(),
    co_alarm_status: CoAlarmStatus,
    shipped_at: z.string().nullable(),
    delivered_at: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string().nullable(),
    status: z.enum(['requested', 'shipped', 'delivered'])
})
export type CoAlarmRequest = z.infer<typeof CoAlarmRequest>

export const CoAlarmListingRequest = CoAlarmListing.extend({
    co_alarm: z.object({
        status: CoAlarmStatus,
        request: CoAlarmRequest.omit({listing: true, co_alarm_status: true, notification_preferences: true}).nullable()
    })
})
export type CoAlarmListingRequest = z.infer<typeof CoAlarmListingRequest>

export const CoAlarmListingRemappedRequest = CoAlarmListing.extend({
    co_alarm: z.object({
        status: CoAlarmStatus,
        request: CoAlarmRequest.omit({listing: true, co_alarm_status: true}).nullable()
    })
})
export type CoAlarmListingRemappedRequest = z.infer<typeof CoAlarmListingRemappedRequest>
