import {create} from 'zustand'

export type HostURLParams = {
    hostCode: number | null
    hostPassword: string | null
}

type HostParamsStoreActions = {
    setHostParams: (params: HostURLParams) => void
}

export const useHostParamsStore = create<HostURLParams & HostParamsStoreActions>()(set => ({
    hostCode: null,
    hostPassword: null,
    setHostParams: params =>
        set({
            hostCode: params.hostCode,
            hostPassword: params.hostPassword
        })
}))
