import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-host-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoAnswerItem} from '@/features/experiences-host-video/components/experiences-host-video-answer-item/ExperiencesHostVideoAnswerItem.tsx'
import {TKey} from '@/translations/translationKeys.ts'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {Dispatch, FC, SetStateAction} from 'react'
import {QuizStep} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'
import {UseQuizAnswerResponse} from '@/features/experiences-host-video/services/useQuizAnswer.ts'
import {raise} from '@utilities/helpers.ts'
import {UseMutationResult} from '@tanstack/react-query'

const optionToTranslationKey = {
    host: 'experiences_host_video:quiz:staged_people_count:host',
    at_least_3: 'experiences_host_video:quiz:staged_people_count:at_least_3'
} as const satisfies Record<string, TKey>

interface QuizModalStagedPeopleCountStepProps {
    quiz: Quiz
    setStep: Dispatch<SetStateAction<QuizStep>>
    quizAnswerMutation: UseMutationResult<UseQuizAnswerResponse, unknown, {questionId: number}, unknown>
}
export const QuizModalStagedPeopleCountStep: FC<QuizModalStagedPeopleCountStepProps> = ({
    quiz,
    setStep,
    quizAnswerMutation
}) => {
    const {t} = useTranslation()
    const hostQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'people_in_trailer' &&
                question.question_answer.answer.key_name == 'host'
        ) ?? raise('hostQuestion is missing')
    const threePeopleQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'people_in_trailer' &&
                question.question_answer.answer.key_name == '3_people'
        ) ?? raise('threePeopleQuestion is missing')

    return (
        <>
            <ExperiencesHostVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_host_video:quiz:staged_people_count:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>{t('experiences_host_video:quiz:tap_options')}</QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesHostVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['host'])}
                                {quizAnswerMutation.data?.question_answer_id == hostQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="host"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: hostQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != hostQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == hostQuestion.question_answer.id}
                    />
                    <ExperiencesHostVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['at_least_3'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    threePeopleQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="at_least_3"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: threePeopleQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != threePeopleQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == threePeopleQuestion.question_answer.id}
                    />
                </Flexbox>
                {quizAnswerMutation.isSuccess && (
                    <QuizModalAnswerResponse
                        variant={quizAnswerMutation.data.is_correct ? 'success' : 'danger'}
                        title={t(
                            `experiences_host_video:quiz:${
                                quizAnswerMutation.data.is_correct ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_host_video:quiz:staged_people_count:description')}
                    />
                )}
            </ExperiencesHostVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 100, 0]}>
                <Button
                    disabled={!quizAnswerMutation.isSuccess}
                    onClick={() => {
                        quizAnswerMutation.reset()
                        setStep('elements')
                    }}
                >
                    {t('experiences_host_video:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
