import React, {FC} from 'react'
import {DPMonth, DPPropGetter} from '@rehookify/datepicker'
import {StyledCalendarHeader} from '@components/commons/calendar/style.ts'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {View} from '@components/commons/calendar/Calendar.tsx'
import {capitalize} from '@utilities/helpers.ts'
import dayjs from '@utilities/dayjs.ts'
import {
    HostCalendarWrapper,
    StyledHostBackButton,
    StyledHostYearMonthGrid
} from '@/features/service/components/host-calendar/style.ts'

type MonthSelectorProps = {
    months: DPMonth[]
    monthButton: (month: DPMonth) => DPPropGetter
    currentMonth: string
    setViewState: React.Dispatch<React.SetStateAction<View>>
}

export const HostMonthSelector: FC<MonthSelectorProps> = ({months, monthButton, currentMonth, setViewState}) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    return (
        <HostCalendarWrapper direction="column" className={'monthYearCalendar'} width={'100%'} height={'100%'} gap={6}>
            <StyledCalendarHeader>
                <h3>{t('commons:months')}</h3>
            </StyledCalendarHeader>
            <StyledHostYearMonthGrid>
                {months.map(month => (
                    <Button
                        key={month.month}
                        variant={month.month == currentMonth ? 'primary' : 'ghost'}
                        {...monthButton(month)}
                    >
                        {capitalize(dayjs(month.$date).locale(language).format('MMM'))}
                    </Button>
                ))}
            </StyledHostYearMonthGrid>
            <StyledHostBackButton variant="ghost" onClick={() => setViewState('date')}>
                {t('commons:back')}
            </StyledHostBackButton>
        </HostCalendarWrapper>
    )
}
