import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    HttpGetServiceCancellationReasonsParams,
    httpGetServiceCancellationReasons,
    HttpGetServiceCancellationReasonsResponse
} from '@/features/service-cancel/services/serviceCancellation.http.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useServiceCancellationReasons = (params: HttpGetServiceCancellationReasonsParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SERVICE_CANCELLATION_REASONS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServiceCancellationReasons(params),
                onZodError: captureException,
                responseShape: HttpGetServiceCancellationReasonsResponse
            })
    })
}
