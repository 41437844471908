import {z} from 'zod'
import {CoAlarmStatus} from '@/features/co-alarm/types.ts'

export const User = z.object({
    id: z.string(),
    email: z.string().email(),
    first_name: z.string(),
    last_name: z.string(),
    picture_url: z.string().url().nullable(),
    phone: z.string().nullable(),
    code: z.coerce.number().int(),
    password: z.string(),
    co_alarm: z.object({
        requests_count: z.coerce.number().int(),
        status: CoAlarmStatus
    })
})
export type User = z.infer<typeof User>

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>
