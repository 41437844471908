import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {httpUpdateCoAlarmRequest, HttpUpdateCoAlarmRequestOptions} from '@/features/co-alarm/services/coAlarm.http'
import {ErrorResponseData} from '@/types/commons.ts'
import {AxiosError} from 'axios'

type UseUpdateCoAlarmRequest = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdateCoAlarmRequest = (options?: UseUpdateCoAlarmRequest) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>, HttpUpdateCoAlarmRequestOptions>({
        ...options,
        mutationKey: [MUTATION_KEYS.EDIT_CO_ALARM_REQUEST],
        mutationFn: httpUpdateCoAlarmRequest,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.CO_ALARM_LISTINGS]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.CO_ALARM_REQUEST_DETAILS]})
            options?.onSuccess?.()
        },
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error)
    })
}
