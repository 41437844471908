import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledTermsOfServiceBody = styled(Flexbox)`
    ${({theme: {palette, spacing, typography}}) => css`
        margin-top: ${spacing * 12}px;
        & h2 {
            color: ${palette.neutral['900']};
            ${typography.displayXs};
            font-weight: 600;
        }

        & span {
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }

        & p {
            color: ${palette.neutral['600']};
            ${typography.textMd};
            & > a {
                color: ${palette.neutral['800']};
                text-decoration: underline;
                font-weight: 500;
                cursor: pointer;
            }
        }
    `}
`
