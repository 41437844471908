import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledServiceResources = styled(Flexbox)(
    () => css`
        width: 100%;
        flex-wrap: wrap;
    `
)

export const StyledServiceResourcesTitle = styled.div(
    ({theme: {typography, palette}}) => css`
        & h3 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledServiceResourceCard = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        cursor: pointer;

        & img {
            overflow: hidden;
            width: 234px;
            height: 155px;
            border-radius: 12px;
        }

        & p {
            ${typography.textSm};
            font-weight: 500;
            width: 234px;
            color: ${palette.neutral['900']};
        }
    `
)
