import React, {FC} from 'react'
import {DPCalendar, DPDay, DPOffsetValue, DPPropGetter, DPPropsGetterConfig} from '@rehookify/datepicker'
import {
    StyledCalendarWrapper,
    StyledDayButtonWrapper,
    StyledCalendarHeader,
    StyledDayButton,
    StyledDayGrid,
    StyledTextButton,
    StyledWeekDayContainer
} from '@components/commons/calendar/style.ts'
import {capitalize} from '@utilities/helpers.ts'
import {ChevronLeftIcon, ChevronRightIcon} from '@components/ui/icon'
import {View} from '@components/commons/calendar/Calendar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import dayjs from '@utilities/dayjs.ts'

type DateSelectorProps = {
    mode: 'single' | 'multiple' | 'range'
    calendar: DPCalendar
    addOffset?: (offsetValue: DPOffsetValue, config?: DPPropsGetterConfig) => DPPropGetter
    subtractOffset?: (offsetValue: DPOffsetValue, config?: DPPropsGetterConfig) => DPPropGetter
    dayButton: (day: DPDay) => DPPropGetter
    weekDays: string[]
    locale?: string
    setViewState: React.Dispatch<React.SetStateAction<View>>
}

export const DateSelector: FC<DateSelectorProps> = ({
    calendar,
    addOffset,
    subtractOffset,
    weekDays,
    dayButton,
    setViewState,
    mode
}) => {
    const {
        i18n: {language}
    } = useTranslation()
    const inMonthDays = calendar.days.filter(day => day.inCurrentMonth)

    return (
        <StyledCalendarWrapper>
            <StyledCalendarHeader>
                <Button
                    className={subtractOffset ? '' : 'invisible'}
                    shape="square"
                    variant="ghost"
                    {...subtractOffset?.({months: 1})}
                >
                    <ChevronLeftIcon size={20} />
                </Button>
                <h3>
                    <StyledTextButton onClick={() => setViewState('month')}>
                        {capitalize(dayjs(`1-${calendar.month}-${calendar.year}`).locale(language).format('MMMM'))}
                    </StyledTextButton>{' '}
                    <StyledTextButton onClick={() => setViewState?.('year')}>
                        {dayjs(calendar.year).format('YYYY')}
                    </StyledTextButton>
                </h3>
                <Button
                    className={addOffset ? '' : 'invisible'}
                    shape="square"
                    variant="ghost"
                    {...addOffset?.({months: 1})}
                >
                    <ChevronRightIcon size={20} />
                </Button>
            </StyledCalendarHeader>
            <StyledDayGrid>
                {weekDays.map((_, index) => (
                    <StyledWeekDayContainer key={index}>
                        {capitalize(
                            dayjs()
                                .locale(language)
                                .day((index + 7) % 7)
                                .format('dddd')
                                .slice(0, 2)
                        )}
                    </StyledWeekDayContainer>
                ))}
            </StyledDayGrid>
            <StyledDayGrid>
                {calendar.days.map((day, index) => {
                    const isFirstDayOfTheMonth = inMonthDays[0].$date.toISOString() == day.$date.toISOString()
                    const isLastDayOfTheMonth =
                        inMonthDays[inMonthDays.length - 1].$date.toISOString() == day.$date.toISOString()

                    return (
                        <Ariakit.PopoverDismiss
                            key={`${calendar.year}-${calendar.month}-${day.day}-${index}`}
                            render={
                                <Ariakit.DialogDismiss
                                    onClick={mode == 'single' ? undefined : event => event.preventDefault()}
                                    render={
                                        <StyledDayButtonWrapper
                                            className={`${isFirstDayOfTheMonth ? 'firstMonthDay' : ''} ${
                                                isLastDayOfTheMonth ? 'lastMonthDay' : ''
                                            }`}
                                            $inCurrentMonth={day.inCurrentMonth}
                                            $rangeStatus={day.range}
                                        >
                                            <StyledDayButton
                                                $isInCurrentMonth={day.inCurrentMonth}
                                                $rangeStatus={day.range}
                                                {...dayButton(day)}
                                                $isSelected={day.selected}
                                                disabled={day.disabled}
                                            >
                                                {day.$date.getDate()}
                                            </StyledDayButton>
                                        </StyledDayButtonWrapper>
                                    }
                                />
                            }
                        />
                    )
                })}
            </StyledDayGrid>
        </StyledCalendarWrapper>
    )
}
