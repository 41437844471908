import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledServiceWarning} from '@components/commons/service-suspended-banner/styles.ts'

export const ServiceSuspendedBanner: React.FC = () => {
    const {t} = useTranslation()

    return (
        <StyledServiceWarning direction={'column'} gap={1} align={'center'}>
            <h5>{t('coAlarm:service_suspended:title')}</h5>
            <p>{t('coAlarm:service_suspended:subtitle')}</p>
        </StyledServiceWarning>
    )
}

ServiceSuspendedBanner.displayName = 'ServiceSuspendedBanner'
