import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import i18next from 'i18next'

export const hp5 = () => [
    {
        id: 'HP5',
        options: [
            {
                value: 'HP5.1',
                label: i18next.t('chatbot:HP5:HP5.1_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP5',
                        source: 'User',
                        text: 'How long will it take to get connected to a photographer?'
                    })
                    return 'HP5.1'
                }
            },
            {
                value: 'HP5.2',
                label: i18next.t('chatbot:HP5:HP5.2_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP5',
                        source: 'User',
                        text: 'How do I communicate with the photographer?'
                    })
                    return 'HP5.2'
                }
            },
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP5',
                        source: 'User',
                        text: 'Other'
                    })
                    return 'start'
                }
            }
        ]
    },
    {
        id: 'HP5.1',
        message: i18next.t('chatbot:HP5:HP5.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP5.1',
                source: 'Chatbot',
                text: "Once you have signed the price quote, we will then connect you with a local photographer. It usually takes about 48 hours to get connected to a local photographer. Please note that this service is subject to the freelance photographer's availability."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP5.2',
        message: i18next.t('chatbot:HP5:HP5.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP5.2',
                source: 'Chatbot',
                text: 'Once you have signed the price quote, we will search and assign a photographer to your request. Once a photographer accepts, you will receive a new email with the subject line "Meet your photographer."'
            })
            return 'HP5.2.1'
        }
    },
    {
        id: 'HP5.2.1',
        message: i18next.t('chatbot:HP5:HP5.2.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP5.2.1',
                source: 'Chatbot',
                text: 'Your assigned photographer will be included on that email too. Simply respond to the email  and share your availabilities with your photographer. Scheduling the photoshoot will happen directly between you and the photographer.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
