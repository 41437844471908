import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Skeleton from '@/components/ui/skeleton/Skeleton'

export const CoAlarmListingsListItemLoader = () => {
    return (
        <Flexbox direction="column" gap={6} width="100%">
            <Flexbox gap={3} direction="column">
                <Skeleton width={64} height={44} />
                <Skeleton width={180} height={20} />
            </Flexbox>
            <div>
                <Skeleton width={200} height={20} />
            </div>
            <div>
                <Skeleton width="100%" height={20} />
            </div>
        </Flexbox>
    )
}
