import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledActions = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 8}px 0;

        & .actions-list {
            width: 100%;
            flex-wrap: wrap;
        }
    `
)

export const StyledActionsTitles = styled.div(
    ({theme: {typography, palette}}) => css`
        & h3 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & h4 {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `
)
