import {
    StyledProgressbarIndicator,
    StyledProgressbar
} from '@/features/experiences-host-video/components/progress-bar/style.ts'
import {FC} from 'react'

export const ProgressBar: FC<{percentage: number}> = ({percentage}) => (
    <StyledProgressbar>
        <StyledProgressbarIndicator style={{width: `${percentage}%`}} />
    </StyledProgressbar>
)
