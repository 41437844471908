import styled, {css} from 'styled-components'
import {spacing} from '@/theme'

import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledRequestsSearchWrapper = styled(Flexbox)`
    ${({theme: {spacing, mediaQueries}}) => css`
        align-items: end;
        justify-content: space-between;
        padding: ${spacing * 6}px 0 ${spacing * 8}px;
        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing * 8}px;
            padding: ${spacing * 6}px 0 0;
            align-items: baseline;
        }
    `}
`

export const StyledRequestsSearchInfo = styled.div`
    ${({theme: {typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: ${spacing}px;
        & h4 {
            ${typography.textXl};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textMd};
        }
    `}
`

export const StyledPhotoshootRequestsWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
        gap: ${spacing * 8}px;
        justify-items: center;
        ${mediaQueries.l} {
            grid-template-columns: 1fr 1fr;
        }
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            padding: ${spacing * 8}px 0;
        }
    `
)
