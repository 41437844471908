import styled, {css} from 'styled-components'

export const StyledDoNotVideo = styled.video(
    ({theme: {palette}}) => css`
        border-radius: 6px;
        width: 169px;
        aspect-ratio: 9/16;
        object-fit: cover;
        background-color: ${palette.neutral['900']};
        pointer-events: none;
        height: 100%;
    `
)
