import axios from '@/axiosInstance.ts'
import {HttpAcceptRequestedServiceParams, HttpGetPhotoServiceRequestsParams} from '@/features/service-requests/types.ts'

/**
 * Get photo services that user can request on his listings
 */
export const httpGetPhotoServiceRequests = ({urlParams, params}: HttpGetPhotoServiceRequestsParams) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/listings`, {
        params
    })
}

/**
 * Accept requested services
 */
export const httpAcceptRequestedService = (params: HttpAcceptRequestedServiceParams) => {
    return axios.post(
        `host-services/hosts/${params.hostCode}/${params.hostPassword}/listings/${params.externalId}/accept-quote`
    )
}
