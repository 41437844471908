import styled, {css} from 'styled-components'

export const StyledPreviewUploadedClips = styled.video(
    ({theme: {palette}}) => css`
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        aspect-ratio: 9 / 16;
        background-color: ${palette.neutral['black']};
    `
)
export const StyledPreviewUploadedClipsWrapper = styled.div(
    () => css`
        position: relative;
    `
)

export const StyledPreviewUploadedClipsRemoveButton = styled.button(
    ({theme: {palette, opacities}}) => css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 8px;
        right: 8px;
        height: 32px;
        width: 32px;
        border-radius: 6px;
        background-color: ${palette.neutral.white}${opacities['70']};
        color: ${palette.neutral.black};
        cursor: pointer;
    `
)

export const StyledPreviewUploadedClipsPlayButton = styled.button(
    ({theme: {palette, opacities}}) => css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 32px;
        width: 32px;
        border-radius: 16px;
        background-color: ${palette.neutral.white}${opacities['70']};
        color: ${palette.neutral.black};
        cursor: pointer;
    `
)

export const StyledPreviewUploadedClipsSpinner = styled.div(
    ({theme: {palette}}) => css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 32px;
        width: 32px;
        color: ${palette.neutral.black};
    `
)

export const StyledPreviewUploadedClipsLoaderWrapper = styled.div(
    ({theme: {palette, opacities}}) => css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        color: ${palette.neutral.white};
        background-color: ${palette.neutral.black}${opacities['30']};
        border-radius: 8px;
        aspect-ratio: 9 / 16;
    `
)
