import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import i18next from 'i18next'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {HttpGetServiceResponse} from '@/features/service/services/service.http.ts'
//import dayjs from "dayjs";

export const hp35 = ({
    quoteLink,
    service
}: {
    quoteLink: string
    service: HttpGetServiceResponse | null
}): ChatbotStep[] => {
    //const quoteStatus = service?.quote?.quote_status
    const taskStatus = service?.assignment?.task_status?.status
    const isCompleted = taskStatus == 'completed'
    /*const postProdStatus = service?.assignment?.task_status?.post_prod_status
    const shootBeyondSchedule = dayjs(service?.assignment?.to_shoot_at).isBefore(dayjs(new Date()))
    const isQuoteNotSigned = quoteStatus !== 'signed' && quoteStatus !== 'completed' && taskStatus != 'auto_cancelled'
    const showPreparingForPhotoshoot = quoteStatus !== 'completed' && taskStatus == 'scheduled'
    const isScheduled = taskStatus == 'scheduled'
    const isCompleted = taskStatus == 'completed'
    const isAssignmentAccepted = taskStatus == 'accepted'*/

    return [
        {
            id: 'HP35',
            options: [
                {
                    value: 'HP35.1',
                    label: i18next.t('chatbot:HP35:options:label_1'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35.1',
                            source: 'User',
                            text: 'Incorrect details'
                        })
                        return 'HP35.1.1'
                    }
                },
                {
                    value: 'HP35.2',
                    label: i18next.t('chatbot:HP35:options:label_2'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35.2',
                            source: 'User',
                            text: 'Requesting a photoshoot'
                        })
                        return 'HP35.2.1'
                    }
                },
                {
                    value: 'HP35.3',
                    label: i18next.t('chatbot:HP35:options:label_3'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35.3',
                            source: 'User',
                            text: 'I want to cancel my walkthrough'
                        })
                        return 'HP35.3.1'
                    }
                },
                ...(isCompleted
                    ? [
                          {
                              value: 'HP35.4',
                              label: i18next.t('chatbot:HP35:options:label_4'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP35.4',
                                      source: 'User',
                                      text: "I completed the walkthrough. What's next?"
                                  })
                                  return 'HP35.4.1'
                              }
                          }
                      ]
                    : [])
            ]
        },
        {
            id: 'HP35.1.1',
            message: i18next.t('chatbot:HP35:HP35.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.1.1',
                    source: 'Chatbot',
                    text: 'Please describe in detail your issue or question. Make sure to add as much information as possible for our team to be able to quickly resolve your query.'
                })
                return 'HP35_message'
            }
        },
        {
            id: 'HP35_message',
            user: true,
            trigger: ({value}) => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35_message',
                    source: 'User',
                    text: String(value)
                })
                return 'HP35_check'
            },
            validator: value => {
                if (value === '') {
                    return 'You should enter a message'
                } else if (String(value).length < 30) {
                    return 'The message should be longer'
                }
                return true
            }
        },
        {
            id: 'HP35_check',
            options: [
                {
                    value: 'confirm',
                    label: i18next.t('chatbot:HP11:HP11_check_options:label_1'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35_check',
                            source: 'User',
                            text: 'Send ticket'
                        })
                        return 'HP35_submit'
                    }
                },
                {
                    value: 'edit',
                    label: i18next.t('chatbot:HP11:HP11_check_options:label_2'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP35_check',
                            source: 'User',
                            text: 'Rewrite your message'
                        })
                        return 'HP35_edit'
                    }
                }
            ]
        },
        {
            id: 'HP35_edit',
            user: true,
            trigger: ({value}) => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35_edit',
                    source: 'User',
                    text: String(value)
                })
                return 'HP35_check'
            },
            validator: value => {
                if (value === '') {
                    return 'You should enter a message'
                } else if (String(value)?.length < 30) {
                    return 'The message should be longer'
                }
                return true
            }
        },
        {
            id: 'HP35_submit',
            component: <TicketSubmit reason="open_ticket" link={quoteLink} />,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP4.1.2',
                    source: 'Chatbot',
                    text: '[Ticket submitted]'
                })
                return 'HP4.1.3'
            },
            asMessage: true,
            replace: true
        },
        {
            id: 'HP35.2.1',
            message: i18next.t('chatbot:HP35:HP35.2_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.2.1',
                    source: 'Chatbot',
                    text: "If your listing passes the walkthrough successfully, we'll pair you up with a Luxe photographer to schedule a photoshoot for your listing, if required."
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP35.3.1',
            message: i18next.t('chatbot:HP35:HP35.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.3.1',
                    source: 'Chatbot',
                    text: 'Did you want to reschedule to a new date?'
                })
                return 'HP35.3.1_options'
            }
        },
        {
            id: 'HP35.3.1_options',
            options: [
                {
                    value: 'yes',
                    label: i18next.t('commons:yes'),
                    trigger: () => 'HP33.1.1'
                },
                {
                    value: 'no',
                    label: i18next.t('commons:no'),
                    trigger: () => 'HP35.3.1.1'
                }
            ]
        },
        {
            id: 'HP35.3.1.1',
            message: i18next.t('chatbot:HP35:HP35.3.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.3.1.1',
                    source: 'Chatbot',
                    text: "Declining your walkthrough will cancel your eligibility to join the Luxe category. When you're ready, you can nominate your listing again. Please note that many factors are used to determine whether a listing qualifies for inclusion and that the eligibility criteria are subject to change at any time without notice."
                })
                return 'HP35.3.1.2'
            }
        },
        {
            id: 'HP35.3.1.2',
            message: i18next.t('chatbot:HP35:HP35.3.1.2_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.3.1.2',
                    source: 'Chatbot',
                    text: "If you want to cancel your walkthrough request, please click on the 'Cancel' link you can find at the bottom left of your walkthrough page."
                })
                return 'oq_message'
            }
        },
        {
            id: 'HP35.4.1',
            message: i18next.t('chatbot:HP35:HP35.4_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP35.4.1',
                    source: 'Chatbot',
                    text: 'Our team is now reviewing the report. This typically takes 5 business days. Once your listing has passed successfully, your photos and content will be reviewed to ensure alignment with our Luxe guidelines and to better showcase your listing before being added to the Luxe category.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        }
    ]
}
