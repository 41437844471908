import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {XCloseIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from 'react-i18next'

export const ExperienceVideoCanceledStatusDescription = () => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <XCloseIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:canceled_title')}
            </StatusDescriptionTitle>
        </StatusDescriptionWrapper>
    )
}

ExperienceVideoCanceledStatusDescription.displayName = 'ExperienceVideoCanceledStatusDescription'
