import Container from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckIcon} from '@/components/ui/icon'
import {IconContainer} from '@/components/ui/icon-container/IconContainer'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {StyledCoAlarmInstallationCompletedTitle} from '@/features/co-alarm/components/co-alarm-installation-completed-box/style'

export const CoAlarmInstallationCompletedBox = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <Container fullHeight={false}>
            <Flexbox direction="column" gap={6} align="center">
                <Flexbox direction="column" gap={4} align="center">
                    <IconContainer size="lg">
                        <CheckIcon color={palette.success[600]} />
                    </IconContainer>

                    <StyledCoAlarmInstallationCompletedTitle>
                        {t('coAlarm:installation_cards:completed_title')}
                    </StyledCoAlarmInstallationCompletedTitle>
                </Flexbox>
            </Flexbox>
        </Container>
    )
}
