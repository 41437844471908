import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpSendReminderParam = z.object({
    code: z.string(),
    password: z.string(),
    media: z.coerce.number().int()
})
export type HttpSendReminderParam = z.infer<typeof HttpSendReminderParam>

export const httpSendReminder = (params: HttpSendReminderParam) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/sendReminder`
    return axios.get(url)
}
