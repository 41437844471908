import styled, {css} from 'styled-components'
import {ModalBody} from '@components/ui/modal-atoms/ModalAtoms.tsx'

export const StyledModalTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledModalBody = styled(ModalBody)(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 2}px;
        padding-top: 0;
    `
)
