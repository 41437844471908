import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useTranslation} from 'react-i18next'
import {AlertTriangleIcon} from 'src/components/ui/icon'

export const DashboardURLParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string()
})
export type DashboardURLParams = z.infer<typeof DashboardURLParams>

const DashboardRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(DashboardURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

export default DashboardRoute
