import {useDropzone} from 'react-dropzone'
import {useTranslation} from '@/translations/i18n.tsx'
import {AlertTriangleIcon, Film01Icon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {Media} from '@/features/experiences-host-video/types.ts'
import {
    httpConfirmUploadClips,
    httpGetPresignBulkClips,
    httpUploadFile
} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {z} from 'zod'
import {UploadedClipsView} from '@/features/experiences-host-video/components/uploaded-clips-view/UploadedClipsView.tsx'
import {StyledUploadClipWarning} from '@/features/experiences-host-video/components/upload-clips/style.ts'
import {
    StyledUploadClipsDropzone,
    StyledUploadClipsTrigger
} from '@/features/experiences-host-video/components/upload-clips-dropzone/style.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {raise} from '@utilities/helpers.ts'
import {useShowUploadedVideos} from '@/features/experiences-host-video/services/useShowUploadedVideos.ts'
import {useEffect} from 'react'

export const UploadClipsDropzone = () => {
    const {t} = useTranslation()
    const addUploadingFile = useClipsUpload(store => store.addUploadingFile)
    const uploadingFiles = useClipsUpload(store => store.uploadingFiles)
    const uploadedFiles = useClipsUpload(store => store.uploadedFiles)
    const errorFiles = useClipsUpload(store => store.errorFiles)
    const setUploadingFileProgressValue = useClipsUpload(store => store.setUploadingFileProgressValue)
    const removeUploadingFiles = useClipsUpload(store => store.removeUploadingFiles)
    const addUploadedFile = useClipsUpload(store => store.addUploadedFile)
    const addErrorFile = useClipsUpload(store => store.addErrorFiles)
    const videosetId = useClipsUpload(store => store.videosetId)
    const setUploadedFiles = useClipsUpload(store => store.setUploadedFiles)
    const routeParams = useExperiencesHostVideoRouteParams()
    const showUploadedVideo = useShowUploadedVideos({
        urlParams: {...routeParams, videoSetId: videosetId},
        queryOptions: {enabled: !!videosetId}
    })

    useEffect(() => {
        if (showUploadedVideo.data && showUploadedVideo.data.length > 0) {
            setUploadedFiles(showUploadedVideo.data.map(value => ({id: value.id, url: value.url})))
        }
    }, [showUploadedVideo.data])

    const uploadFile = async (url: string, media: Media, file: File, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url,
                payload: file,
                onUploadProgress: progressEvent =>
                    setUploadingFileProgressValue(
                        file.name,
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadClips({
                ...routeParams,
                videoSetId: videosetId || raise('videosetId is undefined'),
                mediaId: media.id
            })
            addUploadedFile({id: media.id, url: response.data.url})
            removeUploadingFiles([file])
        } catch (error) {
            removeUploadingFiles([file])
            addErrorFile([{file: file}])
        } finally {
            uploadNextGroup()
        }
    }
    const uploadFiles = async (acceptedFiles: File[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 5
        const othersGroupSize = 3
        let queue = [acceptedFiles.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < acceptedFiles.length; index += othersGroupSize) {
            queue = queue.concat([acceptedFiles.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = async (files: File[]) => {
            try {
                const response = await httpGetPresignBulkClips({
                    urlParams: {
                        ...routeParams,
                        videoSetId: videosetId ?? raise('videosetId is undefined')
                    },
                    payload: {medias: files.map(file => file.name)}
                })
                const parsedResponseData = z
                    .array(z.object({media: Media, presigned_url: z.string().url()}))
                    .parse(response.data)
                for (const [index, item] of Object.entries(parsedResponseData)) {
                    void uploadFile(item.presigned_url, item.media, files[Number(index)], () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
                }
            } catch (error) {
                removeUploadingFiles(acceptedFiles)
                addErrorFile(files.map(file => ({file: file})))
            }
        }

        await uploadFilesGroup(filesQueue[0])
    }

    const dropzoneState = useDropzone({
        disabled: false,
        accept: {
            'video/*': []
        },
        multiple: true,
        onDropAccepted: acceptedFiles => {
            acceptedFiles.map(file => addUploadingFile(file))
            void uploadFiles([...acceptedFiles])
        },
        onDropRejected: rejectedFiles => {
            console.error('Rejected files: ', rejectedFiles)
        }
    })
    if (uploadingFiles.length > 0 || uploadedFiles.length > 0 || errorFiles.length > 0) {
        return <UploadedClipsView dropzoneState={dropzoneState} />
    }

    return (
        <Flexbox direction={'column'} gap={9} align={'center'} width={'100%'}>
            <StyledUploadClipWarning>
                <AlertTriangleIcon size={16} />
                {t('experiences_host_video:upload_clip:warning')}
            </StyledUploadClipWarning>
            <StyledUploadClipsDropzone state={dropzoneState}>
                <StyledUploadClipsTrigger direction="column" gap={1} align="center" justify="center" width={'100%'}>
                    <Film01Icon size={32} />
                    <h3>{t('experiences_host_video:upload_clip:add_your_clips')}</h3>
                    <p>{t('experiences_host_video:upload_clip:press_here')}</p>
                </StyledUploadClipsTrigger>
            </StyledUploadClipsDropzone>
        </Flexbox>
    )
}
