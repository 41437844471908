import styled, {css} from 'styled-components'

export const StyledTrailerExampleVideoTitle = styled.h1(
    ({theme: {typography, palette}}) => css`
        ${typography.textXlFixed};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)
export const StyledTrailerExampleVideoDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['900']};
    `
)
export const StyledTrailerExampleVideoWrapper = styled.div<{$isFullscreen?: boolean}>(
    ({$isFullscreen, theme: {zIndex}}) => css`
        position: relative;
        width: 100%;
        height: 530px;
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        animation-delay: 1s;
        ${$isFullscreen &&
        css`
            z-index: ${zIndex.modal};
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 0;
        `}
    `
)

export const StyledTrailerExampleVideoFullScreenGhost = styled.div(
    () => css`
        position: relative;
        width: 100%;
        height: 530px;
    `
)

export const StyledTrailerExampleVideo = styled.video(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${palette.neutral['black']};
    `
)

export const StyledMuteVideoToggle = styled.button(
    ({theme: {palette}}) => css`
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: ${palette.neutral['black']}B3;
        color: ${palette.neutral['white']};
        border-radius: 18px;
    `
)

export const StyledPlayVideoButton = styled.button(
    ({theme: {palette}}) => css`
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        height: 52px;
        width: 52px;
        position: absolute;
        bottom: 22px;
        left: 22px;
        background-color: ${palette.neutral['black']}B3;
        color: ${palette.neutral['white']};
        border-radius: 26px;
    `
)
export const StyledLoadingVideoWrapper = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        height: 100%;
        width: 100%;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: ${palette.neutral['black']}2D;
        color: ${palette.neutral['white']};
        border-radius: 8px;
    `
)
