import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget.tsx'
import {AirbnbIcon} from 'src/components/ui/icon'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Navigate} from 'react-router-dom'
import {routes} from '@utilities/constants'

export const SsoLogin = () => {
    const {palette} = useTheme()
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)

    if (accessToken) {
        return <Navigate to={routes.PHOTOSHOOT_REQUEST.path} replace />
    }

    return (
        <Container>
            <Helmet title={String(t('sso_login:page_title_meta'))} />

            <AirbnbSSOWidget
                serviceIcon={<AirbnbIcon size={64} fill={palette.primary['600']} />}
                title={t('sso_login:title')}
                subtitle={t('sso_login:subtitle')}
                disclaimer={<Trans i18nKey="sso_login:disclaimer" />}
            />
        </Container>
    )
}

SsoLogin.displayName = 'SsoLogin'
