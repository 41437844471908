import {ChatbotStep} from '@/features/chatbot/types.ts'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import TicketSubmit from '@/features/chatbot/components/ticket-submit/TicketSubmit.tsx'
import i18next from 'i18next'

export const hp9 = ({quoteLink}: {quoteLink: string}): ChatbotStep[] => [
    {
        // Steps if the user found the listing
        id: 'HP9.2',
        message: i18next.t('chatbot:HP9:HP9.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9.2',
                source: 'Chatbot',
                text: 'Do you confirm that the images have been wrongly uploaded to this listing page?'
            })
            return 'HP9.2_options'
        }
    },
    {
        id: 'HP9.2_options',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9.2_options',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP9.1.1'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9.2_options',
                        source: 'User',
                        text: 'No'
                    })
                    return 'HP9.5'
                }
            }
        ]
    },
    /* {
        id: 'HP9.2_send',
        component: <TicketSubmit reason="wrong_listing" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9.2_send',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP9_sent'
        },
        asMessage: true,
        replace: true
    }, // END Steps if the user found the listing*/

    {
        // Steps if the user did not find the listing
        id: 'HP9.1.1',
        message: i18next.t('chatbot:HP9:HP9.1.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9.1.1',
                source: 'Chatbot',
                text: 'Please type the listing title where images have been incorrectly uploaded:'
            })
            return 'HP9_not_found'
        }
    },
    {
        id: 'HP9_not_found',
        user: true,
        trigger: ({value}) => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9_not_found',
                source: 'User',
                text: String(value)
            })
            return 'HP9_not_found_confirm'
        },
        validator: value => {
            if (value === '') {
                return 'You should enter a message'
            } else if (String(value).length < 3) {
                return 'The title should be longer'
            }
            return true
        }
    },
    {
        id: 'HP9_not_found_confirm',
        message: i18next.t('chatbot:HP9:HP9_not_found_confirm_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9_not_found_confirm',
                source: 'Chatbot',
                text: 'Do you confirm that the images have been wrongly uploaded to this listing page?'
            })
            return 'HP9_not_found_options'
        }
    },
    {
        id: 'HP9_not_found_options',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9_not_found_options',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP9_not_found_send'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9_not_found_options',
                        source: 'User',
                        text: 'No'
                    })
                    return 'HP9.5'
                }
            }
        ]
    },
    {
        id: 'HP9_not_found_send',
        component: <TicketSubmit reason="wrong_listing" link={quoteLink} />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9_not_found_send',
                source: 'Chatbot',
                text: '[Ticket submitted]'
            })
            return 'HP9_sent'
        },
        asMessage: true,
        replace: true
    }, // END Steps if the user did not find the listing

    {
        // Regular reply if the user proceeds
        id: 'HP9_sent',
        message: i18next.t('chatbot:HP9:HP9_sent_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP9_sent',
                source: 'Chatbot',
                text: 'Thank you for the information provided. A ticket has been opened with our Photography team. We will upload the images to the correct listing page in the next 48 hours.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },

    {
        // Regular reply if the user does not proceed
        id: 'HP9.5',
        options: [
            /*{
                value: 'restart',
                label: i18next.t('chatbot:HP9:HP9.5_options:label_1'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9.5',
                        source: 'User',
                        text: 'I want to select a different Listing title'
                    })
                    return 'service_select_message'
                }
            },*/
            {
                value: 'start',
                label: i18next.t('chatbot:HP9:HP9.5_options:label_2'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9.5',
                        source: 'User',
                        text: 'I have another question before proceeding'
                    })
                    return 'start'
                }
            }, // Final trigger of the flow's branch
            {
                value: 'close',
                label: i18next.t('chatbot:HP9:HP9.5_options:label_3'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP9.5',
                        source: 'User',
                        text: "I don't have any further questions, I am ready to close the chat."
                    })
                    return 's_start'
                }
            }
        ]
    }
]
