import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetInProgressServices,
    HttpInProgressServicesParams,
    HttpInProgressServicesResponse
} from '@/features/in-progress-services/services/inProgress.http.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useInProgressServices = (params: Omit<HttpInProgressServicesParams, 'per_page' | 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.IN_PROGRESS_SERVICES, params.orderBy, params.orderDirection, params.search],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetInProgressServices({
                    ...params,
                    page: pageParam,
                    per_page: 50
                }),
                responseShape: HttpInProgressServicesResponse,
                onZodError: captureException
            }),
        initialPageParam: 1,
        getNextPageParam: lastPage => (lastPage.current_page > lastPage.last_page ? lastPage.current_page + 1 : null)
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        //to avoid results like [undefined]
        remappedData: query.data?.pages[0]?.data ? query.data.pages.flatMap(page => page.data) : []
    }
}
