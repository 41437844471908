import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalBody, ModalFooter, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    StyledHeader,
    StyledParagraph
} from '@/features/experiences-host-video/components/record-again-confirmation-modal/style.ts'
import {useIsMutating} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
interface RecordAgainConfirmationModalProps {
    onClose: () => void
    onContinue: () => void
}
export const RecordAgainConfirmationModal: FC<RecordAgainConfirmationModalProps> = ({onClose, onContinue}) => {
    const {t} = useTranslation()
    const isMutatingDelete = !!useIsMutating({mutationKey: [MUTATION_KEYS.DELETE_VOICEOVER]})

    return (
        <Modal width={360}>
            <StyledHeader>
                <ModalXCloseButton onClick={onClose} />
                {t('experiences_host_video:record_again')}
            </StyledHeader>
            <ModalBody>
                <StyledParagraph>{t('experiences_host_video:record_again_modal:description')}</StyledParagraph>
            </ModalBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" onClick={onClose} disabled={isMutatingDelete}>
                    {t('commons:cancel')}
                </Button>
                <Button variant="primary" onClick={onContinue} disabled={isMutatingDelete}>
                    {t('experiences_host_video:record_again_modal:cta')}
                    {isMutatingDelete && <Spinner />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
