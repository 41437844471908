import uploadClipsImage from '@assets/images/experiences-host-video/uploadclips.png'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle,
    IntroImage,
    IntroStepTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import toast from 'react-hot-toast'
import {useUpdateFriendStep} from '@/features/experiences-host-video/services/useUpdateFriendStep.ts'

export const UploadClipsIntroFriend = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const backUpdateStepMutation = useUpdateFriendStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateFriendStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    return (
        <>
            <ExperiencesHostVideoBody>
                <IntroImage src={uploadClipsImage} alt="step image" />
                <Flexbox direction="column" gap={4}>
                    <IntroStepTitle>{t('experiences_host_video:step_x', {stepNumber: 3})}</IntroStepTitle>
                    <ExperiencesHostVideoTitle>
                        {t('experiences_host_video:upload_video_clips')}
                    </ExperiencesHostVideoTitle>
                    <ExperiencesHostVideoParagraph>
                        {t('experiences_host_video:upload_clip_intro:description')}
                    </ExperiencesHostVideoParagraph>
                </Flexbox>
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'ready-to-film', step: 'schedule'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'upload',
                                    step: 'video-upload'
                                })
                            }
                            disabled={continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
