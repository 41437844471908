import {CoAlarmRequest} from '@/features/co-alarm/types'
import {ServiceProposal} from '@/features/promotional-services/types'
import {Host} from '@/features/service/types'
import {User} from '@/features/sso/types'

export const getNotificationPreferences = ({
    servicePreferences,
    coAlarmPreferences,
    user,
    currentHost
}: {
    servicePreferences?: ServiceProposal['notification_preferences']
    coAlarmPreferences?: CoAlarmRequest['notification_preferences']
    user?: User
    currentHost?: Host
}) => {
    if (coAlarmPreferences) {
        return {
            phone: coAlarmPreferences.phone_number,
            email: coAlarmPreferences.email
        }
    } else if (servicePreferences) {
        return {
            phone: servicePreferences.phone_number,
            email: servicePreferences.email
        }
    } else if (user) {
        return {
            phone: user.phone,
            email: user.email
        }
    } else {
        return {
            phone: currentHost?.phone,
            email: currentHost?.email
        }
    }
}

export const getNotifySettingsId = ({
    service,
    coAlarmRequest
}: {
    service: ServiceProposal | null
    coAlarmRequest: CoAlarmRequest | null
}) => {
    if (service && service.notification_preferences) {
        return service.notification_preferences.id
    } else if (coAlarmRequest && coAlarmRequest.notification_preferences) {
        return coAlarmRequest.notification_preferences.id
    }

    return null
}
