import {Entity} from '@/features/service/types.ts'
import {
    HttpGetAmenitiesAutocompleteResponse,
    HttpGetMatterportResponse,
    HttpGetRoomsAutocompleteResponse,
    httpGetAmenitiesAutocomplete,
    httpGetMatterportInfo,
    httpGetRoomsAutocomplete
} from './services/matterport.http'
import {
    Amenity,
    BedroomMap,
    ConfirmedImage,
    ExtendedAccessibilityImage,
    HostApprovalImagesFormatted,
    ReviewList,
    Room
} from './types'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {HttpHostApprovalRequestDetailResponse} from '@/features/host-approval/types.ts'

/**
 * Api utilities
 */
export const getMatterportData = async (entity: Entity) => {
    const rooms = await parseAxiosPromise({
        axiosPromise: httpGetRoomsAutocomplete(),
        onZodError: captureException,
        responseShape: HttpGetRoomsAutocompleteResponse
    })
    const amenities = await parseAxiosPromise({
        axiosPromise: httpGetAmenitiesAutocomplete(),
        onZodError: captureException,
        responseShape: HttpGetAmenitiesAutocompleteResponse
    })
    const infos = await parseAxiosPromise({
        axiosPromise: httpGetMatterportInfo({entity, params: {response_type: 'extended'}}),
        onZodError: captureException,
        responseShape: HttpGetMatterportResponse
    })

    if (!rooms.length || !amenities.length || !infos.accessibility_images.length) {
        throw new Error('Failed to get matterport data')
    }

    return {rooms, amenities, infos}
}

export const imagesObjArraysFlatter = (
    toReviewList: ReviewList | Record<string, HostApprovalImagesFormatted>
): ConfirmedImage[] => {
    return Object.keys(toReviewList).map(imageId => ({
        id: imageId,
        action: toReviewList[imageId].accepted ? 'accepted' : 'removed',
        old: toReviewList[imageId].old
    }))
}

/**
 * Store utilities
 */
export const formatAccessibilityInfos = (
    rooms: HttpGetRoomsAutocompleteResponse,
    amenities: HttpGetAmenitiesAutocompleteResponse,
    infos: HttpGetMatterportResponse
): {
    roomsList: Room[]
    accessibilityImages: ExtendedAccessibilityImage[]
} => {
    // get accessibility image data from api and extend the response
    const accessibilityImages: ExtendedAccessibilityImage[] = infos.accessibility_images.map(img => ({
        ...img,
        accepted: false,
        removed: false,
        toCheck: true
    }))

    // create a map of bedrooms as a safe base to get room_id
    const bedrooms: BedroomMap = infos.entity_bedroom.reduce(
        (acc, val) => ({
            ...acc,
            [val.id]: val.room_id
        }),
        {}
    )

    // create a map of amenities by forcing room_id in the amenities to cover the legacy case with amenities without room_id
    const amenitiesImagesMap: Record<string, ExtendedAccessibilityImage> = accessibilityImages.reduce(
        (acc, img) => ({
            ...acc,
            [img.amenity_id]: {
                ...img,
                room_id: bedrooms[img.entity_bedroom_id],
                amenity_id: img.amenity_id,
                accepted: false,
                removed: false,
                toCheck: true
            }
        }),
        {}
    )

    // create a new rooms list by merging accessibility images, amenities and rooms in a more logic way for the ui
    const roomsList: Room[] = rooms.map(room => ({
        ...room,
        amenities: Object.keys(amenitiesImagesMap)
            .filter(amenityId => amenitiesImagesMap[amenityId].room_id === room.id)
            .map(
                amenityId =>
                    ({
                        ...amenities.find(amenity => amenity.id.toString() === amenityId),
                        accessibility_images: accessibilityImages.filter(img => amenityId === img.amenity_id.toString())
                    }) as Amenity
            )
    }))

    return {
        roomsList,
        accessibilityImages
    }
}

/**
 * Store utilities format and grouping images for host approval flow
 */
export const formatHostApprovalInfos = (
    rooms: HttpGetRoomsAutocompleteResponse,
    amenities: HttpGetAmenitiesAutocompleteResponse,
    infos: HttpHostApprovalRequestDetailResponse
): {
    roomsList: Room[]
    accessibilityImages: HostApprovalImagesFormatted[]
} => {
    // get accessibility image data from api and extend the response
    const accessibilityImages = infos.accessibility_images.map(img => ({
        ...img,
        accepted: false,
        removed: false,
        toCheck: true
    }))

    // create a map of bedrooms as a safe base to get room_id
    const bedrooms: BedroomMap = rooms.reduce(
        (acc, val) => ({
            ...acc,
            [val.id]: val.id
        }),
        {}
    )

    // create a map of amenities by forcing room_id in the amenities to cover the legacy case with amenities without room_id
    const amenitiesImagesMap: Record<string, HostApprovalImagesFormatted> = accessibilityImages.reduce(
        (acc, img) => ({
            ...acc,
            [img.amenity_id]: {
                ...img,
                room_id: bedrooms[img.room_id],
                amenity_id: img.amenity_id,
                accepted: false,
                removed: false,
                toCheck: true
            }
        }),
        {}
    )

    // create a new rooms list by merging accessibility images, amenities and rooms in a more logic way for the ui
    const roomsList: Room[] = rooms.map(room => ({
        ...room,
        amenities: Object.keys(amenitiesImagesMap)
            .filter(amenityId => amenitiesImagesMap[amenityId].room_id === room.id)
            .map(
                amenityId =>
                    ({
                        ...amenities.find(amenity => amenity.id.toString() === amenityId),
                        accessibility_images: accessibilityImages.filter(img => amenityId === img.amenity_id.toString())
                    }) as Amenity
            )
    }))

    return {
        roomsList,
        accessibilityImages
    }
}
