import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpSelectQuestionAnswer} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'

interface UseUpdateStepArgs {
    params: {
        hostCode: number
        hostPassword: string
        taskId: number
        experiencesHostVideoId: number
    }
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                questionAnswerId: number
                notes?: string[]
            }
        >,
        'mutationFn'
    >
}
export const useSelectQuestionAnswer = ({params, mutationOptions}: UseUpdateStepArgs) => {
    return useMutation<unknown, unknown, {questionAnswerId: number; notes?: string[]}>({
        mutationFn: ({questionAnswerId, notes}) =>
            httpSelectQuestionAnswer({
                urlParams: {...params},
                payload: {questionAnswerId, notes}
            }),

        ...mutationOptions
    })
}
