import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const MapWrapper = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        margin-top: ${spacing * 2}px;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
        overflow: hidden;
        p {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['700']};
            padding: ${spacing * 2}px;
            border-bottom: 1px solid ${palette.neutral['300']};
        }
    `}
`
