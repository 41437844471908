import {FC} from 'react'
import {DefaultNamespace} from 'i18next'
import {
    ServiceSingleStep,
    ServiceStepsList,
    ServiceStepsWrapper
} from '@/features/landing/components/service-steps/style.ts'

type ServiceStep = {
    title: string | DefaultNamespace
    subtitle: string | DefaultNamespace
    image: string
}
interface ServiceStepsProps {
    title?: string | DefaultNamespace
    subtitle?: string | DefaultNamespace
    steps: ServiceStep[]
}

export const ServiceSteps: FC<ServiceStepsProps> = ({title, subtitle, steps}) => {
    return (
        <ServiceStepsWrapper>
            {title && (
                <h3>
                    {title}
                    {subtitle && <span>{subtitle}</span>}
                </h3>
            )}
            <ServiceStepsList>
                {steps.map(({title, subtitle, image}, index) => (
                    <ServiceSingleStep key={index}>
                        <span>{index + 1}</span>
                        <div>
                            <h5>{title}</h5>
                            <p>
                                {subtitle} <img src={image} alt={title} />
                            </p>
                        </div>
                        <img src={image} alt={title} />
                    </ServiceSingleStep>
                ))}
            </ServiceStepsList>
        </ServiceStepsWrapper>
    )
}

ServiceSteps.displayName = 'ServiceSteps'
