import styled, {css} from 'styled-components'

export const StyledStatsWidgetWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, typography, palette}}) => css`
        max-width: 350px;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            gap: ${spacing * 2}px;
            max-width: unset;
            min-height: unset;
        }

        & > h4 {
            display: flex;
            flex-direction: column;
            ${typography.textMd};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }

        & > small {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `}
`
