import {z} from 'zod'

export const FeedbackValidationSchema = z.object({
    notes: z.string().min(1),
    recommendation: z.coerce.number().min(0).max(10),
    delivery_time_satisfaction: z.coerce.number().min(1).max(5),
    image_quality_satisfaction: z.coerce.number().min(1).max(5),
    find_page_ease: z.coerce.number().min(1).max(5),
    scheduling_ease: z.coerce.number().min(1).max(5)
})
export type FeedbackValidationSchema = z.infer<typeof FeedbackValidationSchema>
export type RadioInput = Omit<FeedbackValidationSchema, 'notes'>
