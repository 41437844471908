import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {ExperiencesHostVideoReadyShootCard} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {StyledCaptureMoments, StyledSectionWrapper} from './style'
import {FC, useState} from 'react'
import {Question} from '../../types'
import {Users03Icon} from '@/components/ui/icon'
import {useTheme} from 'styled-components'
import {ShortClipModal} from '../short-clip-modal/ShortClipModal'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'

import preparationExampleClip from '@/assets/videos/experiences-host-video/preparation_example.mp4'
import locationExampleClip from '@/assets/videos/experiences-host-video/location_example.mp4'
import greetingExampleClip from '@/assets/videos/experiences-host-video/greeting_example.mp4'
import takeawayExampleClip from '@/assets/videos/experiences-host-video/takeaway_example.mp4'

interface GetReadyToShootContentProps {
    answers: Question[]
    haveCheckbox?: boolean
}
export const Checklist: FC<GetReadyToShootContentProps> = ({answers, haveCheckbox}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const [selectedExampleLink, setSelectedExampleLink] = useState<string | null>(null)
    const requirePreppingSpaceAnswer = answers.find(
        answer => answer.question_answer.question.key_name == 'require_prepping_space'
    )
    const takingAnythingHomeAnswer = answers.find(
        answer => answer.question_answer.question.key_name == 'taking_anything_home'
    )
    const youUniqueAnswer = answers.find(answer => answer.question_answer.question.key_name == 'you_unique')
    const experienceUniqueAnswer = answers.find(
        answer => answer.question_answer.question.key_name == 'experience_unique'
    )
    const highlightsHomeAnswer = answers.find(answer => answer.question_answer.question.key_name == 'highlights')

    // TODO add correct  Example Link
    return (
        <Flexbox width="100%" gap={8} direction="column">
            <StyledSectionWrapper direction="column" gap={4} width="100%">
                <h5>{t('experiences_host_video:get_ready_shoot:before_guests_arrive')}</h5>

                <ExperiencesHostVideoReadyShootCard
                    checkBoxId={haveCheckbox ? 'location' : undefined}
                    title={t('commons:location')}
                    exampleAction={() => {
                        setSlidingModalState('open')
                        setSelectedExampleLink(locationExampleClip)
                    }}
                >
                    <ul>
                        <li>{t('experiences_host_video:get_ready_shoot:location_text_1')}</li>
                        <li>{t('experiences_host_video:get_ready_shoot:location_text_2')}</li>
                    </ul>
                </ExperiencesHostVideoReadyShootCard>

                {requirePreppingSpaceAnswer?.question_answer.answer.key_name == 'yes' && (
                    <ExperiencesHostVideoReadyShootCard
                        checkBoxId={haveCheckbox ? 'preparation' : undefined}
                        title={t('experiences_host_video:get_ready_shoot:preparation')}
                        exampleAction={() => {
                            setSlidingModalState('open')
                            setSelectedExampleLink(preparationExampleClip)
                        }}
                    >
                        <em>“{requirePreppingSpaceAnswer.notes[0].notes}”</em>
                    </ExperiencesHostVideoReadyShootCard>
                )}
            </StyledSectionWrapper>

            <StyledSectionWrapper direction="column" gap={4} width="100%">
                <h5>{t('experiences_host_video:get_ready_shoot:when_guests_arrive:title')}</h5>

                <ExperiencesHostVideoReadyShootCard
                    title={t('experiences_host_video:get_ready_shoot:when_guests_arrive:description')}
                    checkBoxId={haveCheckbox ? 'guests_arriving' : undefined}
                    exampleAction={() => {
                        setSlidingModalState('open')
                        setSelectedExampleLink(greetingExampleClip)
                    }}
                >
                    <ul>
                        <li>{t('experiences_host_video:get_ready_shoot:when_guests_arrive:text_1')}</li>
                    </ul>
                </ExperiencesHostVideoReadyShootCard>

                <StyledCaptureMoments direction="column" gap={4} width="100%">
                    <Flexbox width="100%" gap={3} direction="column">
                        <Users03Icon fill={palette.warning[600]} />
                        <h5>{t('experiences_host_video:get_ready_shoot:capture_moments')}</h5>

                        <h4>{t('experiences_host_video:get_ready_shoot:reason_guests_book')}</h4>
                        <em>“{experienceUniqueAnswer?.notes[0].notes}”</em>

                        <h4>{t('experiences_host_video:get_ready_shoot:great_host')}</h4>
                        <em>“{youUniqueAnswer?.notes[0].notes}”</em>
                    </Flexbox>

                    <p>{t('experiences_host_video:get_ready_shoot:activities')}</p>

                    <Flexbox width="100%" gap={2.5} direction="column">
                        {highlightsHomeAnswer?.notes.map(note => (
                            <ExperiencesHostVideoReadyShootCard
                                checkBoxId={haveCheckbox ? note.id.toString() : undefined}
                                title={note.notes}
                                key={note.id}
                            />
                        ))}
                    </Flexbox>
                </StyledCaptureMoments>
            </StyledSectionWrapper>

            {takingAnythingHomeAnswer?.question_answer.answer.key_name == 'yes' && (
                <StyledSectionWrapper direction="column" gap={4} width="100%">
                    <h5>{t('experiences_host_video:get_ready_shoot:guests_take_something_home')}</h5>

                    <ExperiencesHostVideoReadyShootCard
                        checkBoxId={haveCheckbox ? 'memento' : undefined}
                        title={t('experiences_host_video:get_ready_shoot:memento')}
                        exampleAction={() => {
                            setSlidingModalState('open')
                            setSelectedExampleLink(takeawayExampleClip)
                        }}
                    >
                        <ul>
                            <li>{t('experiences_host_video:get_ready_shoot:memento_text_1')}</li>
                            <li>{t('experiences_host_video:get_ready_shoot:memento_text_2')}</li>
                        </ul>
                    </ExperiencesHostVideoReadyShootCard>
                </StyledSectionWrapper>
            )}

            {slidingModalState != 'closed' && selectedExampleLink && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={selectedExampleLink}
                />
            )}
        </Flexbox>
    )
}
