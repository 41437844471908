import {
    ListingThumbnailImage,
    ListingThumbnailName,
    ListingThumbnailParagraph
} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'
import {CoAlarmListing} from '@/features/co-alarm/types'
import {StyledCoAlarmListingCard} from '@/features/co-alarm/components/co-alarm-listing-card/style'

export const CoAlarmListingCard = ({listing}: {listing: CoAlarmListing}) => {
    return (
        <StyledCoAlarmListingCard gap={3}>
            <ListingThumbnailImage src={listing.image_url ?? null} alt={listing.name} />
            <div>
                <ListingThumbnailName>
                    <a href={`https://www.airbnb.com/rooms/${listing.id}`} target="_blank">
                        {listing.name}
                    </a>
                </ListingThumbnailName>
                <ListingThumbnailParagraph>{`${listing.city ?? '-'}, ${
                    listing.state ?? '-'
                }`}</ListingThumbnailParagraph>
            </div>
        </StyledCoAlarmListingCard>
    )
}
