import {PHOTOS_LIMIT} from '@utilities/constants'

export const retrieveOrganicCardValueProp = (listingPhotosCount: number, accuracy: number) => {
    if (listingPhotosCount < PHOTOS_LIMIT) {
        return '[Blue] - listingPhotoCount < 10'
    } else if (listingPhotosCount >= PHOTOS_LIMIT && accuracy < 4.5 && accuracy > 0) {
        return '[Yellow] - listingPhotoCount >= 10 & accuracy < 4.5'
    } else if ((listingPhotosCount > PHOTOS_LIMIT && accuracy > 4.5) || accuracy <= 0) {
        return '[Green] - listingPhotoCount > 10 & accuracy > 4.5'
    } else {
        return '[Blank] - no value props shown'
    }
}
