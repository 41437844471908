import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledStatusDescriptionWrapper = styled(Flexbox)<{centered?: boolean}>(
    ({centered}) => css`
        text-align: ${centered ? 'center' : 'left'};
        align-items: ${centered ? 'center' : 'flex-start'};
        ${centered &&
        css`
            max-width: 480px;
            margin: auto;
        `};
    `
)
