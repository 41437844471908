import {FC, useMemo, useRef} from 'react'
import {useCategoriesAvailability} from '../services/queries/useCategoriesAvailability'
import {
    StyledCategoriesRequestBody,
    StyledSuccessRequestToast,
    StyledCategoriesRequestFooter,
    StyledCategoriesRequestHeader,
    StyledCategoriesRequestInputText,
    StyledCategoryRow
} from './style'
import {SearchLgIcon} from '@/components/ui/icon'
import {useTranslation} from 'react-i18next'
import {useCategories} from '../services/queries/useCategories'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {useCategoriesRequestStore} from '../store/CategoriesRequestStore'
import {AddCategoriesRequestLoader} from './AddCategoriesRequestLoader'
import {CategoriesDictionary, CategoryDictionaryKey} from '../utils'
import {AvailabilityCategoryStatus} from '../types'
import Button from '@components/ui/button-legacy/Button'
import {useAddCategories} from '../services/queries/useAddCategories'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/helpers'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface AddCategoriesRequestProps {
    listingId: string
    hostCode: number
    hostPassword: string
}

export const AddCategoriesRequest: FC<AddCategoriesRequestProps> = ({listingId, hostCode, hostPassword}) => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const {data: allCategories} = useCategories()
    const {
        data: categoriesAvailability,
        isPending: isPendingCategoryAvailability,
        isFetching,
        onSearch,
        searchValue,
        isError
    } = useCategoriesAvailability(
        {
            urlParams: {
                hostCode,
                hostPassword,
                listingId
            },
            params: {
                search: searchRef.current?.value
            }
        },
        {retry: false}
    )

    const activeRequestsCount = useCategoriesRequestStore(state => state.activeRequestsCount)
    const remainingRequests = useMemo(() => 5 - Number(activeRequestsCount), [activeRequestsCount])
    const toggleCategoriesModal = useCategoriesRequestStore(state => state.toggleModalVisibility)
    const selectedCategoryIds = useCategoriesRequestStore(store => store.selectedCategoryIds)
    const onChangeCategoryIds = useCategoriesRequestStore(store => store.changeSelectedCategoryIds)

    const {mutate: addCategories, isPending: isPendingAddCategories} = useAddCategories(
        {
            hostCode,
            hostPassword,
            listingId
        },
        {
            onSuccess: () => {
                toast.success(
                    () => (
                        <StyledSuccessRequestToast>
                            <h5>{t('categories:toasts:success_request_title')}</h5>
                            <p>{t('categories:toasts:success_request_description')}</p>
                        </StyledSuccessRequestToast>
                    ),
                    {duration: 4500}
                )
                toggleCategoriesModal()
            },
            onError: errorHandler
        }
    )

    const onAddCategoriesRequest = () => {
        if (selectedCategoryIds.length > 0 && selectedCategoryIds.length <= 5) {
            addCategories({
                categories: selectedCategoryIds
            })
        }
    }

    return (
        <>
            <StyledCategoriesRequestHeader direction="column" gap={6}>
                <Flexbox gap={2} direction="column" width={'100%'}>
                    <h3>{t('categories:modals:add:title')}</h3>
                    <p>{t('categories:modals:add:subtitle', {value: remainingRequests})}</p>
                </Flexbox>

                <StyledCategoriesRequestInputText
                    isLoading={isFetching}
                    ref={searchRef}
                    type="text"
                    defaultValue={searchValue}
                    typeIcon={<SearchLgIcon size={20} />}
                    inputSize="sm"
                    placeholder={t('categories:search_category_placeholder')}
                    onChange={event => onSearch(event.target.value)}
                    disabled={isPendingAddCategories}
                />
            </StyledCategoriesRequestHeader>

            <ScrollArea scrollbar={<Scrollbar />}>
                {isPendingCategoryAvailability ? (
                    <AddCategoriesRequestLoader />
                ) : (
                    <StyledCategoriesRequestBody>
                        {isError && <p>{t('errors:default')}</p>}

                        {searchValue && categoriesAvailability.length === 0 && (
                            <p>{t('categories:modals:add:empty_search', {value: searchValue})}</p>
                        )}

                        {categoriesAvailability.length > 0 && (
                            <Flexbox direction="column" gap={5}>
                                {categoriesAvailability
                                    .filter(({status}) =>
                                        !searchValue ? status !== AvailabilityCategoryStatus.Enum.assigned : status
                                    )
                                    .map(({home_category_id, status}) => {
                                        const category = allCategories.find(({id}) => id === home_category_id)
                                        return (
                                            <StyledCategoryRow
                                                key={home_category_id}
                                                gap={2}
                                                disabled={status !== AvailabilityCategoryStatus.Enum.available}
                                            >
                                                <Checkbox
                                                    id={home_category_id.toString()}
                                                    checked={selectedCategoryIds.includes(home_category_id)}
                                                    onChange={() => onChangeCategoryIds(home_category_id)}
                                                    disabled={
                                                        status !== AvailabilityCategoryStatus.Enum.available ||
                                                        isPendingAddCategories ||
                                                        (!selectedCategoryIds.includes(home_category_id) &&
                                                            selectedCategoryIds.length >= remainingRequests)
                                                    }
                                                />

                                                <div>
                                                    <Flexbox gap={1.5}>
                                                        {
                                                            CategoriesDictionary[
                                                                category?.name as CategoryDictionaryKey
                                                            ]?.icon
                                                        }
                                                        <label htmlFor={home_category_id.toString()}>
                                                            {t(
                                                                CategoriesDictionary[
                                                                    category?.name as CategoryDictionaryKey
                                                                ]?.label ?? category?.name
                                                            )}
                                                        </label>
                                                    </Flexbox>

                                                    {status != AvailabilityCategoryStatus.Enum.available && (
                                                        <p>{t(`categories:listing_level_category_status:${status}`)}</p>
                                                    )}
                                                </div>
                                            </StyledCategoryRow>
                                        )
                                    })}
                            </Flexbox>
                        )}
                    </StyledCategoriesRequestBody>
                )}
            </ScrollArea>

            <StyledCategoriesRequestFooter justify="space-between">
                <Button variant="tertiary" onClick={() => toggleCategoriesModal()} disabled={isPendingAddCategories}>
                    {t('commons:close')}
                </Button>
                <Button
                    variant="primary"
                    disabled={selectedCategoryIds.length === 0 || isPendingAddCategories}
                    onClick={onAddCategoriesRequest}
                >
                    {isPendingAddCategories && <Spinner size={24} />}
                    {t('categories:modals:add:btn')}
                </Button>
            </StyledCategoriesRequestFooter>
        </>
    )
}
