import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {
    HttpGetCoAlarmListingsOptions,
    HttpGetCoAlarmListingsResponse,
    httpGetCoAlarmListings
} from '@/features/co-alarm/services/coAlarm.http'

export const useGetCoAlarmListings = (promiseArgs: HttpGetCoAlarmListingsOptions) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.CO_ALARM_LISTINGS, promiseArgs.params],
        queryFn: ({pageParam}: {pageParam: string | undefined}) =>
            parseAxiosPromise({
                axiosPromise: httpGetCoAlarmListings({
                    ...promiseArgs,
                    params: {
                        ...promiseArgs.params,
                        cursor: pageParam
                    }
                }),
                responseShape: HttpGetCoAlarmListingsResponse,
                onZodError: captureException
            }),
        initialPageParam: undefined,
        getNextPageParam: lastPage => lastPage.paging.next_cursor
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        //to avoid results like [undefined]
        remappedData: query.data?.pages[0]?.listings
            ? query.data.pages.flatMap(page => page.listings)
            : /*.filter(
                      (listing): listing is CoAlarmListingRemappedRequest => listing.co_alarm.status != 'ineligible'
                  )*/
              []
    }
}
