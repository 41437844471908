import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledSectionWrapper = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        & h5 {
            ${typography.textSm}
            color: ${palette.neutral[700]};
            font-weight: 400;
        }
    `
)

export const StyledCaptureMoments = styled(Flexbox)(
    ({theme: {palette, spacing, typography, opacities, mediaQueries}}) => css`
        padding: ${spacing * 4}px;
        background-color: ${palette.warning[600]}${opacities[10]};

        & h5 {
            ${typography.textSm}
            color: ${palette.warning[700]};
            font-weight: 400;
        }
        & h4 {
            ${typography.textSm}
            color: ${palette.neutral[900]};
            font-weight: 500;
        }

        & em {
            font-style: italic;
        }

        ${mediaQueries.m} {
            margin-left: -${spacing * 4}px;
            width: calc(100% + ${spacing * 8}px);
        }
    `
)
