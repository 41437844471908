import styled, {css} from 'styled-components'

export const MOBILE_TABLE_SCROLLBAR_WIDTH = '4px'

export const StyledMobileTable = styled.div<{sorter: boolean}>`
    ${({sorter}) => css`
        min-height: 400px;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: ${sorter ? 'auto 1f' : '1fr'};
    `}
`
