import {CircularProgressBar} from '@/features/experiences-host-video/components/circular-progress-bar/CircularProgressBar.tsx'
import {UploadingFile, useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {useMemo} from 'react'
import {
    StyledPreviewUploadedClips,
    StyledPreviewUploadedClipsLoaderWrapper,
    StyledPreviewUploadedClipsWrapper
} from '@/features/experiences-host-video/components/uploaded-clip-item/style.ts'

export const UploadingClipItem = (uploadingFile: UploadingFile) => {
    const uploadedFiles = useClipsUpload(store => store.uploadedFiles)
    const errorFiles = useClipsUpload(store => store.errorFiles)
    const clipSrc = useMemo(() => URL.createObjectURL(uploadingFile.file), [errorFiles, uploadedFiles])
    return (
        <StyledPreviewUploadedClipsWrapper>
            <StyledPreviewUploadedClips title={uploadingFile.file.name} src={clipSrc} />
            <StyledPreviewUploadedClipsLoaderWrapper>
                <CircularProgressBar progress={uploadingFile.progressValue} />
            </StyledPreviewUploadedClipsLoaderWrapper>
        </StyledPreviewUploadedClipsWrapper>
    )
}
