import {useTranslation} from 'react-i18next'
import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {ImageCheckIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {FC} from 'react'

export const MatterportCompletedStatusDescription: FC<{hasRequestedChanges: boolean}> = ({hasRequestedChanges}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper centered>
            <StatusDescriptionIconWrapper>
                <ImageCheckIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {hasRequestedChanges
                    ? t('service_details:descriptions:matterport:completed_changes_title')
                    : t('service_details:descriptions:matterport:completed_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:matterport:completed_paragraph')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

MatterportCompletedStatusDescription.displayName = 'MatterportCompletedStatusDescription'
