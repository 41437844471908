import {ListingThumbnailName} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'
import styled, {css} from 'styled-components'

export const CoAlarmListingsListThumbnailName = styled(ListingThumbnailName)`
    white-space: normal;
`

export const StyledCoAlarmListingListUpdatedAt = styled.span(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)
