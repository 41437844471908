import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCoAlarmShippingInfoCardGrid = styled.div(
    ({theme: {spacing, mediaQueries, palette}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: ${spacing * 2}px ${spacing * 4}px;
        border-radius: 16px;
        border: 1px solid ${palette.neutral[300]};
        column-gap: ${spacing * 8}px;

        & > .shipping-info-cell:not(:nth-last-of-type(-n + 2)) {
            border-bottom: 1px solid ${palette.neutral[200]};
        }

        ${mediaQueries.m} {
            grid-template-columns: 1fr;

            & > .shipping-info-cell:not(:last-child) {
                border-bottom: 1px solid ${palette.neutral[200]};
            }
        }
    `
)

export const StyledCoAlarmShippingInfoCell = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${3 * spacing}px 0;
        color: ${palette.neutral[900]};

        & > h4 {
            ${typography.textSm};
            font-weight: 500;
        }
    `
)
