import {create} from 'zustand'
import {ShareHostDatesStep, HostDates} from '../components/host-date-picker/types.ts'

type ShareHostDatesState = {
    modalVisible: boolean
    step: ShareHostDatesStep
    dates: HostDates
}

type ShareHostDatesActions = {
    toggleModal: (dates?: HostDates) => void
    changeStep: (nextStep: ShareHostDatesStep) => void
}

export const useShareHostDatesStore = create<ShareHostDatesState & ShareHostDatesActions>()(set => ({
    modalVisible: false,
    step: ShareHostDatesStep.Pick,
    dates: [],
    changeStep: nextStep => set({step: nextStep}),
    toggleModal: dates =>
        set(state => {
            if (state.modalVisible) {
                return {modalVisible: false, dates: [], step: ShareHostDatesStep.Pick}
            } else {
                return {modalVisible: true, dates: dates && dates?.length > 0 ? dates : []}
            }
        })
}))
