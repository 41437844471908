import axios from '@/axiosInstance.ts'
import {ResponseType} from '@/types/commons.ts'
import {z} from 'zod'
import {
    Assignment,
    Entity,
    EntityMeta,
    Host,
    HostAvailabilityDate,
    Quote,
    TaskMatterport,
    TaskMedia,
    TaskResource
} from '@/features/service/types.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {ServiceResource} from '@/features/service-resources/types.ts'

export const HttpGetServiceParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string(),
        quoteId: z.coerce.number().int()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type HttpGetServiceParams = z.infer<typeof HttpGetServiceParams>

export const HttpGetServiceResponse = z.object({
    actions: z.array(ServiceAction),
    added_new_images: z.boolean(),
    assignment: Assignment.nullable(),
    entity: Entity,
    entity_meta: EntityMeta,
    host: Host,
    host_availability_dates: z.tuple([HostAvailabilityDate, HostAvailabilityDate]).or(z.tuple([])),
    quote: Quote,
    tips: z.array(ServiceResource).nullable(),
    task_category: TaskResource,
    task_matterport: TaskMatterport.nullable(),
    task_media: TaskMedia,
    task_service: TaskResource
})
export type HttpGetServiceResponse = z.infer<typeof HttpGetServiceResponse>

export const httpGetService = ({urlParams, params}: HttpGetServiceParams) => {
    return axios.get(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}`,
        {
            params
        }
    )
}

interface HttpAnswerCancellationRequestOptions {
    urlParams: {
        hostCode: number
        hostPassword: string
        quoteId: number
    }
    payload: {action: 'rejected' | 'confirmed'}
}
export const httpAnswerCancellationRequest = ({urlParams, payload}: HttpAnswerCancellationRequestOptions) =>
    axios.post(
        `host-services/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/cancellation`,
        payload
    )
