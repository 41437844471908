import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const StyledServicesSectionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        gap: ${spacing * 8}px;
        scroll-margin-top: ${spacing * 8}px;
        padding: ${spacing * 6}px 0 ${spacing * 12}px;
        grid-template-columns: 1fr;
    `}
`

export const StyledServicesSectionHeader = styled.div`
    ${({theme: {spacing, typography, mediaQueries, palette}}) => css`
        position: relative;
        display: grid;
        gap: ${spacing}px;
        grid-template-columns: 1fr;
        ${mediaQueries.m} {
            & > div {
                gap: ${spacing * 3}px;
                flex-direction: column;
            }
        }
        & h4 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
            & > a {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    `}
`

export const StyledServicesSectionBody = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 380px));
        grid-gap: ${spacing * 6}px;
    `}
`

export const StyledTermsLink = styled(Link)`
    ${() => css`
        cursor: pointer;
        text-decoration: underline;
    `}
`
