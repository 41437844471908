import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {
    ExperiencesHostVideoUploadRouteParams,
    httpDeleteVoiceover
} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'

interface UseDeleteVoiceover {
    params: ExperiencesHostVideoUploadRouteParams
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                mediaId: number
            }
        >,
        'mutationFn'
    >
}
export const useDeleteVoiceover = ({params, mutationOptions}: UseDeleteVoiceover) =>
    useMutation<unknown, unknown, {mediaId: number}>({
        mutationKey: [MUTATION_KEYS.DELETE_VOICEOVER],
        mutationFn: ({mediaId}) => httpDeleteVoiceover({...params, mediaId: mediaId}),
        ...mutationOptions
    })
