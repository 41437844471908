import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {StyledChatbotLink} from '@/features/chatbot/components/styles.ts'
import i18next from 'i18next'

interface Hp7Params {
    isPending: boolean
    isAutoCanceledOrSoftCanceled: boolean
}

export const hp7 = ({isPending, isAutoCanceledOrSoftCanceled}: Hp7Params) => [
    {
        id: 'HP7',
        options: [
            {
                value: 'HP7.1',
                label: i18next.t('chatbot:HP7:HP7.1_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP7',
                        source: 'User',
                        text: 'How long will it take to get connected to a photographer?'
                    })
                    return 'HP7.1'
                }
            },
            ...(isPending || isAutoCanceledOrSoftCanceled
                ? [
                      {
                          value: 'HP7.2',
                          label: i18next.t('chatbot:HP7:HP7.2_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP7',
                                  source: 'User',
                                  text: "After 48 hours I haven't been connected to a photographer. What can I do?"
                              })
                              return 'HP7.2'
                          }
                      }
                  ]
                : []),
            /*...(isDeclined || isPending
                ? [
                      {
                          value: 'HP7.3',
                          label: i18next.t('chatbot:HP7:HP7.3_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP7',
                                  source: 'User',
                                  text: 'No photographer is available in my area, what do I do?'
                              })
                              return 'HP7.3'
                          }
                      }
                  ]
                : []),*/
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP7',
                        source: 'User',
                        text: 'Other'
                    })
                    return 'start'
                }
            }
        ]
    },
    {
        id: 'HP7.1',
        message: i18next.t('chatbot:HP7:HP7.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP7.1',
                source: 'Chatbot',
                text: 'It usually takes about 48 hours to get connected to a local photographer. Once a local photographer accepts your photography request, you will receive a new email with the subject line, "Meet your photographer."'
            })
            return 'HP7.1.1'
        }
    },
    {
        id: 'HP7.1.1',
        message: i18next.t('chatbot:HP7:HP7.1.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP7.1.1',
                source: 'Chatbot',
                text: "Please respond directly to that email and check to see that the photographer's email address is included as a recipient. Scheduling the photoshoot will happen directly between you and the photographer."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP7.2',
        message: i18next.t('chatbot:HP7:HP7.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP7.2',
                source: 'Chatbot',
                text: 'We will keep looking for an available photographer in your area for another 20 days. If we are unable able to connect you with a local photographer, your photoshoot request will be canceled and you will not be charged.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP7.3',
        message: i18next.t('chatbot:HP7:HP7.3_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP7.3',
                source: 'Chatbot',
                text: 'If no photographer is available in your area, your request will be canceled and you will not be charged for the photo shoot. We will keep looking for photographers in your area, so feel free to request a shoot again in a few weeks. In the meantime, here is a resource with tips on how to take great images with your smartphone:'
            })
            return 'HP7.3.1'
        }
    },
    {
        id: 'HP7.3.1',
        component: (
            <StyledChatbotLink
                target={'_blank'}
                to={'https://www.airbnb.com/resources/hosting-homes/a/take-great-listing-photos-on-your-smartphone-221'}
            >
                {i18next.t('chatbot:HP7:HP7.3.1_link')}
            </StyledChatbotLink>
        ),
        asMessage: true,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP7.3.1',
                source: 'Chatbot',
                text: '[LINK] Take great listing photos on your smartphone'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
