import {z} from 'zod'
import {AccessibilityImage} from '@/features/matterport/types.ts'

/**
 * Host approval request schema and Types
 */
export const HostApprovalRequest = z.object({
    id: z.number(),
    title: z.string().nullable(),
    completed_at: z.coerce.date().nullable(),
    approved_at: z.coerce.date().nullable()
})
export type HostApprovalRequest = z.infer<typeof HostApprovalRequest>

export const HttpHostApprovalRequestDetailResponse = z.object({
    accessibility_images: AccessibilityImage.omit({admin_action: true, entity_bedroom_id: true})
        .extend({room_id: z.coerce.number()})
        .array(),
    title: z.string().nullable(),
    id: z.number(),
    completed_at: z.coerce.date().nullable(),
    approved_at: z.coerce.date().nullable()
})
export type HttpHostApprovalRequestDetailResponse = z.infer<typeof HttpHostApprovalRequestDetailResponse>
