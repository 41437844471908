import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Link} from 'react-router-dom'

export const StyledChatbotRoot = styled.div`
    position: relative;
`

export const StyledChatbotWrapper = styled.div<{opened: boolean}>`
    ${({opened, theme: {zIndex, mediaQueries, palette, typography, spacing}}) => css`
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 15%) 0 12px 24px 0;
        overflow: hidden;
        position: fixed;
        bottom: ${spacing * 21}px;
        right: ${spacing * 5}px;
        z-index: ${zIndex.chatbot};
        transform: scale(${opened ? 1 : 0});
        transform-origin: right bottom;
        transition: transform 0.3s ease 0s;
        ${mediaQueries.s} {
            right: 0;
            bottom: 0;
            border-radius: 0;
        }
        & .rsc-container {
            height: 700px;
            ${mediaQueries.s} {
                width: ${window.innerWidth}px;
                height: ${window.innerHeight}px;
                border-radius: 0;
            }

            & .rsc-content {
                height: calc(700px - 110px);
                &::-webkit-scrollbar {
                    width: 4px;
                    background-color: ${palette.neutral[50]};
                }
                &::-webkit-scrollbar-thumb {
                    background-color: ${palette.neutral[300]};
                    border-radius: 3px;

                    &:hover {
                        background-color: ${palette.neutral[400]};
                    }
                }
                ${mediaQueries.s} {
                    height: calc(${window.innerHeight}px - 110px);
                }
                & .rsc-ts-user {
                    & .rsc-ts-bubble {
                        display: flex;
                        border-radius: ${spacing * 2}px;
                        max-width: 260px;
                        padding: ${spacing * 4}px ${spacing * 5}px;
                        align-items: center;
                        background: ${palette.primary['600']};
                        color: ${palette.neutral.white};
                    }
                }

                & .rsc-ts-bot {
                    display: flex;
                    align-items: flex-end;
                    gap: ${spacing}px;
                    align-self: stretch;

                    & .rsc-ts-image-container {
                        & .rsc-ts-image {
                            padding: 0;
                            border-radius: 100%;
                            height: 32px;
                            min-width: 32px;
                        }
                    }

                    & .rsc-ts-bubble {
                        display: flex;
                        border-radius: ${spacing * 2}px;
                        max-width: 260px;
                        padding: ${spacing * 4}px ${spacing * 5}px;
                        align-items: center;
                        margin: ${spacing * 1.5}px 0;
                    }
                }

                & .rsc-cs {
                    background: transparent;
                    box-shadow: none;
                    display: block;
                }
            }

            & .rsc-os-options {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                align-content: flex-start;
                gap: ${spacing * 2}px;
                align-self: stretch;
                flex-wrap: wrap;

                & .rsc-os-option {
                    & .rsc-os-option-element {
                        background: transparent;
                        ${typography.textSm};
                        color: ${palette.primary['600']};
                        display: flex;
                        min-width: 64px;
                        max-width: 320px;
                        padding: ${spacing * 2}px;
                        justify-content: center;
                        align-items: center;
                        gap: ${spacing * 2}px;
                        border-radius: 8px;
                        border: 1.5px solid ${palette.primary['600']};
                        opacity: var(--selected-tab, 1);
                    }
                }
            }

            & .rsc-footer {
                & textarea {
                    height: 54px;
                    resize: none;
                }
                ,
                & input {
                    cursor: auto;
                    background: ${palette.neutral.white};
                    ${typography.textSm};
                }
                ,
        & input: disabled, textarea: disabled {
                    cursor: not-allowed;
                    background: ${palette.neutral['200']};
                }
            }
        }
    `}
`

export const StyledChatbotButton = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        position: fixed;
        width: 48px;
        height: 48px;
        transform: scale(1);
        transition: transform 0.3s ease 0s;
        bottom: ${spacing * 5}px;
        right: ${spacing * 5}px;
        border-radius: 100%;
        -webkit-box-align: center;
        cursor: pointer;
        color: ${palette.neutral.white};
        background: ${palette.primary[600]};
    `}
`

export const StyledChatBotHeader = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        background: ${palette.primary[600]};
        padding: 0 ${spacing * 2}px;
        height: 56px;
        ${typography.textSm};
        color: ${palette.neutral.white};
    `}
`

const stepBasedColor = (value: string) => {
    switch (value) {
        case '1':
            return '#f93533'
        case '2':
            return '#f96b04'
        case '3':
            return '#f9bb06'
        case '4':
            return '#c4d718'
        case '5':
            return '#68c239'
        default:
            return '#ccc'
    }
}

export const StyledBotRatingOption = styled(Flexbox)<{value: string; selectedRating: string; disabled: boolean}>`
    ${({disabled, value, selectedRating, theme: {typography}}) => css`
        text-align: center;
        cursor: ${selectedRating ? 'not-allowed' : 'pointer'};
        & svg {
            fill: ${stepBasedColor(disabled ? 'disabled' : value)};
            transition: ease-in-out 400ms;
            &:hover {
                fill: ${stepBasedColor(value)};
            }
        }
        & small {
            ${typography.textSm};
        }
    `}
`

export const StyledChatbotLink = styled(Link)`
    ${({theme: {typography}}) => css`
        cursor: pointer;
        ${typography.textSm};
        font-weight: 500;
        text-decoration: underline;
    `}
`

export const StyledChatbotResources = styled(Link)`
    ${({theme: {typography, spacing}}) => css`
        cursor: pointer;
        display: grid;
        gap: ${spacing * 2}px;
        & img {
            height: 120px;
        }
        & p {
            ${typography.textSm};
            font-weight: 500;
            text-decoration: underline;
        }
    `}
`
