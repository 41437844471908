import {useTranslation} from 'react-i18next'
import {
    StyledDeclineStatsWidgetWrapper,
    StyledDeclineStepFooter,
    StyledDeclineStepWrapper,
    StyledDeclineStepBody,
    StyledDeclineStepDesc,
    StyledDeclineDisclaimer,
    StyledDeclineDisclaimerTooltip
} from '@/features/service-decline/components/service-decline-process/style.ts'
import Button from '@components/ui/button-legacy/Button.tsx'
import {useDeclineServiceStore} from '@/features/service-decline/store/declineServiceStore.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {ServiceValueProps} from '@/features/in-progress-services/utils.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {useMemo} from 'react'
import {DeclineStepsConfigByService} from '@/features/service-decline/utils.tsx'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {breakpoints} from '@/theme/mediaQueries.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'

export const ServiceDeclineWarningStep = () => {
    const {t} = useTranslation()
    const declineServiceModalToggle = useDeclineServiceStore(state => state.declineServiceModalToggle)
    const changeDeclineModalStep = useDeclineServiceStore(state => state.changeStep)
    const service = useDeclineServiceStore(state => state.service)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    const {width: viewportWidth} = useWindowDimensions()

    return (
        <StyledDeclineStepWrapper>
            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledDeclineStepBody direction={'column'} gap={6}>
                <StyledDeclineStepDesc direction={'column'} gap={2}>
                    <h5>{t(DeclineStepsConfigByService['warning'][serviceType].title)}</h5>
                    <small>{t(DeclineStepsConfigByService['warning'][serviceType].subtitle)}</small>
                </StyledDeclineStepDesc>
                {DeclineStepsConfigByService['warning'][serviceType].stats && (
                    <Flexbox gap={2} justify={'space-between'} width="100%">
                        {Object.values(ServiceValueProps[serviceType])
                            .slice(0, 2)
                            .map((item, index) => (
                                <StyledDeclineStatsWidgetWrapper direction={'column'} gap={1} key={index}>
                                    <small>{t(item.labelText)}</small>
                                    <h4>{t(item.data)}</h4>
                                    <p>{t(item.subtext)}</p>
                                </StyledDeclineStatsWidgetWrapper>
                            ))}
                    </Flexbox>
                )}
                {serviceType == 'home' && (
                    <StyledDeclineDisclaimer>
                        {t('service_cancel:warning:home:stats_disclaimer_paragraph')}{' '}
                        <Tooltip
                            maxWidth="320px"
                            contentProps={{side: viewportWidth <= breakpoints.width.m ? undefined : 'right'}}
                            content={
                                <StyledDeclineDisclaimerTooltip direction="column" gap={4}>
                                    <p>{t('service_cancel:warning:home:stats_disclaimer_tooltip1')}</p>
                                    <p>{t('service_cancel:warning:home:stats_disclaimer_tooltip2')}</p>
                                </StyledDeclineDisclaimerTooltip>
                            }
                        >
                            <span>{t('commons:view_more')}</span>
                        </Tooltip>
                    </StyledDeclineDisclaimer>
                )}
            </StyledDeclineStepBody>
            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledDeclineStepFooter justify={'space-between'}>
                <Button variant={'tertiary'} size={'md'} onClick={() => declineServiceModalToggle()}>
                    {t(DeclineStepsConfigByService['warning'][serviceType].secondary_cta)}
                </Button>
                <Button variant={'primary'} size={'md'} onClick={() => changeDeclineModalStep('final')}>
                    {t(DeclineStepsConfigByService['warning'][serviceType].primary_cta)}
                </Button>
            </StyledDeclineStepFooter>
        </StyledDeclineStepWrapper>
    )
}

ServiceDeclineWarningStep.displayName = 'ServiceDeclineWarningStep'
