import {FC, ReactNode, useState} from 'react'
import {SlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FriendOnboardingModalIntroStep} from '@/features/experiences-host-video/components/friend-onboarding-modal-intro-step/FriendOnboardingModalIntroStep.tsx'
import {FriendOnboardingModalRequirementsStep} from '@/features/experiences-host-video/components/friend-onboarding-modal-requirements-step/FriendOnboardingModalRequirementsStep.tsx'
import {FriendOnboardingModalPerspectiveStep} from '@/features/experiences-host-video/components/friend-onboarding-modal-prespective-step/FriendOnboardingModalPerspectiveStep.tsx'
import {FriendOnboardingModalChecklistStep} from '@/features/experiences-host-video/components/friend-onboarding-modal-checklist-step/FriendOnboardingModalChecklistStep.tsx'
import {Question} from '@/features/experiences-host-video/types.ts'

export type FriendOnboardingModalStep = 'intro' | 'requirements' | 'perspective' | 'checklist'

interface FriendOnboardingModalProps {
    answers: Question[]
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const FriendOnboardingModal: FC<FriendOnboardingModalProps> = ({
    answers,
    slidingModalState,
    setSlidingModalState
}) => {
    const [step, setStep] = useState<FriendOnboardingModalStep>('intro')

    const stepToComponent = {
        intro: <FriendOnboardingModalIntroStep setStep={setStep} />,
        requirements: <FriendOnboardingModalRequirementsStep setStep={setStep} />,
        perspective: <FriendOnboardingModalPerspectiveStep setStep={setStep} />,
        checklist: (
            <FriendOnboardingModalChecklistStep
                answers={answers}
                setStep={setStep}
                setSlidingModalState={setSlidingModalState}
            />
        )
    } as const satisfies Record<FriendOnboardingModalStep, ReactNode>

    return <SlidingModal slidingModalState={slidingModalState}>{stepToComponent[step]}</SlidingModal>
}
