import {create} from 'zustand'
import {AuthState} from '@/features/sso/types.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'

type AuthStoreActions = {
    setAccessToken: (accessToken: string) => void
    reset: () => void
}

export const useAuthStore = create<Partial<AuthState> & AuthStoreActions>()(set => ({
    user: null,
    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || undefined,
    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
    setAccessToken: accessToken => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken)
        set({accessToken})
    },
    reset: () => set({user: null, accessToken: undefined, refreshToken: undefined})
}))
