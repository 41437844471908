import styled, {css} from 'styled-components'
import {ModalHeader, ModalParagraph} from '@components/ui/modal-atoms/ModalAtoms.tsx'

export const StyledHeader = styled(ModalHeader)(
    ({theme: {palette}}) => css`
        border-bottom: 1px solid ${palette.neutral[300]};
    `
)

export const StyledParagraph = styled(ModalParagraph)(
    ({theme: {typography}}) => css`
        ${typography.textMd};
    `
)
