import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalFooter, ModalHeader, ModalParagraph, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC} from 'react'
import {
    StyledModalBody,
    StyledModalTitle
} from '@/features/experiences-host-video/components/confirm-schedule-step-modal/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'

export const ConfirmScheduleStepModal: FC<{onClose: () => void}> = ({onClose}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const updateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <StyledModalBody gap={8}>
                <StyledModalTitle>
                    {t('experiences_host_video:ready_to_film_schedule:confirm_modal_title')}
                </StyledModalTitle>
                <ModalParagraph>
                    {t('experiences_host_video:ready_to_film_schedule:confirm_modal_description')}
                </ModalParagraph>
            </StyledModalBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" disabled={updateStepMutation.isPending} onClick={onClose}>
                    {t('commons:close')}
                </Button>
                <Button
                    disabled={updateStepMutation.isPending}
                    variant="primary"
                    onClick={() =>
                        updateStepMutation.mutate({
                            status: 'upload',
                            step: 'intro'
                        })
                    }
                >
                    {t('commons:confirm')}
                    {updateStepMutation.isPending && <Spinner size={12} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
