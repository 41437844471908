import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {errorHandler} from '@utilities/helpers.ts'
import {httpSendReminder} from '@/features/chatbot/services/sendReminder.http.ts'

export const SendReminder = ({flowSource}: {flowSource: string}) => {
    const {run} = useAsync()
    const service = useChatbotStore(state => state.service)

    useEffect(() => {
        onSendReminder()
    }, [])

    const onSendReminder = async () => {
        try {
            await run(
                httpSendReminder({
                    code: String(service?.entity.code),
                    password: String(service?.entity.password),
                    media: Number(service?.task_media?.id)
                })
            )
            useChatbotStore.getState().addStepToHistory({
                id: flowSource,
                source: 'Chatbot',
                text: '[Reminder sent]'
            })
        } catch (e) {
            errorHandler(e as AxiosError<{message: string}>)
            useChatbotStore.getState().addStepToHistory({
                id: flowSource,
                source: 'Chatbot',
                text: '[Reminder sent error]'
            })
        }
    }

    return <div />
}

SendReminder.displayName = 'SendReminder'
