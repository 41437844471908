import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {HostApprovalRequest} from '@/features/host-approval/types.ts'
import {ResponseType} from '@/types/commons.ts'
import {HostApprovalURLParams} from '@route-guards/host-approval-route/HostApprovalRoute.tsx'

/**
 * httpGetHostApprovalRequests
 * Here we will fetch all the approval requests data for the dashboard cards
 */
export const HttpHostApprovalRequestsResponse = z.array(HostApprovalRequest)
export type HttpHostApprovalRequestsResponse = z.infer<typeof HttpHostApprovalRequestsResponse>

export const httpGetHostApprovalRequests = (urlParams: DashboardURLParams) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/approval-requests`)
}

/**
 * httpGetHostApprovalRequestDetail
 * Here we will fetch all the approval requests data for the dashboard cards
 */
export const HttpGetHostApprovalRequestDetail = HostApprovalRequest
export type HttpGetHostApprovalRequestDetail = z.infer<typeof HttpGetHostApprovalRequestDetail>

export const HttpGetHostApprovalRequestDetailParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string(),
        requestId: z.coerce.number().int()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type HttpGetHostApprovalRequestDetailParams = z.infer<typeof HttpGetHostApprovalRequestDetailParams>

//host approval request list
export const httpGetHostApprovalRequestsListParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type httpGetHostApprovalRequestsListParams = z.infer<typeof httpGetHostApprovalRequestsListParams>

export const httpGetHostApprovalRequestsList = ({urlParams, params}: httpGetHostApprovalRequestsListParams) => {
    return axios.get(`/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks`, {
        params
    })
}

export const httpGetHostApprovalRequestDetail = ({urlParams, params}: HttpGetHostApprovalRequestDetailParams) => {
    return axios.get(
        `/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks/${urlParams.requestId}`,
        {
            params
        }
    )
}

/**
 * httpSendReviewedApproval
 * Here we will send the host decision for the approval
 */
export const HttpSendReviewedApprovalProps = z.object({
    urlParams: HostApprovalURLParams,
    params: z
        .object({
            id: z.coerce.string(),
            action: z.enum(['accepted', 'removed']),
            old: z.boolean()
        })
        .array()
})

export type HttpSendReviewedApprovalProps = z.infer<typeof HttpSendReviewedApprovalProps>

export const httpSendReviewedApproval = ({urlParams, params}: HttpSendReviewedApprovalProps) => {
    const url = `/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks/${urlParams.requestId}/approve`
    return axios.post(url, {images: params})
}
