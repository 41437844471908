import {z} from 'zod'
import {CANCEL_OTHER_REASON_ID} from '@utilities/constants'

export type CancelStep = 'warning' | 'final'

export type CancelStepField =
    | 'modal_title'
    | 'title'
    | 'subtitle'
    | 'reason'
    | 'primary_cta'
    | 'secondary_cta'
    | 'stats'

export const ServiceCancellationSchema = z
    .object({
        reason_id: z.coerce.number().min(1),
        notes: z.string().optional()
    })
    .superRefine((values, context) => {
        if (values.reason_id == CANCEL_OTHER_REASON_ID && !values.notes) {
            context.addIssue({
                code: 'custom',
                path: ['reason_id'],
                message: 'required'
            })
        }
    })
export type ServiceCancellationSchema = z.infer<typeof ServiceCancellationSchema>

export const CancellationReasonKey = z.enum([
    'accidental_sign_up',
    'changed_decision',
    'discomfort_with_assigned_provider',
    'excessive_cost',
    'found_alternative_provider',
    'listing_not_prepared',
    'lost_interest',
    'unresponsive_provider',
    'other'
])
export type CancellationReasonKey = z.infer<typeof CancellationReasonKey>

export const CancellationReasonKeyWithPrefix = z.custom<`reasons.${CancellationReasonKey}`>()
export type CancellationReasonKeyWithPrefix = z.infer<typeof CancellationReasonKeyWithPrefix>
