import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'

export const hp1Luxe = (): ChatbotStep[] => [
    {
        id: 'HP1_luxe',
        options: [
            {
                value: 'HP1.1_walk_price',
                label: i18next.t('chatbot:HP1_luxe:HP1.1_luxe_price_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1.1_walk_price',
                        source: 'User',
                        text: 'Do I need to pay for the walkthrough?'
                    })
                    return 'HP1.3'
                }
            }
        ]
    },
    {
        id: 'HP1.1_walk_price_answer',
        message: i18next.t('chatbot:HP1_luxe:HP1.1_luxe_price_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.1_walk_price_answer',
                source: 'Chatbot',
                text: 'There is no cost to Luxe walkthroughs, your quote price will always be $0. However if you cancel last minute, you may be charged a late cancellation fee to compensate the home consultant previously scheduled.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
