import dayjs from 'dayjs'
import {FC} from 'react'
import {StyledDateChip} from './style'
import {XCloseIcon} from '@/components/ui/icon'

interface DateChipProps {
    selectedDate: Date
    onClose: (value: Date) => void
}

export const DateChip: FC<DateChipProps> = ({selectedDate, onClose}) => {
    return (
        <StyledDateChip align={'center'}>
            <span>{dayjs(selectedDate).format('LLL')}</span>
            <button onClick={() => onClose(selectedDate)}>
                <XCloseIcon data-color size={16} />
            </button>
        </StyledDateChip>
    )
}
