import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {httpOtpCodeVerificationV2} from '@/features/otp-flow/services/opt.http.ts'
import {HttpOtpCodeVerificationParamsV2} from '@/features/otp-flow/types.ts'

type useOtpValidationProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useOtpValidationV2 = (options?: useOtpValidationProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.VALIDATE_OTP_CODE],
        mutationFn: (params: HttpOtpCodeVerificationParamsV2) => httpOtpCodeVerificationV2(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            options?.onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_PROPOSALS]})
        }
    })
}
