import {create} from 'zustand'
import {HostApprovalImagesFormatted, ReviewList, Room, UpdateReviewAction} from '../types'
import {
    HttpGetRoomsAutocompleteResponse,
    HttpGetAmenitiesAutocompleteResponse,
    HttpGetMatterportResponse
} from '../services/matterport.http'
import {formatAccessibilityInfos, formatHostApprovalInfos} from '../utils'
import {HttpHostApprovalRequestDetailResponse} from '@/features/host-approval/types.ts'

type MatterportState = {
    modalVisible: boolean
    roomsList: Room[]
    toReviewList: ReviewList | Record<string, HostApprovalImagesFormatted>
}

type MatterportActions = {
    toggleModal: () => void
    showModal: () => void
    closeModal: () => void
    initMatterport: (
        rooms: HttpGetRoomsAutocompleteResponse,
        amenities: HttpGetAmenitiesAutocompleteResponse,
        infos: HttpGetMatterportResponse
    ) => void
    initHostApprovalRequest: (
        rooms: HttpGetRoomsAutocompleteResponse,
        amenities: HttpGetAmenitiesAutocompleteResponse,
        infos: HttpHostApprovalRequestDetailResponse
    ) => void
    updateReview: (actionType: UpdateReviewAction, imageId: number) => void
    addAllPhotos: () => void
    resetReview: () => void
}

export const useMatterportStore = create<MatterportState & MatterportActions>()(set => ({
    modalVisible: false,
    step: 'intro',
    roomsList: [],
    toReviewList: {},
    initHostApprovalRequest: (rooms, amenities, infos) =>
        set(() => {
            // get formatted data and merge values from APIs
            const {accessibilityImages, roomsList} = formatHostApprovalInfos(rooms, amenities, infos)

            // collect images in a pending review status
            const toReviewList: Record<string, HostApprovalImagesFormatted> = Object.fromEntries(
                accessibilityImages.map(image => [image.id, image])
            )

            return {
                roomsList: roomsList.filter(room => room.amenities.length > 0),
                toReviewList
            }
        }),
    initMatterport: (rooms, amenities, infos) =>
        set(() => {
            // get formatted data and merge values from APIs
            const {accessibilityImages, roomsList} = formatAccessibilityInfos(rooms, amenities, infos)

            // collect images in a pending review status
            const toReviewList: ReviewList = Object.fromEntries(
                accessibilityImages.filter(image => image.admin_action === 'to_accept').map(image => [image.id, image])
            )

            return {
                roomsList: roomsList.filter(room => room.amenities.length > 0),
                toReviewList
            }
        }),
    updateReview: (actionType, imageId) =>
        set(state => {
            if (actionType == 'add') {
                return {
                    toReviewList: {
                        ...state.toReviewList,
                        [imageId]: {
                            ...state.toReviewList[imageId],
                            toCheck: false,
                            accepted: true,
                            removed: false
                        }
                    }
                }
            } else {
                return {
                    toReviewList: {
                        ...state.toReviewList,
                        [imageId]: {
                            ...state.toReviewList[imageId],
                            toCheck: false,
                            accepted: false,
                            removed: true
                        }
                    }
                }
            }
        }),
    addAllPhotos: () =>
        set(state => {
            const processList = (list: ReviewList | Record<string, HostApprovalImagesFormatted>) =>
                Object.keys(list).map(imageId => ({
                    ...list[imageId],
                    toCheck: false,
                    accepted: true,
                    removed: false
                }))

            const toReviewList = processList(state.toReviewList)
            return {
                toReviewList: toReviewList.reduce(
                    (acc, val) => ({
                        ...acc,
                        [val.id]: val
                    }),
                    {}
                )
            }
        }),
    resetReview: () =>
        set(state => {
            const processList = (list: ReviewList | Record<string, HostApprovalImagesFormatted>) =>
                Object.keys(list).map(imageId => ({
                    ...list[imageId],
                    toCheck: true,
                    accepted: false,
                    removed: false
                }))

            const toReviewList = processList(state.toReviewList)
            return {
                toReviewList: toReviewList.reduce(
                    (acc, val) => ({
                        ...acc,
                        [val.id]: val
                    }),
                    {}
                )
            }
        }),
    toggleModal: () =>
        set(state => {
            if (state.modalVisible) {
                return {modalVisible: false, step: 'intro'}
            } else {
                return {modalVisible: true, step: 'intro'}
            }
        }),
    showModal: () =>
        set(() => {
            return {modalVisible: true, step: 'intro'}
        }),
    closeModal: () =>
        set(() => {
            return {modalVisible: false}
        })
}))
