import {useDeclineServiceStore} from '@/features/service-decline/store/declineServiceStore.tsx'
import {ServiceDeclineWarningStep} from '@/features/service-decline/components/service-decline-process/ServiceDeclineWarningStep.tsx'
import {ServiceDeclineFinalStep} from '@/features/service-decline/components/service-decline-process/ServiceDeclineFinalStep.tsx'

export const ServiceDeclineProcess = () => {
    const declineActiveStep = useDeclineServiceStore(state => state.step)

    return (
        <>
            {declineActiveStep == 'warning' && <ServiceDeclineWarningStep />}
            {declineActiveStep == 'final' && <ServiceDeclineFinalStep />}
        </>
    )
}

ServiceDeclineProcess.displayName = 'PhotoShootDeclineProcess'
