import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledServicePromoCardWrapper = styled.div`
    ${({theme: {palette, shadows}}) => css`
        border-radius: 12px;
        border: 1px solid ${palette.neutral['200']};
        background: ${palette.neutral.white};
        box-shadow: ${shadows.md};
    `}
`

export const StyledServicePromoCardBody = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 4}px;
        padding: ${spacing * 6}px;
    `}
`

export const StyledServicePromoCardTitle = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        gap: ${spacing}px;

        & > a {
            ${typography.textMd};
            cursor: pointer;
            font-weight: 500;
            color: ${palette.neutral['900']};
            height: 48px;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & p {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `}
`

export const StyledServicePromoCardInfo = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 8}px;
    `}
`

export const ServicePromoCardInfoItem = styled(Flexbox)`
    ${({theme: {spacing, palette, typography}}) => css`
        gap: ${spacing * 2}px;
        & > svg {
            fill: ${palette.neutral['900']};
        }
        & > p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `}
`

export const StyledServicePromoCardFooter = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 4}px;
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`

export const StyledServicePromoCardQuoteInfo = styled(Flexbox)`
    ${({theme: {palette, spacing, typography}}) => css`
        & h6 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
            display: flex;
            gap: ${spacing}px;
            & span {
                text-decoration: line-through;
                font-weight: 400;
                color: ${palette.neutral['700']};
            }
        }

        & small {
            ${typography.textXs};
            color: ${palette.neutral['500']};
        }
    `}
`

export const StyledServicePromoCardCtas = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 1.5}px;
        flex: none;
    `}
`
