import {
    handleCloseSlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FC, ReactElement, useState} from 'react'
import {StyledQuizModal, StyledModalHeader} from '@/features/experiences-host-video/components/quiz-modal/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {QuizModalPhoneStep} from '@/features/experiences-host-video/components/quiz-modal-phone-step/QuizModalPhoneStep.tsx'
import {QuizModalUploadStep} from '@/features/experiences-host-video/components/quiz-modal-upload-step/QuizModalUploadStep.tsx'
import {QuizModalHoldCameraStep} from '@/features/experiences-host-video/components/quiz-modal-hold-camera-step/QuizModalHoldCameraStep.tsx'
import {QuizModalStagedPeopleCountStep} from '@/features/experiences-host-video/components/quiz-modal-staged-people-count-step/QuizModalStagedPeopleCountStep.tsx'
import {QuizModalElementsStep} from '@/features/experiences-host-video/components/quiz-modal-elements-step/QuizModalElementsStep.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, XCloseIcon} from '@components/ui/icon'
import {ModalBody} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {UseMutationResult, UseQueryResult} from '@tanstack/react-query'
import {UseQuizAnswerResponse} from '@/features/experiences-host-video/services/useQuizAnswer.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {HttpCompleteQuizOptions} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {AxiosResponse} from 'axios'

export type QuizStep = 'phone' | 'upload' | 'hold_camera' | 'staged_people_count' | 'elements'

interface QuizModalProps {
    quizQuery: UseQueryResult<Quiz, unknown>
    slidingModalState: SlidingModalState
    completePromise: (promiseProps: HttpCompleteQuizOptions) => Promise<AxiosResponse>
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    quizAnswerMutation: UseMutationResult<UseQuizAnswerResponse, unknown, {questionId: number}, unknown>
}
export const QuizModal: FC<QuizModalProps> = ({
    slidingModalState,
    setSlidingModalState,
    quizQuery,
    quizAnswerMutation,
    completePromise
}) => {
    const {t} = useTranslation()
    const [step, setStep] = useState<QuizStep>('phone')

    if (quizQuery.isPending) {
        return (
            <StyledQuizModal slidingModalState={slidingModalState}>
                <StyledModalHeader>
                    <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                    {t('experiences_host_video:quiz:title')}
                </StyledModalHeader>
                <ModalBody justify="center" align="center">
                    <Spinner size={32} />
                </ModalBody>
            </StyledQuizModal>
        )
    }
    if (quizQuery.isError) {
        return (
            <StyledQuizModal slidingModalState={slidingModalState}>
                <StyledModalHeader>
                    <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                    {t('experiences_host_video:quiz:title')}
                </StyledModalHeader>
                <ModalBody>
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                </ModalBody>
            </StyledQuizModal>
        )
    }

    const stepToComponent = {
        phone: <QuizModalPhoneStep quiz={quizQuery.data} setStep={setStep} quizAnswerMutation={quizAnswerMutation} />,
        upload: <QuizModalUploadStep quiz={quizQuery.data} setStep={setStep} quizAnswerMutation={quizAnswerMutation} />,
        hold_camera: (
            <QuizModalHoldCameraStep quiz={quizQuery.data} setStep={setStep} quizAnswerMutation={quizAnswerMutation} />
        ),
        staged_people_count: (
            <QuizModalStagedPeopleCountStep
                quiz={quizQuery.data}
                setStep={setStep}
                quizAnswerMutation={quizAnswerMutation}
            />
        ),
        elements: (
            <QuizModalElementsStep
                quiz={quizQuery.data}
                setSlidingModalState={setSlidingModalState}
                quizAnswerMutation={quizAnswerMutation}
                completePromise={completePromise}
            />
        )
    } as const satisfies Record<QuizStep, ReactElement>

    return (
        <StyledQuizModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <Button
                    shape="square"
                    onClick={() => {
                        quizAnswerMutation.reset()
                        handleCloseSlidingModal(setSlidingModalState)
                    }}
                    variant={'ghost'}
                    size={'sm'}
                >
                    <XCloseIcon />
                </Button>
                {t('experiences_host_video:quiz:title')}
            </StyledModalHeader>
            {stepToComponent[step]}
        </StyledQuizModal>
    )
}
