import styled, {css, DefaultTheme} from 'styled-components'
import {RequirementCardProps} from '@/features/experiences-host-video/components/requirement-card/RequirementCard.tsx'

export const StyledRequirementCard = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        gap: ${spacing * 3}px;
        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `
)

export const makeRequirementCardIconWrapper = (palette: DefaultTheme['palette']) =>
    ({
        neutral: css`
            background-color: ${palette.neutral['100']};
        `,
        success: css`
            background-color: ${palette.success['50']};
            & svg {
                color: ${palette.success['600']};
            }
        `,
        danger: css`
            background-color: ${palette.danger['50']};
            & svg {
                color: ${palette.danger['600']};
            }
        `
    }) as const satisfies Record<NonNullable<RequirementCardProps['iconWrapperVariant']>, ReturnType<typeof css>>

export const StyledIconWrapper = styled.div<{$variant: RequirementCardProps['iconWrapperVariant']}>(
    ({theme: {palette}, $variant}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        ${makeRequirementCardIconWrapper(palette)[$variant || 'neutral']};
        color: ${palette.neutral['900']};
        width: 38px;
        height: 38px;
        border-radius: 50%;
        & svg {
            width: 20px;
            height: 20px;
        }
    `
)
