import styled, {css} from 'styled-components'

export const StyledPerspectiveInfo = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        ${typography.textSm};

        & svg {
            fill: ${palette.blue['600']};
        }
        & h3 {
            color: ${palette.neutral['900']};
            font-weight: 500;
        }
        & p {
            color: ${palette.neutral['700']};
        }
    `
)
