import {Diamond01Icon, ImagePlusIcon, Star06Icon} from '@/components/ui/icon'
import {PHOTOS_LIMIT} from '@/utilities/constants'
import {StyledRequestValuesText, StyledRequestValues} from './style'
import {Trans} from 'react-i18next'
import {RequestValueLimits} from '@/features/service-requests/types'

interface RequestValuesProps {
    listingPhotosCount: number
    accuracy: number
    photoServiceCount: number
}

export const RequestValues = ({photoServiceCount, listingPhotosCount, accuracy}: RequestValuesProps) => {
    let currentValues = null

    const values: RequestValueLimits = {
        underLimits: {
            icon: <Diamond01Icon size={20} fill="#175CD3" />,
            label: 'photoshoot_requests:photo_request_card:values:under_limit_other',
            limit: photoServiceCount + listingPhotosCount,
            count: listingPhotosCount,
            accuracy: undefined
        },
        betweenLimits: {
            icon: <Star06Icon size={20} fill="#EF6820" />,
            label: 'photoshoot_requests:photo_request_card:values:over_limit_not_accuracy',
            limit: PHOTOS_LIMIT,
            count: listingPhotosCount,
            accuracy: accuracy
        },
        overLimits: {
            icon: <ImagePlusIcon size={20} fill="#079455" />,
            label: 'photoshoot_requests:photo_request_card:values:over_limit_and_accuracy',
            limit: PHOTOS_LIMIT,
            count: listingPhotosCount
        }
    }

    if (listingPhotosCount < PHOTOS_LIMIT) {
        currentValues = values.underLimits
    } else if (listingPhotosCount >= PHOTOS_LIMIT && accuracy < 4.5 && accuracy > 0) {
        currentValues = values.betweenLimits
    } else if ((listingPhotosCount > PHOTOS_LIMIT && accuracy > 4.5) || accuracy <= 0) {
        currentValues = values.overLimits
    }

    if (!currentValues) return null

    return (
        <StyledRequestValues align="center" gap={3}>
            {currentValues.icon}
            <StyledRequestValuesText>
                <Trans
                    i18nKey={currentValues.label}
                    components={[<b />]}
                    values={{
                        limit: currentValues.limit,
                        count: currentValues.count,
                        accuracy: currentValues?.accuracy
                    }}
                />
            </StyledRequestValuesText>
        </StyledRequestValues>
    )
}
