import axios from '@/axiosInstance.ts'
import {AcceptPortraitShootParams, HttpAcceptQuoteParams} from '@/features/service-acceptance-flow/types.ts'

export const httpAcceptService = (params: HttpAcceptQuoteParams) => {
    return axios.post(`host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/accept`)
}

export const httpAcceptPortraitShoot = (params: AcceptPortraitShootParams) => {
    return axios.post(
        `host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/host-portrait`,
        {
            host_preference: params.hostPreference
        }
    )
}
