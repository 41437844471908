import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {Reason} from '@/features/service-decline/types.ts'
import {TaskMedia} from '@/features/service/types.ts'

/**
 * httpGetServiceCancellationReasons
 * Here we will retrieve the cancellation reasons to show and send back after selection
 */
export const HttpGetServiceCancellationReasonsParams = z.object({
    task_media_name: TaskMedia.shape.name,
    by: z.enum(['host', 'creator'])
})
export type HttpGetServiceCancellationReasonsParams = z.infer<typeof HttpGetServiceCancellationReasonsParams>
export const HttpGetServiceCancellationReasonsResponse = z.array(Reason)
export type HttpGetServiceCancellationReasonsResponse = z.infer<typeof HttpGetServiceCancellationReasonsResponse>

export const httpGetServiceCancellationReasons = (params: HttpGetServiceCancellationReasonsParams) => {
    return axios.get('reasons/cancel', {params})
}

/**
 * httpCancelService
 * Here we will handle the service cancellation process
 */
export const HttpCancelServiceParams = z.object({
    ...DashboardURLParams.shape,
    reason_id: z.number().min(1),
    notes: z.string().optional(),
    quoteId: z.number()
})
export type HttpCancelServiceParams = z.infer<typeof HttpCancelServiceParams>

export const httpCancelService = (params: HttpCancelServiceParams) => {
    const url = `/host-services/hosts/${params.hostCode}/${params.hostPassword}/quotes/${params.quoteId}/cancel-assignment`
    return axios.post(url, {reason_id: params.reason_id, notes: params.notes})
}
