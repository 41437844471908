import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {StyledChatbotLink} from '@/features/chatbot/components/styles.ts'
import i18next from 'i18next'

export const hp1 = ({
    quotePrice,
    listingTitle,
    backToGeneric
}: {
    backToGeneric?: boolean
    quotePrice: number | null
    listingTitle: string
}): ChatbotStep[] => [
    {
        id: 'HP1',
        options: [
            ...(quotePrice === 0
                ? [
                      {
                          value: 'HP1.1_free',
                          label: i18next.t('chatbot:HP1:HP1.1_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP1',
                                  source: 'User',
                                  text: 'What is the price of the photoshoot?'
                              })
                              return 'HP1.1_free'
                          }
                      }
                  ]
                : quotePrice
                ? [
                      {
                          value: 'HP1.1',
                          label: i18next.t('chatbot:HP1:HP1.1_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP1',
                                  source: 'User',
                                  text: 'What is the price of the photoshoot?'
                              })
                              return 'HP1.1'
                          }
                      }
                  ]
                : []),
            {
                value: 'HP1.3',
                label: i18next.t('chatbot:HP1:HP1.3_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1',
                        source: 'User',
                        text: 'When do I pay for the photoshoot?'
                    })
                    return 'HP1.3'
                }
            },
            {
                value: 'HP1.4',
                label: i18next.t('chatbot:HP1:HP1.4_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1',
                        source: 'User',
                        text: 'Can I redeem my Superhost credit to pay for the photoshoot?'
                    })
                    return 'HP1.4'
                }
            },
            {
                value: 'HP1.5',
                label: i18next.t('chatbot:HP1:HP1.5_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1',
                        source: 'User',
                        text: 'Can I user the free offer for another listing?'
                    })
                    return 'HP1.5'
                }
            },
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1',
                        source: 'User',
                        text: 'Other'
                    })
                    return backToGeneric ? 'generic_flows_dash_options' : 'start'
                }
            }
        ]
    },
    {
        id: 'HP1.1_free',
        message: i18next.t('chatbot:HP1:HP1.1_free_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.1_free',
                source: 'Chatbot',
                text: 'This is a free quote. Your photoshoot will be free of charge.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP1.1',
        message: i18next.t('chatbot:HP1:HP1.1_message', {quotePrice: quotePrice}),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.1',
                source: 'Chatbot',
                text: `Your price quote is $${quotePrice} USD. You won't be charged until the photos are live on your listing. The cost of your photoshoot will be subtracted from your next payout(s) until the balance is paid in full. If you choose to cancel before the shoot, you won't be charged.`
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },

    {
        id: 'HP1.3',
        message: i18next.t('chatbot:HP1:HP1.3_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.3',
                source: 'Chatbot',
                text: "You won't be charged until the photos are live on your Airbnb listing page. The cost of your photoshoot will be subtracted from your next payout, even if your next payout comes from a different listing. If you choose to cancel before the shoot, you won't be charged."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP1.4',
        message: i18next.t('chatbot:HP1:HP1.4_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.4',
                source: 'Chatbot',
                text: 'Unfortunately, the Superhost credit has not been redeemable for professional photography in the past few years.'
            })
            return 'HP1.4.1'
        }
    },
    {
        id: 'HP1.4.1',
        component: (
            <StyledChatbotLink to="https://www.airbnb.com/d/superhost" target={'_blank'}>
                {i18next.t('chatbot:HP1:HP1.4.1_link')}
            </StyledChatbotLink>
        ),
        asMessage: true,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.4.1',
                source: 'Chatbot',
                text: '[Link] Here are details on the program.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP1.5',
        message: i18next.t('chatbot:HP1:HP1.5_message', {listingTitle: listingTitle}),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.5',
                source: 'Chatbot',
                text: 'This free offer is related to the specific listing we reached out for and cannot be used for another listing.'
            })
            return 'HP1.5.1'
        }
    },
    {
        id: 'HP1.5.1',
        message: i18next.t('chatbot:HP1:HP1.5.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP1.5.1',
                source: 'Chatbot',
                text: 'Would you like to get a price quote for another listing?'
            })
            return 'HP1.5.2'
        }
    },
    {
        id: 'HP1.5.2',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1.5.2',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP0'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP1.5.2',
                        source: 'User',
                        text: 'No'
                    })
                    return 'oq_message'
                }
            } // Final trigger of the flow's branch
        ]
    }
]
