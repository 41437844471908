import styled, {css} from 'styled-components'

export const StyledPreviewUploadedClipsErrorWrapper = styled.div(
    ({theme: {palette, opacities, spacing, typography}}) => css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 4}px;
        top: 0;
        left: 0;
        width: 100%;
        background-color: ${palette.neutral.black}${opacities['30']};
        border-radius: 8px;
        aspect-ratio: 9 / 16;
        & p {
            ${typography.textMd};
            color: ${palette.neutral.white};
            text-align: center;
        }
        & svg {
            color: ${palette.neutral.white};
        }
    `
)
