import axios from '../../../axiosInstance.ts'
import {z} from 'zod'
import {User} from '@/features/sso/types.ts'

/**
 * @httpAirbnbSSOLogin Function and types inferred with zod
 * This function is used to validate the code received during the sso process
 */
const SSOAuthorizationSchema = z.object({
    access_token: z.string(),
    refresh_token: z.string(),
    user_id: z.coerce.number().int()
})
export const HttpAirbnbSSOResponse = z.object({
    oauth2_authorization: SSOAuthorizationSchema
})
export type HttpAirbnbSSOResponse = z.infer<typeof HttpAirbnbSSOResponse>
export const httpAirbnbSSOLogin = (code: string) => {
    return axios.post(`/host-services/login`, {code: code})
}

/**
 * @httpLogout
 * Simple Logout function
 */
export const httpLogout = () => {
    return axios.get('/host-services/logout')
}

/**
 * @httpUserMe
 * Simple Logout function
 */
export const HttpUserMeResponse = User // Directly use already created User schema
type HttpUserMeResponse = z.infer<typeof HttpUserMeResponse>
export const httpUserMe = () => {
    return axios.get(`/host-services/hosts/me`)
}
