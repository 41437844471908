import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'

export const useExperiencesHostVideoRouteParams = () => {
    const urlParams = useURLParsedParams(ExperiencesHostVideoRouteParams)
    if (urlParams.success) {
        return urlParams.data
    }

    throw new Error('wrong urlParams')
}
