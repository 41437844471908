import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledListigCategoriesHeader,
    StyledListingCategoriesBody,
    StyledListingCategoriesBodyHeading,
    StyledListingCategoriesChips,
    StyledListingCategoriesFooter,
    StyledListingCategoriesCard,
    StyledListingCategoriesSkeletonButton,
    StyledListingCategoriesHeaderContainer
} from '../listing-categories-card/style'
import Skeleton from '@/components/ui/skeleton/Skeleton'

export const ListingCategoriesLoaderCard = () => {
    return (
        <StyledListingCategoriesCard>
            <StyledListigCategoriesHeader width={'100%'} gap={2}>
                <StyledListingCategoriesHeaderContainer gap={4}>
                    <Skeleton width={64} height={44} />
                    <Skeleton width={200} height={44} />
                </StyledListingCategoriesHeaderContainer>

                <StyledListingCategoriesSkeletonButton>
                    <Skeleton width={120} height={36} />
                </StyledListingCategoriesSkeletonButton>
            </StyledListigCategoriesHeader>

            <StyledListingCategoriesBody direction="column" width={'100%'}>
                <StyledListingCategoriesBodyHeading>
                    <Skeleton height={40} />
                </StyledListingCategoriesBodyHeading>

                <StyledListingCategoriesChips gap={2}>
                    <Skeleton width={80} height={30} />
                    <Skeleton width={80} height={30} />
                    <Skeleton width={80} height={30} />
                    <Skeleton width={80} height={30} />
                </StyledListingCategoriesChips>
            </StyledListingCategoriesBody>

            <StyledListingCategoriesFooter>
                <Flexbox width={'100%'}>
                    <Skeleton height={22} />
                </Flexbox>
            </StyledListingCategoriesFooter>
        </StyledListingCategoriesCard>
    )
}
