import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ClockIcon,
    CurrencyDollarIcon,
    Home05Icon,
    Image03Icon,
    MarkerPin01Icon,
    VideoRecorderIcon
} from '@components/ui/icon'
import {formatDurationFromMinutes, formatLocation, getDuration} from '@utilities/helpers.ts'
import {StyledServiceRecap} from '@/features/service/components/service-recap/style.ts'
import {FC} from 'react'
import {Entity, EntityMeta, Quote} from '@/features/service/types.ts'
import {useTranslation} from 'react-i18next'
import {ServiceType} from '@/types/commons.ts'

type ServiceRecapProps = {
    entity: Entity
    entityMeta: EntityMeta
    quote: Quote
    serviceType: ServiceType
}
export const ServiceRecap: FC<ServiceRecapProps> = ({entity, entityMeta, quote, serviceType}) => {
    const {t} = useTranslation()
    const duration = getDuration(entityMeta)

    if (serviceType == 'experience_video') {
        return (
            <StyledServiceRecap gap={6}>
                {entity.bedrooms ? (
                    <Flexbox gap={2} align="center">
                        <Home05Icon size={20} />
                        {t('commons:n_bedrooms', {count: entity.bedrooms})}
                    </Flexbox>
                ) : null}
                {duration ? (
                    <Flexbox gap={2} align="center">
                        <VideoRecorderIcon size={20} />
                        {formatDurationFromMinutes(duration)}
                    </Flexbox>
                ) : null}
                <Flexbox gap={2} align="center">
                    <MarkerPin01Icon size={20} />
                    {formatLocation(entity.city, entity.country)}
                </Flexbox>
                {quote.quote_status != 'cancelled' && (
                    <Flexbox gap={2} align="center">
                        <CurrencyDollarIcon size={20} />
                        {quote.amount ? t('service_details:total_cost_n', {amount: quote.amount}) : t('commons:free')}
                    </Flexbox>
                )}
            </StyledServiceRecap>
        )
    }
    if (serviceType == 'matterport') {
        return (
            <StyledServiceRecap gap={6}>
                {entity.bedrooms ? (
                    <Flexbox gap={2} align="center">
                        <Home05Icon size={20} />
                        {t('commons:n_bedrooms', {count: entity.bedrooms})}
                    </Flexbox>
                ) : null}
                <Flexbox gap={2} align="center">
                    <MarkerPin01Icon size={20} />
                    {formatLocation(entity.city, entity.country)}
                </Flexbox>
                {quote.quote_status != 'cancelled' && (
                    <Flexbox gap={2} align="center">
                        <CurrencyDollarIcon size={20} />
                        {quote.amount ? t('service_details:total_cost_n', {amount: quote.amount}) : t('commons:free')}
                    </Flexbox>
                )}
            </StyledServiceRecap>
        )
    }
    if (serviceType == 'walkthrough') {
        return (
            <StyledServiceRecap gap={6}>
                {entity.bedrooms ? (
                    <Flexbox gap={2} align="center">
                        <Home05Icon size={20} />
                        {t('commons:n_bedrooms', {count: entity.bedrooms})}
                    </Flexbox>
                ) : null}
                {duration ? (
                    <Flexbox gap={2} align="center">
                        <ClockIcon size={20} />
                        {formatDurationFromMinutes(duration)}
                    </Flexbox>
                ) : null}
                <Flexbox gap={2} align="center">
                    <MarkerPin01Icon size={20} />
                    {formatLocation(entity.city, entity.country)}
                </Flexbox>
            </StyledServiceRecap>
        )
    }

    return (
        <StyledServiceRecap gap={6}>
            {entity.bedrooms ? (
                <Flexbox gap={2} align="center">
                    <Home05Icon size={20} />
                    {t('commons:n_bedrooms', {count: entity.bedrooms})}
                </Flexbox>
            ) : null}
            <Flexbox gap={2} align="center">
                <Image03Icon size={20} />
                {t('commons:up_to_n_photos', {count: quote.photo_count ?? 15})}
            </Flexbox>
            <Flexbox gap={2} align="center">
                <MarkerPin01Icon size={20} />
                {formatLocation(entity.city, entity.country)}
            </Flexbox>
            {quote.quote_status != 'cancelled' && (
                <Flexbox gap={2} align="center">
                    <CurrencyDollarIcon size={20} />
                    {quote.amount ? t('service_details:total_cost_n', {amount: quote.amount}) : t('commons:free')}
                </Flexbox>
            )}
        </StyledServiceRecap>
    )
}
