import axios from '@/axiosInstance.ts'
import {
    HttpEnableNotificationChannelsParams,
    HttpOtpCodeVerificationParams,
    HttpOtpCodeVerificationParamsV2,
    HttpUpdateNotificationPreferences,
    HttpVerificationCodeParams
} from '@/features/otp-flow/types.ts'

export const httpRequestVerificationCode = ({urlParams, params}: HttpVerificationCodeParams) => {
    return axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/send-verification-code`,
        {...params}
    )
}

export const httpOtpCodeVerification = ({urlParams, params}: HttpOtpCodeVerificationParams) => {
    return axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/verify-code`,
        {...params}
    )
}

export const httpEnableNotificationChannels = ({urlParams, params}: HttpEnableNotificationChannelsParams) => {
    return axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/notifications`,
        {...params}
    )
}

/* v2 */
export const httpOtpCodeVerificationV2 = ({urlParams, params}: HttpOtpCodeVerificationParamsV2) => {
    return axios.post(`host-services/notification-preferences/${urlParams.notifySettingsId}`, {...params})
}

/* v2 new update permissions and update sms or email */
export const httpUpdateNotificationPreferences = ({urlParams, params}: HttpUpdateNotificationPreferences) => {
    return axios.patch(`/host-services/notification-preferences/${urlParams.notifySettingsId}`, {...params})
}
