import {FC, ReactNode} from 'react'
import {StyledStatsWidgetWrapper} from './style.ts'
import {DefaultNamespace} from 'i18next'

export interface StatsWidgetProps {
    labelText: string | DefaultNamespace
    data?: string | ReactNode
    icon?: ReactNode
    subtext?: string | DefaultNamespace
    description?: string | DefaultNamespace
}

export const StatsWidget: FC<StatsWidgetProps> = ({labelText, icon, data, subtext, description}) => (
    <StyledStatsWidgetWrapper>
        {icon}
        <h4>
            {labelText} {data} <br /> {subtext}
        </h4>
        {description && <small>{description}</small>}
    </StyledStatsWidgetWrapper>
)

StatsWidget.displayName = 'StatsWidget'
