import {z} from 'zod'

export type UpdateReviewAction = 'add' | 'not_add'
export type ConfirmedImageAction = 'accepted' | 'removed'

/**
 * Entity schema and Types
 */
export const MatterportUrlParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string(),
    quoteId: z.coerce.number().int(),
    taskMatterportId: z.coerce.number().int()
})
export type MatterportUrlParams = z.infer<typeof MatterportUrlParams>

export const AccessibilityImage = z.object({
    id: z.coerce.number(),
    name: z.string().min(1),
    checked: z.coerce.boolean(),
    url: z.string().url(),
    admin_action: z.enum(['to_accept', 'to_remove']),
    old: z.coerce.boolean(),
    entity_bedroom_id: z.coerce.number(),
    amenity_id: z.coerce.number()
})
export type AccessibilityImage = z.infer<typeof AccessibilityImage>

export const EntityBedroom = z.object({
    id: z.coerce.number(),
    room_id: z.coerce.number()
})
export type EntityBedroom = z.infer<typeof EntityBedroom>

export type ExtendedAccessibilityImage = AccessibilityImage & {
    accepted: boolean
    removed: boolean
    toCheck: boolean
    room_id?: number
}

export type Amenity = {
    id: number
    name: string
    description: string
    internal_key: string
    room: string
    room_id: number
    accessibility_images: ExtendedAccessibilityImage[]
}

export type Room = {
    id: number
    name: string
    amenities: Amenity[]
}

export type ReviewList = Record<string, ExtendedAccessibilityImage>

export type ConfirmedImage = {
    id: string
    action: ConfirmedImageAction
    old: boolean
}

export type BedroomMap = Record<number, number>

export type HostApprovalImagesFormatted = Omit<AccessibilityImage, 'admin_action' | 'entity_bedroom_id'> & {
    room_id: number
    toCheck: boolean
    removed: boolean
    accepted: boolean
}
