import {ReactElement} from 'react'
import {z} from 'zod'
import {TaskMedia, TaskResource} from '@/features/service/types.ts'

export const HttpGetPhotoServiceRequestsParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string()
    }),
    params: z.object({
        media_type: z.enum(['photo']),
        search: z
            .string()
            .nullish()
            .transform(x => x ?? undefined)
    })
})
export type HttpGetPhotoServiceRequestsParams = z.infer<typeof HttpGetPhotoServiceRequestsParams>

export const RequestedPhotoService = z.object({
    accuracy: z.coerce.number().nullable(),
    airbnb_link: z.string().url().nullable(),
    available: z.boolean(),
    bedrooms: z.number(),
    city: z.string().optional(),
    country_code: z.string(),
    entity_type: z.object({
        id: z.number(),
        name: z.string()
    }),
    geo: z.string(),
    id: z.string(),
    image_url: z.string().nullable(),
    is_luxe: z.boolean(),
    listing_photos_count: z.number(),
    market_closed: z.boolean(),
    name: z.string(),
    photo_service_count: z.number(),
    property_type_category: z.string().nullable(),
    quote_price: z.number(),
    room_type_category: z.string().nullable(),
    state: z.string().nullish(),
    task_category: TaskResource,
    task_media: TaskMedia,
    task_service: TaskResource
})
export type RequestedPhotoService = z.infer<typeof RequestedPhotoService>

export const HttpGetPhotoServiceRequestsResponse = z.array(RequestedPhotoService)
export type HttpGetPhotoServiceRequestsResponse = z.infer<typeof HttpGetPhotoServiceRequestsResponse>

export const HttpAcceptRequestedServiceParams = z.object({
    hostCode: z.coerce.number(),
    hostPassword: z.string(),
    externalId: z.string()
})
export type HttpAcceptRequestedServiceParams = z.infer<typeof HttpAcceptRequestedServiceParams>

export type RequestValueLimit = {
    icon: ReactElement
    label: string
    limit: number
    count: number
    accuracy?: number
}

export type RequestValueLimits = {
    underLimits: RequestValueLimit
    betweenLimits: RequestValueLimit
    overLimits: RequestValueLimit
}
