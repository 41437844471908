import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`

export const StyledTrailerExampleVideoBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        height: 100%;
    `
)

export const StyledSlidingModalHeader = styled(SlidingModalHeader)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
    `
)
