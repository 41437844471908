import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Skeleton from '@components/ui/skeleton/Skeleton.tsx'
import {
    StyledRequestValuesText,
    StyledFooterItem,
    StyledPhotoServiceCard,
    StyledPhotoServiceCardBody,
    StyledPhotoServiceCardFooter,
    StyledPhotoServiceCardHeader
} from '../photo-service-card/style'

export const PhotoServiceContentLoaderCard = () => {
    return (
        <StyledPhotoServiceCard direction="column" width={'100%'}>
            <StyledPhotoServiceCardHeader>
                <Skeleton height={169} />
            </StyledPhotoServiceCardHeader>
            <StyledPhotoServiceCardBody direction="column" gap={4} width={'100%'}>
                <Flexbox direction="column" gap={1}>
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                </Flexbox>
                <Flexbox align="center" gap={2}>
                    <StyledRequestValuesText>
                        <Skeleton width={170} height={20} />
                    </StyledRequestValuesText>
                </Flexbox>
                <Flexbox align="center" gap={2}>
                    <StyledRequestValuesText>
                        <Skeleton width={170} height={20} />
                    </StyledRequestValuesText>
                </Flexbox>
            </StyledPhotoServiceCardBody>
            <StyledPhotoServiceCardFooter justify="space-between" align="center" width={'100%'}>
                <StyledFooterItem direction="column">
                    <Flexbox align="center" gap={1}>
                        <h4>
                            <Skeleton width={100} height={20} />
                        </h4>
                    </Flexbox>

                    <Skeleton width={170} height={20} />
                </StyledFooterItem>
                <Skeleton width={80} height={40} />
            </StyledPhotoServiceCardFooter>
        </StyledPhotoServiceCard>
    )
}

PhotoServiceContentLoaderCard.displayName = 'PhotoServiceContentLoaderCard'
