import Skeleton from '@components/ui/skeleton/Skeleton.tsx'
import {
    StyledServicesSectionBody,
    StyledServicesSectionHeader,
    StyledServicesSectionWrapper
} from '@/features/promotional-services/components/promo-services-section/style.ts'
import Container from '@components/ui/container/Container.tsx'

export const PromoSectionLoader = () => {
    return (
        <Container fullHeight={false}>
            <StyledServicesSectionWrapper>
                <StyledServicesSectionHeader>
                    <Skeleton height={30} width={200} />
                    <Skeleton height={20} />
                </StyledServicesSectionHeader>
                <StyledServicesSectionBody>
                    <Skeleton height={213} />
                    <Skeleton height={213} />
                </StyledServicesSectionBody>
            </StyledServicesSectionWrapper>
            <StyledServicesSectionWrapper>
                <StyledServicesSectionHeader>
                    <Skeleton height={30} width={200} />
                    <Skeleton height={20} />
                </StyledServicesSectionHeader>
                <StyledServicesSectionBody>
                    <Skeleton height={213} />
                    <Skeleton height={213} />
                </StyledServicesSectionBody>
            </StyledServicesSectionWrapper>
        </Container>
    )
}

PromoSectionLoader.displayName = 'PromoSectionLoader'
