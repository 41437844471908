import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {z} from 'zod'

import {
    httpGetHostExperienceProposals,
    HttpGetHostExperienceProposalsOptions
} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {HostExperienceProposal} from '@/features/experiences-host-video/types.ts'

export const useHostExperienceProposals = (
    params: HttpGetHostExperienceProposalsOptions,
    options?: Omit<UseQueryOptions<HostExperienceProposal[]>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.HOST_EXPERIENCE_PROPOSALS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetHostExperienceProposals(params),
                onZodError: captureException,
                responseShape: z.array(HostExperienceProposal)
            })
    })
}
