import {httpAcceptPortraitShoot} from '@/features/service-acceptance-flow/services/serviceAccept.http.ts'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {AcceptPortraitShootParams} from '@/features/service-acceptance-flow/types.ts'

type useOtpValidationProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useAcceptPortraitShoot = (options?: useOtpValidationProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_PORTRAIT],
        mutationFn: (params: AcceptPortraitShootParams) => httpAcceptPortraitShoot(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
            options?.onSuccess?.()
        }
    })
}
