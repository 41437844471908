import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {
    httpGetHostApprovalRequestDetail,
    HttpGetHostApprovalRequestDetailParams
} from '@/features/host-approval/services/hostApproval.http.ts'
import {HttpHostApprovalRequestDetailResponse} from '@/features/host-approval/types.ts'

export const useApprovalRequestDetails = (
    params: HttpGetHostApprovalRequestDetailParams,
    options?: Omit<UseQueryOptions<HttpHostApprovalRequestDetailResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.APPROVAL_REQUEST_DETAILS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetHostApprovalRequestDetail(params),
                onZodError: captureException,
                responseShape: HttpHostApprovalRequestDetailResponse
            })
    })
}
