import styled, {css, DefaultTheme} from 'styled-components'

type StyledBtnProps = {
    fullWidth?: boolean
    iconOnly?: boolean
    size: 'sm' | 'md' | 'lg' | 'xl'
    variant: 'primary' | 'primaryDanger' | 'secondary' | 'tertiary' | 'ghost'
}

export const StyledButton = styled.button<StyledBtnProps>`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        word-break: break-word;
        & svg {
            width: 20px;
        }
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, fullWidth, iconOnly, theme}) => SizeStyles({theme, iconOnly, fullWidth})[size]};
`

const ColorStyles = ({palette, shadows, spacing}: DefaultTheme) => ({
    primary: css`
        color: ${palette.neutral.white};
        background: ${palette.primary['600']};
        border: 1px solid ${palette.primary['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.primary['700']};
            border: 1px solid ${palette.primary['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.primary['200']};
            border: 1px solid ${palette.primary['200']};
            cursor: not-allowed;
        }
    `,
    primaryDanger: css`
        color: ${palette.neutral.white};
        background: ${palette.danger['600']};
        border: 1px solid ${palette.danger['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.danger['700']};
            border: 1px solid ${palette.danger['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.danger['200']};
            border: 1px solid ${palette.danger['200']};
            cursor: not-allowed;
        }
    `,
    secondary: css`
        background: ${palette.neutral.white};
        color: ${palette.neutral['700']};
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }

        &:disabled {
            background: ${palette.neutral[200]};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    ghost: css`
        background: transparent;
        color: ${palette.neutral['700']};
        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    tertiary: css`
        background: transparent;
        color: ${palette.neutral['900']};
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: ${spacing / 2}px;

        &:not(:disabled):hover {
            background: transparent;
        }
        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `
})

type SizesProps = {
    fullWidth?: boolean
    iconOnly?: boolean
    theme: DefaultTheme
}

const SizeStyles = ({theme: {spacing, typography}, fullWidth, iconOnly}: SizesProps) => ({
    /*the Y padding must be ((min-height - line height) / 2)*/
    sm: css`
        min-height: 36px;
        width: ${iconOnly ? '36px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 2}px ${spacing * 3.5}px;
        `};
        ${typography.textSm}
    `,
    md: css`
        min-height: 40px;
        width: ${iconOnly ? '40px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4}px;
        `};
        ${typography.textSm}
    `,
    lg: css`
        min-height: 44px;
        width: ${iconOnly ? '44px' : fullWidth ? '100%' : 'auto'};
        ${iconOnly &&
        css`
            width: 44px;
        `}
        ${!iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        `};
        ${typography.textMd}
    `,
    xl: css`
        min-height: 60px;
        width: ${iconOnly ? '60px' : fullWidth ? '100%' : 'auto'};
        ${!iconOnly &&
        css`
            padding: ${spacing * 4}px ${spacing * 7}px;
        `};
        ${typography.textLg}
    `
})
