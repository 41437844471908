import {useTranslation} from 'react-i18next'
import {StatusDescriptionParagraphsWrapper} from '../status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper'
import {StatusDescriptionWrapper} from '../status-description-wrapper/StatusDescriptionWrapper'
import {useNavigate} from 'react-router-dom'
import {useTheme} from 'styled-components'
import {useState} from 'react'
import {useAuthStore} from '@/store/AuthStore'
import {routes} from '@/utilities/constants'
import {StatusDescriptionIconWrapper} from '../status-description-icon/StatusDescriptionIconWrapper'
import {StatusDescriptionTitle} from '../status-description-title/StatusDescriptionTitle'
import Button from '@components/ui/button-legacy/Button'
import {StyledSSOModal} from '@/features/in-progress-services/components/in-progress-section/style'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget'
import {Camera01Icon, XCloseIcon} from '@/components/ui/icon'

export const HomeAdminCanceledStatusDescription = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const [showSSOModal, setShowSSOModal] = useState(false)
    const accessToken = useAuthStore(state => state.accessToken)

    const requestPhotoshoot = () => {
        if (!accessToken) {
            setShowSSOModal(true)
        }

        return navigate(routes.PHOTOSHOOT_REQUEST.path)
    }

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <XCloseIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>{t('service_details:descriptions:home:canceled_title')}</StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                {t('service_details:descriptions:home:admin_canceled_paragraph1')}
            </StatusDescriptionParagraphsWrapper>
            <StatusDescriptionParagraphsWrapper>
                {t('service_details:descriptions:home:admin_canceled_paragraph2')}
            </StatusDescriptionParagraphsWrapper>
            <Button onClick={requestPhotoshoot}>{t('commons:request_a_photoshoot')}</Button>
            {showSSOModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOModal(false)}>
                    <AirbnbSSOWidget
                        serviceIcon={<Camera01Icon size={40} fill={theme.palette.neutral.black} />}
                        title={t('photoshoot_landing:sso:title')}
                        subtitle={t('photoshoot_landing:sso:subtitle')}
                        disclaimer={t('photoshoot_landing:sso:disclaimer')}
                    />
                </StyledSSOModal>
            )}
        </StatusDescriptionWrapper>
    )
}

HomeAdminCanceledStatusDescription.displayName = 'HomeAdminCanceledStatusDescription'
