import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import TextArea from '@/components/commons/textarea/TextArea'

export const StyledBinaryChoiceItem = styled(Flexbox)<{$isActive: boolean}>(
    ({theme: {spacing, palette, typography}, $isActive}) => css`
        padding: ${spacing * 3}px;
        border: 1px solid ${palette.neutral[300]};
        ${typography.textMd}
        border-radius: 12px;
        color: ${palette.neutral[700]};
        font-weight: 500;
        cursor: pointer;
        ${$isActive &&
        css`
            border-color: ${palette.neutral[900]};
        `}
    `
)

export const StyledTextArea = styled(TextArea)`
    width: 100%;
`
