import styled, {css} from 'styled-components'
import {ModalBody, ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'

export const StyledBody = styled(ModalBody)(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 2}px;
        padding-top: 0;
    `
)

export const StyledTitle = styled(ModalTitle)(
    ({theme: {typography}}) => css`
        ${typography.textMd};
    `
)
