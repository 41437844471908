import {create} from 'zustand'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {DeclineStep} from '@/features/service-decline/types.ts'

type DeclineServiceStoreState = {
    modalVisible: boolean
    step: DeclineStep
    service: ServiceProposal | null
}

type DeclineServiceStoreActions = {
    declineServiceModalToggle: (service?: ServiceProposal) => void
    changeStep: (nextStep: DeclineStep) => void
}

export const useDeclineServiceStore = create<DeclineServiceStoreState & DeclineServiceStoreActions>()(set => ({
    modalVisible: false,
    step: 'warning',
    service: null,
    changeStep: nextStep => set({step: nextStep}),
    declineServiceModalToggle: service =>
        set(state => {
            if (state.modalVisible) {
                return {modalVisible: false, service: null, step: 'warning'}
            } else {
                return {modalVisible: true, service: service}
            }
        })
}))
