import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {ReassignTask} from '@/features/chatbot/components/reassign-task/ReassignTask.tsx'
import {SendReminder} from '@/features/chatbot/components/send-reminder/SendReminder.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'

interface Hp21Params {
    isAutoCanceledOrSoftCanceled: boolean
    isPendingShootDate: boolean
    photographerName?: string
    photographerContact?: string
}
export const hp21 = ({
    isAutoCanceledOrSoftCanceled,
    isPendingShootDate,
    photographerName,
    photographerContact
}: Hp21Params): ChatbotStep[] => [
    {
        id: 'HP21',
        options: [
            ...(isPendingShootDate
                ? [
                      {
                          value: 'HP21.1',
                          label: i18next.t('chatbot:HP21:HP21.1_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP21',
                                  source: 'User',
                                  text: "I don't know how to get in touch with my assigned photographer"
                              })
                              return 'HP21.1'
                          }
                      }
                  ]
                : []),
            ...(isPendingShootDate
                ? [
                      {
                          value: 'HP21.2',
                          label: i18next.t('chatbot:HP21:HP21.2_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP21',
                                  source: 'User',
                                  text: 'My assigned photographer is unresponsive. What do I do?'
                              })
                              return 'HP21.2'
                          }
                      }
                  ]
                : []),
            ...(isAutoCanceledOrSoftCanceled
                ? [
                      {
                          value: 'HP21.3',
                          label: i18next.t('chatbot:HP21:HP21.3_label'),
                          trigger: () => {
                              useChatbotStore.getState().addStepToHistory({
                                  id: 'HP21',
                                  source: 'User',
                                  text: 'My assigned photographer canceled the photoshoot. What do I do?'
                              })
                              return 'HP21.3'
                          }
                      }
                  ]
                : []),
            {
                value: 'HP21.4',
                label: i18next.t('chatbot:HP21:HP21.4_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21',
                        source: 'User',
                        text: 'Can the photographer shoot one of my other listings?'
                    })
                    return 'HP21.4'
                }
            },
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21',
                        source: 'User',
                        text: 'Other'
                    })
                    return 'start'
                }
            }
        ]
    },
    {
        id: 'HP21.1',
        message: i18next.t('chatbot:HP21:HP21.1_message', {
            photographerName,
            photographerContact
        }),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.1',
                source: 'Chatbot',
                text: `Your assigned photographer is ${photographerName}. You can reach them out at ${photographerContact} and schedule your photoshoot date.`
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP21.2',
        message: i18next.t('chatbot:HP21:HP21.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.2',
                source: 'Chatbot',
                text: "We're sorry to hear the photographer has been unresponsive. Would you like us to send a reminder to your assigned photographer? They should contact you within the next 48 hours."
            })
            return 'HP21.2.1'
        }
    },
    {
        id: 'HP21.2.1',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.2.1',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP21.2.1.1_send'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.2.1',
                        source: 'User',
                        text: 'No'
                    })
                    return 'HP21.2.1.2'
                }
            }
        ]
    },
    {
        id: 'HP21.2.1.1_send',
        component: <SendReminder flowSource="HP21.2.1.1_send" />,
        trigger: () => 'HP21.2.1.1',
        asMessage: true,
        replace: true
    },
    {
        id: 'HP21.2.1.1',
        message: i18next.t('chatbot:HP21:HP21.2.1.1_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.2.1.1',
                source: 'Chatbot',
                text: "Awesome, we just emailed the photographer. Please message us if you don't hear from them within the next 2 days."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP21.2.1.2',
        message: i18next.t('chatbot:HP21:HP21.2.1.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.2.1.2',
                source: 'Chatbot',
                text: 'Would you like to be matched with another local photographer?'
            })
            return 'HP21.2.1.3'
        }
    },
    {
        id: 'HP21.2.1.3',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.2.1.3',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP21.2.1.4_send'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.2.1.3',
                        source: 'User',
                        text: 'No'
                    })
                    return 'oq_message'
                }
            } // Final trigger of the flow's branch
        ]
    },
    {
        id: 'HP21.2.1.4_send',
        component: <ReassignTask />,
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.2.1.4_send',
                source: 'Chatbot',
                text: 'Thank you! Our team will get back to you via email in the next 48 hours.' //copy of AssignNew return
            })
            return 'HP21.2.1.4'
        },
        asMessage: true,
        replace: true
    },
    {
        id: 'HP21.2.1.4',
        message: i18next.t('chatbot:HP21:HP21.2.1.4_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.2.1.4',
                source: 'Chatbot',
                text: "Sounds good! We're looking for a new photographer and you should hear from them soon. Please check your emails as we will notify you once we have found someone."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP21.3',
        message: i18next.t('chatbot:HP21:HP21.3_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.3',
                source: 'Chatbot',
                text: "We're sincerely sorry for this cancellation and the inconvenience this has caused. We are already looking to connect you with another local, freelance photographer. Please check your emails as we will notify you once another photographer has accepted your request."
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP21.4',
        message: i18next.t('chatbot:HP21:HP21.4_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP21.4',
                source: 'Chatbot',
                text: 'Photographers will only photograph the listing for which you have signed a price quote. Would you like to request pro photography for another listing?'
            })
            return 'HP21.4.1'
        }
    },
    {
        id: 'HP21.4.1',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.4.1',
                        source: 'User',
                        text: 'Yes'
                    })
                    return 'HP0'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP21.4.1',
                        source: 'User',
                        text: 'No'
                    })
                    return 'oq_message'
                }
            } // Final trigger of the flow's branch
        ]
    }
]
