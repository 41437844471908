import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledCoAlarmRequestFormWrapper} from '@/features/co-alarm/components/co-alarm-send-request-form-wrapper/style'
import {StyledCoAlarmRequestFormSkeleton} from '@/features/co-alarm/components/co-alarm-request-form-loader/style'

export const CoAlarmRequestFormLoader = () => {
    return (
        <StyledCoAlarmRequestFormWrapper>
            <Flexbox direction="column" gap={6}>
                <StyledCoAlarmRequestFormSkeleton height={30} $maxWidth={500} />
                <StyledCoAlarmRequestFormSkeleton height={68} $maxWidth={500} />
                <StyledCoAlarmRequestFormSkeleton height={30} $maxWidth={300} />
                <Flexbox gap={2}>
                    <StyledCoAlarmRequestFormSkeleton height={30} />
                    <StyledCoAlarmRequestFormSkeleton height={30} />
                </Flexbox>
                <StyledCoAlarmRequestFormSkeleton height={30} />
                <StyledCoAlarmRequestFormSkeleton height={30} />
                <StyledCoAlarmRequestFormSkeleton height={30} />
                <StyledCoAlarmRequestFormSkeleton height={30} />
            </Flexbox>
        </StyledCoAlarmRequestFormWrapper>
    )
}
