/**
 * Define ticket's infos by reason
 * @param link
 * @param reason
 * @param steps
 * @returns {{subject: string, message, flow: string}|{subject: string, message: string, flow: string}}
 */
import {ChatbotReason, ChatbotStep, HistoryStep} from '@/features/chatbot/types.ts'

interface DefineTicketInfoParams {
    reason: ChatbotReason
    link: string
    steps: ChatbotStep[]
}

export const defineTicketInfoByReason = ({reason, link, steps}: DefineTicketInfoParams) => {
    switch (reason) {
        case 'new_quote':
            return {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                message: `I want a price quote for another listing: "${steps['HP0.1']?.value?.Listing_Title__c}". | Link: ${steps['HP0.1']?.value?.Listing_URL__c}`,
                subject: '[Chatbot] Support request from the ELEVATE-CHATBOT - Manual Quote',
                flow: 'I WANT A PRICE QUOTE FOR A DIFFERENT LISTING ID'
            }

        case 'new_quote_missing_title':
            return {
                message: `I want a price quote for another listing: "${steps.find(
                    (step: ChatbotStep) => step.id === 'HP0_2_input'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value}".`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Manual Quote`,
                flow: 'I WANT A PRICE QUOTE FOR A DIFFERENT LISTING ID'
            }
        case 'wrong_bedrooms':
            return {
                message: `The number of bedrooms in my quote is incorrect. My listing has ${steps.find(
                    (step: ChatbotStep) => step.id === 'HP4_1_input'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value}. | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Incorrect BR number`,
                flow: 'THE NUMBER OF BEDROOMS IN MY QUOTE IS WRONG. WHAT CAN I DO?'
            }
        case 'pp_upload_issue':
            return {
                message: `My retouched images have not been uploaded yet on my listing page. SHOOT STATUS: UPLOAD ISSUE | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Upload issue`,
                flow: 'MY RETOUCHED IMAGES HAVE NOT BEEN UPLOADED YET ON MY AIRBNB LISTING PAGE. WHAT IS THE STATUS?'
            }
        case 'pp_late':
            return {
                message: `My retouched images have not been uploaded yet on my listing page. SHOOT STATUS IN PRODUCTION: LATE | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Upload status`,
                flow: 'MY RETOUCHED IMAGES HAVE NOT BEEN UPLOADED YET ON MY AIRBNB LISTING PAGE. WHAT IS THE STATUS?'
            }
        case 'pp_unknown':
            return {
                message: `My retouched images have not been uploaded yet on my listing page. SHOOT STATUS: UNKNOWN - TO BE CHECKED | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Upload status`,
                flow: 'MY RETOUCHED IMAGES HAVE NOT BEEN UPLOADED YET ON MY AIRBNB LISTING PAGE. WHAT IS THE STATUS?'
            }
        case 'wrong_listing':
            return {
                message: `My images have been uploaded to the wrong listing page. On which listing page the images have been wrongly uploaded? ${steps.find(
                    (step: ChatbotStep) => step.id === 'HP9_1_select'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value}. | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Wrong Upload`,
                flow: 'MY IMAGES HAVE BEEN UPLOADED TO THE WRONG LISTING PAGE'
            }
        case 'wrong_listing_missing_title':
            return {
                message: `My images have been uploaded to the wrong listing page. On which listing page the images have been wrongly uploaded? ${steps.find(
                    (step: ChatbotStep) => step.id === 'HP9_not_found'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value}. | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Wrong Upload`,
                flow: 'MY IMAGES HAVE BEEN UPLOADED TO THE WRONG LISTING PAGE'
            }
        case 'missing_images':
            return {
                message: `There are missing images or details in the uploaded photos. Spaces or details not featured in the images uploaded. ${steps.find(
                    (step: ChatbotStep) => step.id === 'HP10_1'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value} | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Revision request`,
                flow: 'THERE ARE MISSING IMAGES OR DETAILS IN THE UPLOADED PHOTOS'
            }
        case 'photoshoot_cancelation':
            return {
                message: `My photoshoot has been canceled but I am still interested. ${steps.find(
                    (step: ChatbotStep) => step.id === 'HP31_input'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                )?.value} | Link: ${link}`,
                subject: `[Chatbot] Support request from the ELEVATE-CHATBOT - Photoshoot Cancelation`,
                flow: 'WHY HAS MY  PHOTOSHOOT REQUEST BEEN CANCELED? I STILL WANT A PRO PHOTOSHOOT'
            }
        default:
            //'open_ticket' case
            return {
                message: `${steps.find((step: ChatbotStep) => step.id === 'HP11_message')?.message} | Link: ${link}`,
                subject: 'Support request from the ELEVATE-CHATBOT [Open Ticket]',
                flow: 'My topic is not listed on the chatbot'
            }
    }
}

/**
 * Save highlights of users' journey in a variable
 * @returns {string}
 * @param chatHistory
 */

export const chatHighlights = (chatHistory: HistoryStep[]) => {
    const highlights: string[] = []

    chatHistory.forEach(({id}) => {
        switch (id) {
            case 'HP0_CONFIRMATION': // Ticket
                highlights.push('New quote request')
                break
            case 'HP4.1.3': // Ticket
                highlights.push('Wrong bedrooms number')
                break
            case 'HP8_2_send': // Ticket
                highlights.push('Status update: Upload issue')
                break
            case 'HP8_3_send': // Ticket
                highlights.push('Status update: Late')
                break
            case 'HP8_4_send': // Ticket
                highlights.push('Status update: Unknown')
                break
            case 'HP9_sent': // Ticket
                highlights.push('Upload to wrong listing id')
                break
            case 'HP10.3': // Ticket
                highlights.push('Images missing')
                break
            case 'HP11_submit': // Ticket
                highlights.push('Generic ticket')
                break
            case 'HP21.2.1.1':
            case 'HP30_reminder_sent':
                highlights.push('Unresponsive PG: reminder sent')
                break
            case 'HP21.2.1.4':
            case 'HP30_match_sent':
                highlights.push('Reassign shoot')
                break
            case 'cancel_shoot':
                highlights.push('Shoot canceled')
                break
            case 'HP31_ticket': // Ticket
                highlights.push('Unwanted cancelation')
                break
        }
    })

    return highlights.join(' --- ')
}

export const VeryPoorIcon = ({...rest}) => {
    return (
        <svg viewBox="0 0 48 48" width="40" height="40" fill="none" {...rest}>
            <path d="M24 27.15q-3.35 0-6.075 1.875T13.9 34h20.2q-1.25-3.15-4-5T24 27.15Zm-9.15-3.6 2.5-2.25 2.25 2.25 1.55-1.8-2.25-2.25 2.25-2.25-1.55-1.8-2.25 2.25-2.5-2.25-1.55 1.8 2.25 2.25-2.25 2.25Zm13.6 0 2.2-2.25 2.55 2.25 1.55-1.8-2.25-2.25 2.25-2.25-1.55-1.8-2.55 2.25-2.2-2.25-1.55 1.8 2.2 2.25-2.2 2.25ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z" />
        </svg>
    )
}

export const PoorIcon = ({...rest}) => {
    return (
        <svg viewBox="0 0 48 48" width="40" height="40" {...rest}>
            <path d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm7.3 5.8q-3.35 0-6.075 1.875T13.9 34h2.65q1.1-2.1 3.1-3.25t4.4-1.15q2.35 0 4.325 1.175T31.5 34h2.6q-1.25-3.15-4-5T24 27.15ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z" />
        </svg>
    )
}

export const AverageIcon = ({...rest}) => {
    return (
        <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
            <path d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm1 9.7h12.65V28.6H17.7ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z" />
        </svg>
    )
}

export const GoodIcon = ({...rest}) => {
    return (
        <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
            <path d="M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm7.3 13.6q3.3 0 6.075-1.775Q32.85 31.4 34.1 28.35h-2.6q-1.15 2-3.15 3.075-2 1.075-4.3 1.075-2.35 0-4.375-1.05t-3.125-3.1H13.9q1.3 3.05 4.05 4.825Q20.7 34.95 24 34.95ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z" />
        </svg>
    )
}

export const ExcellentIcon = ({...rest}) => {
    return (
        <svg viewBox="0 0 48 48" height="40" width="40" {...rest}>
            <path d="M24 34.95q3.3 0 6.075-1.775Q32.85 31.4 34.1 28.35H13.9q1.3 3.05 4.05 4.825Q20.7 34.95 24 34.95Zm-8.9-13.6 2.25-2.25 2.25 2.25 1.8-1.8-4.05-4.05-4.05 4.05Zm13.35 0 2.25-2.25 2.25 2.25 1.8-1.8-4.05-4.05-4.05 4.05ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z" />
        </svg>
    )
}
