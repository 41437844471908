import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {Host} from '@/features/service/types.ts'

/**
 * httpGetServicesProposals
 * Here we will fetch all the services proposals data for the dashboard cards
 */
export const HttpServicesProposalsResponse = z.object({
    ...Host.shape,
    quotes: z.array(ServiceProposal)
})
export type HttpServicesProposalsResponse = z.infer<typeof HttpServicesProposalsResponse>

export const httpGetServicesProposals = (urlParams: DashboardURLParams) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/promotional`)
}
