import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledServiceWarning = styled(Flexbox)`
    ${({theme: {palette, spacing, typography}}) => css`
        background: ${palette.warning['100']};
        border-radius: 8px;
        border: 1px solid ${palette.warning['200']};
        margin-top: ${spacing * 6}px;
        padding: ${spacing * 4}px;
        & > h5 {
            color: ${palette.warning['950']};
            ${typography.textMd};
            font-weight: 500;
        }

        & > p {
            color: ${palette.warning['900']};
            ${typography.textSm};
        }
    `}
`
