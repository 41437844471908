import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from 'react-i18next'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import {formatLocaleDate} from '@utilities/helpers.ts'

export const ExperienceVideoAssignedNotReadyStatusDescription: FC<{
    reminderAt?: Assignment['reminder_at']
    assignedFreelancer: string | undefined
}> = ({assignedFreelancer, reminderAt}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <AlertCircleIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:assigned_not_ready_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:assigned_not_ready_paragraph1', {
                        reminderDate: reminderAt ? formatLocaleDate(reminderAt) : '-'
                    })}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:assigned_not_ready_paragraph2', {
                        freelancer: assignedFreelancer
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

ExperienceVideoAssignedNotReadyStatusDescription.displayName = 'ExperienceVideoAssignedNotReadyStatusDescription'
