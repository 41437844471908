import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCoAlarmListingsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        scroll-margin-top: ${spacing * 8}px;
        padding: ${spacing * 6}px 0 ${spacing * 6}px;
    `}
`

export const StyledCoAlarmListingsHeader = styled(Flexbox)`
    ${({theme: {typography, mediaQueries, palette, spacing}}) => css`
        & > h4 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        ${mediaQueries.s} {
            flex-direction: column;
            align-items: flex-start;
            gap: ${spacing * 4}px;

            & > div {
                width: 100%;
            }
        }
    `}
`

export const StyledCoAlarmListingsSearchLabel = styled.label(
    ({theme: {typography, palette, mediaQueries}}) => css`
        display: none;

        ${mediaQueries.l} {
            display: block;
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledCoAlarmListingsSearchWrapper = styled(Flexbox)<{$isHidden?: boolean}>(
    ({theme: {mediaQueries}, $isHidden}) => css`
        ${$isHidden &&
        css`
            visibility: hidden;
        `};
        ${mediaQueries.l} {
            width: 100%;
            & > div {
                width: 100%;
            }
        }
    `
)
