import styled, {css} from 'styled-components'

export const FooterWrapper = styled.div`
    ${({theme: {palette, spacing, mediaQueries}}) => css`
        background: ${palette.neutral['50']};
        border-top: 1px solid ${palette.neutral['300']};
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 4}px;

        ${mediaQueries.m} {
            height: auto;
            padding: ${spacing * 4}px;
        }

        & a,
        button {
            background: ${palette.neutral['50']} !important;
            color: ${palette.neutral['500']} !important;
            font-size: 12px !important;
            border: none !important;
            text-decoration: underline !important;
            cursor: pointer;
            padding: 0 !important;
            display: flex;
            align-items: center;
            gap: ${spacing}px;
            &:hover {
                color: ${palette.neutral['900']};
            }
        }
    `}
`
