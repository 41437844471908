import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {useAcceptPortraitShoot} from '@/features/service-acceptance-flow/services/queries/useAcceptPortraitShoot.ts'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import toast from 'react-hot-toast'
import {AcceptanceModalHeader} from '@/features/service-acceptance-flow/components/AcceptanceModalHeader.tsx'
import {AcceptanceModalStepsIndicator} from '@/features/service-acceptance-flow/components/AcceptanceModalStepsIndicator.tsx'
import {ProfilePhotoProposal} from '@components/commons/profile-photo-proposal/ProfilePhotoProposal.tsx'
import {XCloseIcon} from '@components/ui/icon'
import {StyledAcceptStepHeaderCloseButton} from '@/features/service-acceptance-flow/components/style.ts'

interface ServiceConfirmEmailStepProps {
    onPortraitAcceptedCb: () => void
    portraitEnabled: boolean
}

export const ServiceAcceptPortraitStep: FC<ServiceConfirmEmailStepProps> = ({
    onPortraitAcceptedCb,
    portraitEnabled
}) => {
    const {t} = useTranslation()
    //host params store
    const hostCode = useHostParamsStore(state => state.hostCode)
    const hostPassword = useHostParamsStore(state => state.hostPassword)
    //accept store
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const service = useServiceAcceptStore(state => state.service)
    //accept portrait mutation
    const {mutate: acceptPortrait, isPending} = useAcceptPortraitShoot({
        onSuccess: () => onPortraitAcceptedCb(),
        onError: error => errorHandler(error)
    })

    const onAcceptPortrait = (hostPreference: boolean) => {
        if (service && hostCode && hostPassword) {
            acceptPortrait({
                hostPreference: hostPreference,
                hostCode: hostCode,
                hostPassword: hostPassword,
                quoteId: service.id
            })
        } else {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <AcceptanceModalHeader
                actionIcon={
                    <StyledAcceptStepHeaderCloseButton
                        disabled={isPending}
                        size="lg"
                        iconOnly
                        variant="ghost"
                        onClick={() => acceptServiceModalToggle()}
                    >
                        <XCloseIcon />
                    </StyledAcceptStepHeaderCloseButton>
                }
                portraitEnabled={portraitEnabled}
                title={t('profile_photo:modal_title')}
                subtitle={t('service_accept:common:step_indicator', {current: 2, total: 2})}
            />
            <AcceptanceModalStepsIndicator portraitEnabled={portraitEnabled} />
            <ProfilePhotoProposal isSubmitting={isPending} onSubmit={onAcceptPortrait} />
        </>
    )
}

ServiceAcceptPortraitStep.displayName = 'ServiceAcceptPortraitStep'
