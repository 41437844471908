import styled, {css, keyframes} from 'styled-components'
import {EXPERIENCES_HOST_VIDEO_CONTENT_MAX_WIDTH} from '@/features/experiences-host-video/components/experiences-host-video-atoms/style.ts'

export const ANIMATION_DURATION = 500
const opacityIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: .4;
    }
`
const opacityOut = keyframes`
    0% {
        opacity: .4;
    }
    100% {
        opacity: 0;
    }
`

export const StyledSlidingModalOverlay = styled.div<{$isModalClosing: boolean}>(
    ({theme: {palette, zIndex}, $isModalClosing}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']};
        opacity: 0.4;
        z-index: ${zIndex.modalOverlay};
        animation: ${$isModalClosing ? opacityOut : opacityIn} ${ANIMATION_DURATION}ms ease;
    `
)

const slideInUp = keyframes`
    0% {
        transform: translateY(100%) translateX(-50%);
    }
    100% {
        transform: translateY(0) translateX(-50%);
    }
`

const slideInDown = keyframes`
    0% {
        transform: translateY(0) translateX(-50%);
    }
    100% {
        transform: translateY(100%) translateX(-50%);
    }
`

export const StyledSlidingModalWrapper = styled.div<{$isModalClosing: boolean}>(
    ({theme: {palette, zIndex, spacing, shadows}, $isModalClosing}) => css`
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(0);
        max-width: ${EXPERIENCES_HOST_VIDEO_CONTENT_MAX_WIDTH}px;
        z-index: ${zIndex.modal};
        border-radius: 12px 12px 0 0;
        background: ${palette.neutral.white};
        width: 100%;
        height: calc(100% - ${spacing * 5}px);
        box-shadow: ${shadows.lg};
        animation: ${$isModalClosing ? slideInDown : slideInUp} ${ANIMATION_DURATION}ms ease;
    `
)

export const StyledSlidingModalHeader = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        font-weight: 500;
        color: ${palette.neutral[900]};
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
