import {HTMLAttributes, ImgHTMLAttributes, ButtonHTMLAttributes} from 'react'
import {
    StyledExperiencesHostVideoBody,
    StyledExperiencesHostVideoFooter,
    StyledExperiencesHostVideoPageLayout,
    StyledExperiencesHostVideoParagraph,
    StyledExperiencesHostVideoTitle,
    StyledIntroImage,
    StyledIntroStepTitle,
    StyledExperiencesHostVideoActionCard,
    StyledExperiencesHostVideoModalBody,
    StyledExperiencesHostVideoReadyShootCard,
    StyledShortExampleButton,
    StyledCheckListCheckBox
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/style.ts'
import {FlexBoxCustomProps, Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {PlayIcon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {useChecklist} from '@/features/experiences-host-video/stores/experiencesHostVideoChecklist.ts'

export const ExperiencesHostVideoPageLayout = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledExperiencesHostVideoPageLayout {...props} />
)

export const ExperiencesHostVideoBody = (props: FlexBoxCustomProps) => (
    <StyledExperiencesHostVideoBody gap={props.gap ?? 8} {...props} />
)

export const ExperiencesHostVideoTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledExperiencesHostVideoTitle {...props} />
)

export const ExperiencesHostVideoParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledExperiencesHostVideoParagraph {...props} />
)

export const ExperiencesHostVideoFooter = (props: FlexBoxCustomProps) => <StyledExperiencesHostVideoFooter {...props} />

export const IntroImage = (props: ImgHTMLAttributes<HTMLImageElement>) => <StyledIntroImage {...props} />

export const IntroStepTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledIntroStepTitle {...props} />

export const ExperiencesHostVideoActionCard = ({
    variant,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {variant?: 'neutral' | 'success' | 'danger'}) => (
    <StyledExperiencesHostVideoActionCard $variant={variant} {...props} />
)

export const ExperiencesHostVideoModalBody = (props: FlexBoxCustomProps) => (
    <StyledExperiencesHostVideoModalBody gap={8} {...props} />
)

export const ExperiencesHostVideoReadyShootCard = ({
    children,
    exampleAction,
    checkBoxId,
    title,
    ...props
}: HTMLAttributes<HTMLDivElement> & {exampleAction?: () => void; title: string; checkBoxId?: string}) => {
    const {t} = useTranslation()
    const addCheckBoxId = useChecklist(store => store.addCheckBoxId)
    const removeCheckBoxId = useChecklist(store => store.removeCheckBoxId)
    const checkBoxIds = useChecklist(store => store.checkBoxIds)
    const onChangeCheckbox = (isChecked: boolean) => {
        if (checkBoxId) {
            if (isChecked) {
                addCheckBoxId(checkBoxId)
            } else {
                removeCheckBoxId(checkBoxId)
            }
        }
    }
    return (
        <StyledExperiencesHostVideoReadyShootCard {...props} gap={2} justify="space-between">
            <div>
                <h4>{title}</h4>
                <Flexbox gap={4} direction="column">
                    {children}

                    {!!exampleAction && (
                        <StyledShortExampleButton gap={1} align="center" onClick={exampleAction}>
                            <PlayIcon width={20} height={20} />
                            {t('experiences_host_video:get_ready_shoot:short_example')}
                        </StyledShortExampleButton>
                    )}
                </Flexbox>
            </div>
            {checkBoxId && (
                <StyledCheckListCheckBox
                    checked={checkBoxIds.includes(checkBoxId)}
                    id={checkBoxId}
                    onChange={e => onChangeCheckbox(e.target.checked)}
                />
            )}
        </StyledExperiencesHostVideoReadyShootCard>
    )
}
