import {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox'
import {HelpCircleIcon} from 'src/components/ui/icon'
import {
    StyledFooterItem,
    StyledPhotoServiceCard,
    StyledPhotoServiceCardBody,
    StyledPhotoServiceCardFooter,
    StyledPhotoServiceCardHeader,
    StyledTooltipContent
} from './style'
import {RequestedPhotoService} from '@/features/service-requests/types.ts'
import {palette} from '@/theme/palette'
import {formatCurrency} from '@/utilities/helpers'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {HOME_IMG_PLACEHOLDER_LG} from '@utilities/constants'
import {RequestValues} from './RequestValues'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {sendQuoteDisplayedMxEvent} from '@utilities/mixpanel/mixpanelEventCenter.ts'

interface PhotoServiceCardProps {
    photoService: RequestedPhotoService
}

export const PhotoServiceCard: FC<PhotoServiceCardProps> = ({photoService}) => {
    const {t} = useTranslation()
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const changeStep = useServiceAcceptStore(state => state.changeStep)
    const setManualRequest = useServiceAcceptStore(state => state.setManualRequest)
    const canRequestService = !photoService.market_closed && photoService.available && !photoService.is_luxe
    const currentUser = useAuthStore(state => state.user)

    const onAcceptManualRequest = () => {
        acceptServiceModalToggle()
        setManualRequest(photoService)
        changeStep('acceptManualRequest')
    }

    useEffect(() => {
        if (canRequestService && currentUser) {
            sendQuoteDisplayedMxEvent({...photoService, external_host_id: currentUser.id})
        }
    }, [canRequestService, currentUser])

    return (
        <>
            <StyledPhotoServiceCard direction="column" width={'100%'} height={'100%'}>
                <StyledPhotoServiceCardHeader>
                    <img src={photoService.image_url ?? HOME_IMG_PLACEHOLDER_LG} alt={photoService.name} />
                </StyledPhotoServiceCardHeader>
                <StyledPhotoServiceCardBody direction="column" gap={4} width={'100%'}>
                    <Flexbox direction="column" gap={1}>
                        <h4>
                            <a href={photoService.airbnb_link ?? undefined} target="_blank">
                                {photoService.name || t('commons:listing_n', {listingId: photoService.id})}
                            </a>
                        </h4>
                        <h5>
                            {photoService.city}, {photoService.state}
                        </h5>
                    </Flexbox>
                    {canRequestService && (
                        <RequestValues
                            photoServiceCount={photoService.photo_service_count}
                            listingPhotosCount={photoService.listing_photos_count}
                            accuracy={photoService.accuracy ?? 0}
                        />
                    )}
                </StyledPhotoServiceCardBody>
                <StyledPhotoServiceCardFooter justify="space-between" align="center" width={'100%'} gap={1}>
                    {canRequestService ? (
                        <>
                            <StyledFooterItem direction="column">
                                <Flexbox align="center" gap={1}>
                                    <h4>{formatCurrency(photoService.quote_price)}</h4>
                                    <Tooltip
                                        content={
                                            <StyledTooltipContent>
                                                <h4>
                                                    {t('photoshoot_requests:photo_request_card:tooltip_title', {
                                                        price: formatCurrency(photoService.quote_price)
                                                    })}
                                                </h4>
                                                <p>
                                                    {t('photoshoot_requests:photo_request_card:tooltip_content', {
                                                        bedrooms: photoService.bedrooms
                                                    })}
                                                </p>
                                            </StyledTooltipContent>
                                        }
                                    >
                                        <Flexbox>
                                            <HelpCircleIcon size={16} fill={palette.light.neutral['400']} />
                                        </Flexbox>
                                    </Tooltip>
                                </Flexbox>
                                {photoService.quote_price !== 0 && (
                                    <p>{t('photoshoot_requests:photo_request_card:payment_method')}</p>
                                )}
                            </StyledFooterItem>
                            <Button variant="primary" onClick={() => onAcceptManualRequest()}>
                                {t('commons:accept')}
                            </Button>
                        </>
                    ) : (
                        <>
                            {photoService.market_closed && (
                                <p>{t('photoshoot_requests:photo_request_card:unavailable_region')}</p>
                            )}

                            {photoService.is_luxe && !photoService.market_closed && (
                                <p>{t('photoshoot_requests:photo_request_card:unavailable_luxe')}</p>
                            )}
                        </>
                    )}
                </StyledPhotoServiceCardFooter>
            </StyledPhotoServiceCard>
        </>
    )
}
