import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetCategoriesAvailability,
    HttpGetCategoriesAvailabilityParams,
    HttpGetCategoriesAvailabilityResponse
} from '../categories.http.ts'
import {useInputSearch} from '@/hooks/useInputSearch.ts'
import {parseAxiosPromise} from '@/utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useCategoriesAvailability = (
    params: HttpGetCategoriesAvailabilityParams,
    options?: Omit<UseQueryOptions<HttpGetCategoriesAvailabilityResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchValue, searchError, onSearch} = useInputSearch()
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.CATEGORIES_AVAILABILITY, params.urlParams.listingId, searchValue],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetCategoriesAvailability(params),
                onZodError: captureException,
                responseShape: HttpGetCategoriesAvailabilityResponse
            })
    })

    return {
        ...query,
        data: query.data ? query.data : [],
        searchValue,
        onSearch,
        searchError
    }
}
