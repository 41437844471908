import styled, {css} from 'styled-components'

export const StyledScheduleTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textXlFixed};
        font-weight: 500;
        color: ${palette.neutral['700']};
    `
)

export const StyledScheduleInputs = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
        & label {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledSupportText = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral['700']};
        & button {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)
