import {create} from 'zustand'
import {CategoryRequestOperation} from '../types'

type CategoriesRequestState = {
    activeRequestsCount: number | null
    modalVisible: boolean
    listingId: string | null
    requestOperation: CategoryRequestOperation | null
    selectedCategoryIds: number[]
}

type CategoriesRequestActions = {
    toggleModalVisibility: (
        requestOperation?: CategoryRequestOperation,
        activeRequestsCount?: number,
        listingId?: string,
        selectedCategoryId?: number
    ) => void
    changeSelectedCategoryIds: (categoryId: number) => void
}

export const useCategoriesRequestStore = create<CategoriesRequestState & CategoriesRequestActions>()(set => ({
    modalVisible: false,
    activeRequestsCount: null,
    requestOperation: null,
    listingId: null,
    selectedCategoryIds: [],
    toggleModalVisibility: (requestOperation, activeRequestsCount, listingId, selectedCategoryId) =>
        set(state => {
            if (state.modalVisible) {
                return {
                    activeRequestsCount: null,
                    modalVisible: false,
                    requestOperation: null,
                    listingId: null,
                    selectedCategoryIds: []
                }
            } else {
                return {
                    activeRequestsCount,
                    modalVisible: true,
                    requestOperation,
                    listingId,
                    selectedCategoryIds: selectedCategoryId ? [selectedCategoryId] : []
                }
            }
        }),
    changeSelectedCategoryIds: categoryId =>
        set(state => {
            return {
                selectedCategoryIds: state.selectedCategoryIds.includes(categoryId)
                    ? state.selectedCategoryIds.filter(id => id !== categoryId)
                    : state.selectedCategoryIds.length < 5
                    ? state.selectedCategoryIds.concat(categoryId)
                    : state.selectedCategoryIds
            }
        })
}))
