import {z} from 'zod'

/**
 * Quote schema and Types
 */
export const NotificationPreferences = z.object({
    phone_number: z.string().nullable(),
    email: z.string(),
    send_phone_notification: z.coerce.boolean(),
    send_email_notification: z.coerce.boolean(),
    created_at: z.coerce.date().nullable(),
    id: z.coerce.number()
})
export type NotificationPreferences = z.infer<typeof NotificationPreferences>
