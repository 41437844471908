import {useTranslation} from 'react-i18next'
import {useEffect, useRef, useState} from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ChatBot from 'react-simple-chatbot'
import {ThemeProvider, useTheme} from 'styled-components'
import {
    StyledChatbotButton,
    StyledChatBotHeader,
    StyledChatbotRoot,
    StyledChatbotWrapper
} from '@/features/chatbot/components/styles.ts'
import {ChevronDownIcon, MessageSmileSquareIcon, XCloseIcon} from '@components/ui/icon'
import {mainSteps} from '@/features/chatbot/steps.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {useParams} from 'react-router-dom'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'

export const ChatBotWrapper = () => {
    const {i18n, t} = useTranslation()
    const theme = useTheme()
    //internal component statuses
    const [botOpened, setBotOpened] = useState(false)
    const [botSteps, setBotSteps] = useState<ChatbotStep[] | null>(null)
    //ref
    const chatbot = useRef()
    const chatbotInput = document.getElementById('chatbotinput')
    const [resetChatbot, setResetChatbot] = useState(false)
    //chatbot store
    const setChatbotRef = useChatbotStore(state => state.setChatbotRef)
    const service = useChatbotStore(state => state.service)
    const resetChatHistory = useChatbotStore(state => state.resetChatHistory)
    //prepare the data for the flows
    const {quoteId} = useParams()

    useEffect(() => {
        chatbotInput?.addEventListener('keydown', e => {
            if (e.code === 'Enter') e.preventDefault()
        })
    }, [chatbotInput])

    useEffect(() => {
        setChatbotRef(chatbot)
        setBotSteps(
            mainSteps({
                service
            })
        )
    }, [quoteId, service])

    useEffect(() => {
        setResetChatbot(true)
        setTimeout(() => setResetChatbot(false), 1000)
        resetChatHistory()
        setBotSteps(mainSteps({service}))
    }, [i18n.language])

    const onBotToggle = () => {
        setBotOpened(!botOpened)
    }

    return (
        <StyledChatbotRoot>
            <StyledChatbotWrapper opened={botOpened}>
                {botSteps && (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    <ThemeProvider theme={theme.chatBotTheme}>
                        {resetChatbot ? (
                            <></>
                        ) : (
                            <ChatBot
                                key={`${botSteps.length}-${quoteId}`}
                                steps={botSteps}
                                ref={chatbot}
                                isTextarea={true}
                                cache={false}
                                inputAttributes={{id: 'chatbotinput'}}
                                placeholder={'Type your message here...'}
                                hideUserAvatar={true}
                                botAvatar={'https://ca.slack-edge.com/T03RF4G89-U01NZ7UHYCQ-9f63803fcc00-512'}
                                headerComponent={
                                    <StyledChatBotHeader align={'center'} justify={'space-between'} gap={4}>
                                        <XCloseIcon onClick={() => setBotOpened(false)} />
                                        <p>{t('chatbot:header')}</p>
                                        <span />
                                    </StyledChatBotHeader>
                                }
                            />
                        )}
                    </ThemeProvider>
                )}
            </StyledChatbotWrapper>

            <StyledChatbotButton align={'center'} justify={'center'} onClick={() => onBotToggle()}>
                {botOpened ? <ChevronDownIcon /> : <MessageSmileSquareIcon />}
            </StyledChatbotButton>
        </StyledChatbotRoot>
    )
}

ChatBotWrapper.displayName = 'ChatBotWrapper'
