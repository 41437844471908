import {ServiceType} from '@/types/commons.ts'
import statsBg from '@/assets/images/stats_bg.jpg'
import {ReactNode} from 'react'
import {Image01Icon, Star06Icon, UserCircleIcon, UserSquareIcon, VideoRecorderIcon} from '@components/ui/icon'

export type ValueProp = {
    labelText: string
    data: string
    subtext: string
    description: string
    icon?: ReactNode
}

export const ServiceValuePropsBg = {
    home: statsBg
} as const satisfies Partial<Record<ServiceType, string>>

export const ServiceValueProps = {
    dash: {
        value1: {
            labelText: 'photoshoot_landing:stats:label_1',
            /*TODO: check if data_1 is a useful translation (same for the other 'data')*/
            data: 'photoshoot_landing:stats:data_1',
            subtext: 'photoshoot_landing:stats:subtext_1',
            description: 'photoshoot_landing:stats:description_1'
        },
        value2: {
            labelText: 'photoshoot_landing:stats:label_2',
            data: 'photoshoot_landing:stats:data_2',
            subtext: 'photoshoot_landing:stats:subtext_2',
            description: 'photoshoot_landing:stats:description_2'
        },
        value3: {
            labelText: 'photoshoot_landing:stats:label_4',
            data: '',
            subtext: '',
            description: 'photoshoot_landing:stats:description_4'
        }
    },
    home: {
        value1: {
            labelText: 'photoshoot_landing:stats:label_1',
            /*TODO: check if data_1 is a useful translation (same for the other 'data')*/
            data: 'photoshoot_landing:stats:data_1',
            subtext: 'photoshoot_landing:stats:subtext_1',
            description: 'photoshoot_landing:stats:description_1'
        },
        value2: {
            labelText: 'photoshoot_landing:stats:label_2',
            data: 'photoshoot_landing:stats:data_2',
            subtext: 'photoshoot_landing:stats:subtext_2',
            description: 'photoshoot_landing:stats:description_2'
        },
        value3: {
            labelText: 'photoshoot_landing:stats:label_3',
            data: '',
            subtext: 'photoshoot_landing:stats:subtext_3',
            description: 'photoshoot_landing:stats:description_3'
        }
    },
    matterport: {
        value1: {
            labelText: 'matterport_landing:stats:label_1',
            data: 'matterport_landing:stats:data_1',
            subtext: 'matterport_landing:stats:subtext_1',
            description: 'matterport_landing:stats:description_1',
            icon: <Star06Icon />
        },
        value2: {
            labelText: 'matterport_landing:stats:label_2',
            data: 'matterport_landing:stats:data_2',
            subtext: 'matterport_landing:stats:subtext_2',
            description: 'matterport_landing:stats:description_2',
            icon: <UserCircleIcon />
        },
        value3: {
            labelText: 'matterport_landing:stats:label_3',
            data: '',
            subtext: 'matterport_landing:stats:subtext_3',
            description: 'matterport_landing:stats:description_3',
            icon: <Image01Icon />
        }
    },
    walkthrough: {
        value1: {
            labelText: '',
            data: '',
            subtext: '',
            description: ''
        }
    },
    experience_video: {
        value1: {
            labelText: 'exp_video_landing:stats:label_1',
            data: 'exp_video_landing:stats:data_1',
            subtext: 'exp_video_landing:stats:subtext_1',
            description: 'exp_video_landing:stats:description_1',
            icon: <Star06Icon />
        },
        value2: {
            labelText: 'exp_video_landing:stats:label_2',
            data: 'exp_video_landing:stats:data_2',
            subtext: 'exp_video_landing:stats:subtext_2',
            description: 'exp_video_landing:stats:description_2',
            icon: <VideoRecorderIcon />
        },
        value3: {
            labelText: 'exp_video_landing:stats:label_3',
            data: '',
            subtext: 'exp_video_landing:stats:subtext_3',
            description: 'exp_video_landing:stats:description_3',
            icon: <UserSquareIcon />
        }
    }
} as const satisfies Partial<Record<ServiceType | 'dash', Partial<Record<'value1' | 'value2' | 'value3', ValueProp>>>>
