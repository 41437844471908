import {FC} from 'react'
import {
    PhotoMosaicContainer,
    PhotoMosaicMainImage,
    PhotoMosaicSideImages,
    PhotoMosaicWrapper
} from '@/features/landing/components/photo-mosaic/style.ts'
import {DefaultNamespace} from 'i18next'

interface PhotoMosaicProps {
    title?: string | DefaultNamespace
    subtitle?: string | DefaultNamespace
    mainImage: string
    sideImages: string[]
}

export const PhotoMosaic: FC<PhotoMosaicProps> = ({title, subtitle, mainImage, sideImages}) => {
    return (
        <PhotoMosaicContainer>
            {title && (
                <h3>
                    {title}
                    {subtitle && <span>{subtitle}</span>}
                </h3>
            )}
            <PhotoMosaicWrapper>
                <PhotoMosaicMainImage src={mainImage} alt={'ambient'} />
                <PhotoMosaicSideImages>
                    {sideImages.map(image => (
                        <img key={image} src={image} alt={'ambient'} />
                    ))}
                </PhotoMosaicSideImages>
            </PhotoMosaicWrapper>
        </PhotoMosaicContainer>
    )
}

PhotoMosaic.displayName = 'PhotoMosaic'
