import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import toast from 'react-hot-toast'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {APP_LANGUAGES, useTranslation} from '@/translations/i18n.tsx'
import Select, {SelectValue} from '@components/commons/select/Select.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {useUpdateVoiceoverLanguage} from '@/features/experiences-host-video/services/useUpdateVoiceoverLanguage.ts'
import {useState} from 'react'
import {SingleValue} from 'react-select'

export const VoiceoverLanguage = ({defaultLanguage}: {defaultLanguage: string | null}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const updateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})

    const updateVoiceoverLanguageMutation = useUpdateVoiceoverLanguage({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    const languagesList = APP_LANGUAGES.map(lang => ({value: lang.value, label: lang.label}))
    const defaultValue = APP_LANGUAGES.find(lang => defaultLanguage == lang.value)
    const [selectedLanguage, setSelectedLanguage] = useState<SelectValue | null>(null)

    const handleContinue = async () => {
        if (selectedLanguage) {
            await updateVoiceoverLanguageMutation.mutateAsync({language: selectedLanguage?.value})
            updateStepMutation.mutate({status: 'upload', step: 'voiceover-guidelines'})
        }
    }

    return (
        <>
            <ExperiencesHostVideoBody gap={6}>
                <Flexbox direction={'column'} gap={2}>
                    <ExperiencesHostVideoTitle>
                        {t('experiences_host_video:upload_voiceover_language:title')}
                    </ExperiencesHostVideoTitle>
                    <ExperiencesHostVideoParagraph>
                        {t('experiences_host_video:upload_voiceover_language:description')}
                    </ExperiencesHostVideoParagraph>
                </Flexbox>
                <Select
                    helpText={t('experiences_host_video:upload_voiceover_language:help_text')}
                    label={t('experiences_host_video:upload_voiceover_language:select_language')}
                    isSearchable
                    isLoading={showExperiencesHostVideoQuery.isLoading}
                    defaultValue={defaultValue && {value: defaultValue.value, label: defaultValue.label}}
                    options={languagesList}
                    value={selectedLanguage ?? undefined}
                    onChange={newValue => setSelectedLanguage(newValue as SingleValue<SelectValue>)}
                />
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Button
                        onClick={handleContinue}
                        disabled={
                            updateStepMutation.isPending ||
                            updateVoiceoverLanguageMutation.isPending ||
                            !selectedLanguage
                        }
                        fullWidth
                    >
                        {t('commons:continue')}

                        {updateStepMutation.isPending ||
                            (updateVoiceoverLanguageMutation.isPending && (
                                <Flexbox>
                                    <Spinner />
                                </Flexbox>
                            ))}
                    </Button>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
