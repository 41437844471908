import axios from '../../../axiosInstance.ts'

export type HttpSendFeedbackPayload = {
    recommendation: number
    delivery_time_satisfaction: number
    image_quality_satisfaction: number
    find_page_ease: number
    scheduling_ease: number
    notes: string
}
export const httpSendFeedback = (
    hostCode: number,
    hostPassword: string,
    quoteId: number,
    payload: HttpSendFeedbackPayload
) => {
    return axios.post(`host-services/hosts/${hostCode}/${hostPassword}/quotes/${quoteId}/satisfaction`, payload)
}
