import {FC, ReactElement, ReactNode} from 'react'
import {
    PromoBanner,
    PromoBannerBackgroundImage,
    PromoBannerContainer,
    PromoBannerContentWrapper,
    PromoBannerCta,
    PromoBannerIconContainer,
    PromoBannerOverlay,
    PromoBannerParagraph,
    PromoBannerTextContent,
    PromoBannerTitle
} from '@/components/ui/promo-banner-atoms/PromoBannerAtoms'
import {useTheme} from 'styled-components'

interface PromoBannerWideProps {
    bannerBg?: string
    serviceIcon?: ReactElement
    title: string
    desc?: ReactNode
    cta: ReactElement
}

export const PromoBannerWide: FC<PromoBannerWideProps> = ({bannerBg, serviceIcon, title, desc, cta}) => {
    const {palette} = useTheme()

    return (
        <PromoBannerContainer fullHeight>
            <PromoBanner textColor={palette.neutral.white}>
                <PromoBannerBackgroundImage bannerBg={bannerBg} />
                <PromoBannerOverlay />

                <PromoBannerIconContainer>{serviceIcon}</PromoBannerIconContainer>

                <PromoBannerContentWrapper>
                    <PromoBannerTextContent>
                        <PromoBannerTitle>{title}</PromoBannerTitle>
                        {desc && <PromoBannerParagraph>{desc}</PromoBannerParagraph>}
                    </PromoBannerTextContent>
                    <PromoBannerCta>{cta}</PromoBannerCta>
                </PromoBannerContentWrapper>
            </PromoBanner>
        </PromoBannerContainer>
    )
}
