import {FC, useState} from 'react'
import {MatterportUrlParams} from '../types'
import {MatterportIntroModal} from './MatterportIntroModal'
import {Entity} from '@/features/service/types.ts'
import {MatterportReview} from './MatterportReview'
import {MatterportIntroFooter} from './MatterportIntroFooter'

interface MatterportProcessProps {
    urlParams: MatterportUrlParams
    entity: Entity
}

export const MatterportProcess: FC<MatterportProcessProps> = ({entity, urlParams}) => {
    const [isIntroModalVisible, setIsIntroModalVisible] = useState(true)

    return isIntroModalVisible ? (
        <MatterportIntroModal
            endSlot={<MatterportIntroFooter onClose={() => setIsIntroModalVisible(false)} />}
            onClose={() => setIsIntroModalVisible(false)}
        />
    ) : (
        <MatterportReview entity={entity} urlParams={urlParams} />
    )
}
