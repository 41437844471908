import {z} from 'zod'
import {Assignment, Entity, Quote, Task, TaskMedia, TaskResource} from '@/features/service/types.ts'

export const InProgressService = z.object({
    entity: Entity,
    ...Quote.shape,
    task: z.object({
        assignment: Assignment.omit({task_status: true}).optional(),
        ...Task.shape
    }),
    task_category: TaskResource,
    task_media: TaskMedia,
    task_service: TaskResource
})
export type InProgressService = z.infer<typeof InProgressService>
