import {Button} from '@components/ui/button/Button'
import {StyledMatterportReviewFooter, StyledMatterportReviewFooterButtons} from './style'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {useMatterportStore} from '../store/MatterportStore'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface MatterportReviewFooterProps {
    hasScrolled: boolean
    isLoadingGet: boolean
    isSubmitting: boolean
    onSubmit: () => void
    pendingCount: number
    isFixed?: boolean
}

export const MatterportReviewFooter: FC<MatterportReviewFooterProps> = ({
    hasScrolled,
    isLoadingGet,
    isSubmitting,
    pendingCount,
    onSubmit,
    isFixed = false
}) => {
    const {t} = useTranslation()
    const addAllPhotos = useMatterportStore(state => state.addAllPhotos)
    const resetReview = useMatterportStore(state => state.resetReview)
    const isSubmitButtonDisabled = !hasScrolled || pendingCount != 0 || isSubmitting || isLoadingGet

    return (
        <StyledMatterportReviewFooter justify="space-between" width="100%" $isFixed={isFixed}>
            <p>{t('matterport:review:pending_images', {count: pendingCount})}</p>

            <StyledMatterportReviewFooterButtons gap={2}>
                <Button variant="tertiary" onClick={resetReview}>
                    {t('matterport:review:reset_btn')}
                </Button>
                <Flexbox gap={2}>
                    <Tooltip
                        rootProps={{open: !hasScrolled ? false : undefined}}
                        triggerProps={{asChild: true}}
                        content={t('matterport:review:tooltip:disabled_other')}
                    >
                        <Button variant="secondary" disabled={!hasScrolled} onClick={addAllPhotos}>
                            {t('matterport:review:add_all')}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        rootProps={{open: !isSubmitButtonDisabled ? false : undefined}}
                        triggerProps={{asChild: true}}
                        content={t('matterport:review:tooltip:disabled_submit')}
                    >
                        <Button disabled={isSubmitButtonDisabled} onClick={onSubmit}>
                            {t('commons:submit')}
                            {isSubmitting && <Spinner size={20} />}
                        </Button>
                    </Tooltip>
                </Flexbox>
            </StyledMatterportReviewFooterButtons>
        </StyledMatterportReviewFooter>
    )
}
