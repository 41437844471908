import dayjs from 'dayjs'
import {HostDates, Slot, SlotValue} from './types'

// Constants
export const MAX_SELECTED_LENGTH = 2

export const SLOTS = [
    {value: 8, label: '8:00 AM'},
    {value: 10, label: '10:00 AM'},
    {value: 12, label: '12:00 PM'},
    {value: 14, label: '2:00 PM'},
    {value: 16, label: '4:00 PM'},
    {value: 18, label: '6:00 PM'}
] as const satisfies ReadonlyArray<Slot>

export const SLOTS_VALUES: SlotValue[] = SLOTS.map(({value}) => value)
export const MIN_DATE: Date = dayjs(new Date()).add(2, 'day').startOf('day').toDate()
export const MAX_DATE: Date = dayjs(new Date()).add(6, 'week').endOf('day').toDate()

// Helpers
export const addTimeToDate = (date: Date, timeValue: number): Date => {
    return dayjs(date)
        .endOf('day')
        .subtract(23 - timeValue, 'hour')
        .subtract(59, 'minutes')
        .subtract(59, 'seconds')
        .toDate()
}

export const isDateSelected = (dateTarget: Date, selectedDates: HostDates): boolean => {
    const selectedDays: string[] = selectedDates.map((date: Date) => dayjs(date).format('l')) // convert to ["M/D/YYYY"] format
    const dayTarget = dayjs(dateTarget).format('l') // convert to "M/D/YYYY" format
    return selectedDays.includes(dayTarget)
}

export const isCompliantDate = (dateTarget: Date): boolean => {
    const selectedHourValue = dayjs(dateTarget).get('hour') as SlotValue
    return SLOTS_VALUES.includes(selectedHourValue)
}

export const findIndexOnSelectedDate = (dateTarget: Date, selectedDates: HostDates): number => {
    const selectedDays: string[] = selectedDates.map((date: Date) => dayjs(date).format('l')) // convert to ["M/D/YYYY"] format
    const dayTarget = dayjs(dateTarget).format('l') // convert to "M/D/YYYY" format
    return selectedDays.findIndex(day => day === dayTarget)
}
