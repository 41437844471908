import {useTranslation} from 'react-i18next'
import {StyledDot, StyledOpenCtaField, StyledStatus, StyledTitleField} from './style.ts'
import Table, {TableSorter} from '@components/commons/table/Table.tsx'
import {generatePath, useOutletContext} from 'react-router-dom'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import {formatLocaleDate, getServiceType} from '@utilities/helpers.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {InProgressService} from '@/features/in-progress-services/types.ts'
import {getSteps, serviceTypeToServiceSubtitle} from '@/features/service/utils.tsx'
import {EmptyResultsBox} from '@components/commons/empty-results-box/EmptyResultsBox.tsx'
import {InProgressServicesSearchParams} from '@/features/in-progress-services/services/inProgress.http.ts'
import {HOME_IMG_PLACEHOLDER, routes} from '@utilities/constants'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink.tsx'
import {AlertTriangleIcon, ArrowRightIcon} from '@components/ui/icon'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {breakpoints} from '@/theme/mediaQueries.ts'

interface InProgressTableProps {
    data: InProgressService[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onChangePage: () => void
    sorter: TableSorter<InProgressServicesSearchParams['orderBy']> | undefined
}

export const InProgressTable = ({data, isChangingPage, isError, isLoading, onChangePage}: InProgressTableProps) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.m

    return (
        <Table
            columns={[
                {
                    label: !isMobile && t('commons:listing'),
                    width: '30%',
                    //sortName: 'title',
                    cellRender: service => {
                        const serviceType = getServiceType(service.task_media.name, service.task_category.name)
                        return (
                            <StyledTitleField justify={'space-between'} align={'center'} gap={4}>
                                <ButtonLink
                                    variant={'ghost'}
                                    size={'sm'}
                                    to={generatePath(routes.SERVICE_DETAILS.path, {
                                        hostCode: urlParams.hostCode,
                                        hostPassword: urlParams.hostPassword,
                                        quoteId: service.id
                                    })}
                                >
                                    <IconContainer>
                                        <ArrowRightIcon size={22} />
                                    </IconContainer>
                                </ButtonLink>
                                <img src={service.listingImgUrl ?? HOME_IMG_PLACEHOLDER} alt={'listing-photo'} />
                                <Flexbox
                                    direction={'column'}
                                    gap={1}
                                    width="100%"
                                    title={service.entity.title || service.entity.external_id}
                                >
                                    <a href={service.entity.airbnb_link ?? undefined} target="_blank">
                                        {service.entity.title ||
                                            t('commons:listing_n', {listingId: service.entity.external_id})}
                                    </a>
                                    <small title={t(serviceTypeToServiceSubtitle[serviceType])}>
                                        {t(serviceTypeToServiceSubtitle[serviceType])}
                                    </small>
                                </Flexbox>
                            </StyledTitleField>
                        )
                    }
                },
                {
                    label: t('commons:status').concat(isMobile ? ':' : ''),
                    width: '20%',
                    //sortName: 'task_status',
                    cellRender: service => {
                        const serviceType = getServiceType(service.task_media.name, service.task_category.name)

                        const currentStep = getSteps({
                            assignedFreelancer: service.task.assignment?.photographer?.first_name,
                            assignmentStatus: service.task.assignment?.status,
                            postProdStatus: service.task.task_status.post_prod_status,
                            quoteAcceptedAt: service.accepted_at,
                            quoteStatus: service.quote_status,
                            serviceType,
                            taskStatus: service.task.task_status.status,
                            assignmentDate: service.task.assignment?.to_shoot_at
                        }).find(step => step.isCurrent)

                        if (!currentStep) {
                            return '-'
                        }
                        return (
                            <StyledStatus align="center" gap={2.5}>
                                {currentStep.status == 'error' ? (
                                    <AlertTriangleIcon size={20} />
                                ) : (
                                    <StyledDot
                                        status={
                                            service.task.task_status.status == 'accepted'
                                                ? 'warning'
                                                : currentStep.status
                                        }
                                    />
                                )}
                                <span title={currentStep.title}>{currentStep.title}</span>
                            </StyledStatus>
                        )
                    }
                },
                {
                    label: t('dashboard:in_progress:table:provided_by').concat(isMobile ? ':' : ''),
                    width: '20%',
                    //sortName: 'photographer_name',
                    cellRender: service => {
                        const photographer = service.task.assignment?.photographer
                        const isPending = service.task.assignment?.status != 'accepted'
                        return photographer && !isPending ? (
                            <Flexbox align={'center'} gap={2}>
                                <Avatar name={photographer.first_name} size={isMobile ? 'sm' : 'md'} />
                                <p>{photographer.first_name}</p>
                            </Flexbox>
                        ) : (
                            '-'
                        )
                    }
                },
                {
                    label: t('dashboard:in_progress:table:scheduled_on').concat(isMobile ? ':' : ''),
                    width: '15%',
                    cellRender: service => {
                        const assignment = service.task.assignment
                        return (
                            <Flexbox>
                                {assignment?.to_shoot_at ? formatLocaleDate(assignment.to_shoot_at) : '-'}
                            </Flexbox>
                        )
                    }
                },
                {
                    width: '15%',
                    alignment: 'right',
                    cellRender: service => (
                        <StyledOpenCtaField justify={'end'}>
                            <ButtonLink
                                variant={'tertiary'}
                                size={'sm'}
                                to={generatePath(routes.SERVICE_DETAILS.path, {
                                    hostCode: urlParams.hostCode,
                                    hostPassword: urlParams.hostPassword,
                                    quoteId: service.id
                                })}
                            >
                                {t('commons:open')}
                            </ButtonLink>
                        </StyledOpenCtaField>
                    )
                }
            ]}
            isMobile={isMobile}
            emptyStateComponent={<EmptyResultsBox title={t('dashboard:in_progress:empty_message')} />}
            data={data}
            isChangingPage={isChangingPage}
            isError={isError}
            isLoading={isLoading}
            onChangePage={onChangePage}
        />
    )
}

InProgressTable.displayName = 'InProgressTable'
