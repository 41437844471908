import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {ResponseType} from '@/types/commons'
import {AccessibilityImage, EntityBedroom, MatterportUrlParams} from '../types'
import {Entity} from '@/features/service/types.ts'

/**
 * httpGetAmenitiesAutocomplete
 * Here we will get informations from amenities autocomplete
 */
export const HttpGetAmenitiesAutocompleteResponse = z
    .object({
        id: z.coerce.number(),
        name: z.string(),
        description: z.string(),
        internal_key: z.enum([
            'HOME_STEP_FREE_ACCESS',
            'DISABLED_PARKING_SPOT',
            'WIDE_DOORWAY',
            'FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR',
            'BEDROOM_STEP_FREE_ACCESS',
            'BEDROOM_WIDE_DOORWAY',
            'BATHROOM_STEP_FREE_ACCESS',
            'WALK_IN_SHOWER',
            'BATHROOM_WIDE_DOORWAY',
            'TOILET_GRAB_BAR',
            'SHOWER_CHAIR',
            'CEILING_HOIST',
            'FLOOR_PLAN',
            'SHOWER_GRAB_BAR',
            'POOL_HOIST'
        ]),
        room: z.enum(['EXTERIOR']),
        room_id: z.coerce.number()
    })
    .array()

export type HttpGetAmenitiesAutocompleteResponse = z.infer<typeof HttpGetAmenitiesAutocompleteResponse>

export const httpGetAmenitiesAutocomplete = () => {
    return axios.get(`/amenity`)
}

/**
 * httpGetRoomsAutocomplete
 * Here we will get informations from rooms autocomplete
 */
export const HttpGetRoomsAutocompleteResponse = z
    .object({
        id: z.coerce.number(),
        name: z.string(),
        internal_name: z.enum(['EXTERIOR', 'BEDROOM', 'FULL_BATHROOM', 'ADAPTIVE_EQUIPMENT', 'EXTRA', 'HALF_BATHROOM'])
    })
    .array()

export type HttpGetRoomsAutocompleteResponse = z.infer<typeof HttpGetRoomsAutocompleteResponse>

export const httpGetRoomsAutocomplete = () => {
    return axios.get(`/rooms`)
}

/**
 * httpGetMatterportInfo
 * Here we will get informations from matterport entity
 */
export const HttpGetMatterportInfoParams = z.object({
    entity: Entity,
    params: z.object({
        response_type: ResponseType
    })
})

export type HttpGetMatterportInfoParams = z.infer<typeof HttpGetMatterportInfoParams>
export const HttpGetMatterportResponse = z.object({
    accessibility_images: AccessibilityImage.array(),
    entity_bedroom: EntityBedroom.array()
})

export type HttpGetMatterportResponse = z.infer<typeof HttpGetMatterportResponse>

export const httpGetMatterportInfo = ({entity, params}: HttpGetMatterportInfoParams) => {
    return axios.get(`/entity/${entity.code}/${entity.password}`, {params})
}

/**
 * httpConfirmMatterportReview
 * Here we will get information from matterport entity
 */
export const HttpConfirmMatterportReviewProps = z.object({
    urlParams: MatterportUrlParams,
    params: z
        .object({
            id: z.coerce.string(),
            action: z.enum(['accepted', 'removed']),
            old: z.boolean()
        })
        .array()
})

export type HttpConfirmMatterportReviewProps = z.infer<typeof HttpConfirmMatterportReviewProps>

export const httpConfirmMatterportReview = ({urlParams, params}: HttpConfirmMatterportReviewProps) => {
    const url = `/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/quotes/${urlParams.quoteId}/tasks-matterport/${urlParams.taskMatterportId}/confirm-images`
    return axios.post(url, {images: params})
}
