import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'

export const EXPERIENCES_HOST_VIDEO_CONTENT_MAX_WIDTH = 600
export const StyledExperiencesHostVideoPageLayout = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
`
export const StyledExperiencesHostVideoBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        flex-direction: column;
        padding: ${spacing * 6}px ${spacing * 4}px;
        width: 100%;
        max-width: ${EXPERIENCES_HOST_VIDEO_CONTENT_MAX_WIDTH}px;
        margin: 0 auto;
    `
)

export const StyledExperiencesHostVideoTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.displaySmFixed};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledExperiencesHostVideoParagraph = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
    `
)

export const StyledExperiencesHostVideoFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        position: sticky;
        bottom: 0;
        padding: ${spacing * 2}px ${spacing * 4}px ${spacing * 6}px ${spacing * 4}px;
        background-color: ${palette.neutral.white};
        width: 100%;
        max-width: ${EXPERIENCES_HOST_VIDEO_CONTENT_MAX_WIDTH}px;
        margin: 0 auto;
    `
)

export const StyledIntroImage = styled.img`
    align-self: center;
    width: 300px;
    height: 300px;
`

export const StyledIntroStepTitle = styled.h2(
    ({theme: {typography, palette}}) => css`
        color: ${palette.neutral['900']};
        ${typography.textMd};
        font-weight: 700;
    `
)

export const StyledExperiencesHostVideoActionCard = styled.button<{$variant?: 'neutral' | 'success' | 'danger'}>(
    ({theme: {palette, spacing, typography}, $variant}) => css`
        width: 100%;
        display: flex;
        gap: ${spacing * 3}px;
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 12px;
        color: ${palette.neutral['700']};
        cursor: pointer;

        & svg {
            width: 28px;
            height: 28px;
            fill: ${$variant == 'success'
                ? palette.success['600']
                : $variant == 'danger'
                ? palette.danger['600']
                : palette.neutral.black};
        }
        & h3 {
            ${typography.textMd};
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
        }

        &:disabled {
            background-color: ${palette.neutral['25']};
            color: ${palette.neutral['500']};
            cursor: not-allowed;
        }
    `
)

export const StyledExperiencesHostVideoModalBody = styled(StyledExperiencesHostVideoBody)(
    () => css`
        max-height: 100%;
        overflow-y: auto;
    `
)

export const StyledExperiencesHostVideoLabel = styled.label(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['900']};
    `
)

export const StyledExperiencesHostVideoReadyShootCard = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        width: 100%;
        padding: ${spacing * 4}px ${spacing * 3}px ${spacing * 4}px;
        background-color: ${palette.neutral.white};
        border-radius: 12px;
        border: 1px solid ${palette.neutral[300]};
        color: ${palette.neutral[900]};
        ${typography.textSm};
        & h4 {
            font-weight: 500;
        }

        & ul {
            list-style-type: disc;
            padding-left: ${spacing * 5}px;
        }

        & em {
            font-style: italic;
        }
    `
)

export const StyledShortExampleButton = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 9999px;
        border: 1px solid ${palette.neutral[300]};
        color: ${palette.neutral[700]};
        ${typography.textSm};
        font-weight: 500;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
    `
)

export const StyledCheckListCheckBox = styled(Checkbox)(
    ({theme: {palette, shadows}}) => css`
        &:checked {
            background: ${palette.neutral['700']};
            border: 1px solid ${palette.neutral['700']};
            content: ${`url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='${palette.neutral[
                'white'
            ].replace(
                '#',
                '%23'
            )}' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`};
        }
        &:not(:disabled):hover {
            border: 1px solid ${palette.neutral['300']};
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 2px ${palette.neutral['100']}`};
        }
        
    }
    `
)

export const StyledCheckIcon = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 20px;
        height: 20px;
        border-radius: 6px;
        background-color: ${palette.neutral[700]};
        color: ${palette.neutral.white};
    `
)
