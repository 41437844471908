import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledDeclineStepWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        margin-top: ${spacing * 14}px;
    `
)

export const StyledDeclineStepBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledDeclineStepDesc = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h5 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        ,
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledDeclineStatsWidgetWrapper = styled(Flexbox)(
    ({theme: {typography, palette, mediaQueries}}) => css`
        width: 160px;
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }

        & > h4 {
            ${typography.displayXs};
            color: ${palette.neutral['900']};
            font-weight: 700;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledDeclineDisclaimer = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textXs};
        color: ${palette.neutral['700']};

        span {
            text-decoration: underline;
        }
    `
)

export const StyledDeclineDisclaimerTooltip = styled(Flexbox)(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 400;
    `
)

export const StyledDeclineStepFinalForm = styled.form(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const StyledDeclineStepFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)
