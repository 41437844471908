import {Trans, useTranslation} from 'react-i18next'
import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget.tsx'
import {routes} from '@utilities/constants'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {raise} from '@utilities/helpers.ts'
import {generatePath, Navigate} from 'react-router-dom'
import {useAuthStore} from '@/store/AuthStore.tsx'

export const ExperiencesHostVideoLogin = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(z.object({airbnbId: z.string().min(1)}))
    const parsedUrlParams = urlParams.success ? urlParams.data : raise('urlParams failed')
    const accessToken = useAuthStore(store => store.accessToken)

    if (accessToken) {
        return (
            <Navigate
                to={generatePath(routes.EXPERIENCE_HOST_VIDEO_REDIRECT.path, {airbnbId: parsedUrlParams.airbnbId})}
                replace
            />
        )
    }

    return (
        <Container>
            <Helmet title={t('sso_login:page_title_meta')} />

            <AirbnbSSOWidget
                title={t('experiences_host_video:sso:title')}
                subtitle={t('experiences_host_video:sso:subtitle')}
                disclaimer={<Trans i18nKey="experiences_host_video:sso:disclaimer" />}
                redirectUrl={generatePath(routes.EXPERIENCE_HOST_VIDEO_REDIRECT.path, {
                    airbnbId: parsedUrlParams.airbnbId
                })}
            />
        </Container>
    )
}
