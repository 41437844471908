import {SVGAttributes} from 'react'
import {StyledCircle} from '@/features/experiences-host-video/components/circular-progress-bar/style.ts'
import {useTheme} from 'styled-components'

interface CircularProgressBarProps extends SVGAttributes<SVGSVGElement> {
    progress: number
    size?: number
    strokeWidth?: number
    color?: string
}

export const CircularProgressBar = ({progress, size = 48, strokeWidth = 8, ...rest}: CircularProgressBarProps) => {
    const radius = (size - strokeWidth) / 2
    const circumference = 2 * Math.PI * radius
    const offset = circumference - (progress / 100) * circumference
    const theme = useTheme()
    return (
        <StyledCircle width={size} height={size} {...rest}>
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={theme.palette.neutral[300]}
                strokeWidth={strokeWidth}
                fill="none"
            />
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={theme.palette.primary[600]}
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                style={{transition: 'stroke-dashoffset 0.1s ease-in-out'}}
            />
        </StyledCircle>
    )
}
