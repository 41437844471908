import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledHeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 64px;
    `}
`

export const StyledHeaderGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr auto auto;
        height: 100%;
        gap: ${spacing * 4}px;
        & > a {
            display: flex;
            cursor: pointer;
        }
    `}
`

export const StyledMenuItems = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        gap: 16px;
    `}
`

export const StyledDropdownUserInfosItems = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        & span {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & small {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['400']};
        }
    `}
`

export const StyledUserNotLoggedIn = styled.div`
    ${() => css`
        cursor: pointer;
    `}
`
