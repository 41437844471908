import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {IconContainer} from '@/components/ui/icon-container/IconContainer'
import styled, {css} from 'styled-components'

export const StyledCoAlarmPostDeliveryCards = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${spacing * 6}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledCoAlarmPostDeliveryCard = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 16px;
    `
)

export const StyledCoAlarmInstallationIconContainer = styled(IconContainer)(
    ({theme: {palette}}) => css`
        background: ${palette.primary['50']};
        border-radius: 100%;
        border: none;
        box-shadow: none;
        color: ${palette.primary['600']};
    `
)

export const StyledCoAlarmPostDeliveryCardContent = styled(Flexbox)(
    ({theme: {palette}}) => css`
        color: ${palette.neutral[900]};

        & > a {
            text-decoration: underline;
            font-weight: 500;
        }
    `
)
