import {z} from 'zod'
import axios from '@/axiosInstance.ts'

export const HttpSubmitTicketParams = z.object({
    code: z.coerce.number().int(),
    password: z.string(),
    media: z.coerce.number().int(),
    data: z.object({
        message: z.string(),
        subject: z.string(),
        flow: z.string(),
        recap: z.string(),
        isLuxe: z.boolean().optional()
    })
})
export type HttpSubmitTicketParams = z.infer<typeof HttpSubmitTicketParams>

export const httpSubmitTicket = (params: HttpSubmitTicketParams) => {
    const url = `/chatbot/quote/${params.code}/${params.password}/${params.media}/open-ticket`
    return axios.post(url, {...params.data})
}
