import {
    StyledPreviewUploadedClips,
    StyledPreviewUploadedClipsRemoveButton,
    StyledPreviewUploadedClipsWrapper
} from '@/features/experiences-host-video/components/uploaded-clip-item/style.ts'
import {ErrorFile, useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {StyledPreviewUploadedClipsErrorWrapper} from '@/features/experiences-host-video/components/error-clip-item/style.ts'
import {AlertTriangleIcon, Trash01Icon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {useMemo} from 'react'

export const ErrorClipsItem = (errorFile: ErrorFile) => {
    const {t} = useTranslation()
    const removeErrorFile = useClipsUpload(store => store.removeErrorFile)
    const uploadedFiles = useClipsUpload(store => store.uploadedFiles)
    const onRemove = () => {
        removeErrorFile(errorFile.file)
    }
    const clipSrc = useMemo(() => URL.createObjectURL(errorFile.file), [errorFile, uploadedFiles])
    return (
        <StyledPreviewUploadedClipsWrapper>
            <StyledPreviewUploadedClips src={clipSrc} />
            <StyledPreviewUploadedClipsErrorWrapper>
                <AlertTriangleIcon size={24} />
                <p>{t('errors:default')}</p>
            </StyledPreviewUploadedClipsErrorWrapper>
            <StyledPreviewUploadedClipsRemoveButton onClick={onRemove}>
                <Trash01Icon size={17} />
            </StyledPreviewUploadedClipsRemoveButton>
        </StyledPreviewUploadedClipsWrapper>
    )
}
