import {ChangeEvent, FC, useState} from 'react'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalBody, ModalFooter, ModalParagraph, ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    StyledInfoBox,
    StyledModalHeader,
    StyledSelectPopover
} from '@/features/experiences-host-video/components/device-modal/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {AlertTriangleIcon, CheckCircleBrokenIcon, InfoCircleIcon} from '@components/ui/icon'
import {ComboboxProvider, SelectProvider} from '@ariakit/react'
import {
    ComboboxItem,
    SelectComboboxInput,
    SelectComboboxList,
    SelectItem,
    SelectTrigger
} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {useDevices} from '@/features/experiences-host-video/services/useDevices.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useTheme} from 'styled-components'
import {debounce} from '@utilities/helpers.ts'
import {useUpdateExperiencesHostVideo} from '@/features/experiences-host-video/services/useUpdateExperiencesHostVideo.ts'
import toast from 'react-hot-toast'
import {QUERY_KEYS} from '@/queryClient.ts'
import {useQueryClient} from '@tanstack/react-query'
import {ExperiencesHostVideo} from '@/features/experiences-host-video/types.ts'

export const DeviceModal: FC<{onClose: () => void; experiencesHostVideo: ExperiencesHostVideo}> = ({
    onClose,
    experiencesHostVideo
}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const queryClient = useQueryClient()
    const routeParams = useExperiencesHostVideoRouteParams()
    const [isUnknownSelected, setIsUnknownSelected] = useState(false)
    const [search, setSearch] = useState('')
    const updateDeviceIdMutation = useUpdateExperiencesHostVideo({
        params: routeParams,
        mutationOptions: {
            onMutate: variables => {
                queryClient.setQueriesData(
                    {
                        queryKey: [
                            QUERY_KEYS.EXPERIENCES_HOST_VIDEO,
                            routeParams.hostCode,
                            routeParams.hostPassword,
                            routeParams.experiencesHostVideoId,
                            routeParams.taskId
                        ]
                    },
                    {
                        ...experiencesHostVideo,
                        device_id: variables.device_id
                    }
                )
            },
            onError: (_, variables) => {
                toast.error(t('errors:default'))
                queryClient.setQueriesData(
                    {
                        queryKey: [
                            QUERY_KEYS.EXPERIENCES_HOST_VIDEO,
                            routeParams.hostCode,
                            routeParams.hostPassword,
                            routeParams.experiencesHostVideoId,
                            routeParams.taskId
                        ]
                    },
                    experiencesHostVideo
                )
                if (variables.device_id === null) {
                    setIsUnknownSelected(false)
                }
            }
        }
    })
    const devicesQuery = useDevices({
        urlParams: routeParams,
        params: {
            page: 1,
            limit: 50,
            search: search || undefined
        }
    })
    const selectedDevice = devicesQuery.data?.data.find(dataItem => dataItem.id == experiencesHostVideo.device_id)

    const onSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }, 800)

    return (
        <Modal width={360}>
            <StyledModalHeader>
                <ModalTitle>{t('experiences_host_video:ready_to_film_get_help:self_filming_device_title')}</ModalTitle>
            </StyledModalHeader>
            <ModalBody>
                <ModalParagraph>
                    {t('experiences_host_video:ready_to_film_get_help:self_filming_device_description')}
                </ModalParagraph>
                <Flexbox direction="column" gap={4} width="100%">
                    <SelectProvider
                        value={selectedDevice?.model ?? ''}
                        setValue={value => updateDeviceIdMutation.mutate({device_id: Number(value)})}
                    >
                        <ComboboxProvider>
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>
                                    {t('experiences_host_video:ready_to_film_get_help:self_filming_device_label')}
                                </Label>
                                <SelectTrigger
                                    disabled={isUnknownSelected}
                                    fullWidth
                                    value={selectedDevice?.model}
                                    displayValue={value => value}
                                    placeholder={t(
                                        'experiences_host_video:ready_to_film_get_help:self_filming_device_placeholder'
                                    )}
                                />
                            </Flexbox>
                            <StyledSelectPopover flip={false} portal style={{zIndex: theme.zIndex.modal + 1}}>
                                <SelectComboboxInput type="text" onChange={onSearch} />
                                {devicesQuery.isPending ? (
                                    <SelectItem style={{pointerEvents: 'none', textAlign: 'center'}}>
                                        <Spinner size={24} />
                                    </SelectItem>
                                ) : devicesQuery.isError ? (
                                    <SelectItem style={{pointerEvents: 'none', textAlign: 'center'}}>
                                        {t('errors:default')}
                                    </SelectItem>
                                ) : devicesQuery.data.data.length == 0 ? (
                                    <SelectItem style={{pointerEvents: 'none', textAlign: 'center'}}>
                                        {t('commons:no_results_found')}
                                    </SelectItem>
                                ) : (
                                    <SelectComboboxList hidden={false} style={{display: 'block !important'}}>
                                        {devicesQuery.data.data.map(device => (
                                            <SelectItem
                                                value={device.id.toString()}
                                                key={device.id}
                                                render={<ComboboxItem />}
                                            >
                                                {device.model}
                                            </SelectItem>
                                        ))}
                                    </SelectComboboxList>
                                )}
                            </StyledSelectPopover>
                        </ComboboxProvider>
                    </SelectProvider>
                    <Checkbox
                        id="unknown_device"
                        label={t('experiences_host_video:ready_to_film_get_help:self_filming_device_unknown')}
                        onChange={() => {
                            updateDeviceIdMutation.mutate({device_id: null})
                            setIsUnknownSelected(prevState => !prevState)
                        }}
                        checked={isUnknownSelected}
                    />
                    {isUnknownSelected ? (
                        <StyledInfoBox $variant="info">
                            <InfoCircleIcon />
                            {t('experiences_host_video:ready_to_film_get_help:self_filming_device_info')}
                        </StyledInfoBox>
                    ) : (
                        selectedDevice?.model && (
                            <>
                                {selectedDevice?.is_approved ? (
                                    <StyledInfoBox $variant="success">
                                        <CheckCircleBrokenIcon />
                                        {t('experiences_host_video:ready_to_film_get_help:self_filming_device_success')}
                                    </StyledInfoBox>
                                ) : (
                                    <StyledInfoBox $variant="warning">
                                        <AlertTriangleIcon />
                                        {t('experiences_host_video:ready_to_film_get_help:self_filming_device_warning')}
                                    </StyledInfoBox>
                                )}
                            </>
                        )
                    )}
                </Flexbox>
            </ModalBody>
            <ModalFooter>
                <Button fullWidth onClick={onClose} disabled={!isUnknownSelected && !selectedDevice?.model}>
                    {t('commons:close')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
